#ui-datepicker-div {
    margin: 5px 0 0 -20px;
}

.form-type-checkbox {

    .skinned-checkbox-wrapper {
        background: url('../images/bg-checkbox.png') no-repeat;
        @include image2x('../images/bg-checkbox@2x.png', 20px, 40px);
        width: 20px;
        height: 20px;
        cursor: pointer;
        float: left;
        margin-right: 9px;
    }
    input[type="radio"]:checked + .skinned-checkbox-wrapper {
        background-position: 0 -20px;
    }

    label {
        cursor: pointer;
        + a {
            padding-top: 2px;
            display: inline-block;
        }
    }
}

.btn {
    @include all-transitions;
    background: #1D1F21;
    display: block;
    width: 132px;
    height: 38px;
    line-height: 38px;
    border: 1px solid #555759;
    border-radius: 3px;
    text-align: center;
    color: #FFF;
    font-family: $font-light;
    font-size: em(14px);
    text-decoration: none;
    outline: none;
    box-sizing: border-box;
    cursor: pointer;

    &:hover {
        background: #212326;
    }
}

input.btn {
    line-height: 34px;
}

.lightbox {
    background: #292C2F;
    position: absolute;
    border-radius: 3px;
    border-top: 6px solid $primary;
    left: 50%;
    top: 25%;
    z-index: 4;
    display: block;

    &.hidden { display: none; }
    &.visible { display: block; }

    &.confirm {
        width: 280px;
        margin-left: -140px;

        p {
            width: 170px;
            margin: 0 auto;
            font-family: $font-light;
            text-align: center;
            color: #FFF;
            font-size: em(16px);
            margin-bottom: 54px;
            line-height: 18px;
        }

        .buttons {
            width: 240px;
            margin: 0 auto;
            padding-bottom: 23px;
            @include clearfix;

            a {
                width: 115px;

                &.no {
                    float: left;
                }

                &.yes {
                    float: right;
                }
            }
        }
    }

    .close {
        background: url('../images/icon-close-x.png') 50% 50% no-repeat;
        @include image2x('../images/icon-close-x@2x.png', 13px, 13px);
        display: block;
        width: 13px;
        height: 13px;
        text-indent: -9999px;
        position: absolute;
        top: 9px;
        right: 9px;
        padding: 6px;
        border-radius: 3px;

        &:hover {
            background-color: rgba(255, 255, 255, 0.1);
        }
    }

    h2 {
        color: #FFF;
        font-family: $font-thin;
        font-size: em(34px);
        padding: 64px 0 29px 0;
        text-align: center;
    }
}

.alert {
    background: #383A3D;
    border-radius: 3px;
    border-top: 6px solid $primary;
    width: 300px;
    position: fixed;
    left: 50%;
    top: 25%;
    margin-left: -150px;
    z-index: 3;
    display: none;

    .inner {
        position: relative;

        .close {
            background: url('../images/icon-close-x.png') 50% 50% no-repeat;
            @include image2x('../images/icon-close-x@2x.png', 13px, 13px);
            display: block;
            width: 13px;
            height: 13px;
            text-indent: -9999px;
            position: absolute;
            top: 9px;
            right: 9px;
            padding: 6px;
            border-radius: 3px;

            &:hover {
                background-color: rgba(255, 255, 255, 0.1);
            }
        }

        h2 {
            color: #FFF;
            font-family: $font-thin;
            font-size: em(34px);
            padding: 45px 0 34px 0;
            text-align: center;
        }

        .text {
            padding: 40px 20px 20px 20px;
            text-align: center;

            p {
                color: #FFF;
                line-height: 20px;
                margin-bottom: 20px;
                font-size: 16px;
                font-family: $font-light;
            }
        }
    }

    &.confirm {

        .buttons {
            width: 300px;
            margin-left: -20px;
            height: 48px;

            a {
                @include button;
                display: block;
                width: 100px;
                float: left;
                margin-right: 20px;
                text-decoration: none;
                line-height: 50px;

                &.no {
                    margin-left: 40px;
                }
            }
        }
    }
}

.login-box {
    background: #383A3D;
    border-radius: 3px;
    border-top: 6px solid $primary;
    width: 300px;
    position: fixed;
    left: 50%;
    top: 15%;
    margin-left: -160px;
    z-index: 3;
    display: none;

    .sign-up-here {
        text-align: center;
        margin-bottom: 20px;
        color: #FFF;
        font-family: $font-light;
        font-size: em(14px);

        a {
            color: $primary;
            text-decoration: none;
        }
    }

    &.show-reset-password {

        #ripcurl-login .close {
            display: none;
        }

        .panel-reset-password {
            display: block;
        }
    }

    &.show-login .panel-login {
        display: block;
    }

    .panel-forgot-password .form-text {
        border-radius: 3px;
    }

    .panel {
        display: none;
    }

    .inner {
        position: relative;
        padding: 0 20px 1px;

        .separator {
            position: relative;
            border-bottom: 1px solid #45474A;
            width: 300px;
            height: 11px;
            margin: 0 0 27px -20px;

            span {
                background: #383A3D;
                padding: 0 20px;
                position: absolute;
                top: 4px;
                left: 120px;
                font-family: $font-light;
                color: #FFF;
                text-transform: uppercase;
                font-size: em(16px);
            }
        }

        .close {
            background: url('../images/icon-close-x.png') 50% 50% no-repeat;
            @include image2x('../images/icon-close-x@2x.png', 13px, 13px);
            display: block;
            width: 13px;
            height: 13px;
            text-indent: -9999px;
            position: absolute;
            top: 9px;
            right: 9px;
            padding: 6px;
            border-radius: 3px;

            &:hover {
                background-color: rgba(255, 255, 255, 0.1);
            }
        }

        h2 {
            color: #FFF;
            font-family: $font-thin;
            font-size: em(34px);
            padding: 45px 0 34px 0;
            text-align: center;
        }

        .facebook-login {
            background: url('../images/btn-bg-facebook-login-small.png') no-repeat;
            @include image2x('../images/btn-bg-facebook-login-small@2x.png', 260px, 100px);
            display: block;
            color: #FFF;
            width: 260px;
            height: 50px;
            line-height: 50px;
            text-transform: uppercase;
            font-family: $font-light;
            text-decoration: none;
            text-indent: 60px;
            margin-bottom: 24px;
            font-size: em(16px);

            &:hover {
                background-position: 0px -50px;
            }
        }

        label.error {
            display: none !important; // overwrite inline style
        }

        .form-items input:first-child {
            border-top-left-radius: 3px;
            border-top-right-radius: 3px;
        }

        .form-items input:last-child {
            border-bottom: none;
            border-bottom-left-radius: 3px;
            border-bottom-right-radius: 3px;
        }

        .message-text {
            color: #FFF;
            text-align: center;
            font-family: $font-light;
            font-size: em(14px);
            margin-bottom: 9px;
            display: none;

            &.error {
                color: $primary;
            }
        }

        input:-webkit-autofill {
            background: #FFF !important;
        }

        .form-text {
            background: #FFF;
            width: 230px;
            height: 51px;
            outline: none;
            padding: 0 15px;
            border: none;
            font-size: em(16px);
            font-family: $font-light;
            border-bottom: 1px solid #BABABA;

            &.error {
                background: #F9D1CC;
                border-bottom-color: #F4ADA3;
            }
        }

        .remember-me {
            @include clearfix;
            margin: 18px 0 0 48px;
            font-size: em(16px);
            font-family: $font-light;
            color: #FFF;
            text-align: center;
        }

        .form-submit {
            @include button;
            width: 260px;
            margin: 18px 0 21px 0;
        }

        .bottom {
            background: #26282B;
            width: 298px;
            height: 48px;
            border-radius: 0 0 3px 3px;
            font-size: em(16px);
            font-family: $font-light;
            text-align: center;
            line-height: 48px;
            margin-left: -19px;
            color: #FFF;

            a {
                color: $primary;
                text-decoration: none;

                &:hover {
                    text-decoration: underline;
                }
            }

            &.terms {
                font-size: em(12px);
            }
        }
    }
}
// download app box on watch page
.download-box {
    padding-bottom: 28px;
    text-align: center;
    color: #ffffff;

    a {
        color: #3d86C8;

        &:hover {
            color: #6093C2;
        }
    }

    .buttons {
        margin-bottom: 14px;

        a {
            color: #ffffff;
            border-bottom: 2px solid #486e91;
            border-radius: 3px;
            display: block;
            font: em(14px) $font-light;
            text-decoration: none;
            margin: 10px 0 0 0px;
            padding: 19px 9px 14px 56px;
            width: 190px;

            &.mac-download {
                background: #3d86C8 url('../images/icon-mac-logo.png') no-repeat 19px 8px;
                @include image2x('../images/icon-mac-logo@2x.png', 26px, 32px);
            }

            &.windows-download {
                background: #3d86C8 url('../images/icon-windows-logo.png') no-repeat 17px 8px;
                @include image2x('../images/icon-windows-logo@2x.png', 32px, 32px);
            }

            &:hover {
                background-color: #6093C2;
            }
        }
    }
}
// Person
.person {
    @include all-transitions;
    float: left;
    width: 220px;
    height: 173px;
    padding: 16px 0 0 0;
    border-radius: 5px;
    margin-right: 17px;
    border: 1px solid transparent;

    &:hover {
        background: #2E3033;
        border: 1px solid #212325;
        border-bottom-color: #1C1E20;
    }

    &.pending {
        @include all-transitions;

        .picture .pending {
            display: block;
        }
    }

    &.is-self {
        text-align: center;

        .bottom .profile-info {
            float: none;
        }
    }

    .picture {
        position: relative;
        width: 108px;
        height: 108px;
        margin: 0 auto 12px auto;

        .pending {
            @include all-transitions;
            position: absolute;
            top: 4px;
            left: 4px;
            display: none;
            width: 100px;
            height: 100px;
            font-family: $font-light;
            text-align: center;
            color: #FFF;
            line-height: 105px;
            font-size: em(16px);
            background: rgba(0, 0, 0, 0.5);
            border-radius: 100px;
            text-transform: uppercase;


            a {
                display: block;
                width: 100px;
                height: 100px;
                font-family: $font-light;
                text-align: center;
                color: #FFF;
                line-height: 105px;
                font-size: em(16px);
                text-decoration: none;
            }
        }

        img {
            width: 100px;
            height: 100px;
            border: 4px solid #393B3E;
            border-radius: 100px;
        }

        .pro {
            position: absolute;
            top: 81px;
            left: 73px;
            background: url('../images/icon-probadge.png') no-repeat;
            @include image2x('../images/icon-probadge@2x.png', 25px, 19px);
            width: 25px;
            height: 19px;
        }
    }

    .bottom {
        @include clearfix;

        .follow-user-toggle {
            float: left;
            width: 34px;
            margin: 0 10px 0 20px;
        }

        .follow-toggle {

            &.following {
                background: url('../images/icon-already-following-user.png') no-repeat;
                @include image2x('../images/icon-already-following-user@2x.png', 22px, 22px);
                width: 22px;
                height: 22px;
                margin-left: 8px;
                margin-top: -2px;
            }

            &.pending {
                background: url('../images/icon-user-pending.png') no-repeat;
                @include image2x('../images/icon-user-pending@2x.png', 34px, 34px);
                width: 34px;
                height: 34px;
            }

            &.not-following {
                background: url('../images/icon-follow-user.png') no-repeat;
                @include image2x('../images/icon-follow-user@2x.png', 34px, 34px);
                width: 34px;
                height: 34px;
            }

            a {
                display: block;
                width: 34px;
                height: 34px;
                text-indent: -9999px;
            }
        }

        .profile-info {
            float: left;

            h2 {
                font-family: $font-light;
                font-size: em(18px);
                margin-bottom: 2px;
                color: #FFF;

                a {
                    color: #FFF;
                    text-decoration: none;
                }
            }

            .location {
                background: url('../images/icon-location-pin-small.png') no-repeat;
                @include image2x('../images/icon-location-pin-small@2x.png', 10px, 14px);
                color: #BCBDBD;
                display: inline-block;
                height: 12px;
                padding: 2px 0 0 14px;
                font-family: $font-light;
                font-size: em(12px);
            }
        }
    }
}

// Invite screen
.find-surfers {
    display: none;

    .top {
        border-bottom: none;
        margin-bottom: 33px;
        padding-bottom: 0;
    }

    .tab-section {
        display: none;

        &.active {
            display: block;
        }
    }

    .no-friends {

        p {
            color: #BEBEBE;
            font-size: em(16px);
            margin-bottom: 15px;
            font-family: $font-light;
        }

        .btn {
            width: auto;
            display: inline-block;
            padding: 0 15px;
        }
    }

    #invite-friends-input {
        height: 40px;

        ul {
            background-color: #FFFFFF;
            width: 420px;
            border-radius: 3px;
            padding: 2px 2px;
            margin-left: 3px;
            box-sizing: border-box;
            float: left;
            min-height: 40px;

            li {
                display: inline-block;

                &.tag {
                    background-color: #6297c8;
                    border-radius: 3px;
                    padding: 9px 12px 9px 13px;
                    position: relative;
                    margin-bottom: 3px;
                    margin-right: 3px;

                    .text {
                        display: block;
                        color: #FFFFFF;
                        font: 14px Arial;
                        padding-right: 22px;
                    }

                    .delete {
                        position: absolute;
                        right: 12px;
                        top: 8px;
                        background: url('../images/icon-tag-close.png') no-repeat;
                        @include image2x('../images/icon-tag-close@2x.png', 18px, 18px);
                        height: 18px;
                        width: 18px;

                        a {
                            display: block;
                            height: 100%;
                            width: 100%;
                        }
                    }
                }

                &.new-item {
                    height: 100%;
                    width: 230px;

                    input {
                        border: 0;
                        margin: 0;
                        color: #b8b8b8;
                        font: 14px Arial;
                        outline: none;
                        width: 100%;
                        padding: 10px;
                        box-sizing: border-box;
                    }
                }
            }
        }

        .send {
            border: 1px solid #4F5153;
            background-color: #1F2124;
            color: #ffffff;
            font: em(14px) $font-light;
            margin: 0 0 0 10px;
            padding: 13px 51px 10px 51px;
            border-radius: 3px;
            cursor: pointer;
            outline: none;
        }
    }

    .results {
        display: none;
        margin-top: 20px;
        clear: both;
        padding-top: 20px;

        @include clearfix;

        p {
            color: #BCBDBD;
            font-size: em(16px);
            font-family: $font-light;
            margin-bottom: 20px;
        }

        .email {
            background: #35373B url('../images/icon-email-invite-success.png') 12px 6px no-repeat;
            @include image2x('../images/icon-email-invite-success@2x.png', 22px, 22px);
            border-radius: 3px;
            height: 34px;
            line-height: 34px;
            color: #FFF;
            padding: 0 15px 0 43px;
            margin-bottom: 3px;
            display: table;
        }
    }

    #find-surfers-input {
        .text {
            color: #333;
            border-radius: 3px;
            border: none;
            width: 267px;
            height: 36px;
            padding-left: 13px;
            outline: none;
            cursor: text;
            float:  left;

            &.invalid{
                color:#ff0000;
            }
        }
        .search {
             display: inline-block;
             margin: 0 0 0 10px;
        }
    }
}

// Invite follow tabs
.follow-tabs {
    background: none;
    border-bottom: 1px solid $primary;
    height: 26px;
    margin-bottom: 40px;

    ul {
        padding-left: 0;

        li {
            font-size: em(16px, $base-font-size);
            margin-right: 28px;
            float: left;

            a {
                font-family: $font-light;
                color: #BCBDBD;
                display: block;
                height: 26px;
                line-height: normal;
                border: none;
                text-decoration: none;
            }

            &.active a {
                background: url('../images/icon-red-tab-triangle.png') bottom center no-repeat;
                @include image2x('../images/icon-red-tab-triangle@2x.png', 14px, 7px);
                color: #FFF;
            }
        }
    }
}

.zoom-controls {
    background: #232528 url('../images/bg-map-zoom-divider.png') 50% 50% no-repeat;
    border-radius: 3px;
    width: 29px;
    height: 57px;

    a {
        display: block;
        text-indent: -9999px;
        width: 29px;
        height: 28px;

        &.in {
            background: url('../images/icon-map-zoom-plus.png') 50% 50% no-repeat;
            @include image2x('../images/icon-map-zoom-plus@2x.png', 11px, 11px);
            margin-bottom: 1px;
        }

        &.out {
            background: url('../images/icon-map-zoom-minus.png') 50% 50% no-repeat;
            @include image2x('../images/icon-map-zoom-minus@2x.png', 11px, 11px);
            height: 28px;
        }
    }
}

.offscreen {
    position: absolute;
    //left: -9999px;
    //top: -9999px;
    visibility: hidden;
    width: 320px;
    height: 320px;
}

#unsupported-box #ripcurl-unsupported {

    p.white-text {
        color: #bebebe;
        font: 16px $font-light;
        text-align: center;

        &.spacer {
            padding-bottom: 20px;
        }
    }

    .browser-details {
        color: #FFFFFF;
    }
}

.ellipsis {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;  // IE 6+, FF 7+, Op 11+, Saf 1.3+, Chr 1+
    -o-text-overflow: ellipsis;  // for Opera 9 & 10
}

#login-buttons {

    .fb-login-btn {
        background: url('../images/btn-bg-facebook-login.png') no-repeat;
        @include image2x('../images/btn-bg-facebook-login@2x.png', 311px, 60px);
        display: block;
        width: 217px;
        height: 36px;
        padding: 24px 0 0 94px;
        color: #FFF;
        text-transform: uppercase;
        font-family: $font-light;
        text-decoration: none;
        font-size: em(16px);
        margin-bottom: 10px;
    }

    .ripcurl-signup-btn {
        background: rgba(0, 0, 0, 0.3);
        display: block;
        width: 310px;
        height: 50px;
        color: #FFF;
        font-size: em(14px);
        text-transform: uppercase;
        font-family: $font-light;
        text-decoration: none;
        border-radius: 3px;
        text-align: center;
        line-height: 54px;
        margin-bottom: 10px;
    }
}

#global-messages {
    position: absolute;
    width: 100%;
    z-index: 99;
    left: 80px;

    .message {
        display: none;
        height: 80px;
        color: #FFF;
        line-height: 82px;
        font-family: $font-light;
        font-size: em(18px);
        text-indent: 62px;

        &.error {
            background: #AC1500 url('../images/icon-message-alert.png') 19px 24px no-repeat;
            @include image2x('../images/icon-message-alert@2x.png', 32px, 32px);
        }

        &.success {
            background: #3D86C8 url('../images/icon-message-tick.png') 19px 24px no-repeat;
            @include image2x('../images/icon-message-tick@2x.png', 32px, 32px);
        }
    }
}

#surf-photo-lightbox {
    position: fixed;
    z-index: 5;
    width: 100%;
    height: 100%;
    left: 80px;
    display: none;

    &.portrait {
        background: #1C1D1F;

        .inner {
            position: fixed;
            width: 600px;
            left: 50%;
            margin-left: -300px;

            .overlay {
                width: 600px;
            }
        }
    }

    .inner {
        position: relative;

        .close {
            position: fixed;
            top: 0;
            right: 0;
            z-index: 2;

            a {
                @include all-transitions;
                background: rgba(0, 0, 0, 0.2) url('../images/icon-close-panel.png') 50% 50% no-repeat;
                @include image2x('../images/icon-close-panel@2x.png', 14px, 14px);
                display: block;
                width: 40px;
                height: 40px;
                text-indent: -9999px;

                &:hover {
                    background-color: rgba(0, 0, 0, 0.6);
                }
            }

        }

        .overlay {
            background: url('../images/bg-image-tile-overlay.png') repeat;
            position: fixed;
            width: 100%;
            height: 100%;
        }

        img {
            width: 100%;
        }
    }
}

.lazyLoad {
    width: 100px;
    margin: 20px auto;
}
/* Modifiers */
.white {
    color: #fff;
}
