/****************************
 
 ____                    ____                   ___      
/\  _`\   __            /\  _`\                /\_ \     
\ \ \L\ \/\_\  _____    \ \ \/\_\  __  __  _ __\//\ \    
 \ \ ,  /\/\ \/\ '__`\   \ \ \/_/_/\ \/\ \/\`'__\\ \ \   
  \ \ \\ \\ \ \ \ \L\ \   \ \ \L\ \ \ \_\ \ \ \/  \_\ \_ 
   \ \_\ \_\ \_\ \ ,__/    \ \____/\ \____/\ \_\  /\____\
    \/_/\/ /\/_/\ \ \/      \/___/  \/___/  \/_/  \/____/
                 \ \_\                                   
                  \/_/

*****************************/

@import "base";

.console-buttons {
	position: absolute;
	top: 20px;
	right: 20px;
	z-index: 3;
	
	a {
    	background: #DF1B00;
    	display: block;
        color: #FFF;
        padding: 8px;
        border-radius: 3px;
        text-decoration: none;
        margin-bottom: 4px;
        font-size: em(14px);
        border: 1px solid rgba(255, 255, 255, 0.3);
        
        &:hover {
            background: #C81800;
        }
	} 
}

.log-messages {
    background: #000;
	padding: 20px;
	color: #32d600;
	z-index: 3;
	width: auto;
    display: none;

	h2 {
		font-weight: bold;
		font-size: 16px;
		margin-bottom: 10px;
	}

	li {
		color: green;
		padding-bottom: 5px;
		border-bottom: 1px dashed #CCC;
		margin-bottom: 5px;
		line-height: 18px;
		word-wrap: break-word;
	}
}