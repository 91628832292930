#app {
    position: relative;
    width: 100%;
    height: 100%;

    &.page-surf {
        overflow-y: hidden;
        overflow-x: hidden;
    }

    &.page-profile {
        height: calc(100vh - 15px);

        @include breakpoint($tabletLandscape) {
            #site-menu {
                position: fixed;
            }

            position: static;
            height: auto;
        }
    }

    .view {
        width: 100%;
        height: 100%;

        &#view-profile {
            @include breakpoint($tabletLandscape) {
                // height: auto;
            }
        }

        &#view-watch,
        &#view-settings {
            width: auto;
            height: auto;
        }

        &#view-clubs {
            width: auto;

            #club-list {
                height: 100%;
            }
        }
    }

    .logbook {
        background: #232528 url('../images/bg-logbook.png') repeat-y;
        position: relative;
        height: 100%;
        float: left;
        z-index: 2;

        .logbook-title {
            padding: 10px 20px 29px 20px;

            .value {
                font-size: em(56px);
                font-family: $font-thin;
                color: #FFF;
            }

            .label {
                font-size: em(22px);
                font-family: $font-thin;
                color: #898A8B;
                text-transform: lowercase;
            }
        }

        .wrapper {
            width: 320px;
        }

        .feed-panel {
            display: none;
            outline: none;
        }

        #feed-no-surfs {
            padding-left: 20px;

            h2 {
                color: #FFF;
                font-family: $font-thin;
                font-size: 20px;
                margin-bottom: 19px;
            }

            p {
                color: #A7A8A9;
                font-size: 16px;
                font-family: $font-thin;
                width: 215px;
                line-height: 20px;

                a {
                    color: #FFF;
                }
            }
        }

        .header {
            height: 25px;
            border-bottom: 1px solid #4F5153;
            padding: 14px 23px 0 21px;
            margin-bottom: 24px;

            h2 {
                font-family: $font-light;
                color: #666768;
                font-size: em(18px);
                float: left;
            }

            a {
                background: url('../images/icon-close-arrow.png') no-repeat;
                @include image2x('../images/icon-close-arrow@2x.png', 7px, 12px);
                display: block;
                float: right;
                width: 7px;
                height: 12px;
                text-indent: -9999px;
            }
        }

        .tabs {
            height: 48px;
            border-bottom: 1px solid $primary;

            ul {
                @include clearfix;

                li {
                    list-style: none;
                    float: left;
                    height: 48px;

                    &.active a {
                        background: url('../images/icon-red-arrow-up.png') bottom center no-repeat;
                        @include image2x('../images/icon-red-arrow-up@2x.png', 16px, 8px);
                        color: #FFF;
                    }

                    a {
                        font-family: $font-light;
                        display: block;
                        height: 29px;
                        padding-top: 19px;
                        font-size: em(14px);
                        color: #BEBEBE;
                        text-decoration: none;
                        text-align: center;

                        &:hover {
                            color: #FFF;
                        }
                    }

                    &.t-my-surfs {
                        width: 117px;
                    }

                    &.t-clubs {
                        width: 89px;
                    }

                    &.t-following {
                        width: 98px;
                    }
                }
            }
        }

        .filter {
            margin-bottom: 27px;
            position: relative;

            #club-feed-select {
                @include all-transitions;
                position: relative;

                .button {
                    display: block;
                    background: transparent url('../images/icon-select-down-white.png') top 22px right 17px no-repeat;
                    @include image2x('../images/icon-select-down-white@2x.png', 13px, 6px);
                    border-radius: 0;
                    font-size: em(14px);
                    border-bottom: 1px solid #4F5153;
                    text-indent: 17px;
                    font-family: $font-light;
                    height: 50px;
                    color: #FFF;
                    line-height: 52px;
                    cursor: pointer;
                    color: #B8B8B8;

                    &:hover {
                        color: #FFF;
                    }
                }

                .options {
                    background: rgba(28, 29, 31, 0.95);
                    border-bottom-left-radius: 3px;
                    border-bottom-right-radius: 3px;
                    position: absolute;
                    width: 100%;
                    z-index: 2;
                    display: none;

                    .option {
                        @include all-transitions;
                        font-family: $font-light;
                        font-size: em(14px);
                        color: #FFF;
                        padding: 5px;
                        cursor: pointer;
                        height: 20px;
                        line-height: 20px;
                        text-indent: 13px;

                        &:last-child {
                            border-bottom-left-radius: 3px;
                            border-bottom-right-radius: 3px;
                        }

                        &:hover {
                            background: #3D86C8;
                        }
                    }
                }
            }

            .close {
                @include all-transitions;
                position: absolute;
                background: url('../images/icon-clear-search.png') no-repeat;
                @include image2x('../images/icon-clear-search@2x.png', 18px, 18px);
                width: 18px;
                height: 18px;
                display: none;
                text-indent: -9999px;
                top: 19px;
                right: 20px;
                opacity: 0.6;

                &:hover {
                    opacity: 1;
                }
            }

            input {
                @include all-transitions;
                background: #1F2124 url('../images/icon-filter.png') 20px 19px no-repeat;
                @include image2x('../images/icon-filter@2x.png', 15px, 15px);
                width: 254px;
                height: 54px;
                padding: 0 20px 0 46px;
                border: none;
                outline: none;
                color: #BEBEBE;
                font-size: em(14px);
                font-family: $font-light;

                &:focus {
                    background: #36383C url('../images/icon-filter-focus.png') 20px 19px no-repeat;
                    @include image2x('../images/icon-filter-focus@2x.png', 15px, 15px);
                }
            }
        }

        .timeline {
            background: url('../images/bg-timeline-line.png') 40px 0px repeat-y;
            height: auto;
            @include clearfix;

            .sort {
                float: right;
                position: relative;
                padding-bottom: 20px;

                .toggle {
                    background: url('../images/icon-triangle-down.png') center right no-repeat;
                    @include image2x('../images/icon-triangle-down@2x.png', 8px, 4px);
                    color: #737475;
                    font-family: $font-light;
                    font-size: em(12px);
                    padding-right: 11px;
                    text-transform: uppercase;
                    text-decoration: none;
                    margin-right: 28px;
                }

                .dropdown {
                    background: rgba(28, 29, 31, 0.95);
                    border-radius: 3px;
                    width: 119px;
                    position: absolute;
                    top: 19px;
                    right: 20px;
                    z-index: 1;
                    display: none;

                    li {

                        a {
                            display: block;
                            height: 28px;
                            font-family: $font-light;
                            font-size: em(12px);
                            border-bottom: 1px solid #181818;
                            color: #FFF;
                            border-radius: 3px 3px 0 0;
                            line-height: 30px;
                            text-decoration: none;
                            padding-left: 10px;
                        }

                        &:last-child a {
                            border-bottom: none;
                            border-radius: 0 0 3px 3px;
                        }

                        &.active a {
                            background: url('../images/icon-tick-blue.png') 6px 9px no-repeat;
                            @include image2x('../images/icon-tick-blue@2x.png', 10px, 9px);
                            padding-left: 21px;
                        }

                        &:hover {
                            background-color: #36383C;
                        }
                    }
                }
            }

            .days {

                #feedsLoading {
                    cursor: wait;
                    background-color: rgba(0,0,0,0);
                    opacity: .8;
                    border-radius: 50px;
                    margin: 5% auto;
                    animation: loading .5s infinite linear;
                    -moz-animation: loading .5s infinite linear;
                    -webkit-animation: loading .5s infinite linear;
                }

                .spin-1{
                    border: 5px solid #fff;
                    border-top: 5px solid rgba(0,0,0,0);
                    border-left: 5px solid rgba(0,0,0,0);
                    width: 30px;
                    height: 30px;
                }
            }

            .day {
                width: 319px;
                margin-bottom: 30px;

                .date {
                    background: url('../images/icon-timline-day-indicator.png') no-repeat;
                    @include image2x('../images/icon-timline-day-indicator@2x.png', 13px, 13px);
                    text-transform: uppercase;
                    color: #AAAAAB;
                    padding-left: 21px;
                    margin: 0 0 25px 34px;
                    font-family: $font-light;
                    font-size: em(12px);
                }
            }

            .surf {
                clear: both;
                @include clearfix;
                @include all-transitions;
                position: relative;
                background: #27292C url('../images/bg-timeline-line-surf.png') 39px 0 repeat-y;
                border-left: 1px solid #1F2124;
                border-right: 1px solid #1F2124;
                border-radius: 3px;
                margin-bottom: 10px;
                padding: 0 20px 0 16px;
                cursor: pointer;

                &:hover,
                &.active,
                &.first {
                    background-color: #36383C;
                }

                &.has-photo {

                    .bottom {
                        margin-left: -55px;
                    }

                    .image {
                        float: left;
                        margin-right: 16px;

                        img {
                            width: 39px;
                            height: 31px;
                            border-radius: 3px;
                        }
                    }

                    .surf-star-rating,
                    .top-speed {
                        margin-top: 8px;
                    }
                }

                .static-map {
                    position: absolute;
                    top: 6px;
                    left: 17px;
                    width: 44px;
                    height: 44px;
                    border-radius: 100px;
                    border: 2px solid #4A4C50;
                    background-size: cover;
                    background-position: center;
                }

                .surf-info {
                    padding: 14px 14px 13px 60px;
                    float: left;

                    .bottom {
                        @include clearfix;
                        margin-top: 12px;
                    }

                    h3 {
                        font-family: $font-light;
                        font-size: em(16px);
                        color: #FFF;
                        margin-bottom: 2px;
                        word-wrap: break-word;
                        width: 155px;
                        vertical-align: top;

                        .pro-badge {
                            background: url('../images/icon-probadge-feed.png') no-repeat;
                            @include image2x('../images/icon-probadge-feed@2x.png', 25px, 19px);
                            display: inline-block;
                            width: 25px;
                            height: 19px;
                            margin-left: 6px;
                        }
                    }

                    .location {
                        background: url('../images/icon-surf.png') no-repeat;
                        @include image2x('../images/icon-surf-2x.png', 17px, 14px);
                        background-position: left 2px;
                        min-height: 14px;
                        font-size: em(11px);
                        color: #BEBEBE;
                        padding: 4px 0 0 18px;
                        width: 130px;
                        line-height: 13px;
                        &.activity {
                            background: url('../images/icon-location-pin-small.png') no-repeat;
                            @include image2x('../images/icon-location-pin-small@2x.png', 10px, 14px);
                            padding: 4px 0 0 16px;
                        }
                    }

                    .surf-star-rating {
                        float: left;
                        margin-right: 13px;
                    }

                    .top-speed {
                        background: url('../images/icon-lightning-bolt-small.png') no-repeat;
                        @include image2x('../images/icon-lightning-bolt-small@2x.png', 8px, 13px);
                        padding-left: 12px;
                        color: #FFF;
                        font-size: em(16px);
                        margin-bottom: 7px;
                        font-family: $font-light;
                        float: left;

                        .measurement {
                            color: #c0c0c1;
                            font-size: 11px;
                            font-family: Arial;
                        }
                    }
                }

                .num-waves {
                    position: absolute;
                    top: 20px;
                    right: 20px;

                    .label {
                        margin: 0 7px 0 10px;
                        color: #C0C0C1;
                        font-size: em(11px);
                        text-align: center;
                    }

                    .number {
                        font-family: $font-thin;
                        font-size: em(46px);
                        color: #FFF;
                        text-align: center;
                    }
                }
            }
        }
    }

    .surf-star-rating {
        background: url('../images/sprite-star-rating.png') no-repeat;
        @include image2x('../images/sprite-star-rating@2x.png', 73px, 143px);
        width: 73px;
        height: 13px;
        text-indent: -9999px;

        &.half-star {
            background-position: 0px -13px;
        }

        &.one-star {
            background-position: 0px -26px;
        }

        &.one-star-half {
            background-position: 0px -39px;
        }

        &.two-star {
            background-position: 0px -52px;
        }

        &.two-star-half {
            background-position: 0px -65px;
        }

        &.three-star {
            background-position: 0px -78px;
        }

        &.three-star-half {
            background-position: 0px -91px;
        }

        &.four-star {
            background-position: 0px -104px;
        }

        &.four-star-half {
            background-position: 0px -117px;
        }

        &.five-star {
            background-position: 0px -130px;
        }
    }

    // Main Menu
    #site-menu {
        background: #2A2C2F;
        width: 80px;
        height: 100%;
        float: left;
        position: relative;
        z-index: 3;

        #logo {
            padding: 34px 0 0 18px;
            margin-bottom: 30px;

            a {
                background: url('../images/ripcurl-logo.png') no-repeat;
                @include image2x('../images/ripcurl-logo@2x.png', 41px, 21px);
                display: block;
                width: 41px;
                height: 21px;
                text-indent: -9999px;
            }
        }

        nav {
            display: none;
            width: 42px;
            margin: 0 auto;

            ul {
                margin: 0;

                li {
                    list-style: none;
                    margin-bottom: 34px;

                    a {
                        color: #979798;
                        text-decoration: none;
                        font-size: em(11px);

                        .icon {
                            background: url('../images/sprite-site-menu-icons.png') no-repeat;
                            @include image2x('../images/sprite-site-menu-icons@2x.png', 96px, 193px);
                            display: block;
                            width: 32px;
                            height: 32px;
                            margin: 0 auto 7px auto;
                            border-radius: 50%;
                        }

                        .label {
                            display: block;
                            text-align: center;
                        }

                        &:hover {
                            color: #FFF;
                        }
                    }

                    .count {
                        background: #3D86C8;
                        position: absolute;
                        top: 9px;
                        right: -4px;
                        display: none;
                        height: 15px;
                        font-size: em(12px);
                        line-height: 17px;
                        padding: 0 4px;
                        border-radius: 3px;
                        color: #FFF;
                    }

                    &.active a {
                        color: #FFF;
                    }

                    &.n-logbook {

                        .icon {
                            background-position: 0px 0px;
                        }

                        a:hover .icon {
                            background-position: -32px 0px;
                        }

                        &.active .icon,
                        &.active a:hover .icon {
                            background-position: -64px 0px;
                        }
                    }

                    &.n-profile {
                        position: relative;
                        background: none;
                        text-indent: 0;

                        .icon {
                            background-position: 0px -32px;
                        }

                        a:hover .icon {
                            background-position: -32px -32px;
                        }

                        &.active .icon,
                        &.active a:hover .icon {
                            background-position: -64px -32px;
                        }
                    }

                    &.n-clubs {
                        position: relative;

                        .icon {
                            background-position: 0px -64px;
                        }

                        a:hover .icon {
                            background-position: -32px -64px;
                        }

                        &.active .icon,
                        &.active a:hover .icon {
                            background-position: -64px -64px;
                        }
                    }

                    &.n-watch {

                        .icon {
                            background-position: 0px -96px;
                        }

                        a:hover .icon {
                            background-position: -32px -96px;
                        }

                        &.active .icon,
                        &.active a:hover .icon {
                            background-position: -64px -96px;
                        }
                    }

                    &.n-settings {

                        .icon {
                            background-position: 0px -128px;
                        }

                        a:hover .icon {
                            background-position: -32px -128px;
                        }

                        &.active .icon,
                        &.active a:hover .icon {
                            background-position: -64px -128px;
                        }
                    }

                    &.n-logout {

                        .icon {
                            background-position: 0px -160px;
                        }

                        a:hover .icon {
                            background-position: -32px -160px;
                        }

                        &.active .icon,
                        &.active a:hover .icon {
                            background-position: -64px -160px;
                        }
                    }
                }
            }
        }
    }

    // Logbook
    #view-surf {

        &.no-surfs {
            display: none;
            background: url('../images/bg-no-surfs.jpg') top right no-repeat;
            background-size: cover;
            width: 100%;
            height: 100%;
        }

        #view-surf-view {
            width: 100%;
            height: 100%;
        }

        // Google Map
        #map {
            position: relative;
            height: 100%;
            float: left;

            #map-controls {
                position: absolute;
                top: 25px;
                left: 20px;
                z-index: 1;

                .map-options {
                    position: absolute;
                    top: 0;
                    left: 39px;

                    a {
                        display: block;

                        &.toggle {
                            background: #232528 url('../images/bg-map-control-view-arrow.png') 62px 22px no-repeat;
                            @include image2x('../images/bg-map-control-view-arrow@2x.png', 5px, 5px);
                            color: #FFF;
                            font-family: $font-light;
                            font-size: em(14px);
                            width: 59px;
                            height: 29px;
                            line-height: 32px;
                            border-radius: 3px;
                            padding-left: 10px;
                            text-decoration: none;
                        }
                    }

                    ul {
                        background: #232528;
                        display: none;
                        position: absolute;
                        top: 0;
                        width: 99px;
                        border-radius: 3px;

                        li {
                            border-radius: 3px;

                            a {
                                display: block;
                                height: 28px;
                                font-family: $font-light;
                                font-size: em(12px);
                                border-bottom: 1px solid #181818;
                                color: #FFF;
                                border-radius: 3px 3px 0 0;
                                line-height: 30px;
                                text-decoration: none;
                                padding-left: 10px;
                            }

                            &:hover {
                                background-color: #36383C;
                            }

                            &:last-child a {
                                border-bottom: none;
                                border-radius: 0 0 3px 3px;
                            }

                            &.active a {
                                background: url('../images/icon-tick-blue.png') 6px 9px no-repeat;
                                @include image2x('../images/icon-tick-blue@2x.png', 10px, 9px);
                                padding-left: 21px;
                            }


                        }
                    }
                }
            }

            #surf-stats {
                display: none;

                .inner {
                    background: rgba(0, 0, 0, 0.4);
                    min-width: 634px;
                    height: 68px;
                    border-radius: 3px;
                    position: absolute;
                    bottom: 30px;
                    left: 30px;
                    z-index: 1;
                    padding: 5px 10px 5px 5px;
                    display: none;

                    @include breakpoint($tabletPortrait) {
                        width: 270px;
                        min-width: 284px;
                        height: 135px;
                    }
                }

                .buttons {
                    float: left;

                    @include breakpoint($tabletPortrait) {
                        display: none;
                    }

                    a {
                        @include all-transitions;
                        display: block;
                        text-indent: -9999px;
                        width: 60px;
                        height: 68px;
                        float: left;

                        &:first-child {
                            border-top-left-radius: 3px;
                            border-bottom-left-radius: 3px;
                        }

                        &.play {
                            background: #36383C url('../images/icon-play.png') 50% 50% no-repeat;
                            @include image2x('../images/icon-play@2x.png', 11px, 15px);
                            margin-right: 2px;

                            &:hover {
                                background-color: $primary;
                            }
                        }

                        &.crop {
                            background: #36383C url('../images/icon-crop-surf.png') 50% 50% no-repeat;
                            @include image2x('../images/icon-crop-surf@2x.png', 19px, 20px);
                            /*border-top-right-radius: 3px;
                            border-bottom-right-radius: 3px;*/
                            border-radius: 3px;
                        }

                        &:hover {
                            background-color: #404246;
                        }
                    }
                }

                .stats {
                    float: left;
                    height: 51px;
                    padding: 17px 0 0 34px;

                    @include breakpoint($tabletPortrait) {
                        height: auto;
                        width: 270px;
                        padding-left: 20px;
                    }

                    .stat {
                        float: left;
                        text-align: center;

                        .value {
                            font-family: $font-thin;
                            font-size: em(30px);
                            color: #FFF;
                            margin-bottom: 2px;

                            .loader {
                                margin-bottom: 15px;
                                height: 15px;
                            }

                            span {
                                font-family: $font-light;
                                font-size: 12px;
                                display: inline-block;
                                padding-left: 3px;
                            }
                        }

                        .label {
                            color: #BEBEBE;
                            font-size: em(12px);
                        }

                        &.waves {
                            margin-right: 48px;
                        }

                        &.longest {
                            margin-right: 38px;
                        }

                        &.speed {
                            margin-right: 30px;
                        }

                        &.paddled {
                            margin-right: 35px;
                        }

                        &.time {
                            margin-right: 48px;
                        }

                        @include breakpoint($tabletPortrait) {
                            margin-right: 20px !important;
                            margin-bottom: 15px;

                            &.speed,
                            &.distance {
                                margin-right: 0;
                            }

                            &.longest,
                            &.paddled,
                            &.distance {
                                // float: right;
                            }
                        }
                    }
                }
                &.isActivity {
                    .inner {
                        min-width: 300px;
                    }
                    .waves, .longest, .paddled, .buttons .play {
                        display: none !important;
                    }
                }
            }

            #map-canvas {
                width: 100%;
                height: 100%;
            }

            #replay-surf,
            #crop-surf {
                position: absolute;
                background: url('../images/bg-replay-surf.png') repeat-x;
                width: 100%;
                height: 150px;
                bottom: -150px;
                z-index: 1;
                display: none;

                #replay-graph,
                #crop-graph {
                    width: 950px;
                    height: 100px;
                    margin: 0 auto;
                    position: relative;
                    border-left: 1px solid rgba(255, 255, 255, 0.2);
                    border-right: 1px solid rgba(255, 255, 255, 0.2);

                    canvas {
                        margin-top: 7px;
                    }

                    .scrubber {
                        background: #FFF;
                        position: absolute;
                        width: 1px;
                        height: 100px;
                        border-left: 1px solid rgba(0, 0, 0, 0.75);
                        border-right: 1px solid rgba(0, 0, 0, 0.75);
                        top: 0;
                        left: 0;
                        display: none;
                    }

                    .graph {
                        height: 100px;

                        .line {
                            width: 1px;
                            float: left;
                            margin-right: 1px;
                            max-height: 100px;

                            &.paddle {
                                background: rgba(255, 255, 255, 0.4);

                                &.active {
                                    background: #6297C8;
                                }
                            }

                            &.wave {
                                background: rgba(255, 255, 255, 0.8);

                                &.active {
                                    background: #A1C1DE;
                                }
                            }
                        }
                    }
                }

                #replay-controls,
                #crop-controls {
                    width: 950px;
                    height: 45px;
                    margin: 0 auto;
                    padding-top: 5px;

                    .speed-control {
                        padding-top: 6px;
                        float: left;
                        margin-right: 345px;

                        a {
                            width: 43px;
                            text-align: center;
                            padding: 0;
                        }
                    }

                    .playback-controls {
                        float: left;
                        margin-right: 345px;

                        a {
                            float: left;
                            border-radius: 0;
                            background-position: 50% 50%;
                            background-repeat: no-repeat;
                            text-indent: -9999px;
                            padding: 0;
                            height: 40px;

                            &.prev {
                                border-top-left-radius: 3px;
                                border-bottom-left-radius: 3px;
                                border-right: 1px solid #1C1D1F;
                                width: 40px;
                                background-image: url('../images/icon-rewind.png');
                                @include image2x('../images/icon-rewind@2x.png', 17px, 11px);
                            }

                            &.play {
                                width: 50px;
                                background-image: url('../images/icon-play.png');
                                @include image2x('../images/icon-play@2x.png', 11px, 15px);
                                border-radius: 3px;
                            }

                            &.pause {
                                width: 50px;
                                background-image: url('../images/icon-pause.png');
                                @include image2x('../images/icon-pause@2x.png', 13px, 16px);
                                border-radius: 3px;
                            }

                            &.next {
                                border-top-right-radius: 3px;
                                border-bottom-right-radius: 3px;
                                border-left: 1px solid #1C1D1F;
                                width: 40px;
                                background-image: url('../images/icon-fastforward.png');
                                @include image2x('../images/icon-fastforward@2x.png', 17px, 11px);
                            }

                            &.disabled {
                                cursor: default;
                                opacity: 0;

                                &:hover {
                                    background-color: #232528;
                                }
                            }
                        }
                    }

                    .close-control {
                        padding-top: 6px;
                        float: right;

                        a {
                            background-image: url('../images/icon-replay-close.png');
                            @include image2x('../images/icon-replay-close@2x.png', 8px, 8px);
                            background-position: 49px 11px;
                            background-repeat: no-repeat;
                            padding-right: 20px;
                        }
                    }

                    a {
                        @include all-transitions;
                        background-color: #232528;
                        display: block;
                        color: #FFF;
                        font-family: $font-light;
                        font-size: em(14px);
                        border-radius: 3px;
                        text-align: center;
                        height: 29px;
                        line-height: 31px;
                        padding: 0 10px;
                        text-decoration: none;

                        &:hover {
                            background-color: #36383C;
                        }
                    }
                }
            }

            #crop-surf {

                .noUi-base {
                    background: rgba(98, 151, 200, .6);
                    top: 0;
                }

                .noUi-state-blocked {
                    background: none !important;
                }
            }
        }

        // Surf Details
        #right-sidebar {
            position: absolute;
            top: 0;
            right: -360px;
            height: 100%;
            z-index: 1;

            .scroll-pane {
                height: auto;
                position: relative;
                padding-bottom: 20px;
            }

            .nav {
                margin-top: 20px;
                float: left;

                ul {

                    li {
                        margin-bottom: 1px;

                        a {
                            @include all-transitions;
                            background-color: rgba(0, 0, 0, 0.2);
                            background-repeat: no-repeat;
                            background-position: 50% 50%;
                            display: block;
                            width: 40px;
                            height: 40px;
                            text-indent: -9999px;

                            &:hover {
                                background-color: rgba(0, 0, 0, 0.6);
                            }
                        }

                        &.active a {
                            background-color: #3D86C8;
                        }

                        &.n-surf-details a {
                            background-image: url('../images/icon-surf-details.png');
                            @include image2x('../images/icon-surf-details@2x.png', 20px, 4px);
                        }

                        &.n-edit-surf a {
                            background-image: url('../images/icon-edit-surf.png');
                            @include image2x('../images/icon-edit-surf@2x.png', 16px, 16px);
                        }

                        &.n-share-surf a {
                            background-image: url('../images/icon-share-surf.png');
                            @include image2x('../images/icon-share-surf@2x.png', 18px, 21px);
                        }

                        &.n-close {
                            display: none;
                        }

                        &.n-close a {
                            background-image: url('../images/icon-close-panel.png');
                            @include image2x('../images/icon-close-panel@2x.png', 14px, 14px);
                        }
                    }
                }
            }

            .rhs-panel {
                background: rgb(42, 44, 47);
                float: left;
                width: 320px;
                height: 100%;
                display: none;
                padding-bottom: 20px;
            }

            .sign-up {
                background: #292D30;
                padding: 29px 42px 31px 20px;

                .cta {
                    float: left;
                    margin-right: 15px;

                    a {
                        font-family: $font-light;
                        background: $primary;
                        display: block;
                        border-bottom: 2px solid #A61500 !important;
                        width: 87px;
                        height: 38px;
                        line-height: 42px;
                        text-align: center;
                        border: none;
                        color: #FFF;
                        text-decoration: none;
                        text-transform: uppercase;
                        border-radius: 3px;
                    }
                }

                .text {
                    padding-top: 4px;

                    p {
                        font-family: $font-light;
                        color: #FFF;
                        line-height: 15px;
                    }

                    a {
                        font-family: Arial;
                        color: $primary;
                        text-decoration: none;
                    }
                }
            }

            #panel-surf-details {

                .jspScrollable {
                    outline: none;
                    height: 100% !important;
                }

                .jspPane {
                    position: absolute;
                    width: 320px !important;
                }

                .surf-photo {
                    background: #000;
                    @include all-transitions;
                    cursor: pointer;

                    img {
                        @include all-transitions;
                        width: 100%;
                        display: block;

                        &.loading {
                            width: 30px;
                            padding: 100px 0;
                            margin: 0 auto;
                        }
                    }
                }

                .surf-location {
                    background: rgba(0, 0, 0, 0.1);
                    padding: 16px 23px 16px 20px;
                    margin-bottom: 30px;

                    p {
                        background: url('../images/icon-clock-small.png') no-repeat;
                        @include image2x('../images/icon-clock-small@2x.png', 16px, 16px);
                        min-height: 16px;
                        font-family: $font-light;
                        font-size: em(14px);
                        color: #BEBEBE;
                        padding: 2px 0 0 22px;
                    }
                }

                .wrapper {
                    padding: 0 20px;
                }

                .surf-description {
                    color: #BEBEBE;
                    line-height: 20px;
                    margin-bottom: 17px;
                    word-wrap: break-word;
                }

                .surf-board {
                    color: #BEBEBE;
                    margin-bottom: 17px;

                    strong {
                        color: #FFF;
                    }
                }

                .has_overridden_weather {
                    color: #9EA2A4;
                    font-family: $font-light;
                    margin: 10px 0 0 0;
                }

                .conditions {
                    height: 150px;
                    margin: 13px 0 0 0;
                    overflow: visible;

                    h2 {
                        color: #FFF;
                        font-size: em(16px);
                        font-family: $font-light;
                        padding-bottom: 9px;
                        border-bottom: 1px solid #545759;
                        margin-bottom: 10px;
                    }

                    .condition {
                        position: relative;
                        background: rgba(0, 0, 0, 0.1);
                        border-radius: 3px;
                        width: 87px;
                        height: 113px;
                        padding: 0 1px;
                        margin-right: 6px;
                        float: left;
                        position: relative;

                        &:hover {

                            .tooltip {
                                display: block;
                            }
                        }

                        .tooltip {
                            @include all-transitions;
                            position: absolute;
                            background: url('../images/bg-tooltip.png') no-repeat;
                            @include image2x('../images/bg-tooltip@2x.png', 110px, 122px);
                            width: 110px;
                            height: 105px;
                            top: -104px;
                            left: -10px;
                            color: #FFF;
                            text-align: center;
                            padding-top: 17px;
                            display: none;

                            img {
                                margin-bottom: 5px;
                            }

                            p {
                                font-size: em(12px);
                                margin-top: 0;
                                text-transform: uppercase;

                                &.bottom {
                                    font-family: $font-light;
                                }
                            }
                        }

                        .icon {
                            margin: 0 auto;
                            width: 21px;
                            padding-top: 17px;

                            img {
                                width: 100%;

                                &.loading {
                                    width: auto;
                                }

                                &.direction {
                                    display: none;
                                }

                                &.null {
                                    width: 13px;
                                    margin: -34px 0 10px 37px;
                                }
                            }

                            .label {
                                color: #BDBDBE;
                                font-size: em(10px);
                                text-align: center;
                                margin-top: 7px;
                                text-transform: uppercase;
                            }

                            &.unknown {
                                width: 100%;
                                padding-top: 29px;

                                .label {
                                    margin-top: 0;
                                    font-size: 16px;
                                    letter-spacing: 5px;
                                }
                            }
                        }

                        &.swell {

                            .tooltip {

                                img {
                                    width: 48px;
                                    margin-bottom: 11px;
                                }

                                .bottom {
                                    margin-top: 17px;
                                }
                            }
                        }

                        &.wind {

                            .tooltip {

                                img {
                                    width: 36px;
                                }
                            }
                        }

                        &.wind,
                        &.swell {

                            .bottom {
                                margin-top: 19px;
                                text-align: center;
                                padding-top: 10px;
                                font-size: em(23px);
                                font-family: $font-light;
                                color: #FFF;
                                text-transform: uppercase;

                                .measurement {
                                    top: 0;
                                    color: #CCC;
                                    font-size: 12px;
                                    padding-left: 2px;
                                    text-transform: uppercase;
                                    display: inline-block;
                                    position: relative;
                                    top: -7px;
                                }
                            }
                        }

                        &.tide {
                            margin-right: 0;

                            .tooltip {

                                img {
                                    width: 33px;
                                }

                                p {
                                    margin-top: 0;

                                    &.bottom {
                                        font-size: em(14px);
                                        margin-top: 33px;
                                    }
                                }
                            }

                            .data {
                                font-size: em(14px);
                                height: 24px;
                                padding-top: 15px;
                            }

                            .icon {
                                width: auto;
                                padding-top: 20px;

                                img {
                                    width: 36px;
                                    margin: 0 auto;

                                    &.loading {
                                        width: 24px;
                                        padding: 0 30px;
                                    }
                                }
                            }

                            .label {
                                margin-top: 14px;
                            }
                        }

                        .data {
                            position: absolute;
                            bottom: 0;
                            border-top: 1px solid #303336;
                            width: 85px;
                            height: 29px;
                            text-align: center;
                            padding-top: 10px;
                            font-size: em(23px);
                            font-family: $font-light;
                            color: #FFF;
                            text-transform: uppercase;

                            .measurement {
                                top: 0;
                                color: #CCC;
                                font-size: 12px;
                                padding-left: 2px;
                                text-transform: uppercase;
                                display: inline-block;
                                position: relative;
                                top: -7px;
                            }
                        }
                    }
                }

                .surf-rating {
                    padding-top: 27px;

                    .title {
                        @include clearfix;
                        padding-bottom: 9px;
                        border-bottom: 1px solid #545759;

                        h2 {
                            float: left;
                            color: #FFF;
                            font-size: em(16px);
                            font-family: $font-light;
                        }

                        #rhs-surf-rating {
                            float: right;
                        }
                    }
                }

                .wave-graph {
                    padding: 0 2px;
                    margin-top: 35px;

                    .columns {
                        height: 9px;
                        margin-bottom: 5px;

                        ol {

                            li {
                                font-family: $font-light;
                                font-size: em(10px);
                                color: #FFF;
                                float: left;

                                &.first {
                                    width: 62px;
                                }

                                &.second {
                                    width: 69px;
                                }

                                &.third {
                                    width: 71px;
                                }

                                &.fourth {
                                    width: 43px;

                                    &.big-number {
                                        margin-left: -10px;
                                    }
                                }

                                &.fifth {
                                    width: 28px;
                                    text-align: right;

                                    &.big-number {
                                        margin-left: 0;
                                    }
                                }
                            }
                        }
                    }

                    .graph {
                        margin-bottom: 10px;

                        li {
                            height: 27px;
                            padding: 1px 2px 2px 2px;
                            border-top: 1px solid #1C1F21;
                            background: #25282B url('../images/bg-wave-graph-dashed-lines.png') no-repeat;
                            margin-bottom: 2px;

                            .wave {
                                background: #3D86C8;
                                width: 0px;
                                max-width: 274px;
                                height: 27px;
                                color: #FFF;
                                font-size: em(16px);
                                line-height: 31px;
                                border-radius: 3px;
                                position: relative;

                                &.longest {
                                    background-color: #DF1B00;
                                }

                                span {
                                    display: none;
                                }

                                .number {
                                    margin-right: 15px;
                                    position: absolute;
                                    padding-left: 6px;
                                    top: 0;
                                    left: 0;
                                    font-family: $font-medium;
                                }

                                .speed {
                                    font-family: $font-light;
                                    position: absolute;
                                    top: 0;
                                    left: 31px;
                                }

                                .icon {
                                    position: absolute;
                                    top: 0;
                                    right: -10px;

                                    img {
                                        width: 7px;
                                    }
                                }
                            }
                        }
                    }

                    .footer {
                        font-family: $font-light;
                        font-size: em(10px);
                        color: #FFF;
                    }
                }
            }

            &.isActivity {
                .wrapper, .board, .t-surf-conditions {
                    display: none !important;
                }
            }

            #panel-share-surf {
                background: #292d30;

                .google-maps-image {
                    height: 320px;
                    width: 320px;
                    background-color: rgba(0,0,0,0.5);
                    color: #FFFFFF;
                    position: relative;
                    overflow: hidden;

                    .google-maps-image-stats {
                        position: absolute;
                        height: 320px;
                        width: 320px;
                        z-index: 1;

                        .top {
                            position: absolute;
                            height: 74px;
                            width: 320px;
                            background-color: rgba(0,0,0,0.7);
                            top: 0;
                            text-shadow: 0px 1px 0px rgba(0, 0, 0, 0.45);
                            text-align: center;

                            .waves {
                                font: 36px $font-thin;
                                color: #FFFFFF;
                                width: 106.666666667px;
                                float: left;
                                padding-top: 10px;

                                .text {
                                    font: 12px $font-thin;
                                    color: #979ea0;
                                }
                            }

                            .longest-wave,
                            .top-speed {
                                font: 36px $font-thin;
                                color: #FFFFFF;
                                width: 106.666666667px;
                                float: left;
                                padding-top: 10px;

                                .measurement-unit {
                                    font: 14px $font-thin;
                                }

                                .text {
                                    font: 12px $font-thin;
                                    color: #979ea0;
                                }
                            }
                        }

                        .bottom {
                            position: absolute;
                            height: 54px;
                            width: 320px;
                            bottom: 0;
                            background: url('../images/icon-mini-map-ripcurl.png') no-repeat #27292C top 15px right 10px;
                            @include image2x('../images/icon-mini-map-ripcurl@2x.png', 43px, 23px);

                            .profile-picture {
                                width: 66px;
                                height: 66px;
                                border-radius: 70px;
                                border: 2px solid rgba(255,255,255,0.4);
                                margin-left: 13px;
                                margin-top: -26px;
                                float: left;

                                #main-profile-photo {
                                    height: 100%;
                                    width: 100%;
                                    border-radius: 70px;
                                    background-size: cover;
                                    background-position: center;
                                }
                            }

                            .profile-name {
                                font: 16px $font-thin;
                                color: #FFFFFF;
                                margin-left: 94px;
                                margin-top: 10px;
                            }

                            .location {
                                font: 12px $font-thin;
                                color: #bebebe;
                                margin-left: 94px;
                                background: url('../images/icon-mini-map-marker.png') no-repeat;
                                @include image2x('../images/icon-mini-map-marker@2x.png', 10px, 14px);
                                padding-left: 13px;
                                padding-top: 2px;
                            }

                        }

                    }
                }

                #share-surf-form {
                    padding: 20px;

                    textarea {
                        box-sizing: border-box;
                        color: #8d8d8d;
                        font: 14px Arial;
                        display: block;
                        border: 0;
                        border-radius: 3px;
                        min-height: 95px;
                        padding: 12px 14px;
                        width: 100%;
                        outline: none;
                    }

                    input[type=submit]{
                        box-sizing: border-box;
                        border: 1px solid #535759;
                        border-radius: 3px;
                        background-color: #24282b;
                        color: #FFFFFF;
                        font: 16px $font-light;
                        text-transform: uppercase;
                        margin: 20px auto 0;
                        display: block;
                        padding-top: 11px;
                        padding-bottom: 10px;
                        width: 100%;
                        cursor: pointer;
                        outline: none;

                        &:hover {
                            background-color: #202426;
                        }
                    }
                }
            }

            #panel-edit-surf {

                .scroll-pane {
                    height: 100%;
                }

                .tab-panel {
                    display: none;
                }

                #t-surf-details {
                    display: block;
                }

                .jspScrollable {
                    outline: none;
                }

                .jspPane {
                    position: absolute;
                    width: 320px !important;
                }

                .header {
                    height: 39px;
                    border-bottom: 1px solid #4F5153;
                    padding-left: 21px;
                    margin-bottom: 43px;

                    h2 {
                        font-family: $font-light;
                        color: #666768;
                        font-size: em(18px);
                        float: left;
                        padding-top: 14px;
                    }

                    input {
                        @include all-transitions;
                        font-family: $font-light;
                        color: #FFF;
                        font-size: em(14px);
                        float: right;
                        border: none;
                        cursor: pointer;
                        background: none;
                        -webkit-appearance: none;
                        padding: 0 18px 0 21px;
                        margin: 0;
                        outline: none;
                        height: 39px;
                        line-height: 42px;

                        &:hover {
                            background: #36383C;
                        }
                    }
                }

                .tabs {
                    height: 29px;
                    border-bottom: 1px solid $primary;
                    margin-bottom: 27px;

                    ul {
                        @include clearfix;

                        li {
                            list-style: none;
                            float: left;
                            width: 50%;
                            height: 29px;

                            &.active a {
                                background: url('../images/icon-red-arrow-up.png') bottom center no-repeat;
                                @include image2x('../images/icon-red-arrow-up@2x.png', 16px, 8px);
                                color: #FFF;
                            }

                            a {
                                font-family: $font-light;
                                display: block;
                                height: 29px;
                                font-size: em(14px);
                                color: #BEBEBE;
                                text-decoration: none;
                                text-align: center;

                                &:hover {
                                    color: #FFF;
                                }
                            }
                        }
                    }
                }

                label {
                    font-family: $font-light;
                    font-size: em(12px);
                    color: #BEBEBE;
                    text-transform: uppercase;
                    margin-bottom: 14px;
                    display: block;
                }

                .form-text,
                textarea {
                    background: #FFF;
                    border: none;
                    border-radius: 3px;
                    color: #B8B8B8;
                    outline: none;
                    margin: 0;
                    width: 252px;
                    padding: 0 14px;

                    &:focus {
                        color: #585858;
                    }
                }

                .form-text {
                    height: 40px;
                }

                textarea {
                    padding-top: 16px;
                    padding-bottom: 16px;
                    height: 48px;
                }

                .row {
                    padding: 0 20px;

                    &.half {

                        .items {
                            @include clearfix;

                            .form-text {
                                float: left;
                                width: 107px;

                                &:last-child {
                                    float: right;
                                }
                            }
                        }
                    }
                }

                .spot {
                    margin-bottom: 21px;
                }

                .surf-rating {
                    background: #36383C;
                    border-radius: 3px;
                    height: 53px;
                    width: 250px;
                    margin: 0 auto 28px auto;
                    padding: 0 14px 0 15px;

                    label {
                        text-transform: none;
                        float: left;
                        font-size: em(14px);
                        padding-top: 20px;
                        color: #FFF;
                    }

                    .edit-star-rating {
                        float: right;
                        margin-top: 17px;
                    }
                }

                .time-date {
                    margin-bottom: 39px;

                    .items {
                        position: relative;
                    }

                    .form-text {
                        text-transform: uppercase;
                    }

                    .time-pickers {
                        @include clearfix;
                        width: 125px;
                        float: left;

                        select {
                            width: 60px;
                            float: left;
                            height: 40px;
                            border-radius: 3px;

                            &.time-picker-hours {
                                margin-right: 5px;
                            }
                        }
                    }
                }

                .surf-visibility {
                    margin-bottom: 39px;

                    .option {
                        background: #36383C;
                        height: 48px;
                        padding: 22px 15px 0 20px;
                        border-bottom: 1px solid #26272A;
                        border-top: 1px solid #4B4D51;
                        border-radius: 3px;

                        &:first-child {
                            border-top: none;
                            border-bottom-left-radius: 0;
                            border-bottom-right-radius: 0;
                        }

                        label {
                            text-transform: none;
                            float: left;

                            span {
                                font-size: 12px;
                                color: #9B9B9D;
                                display: block;
                                margin-top: 10px;
                            }
                        }

                        .switcher {
                            float: right;
                            cursor: pointer;
                        }
                    }
                }

                .notes {
                    margin-bottom: 29px;

                    label span {
                        float: right;

                        &.error {
                            color: $primary;
                        }
                    }
                }

                .drag-drop {
                    margin-bottom: 30px;

                    .dropzone {
                        @include all-transitions;
                        background: #25282A url('../images/bg-dropzone.png') 106px 18px no-repeat;
                        @include image2x('../images/bg-dropzone@2x.png', 54px, 38px);
                        border: 1px dashed #555;
                        border-radius: 3px;
                        width: 278px;
                        min-height: 51px;
                        text-align: center;
                        padding-top: 70px;
                        cursor: pointer;

                        .dz-default,
                        .dz-success-mark,
                        .dz-error-mark,
                        .dz-size {
                            display: none;
                        }

                        p {
                            font-family: $font-light;
                            font-size: em(16px);
                            color: #FFF;
                            cursor: default;

                            &.browse {
                                font-size: 14px;
                                margin: 4px 0 10px 0;
                                color: $primary;
                                display: block;
                                cursor: pointer;
                            }
                        }

                        .dz-details {
                            font-family: $font-light;
                            font-size: em(14px);
                            color: #FFF;

                            .dz-filename {
                                margin-bottom: 10px;
                                display: inline;

                            }

                            img {
                                display: block;
                                margin: 20px auto;
                                border-radius: 3px;
                            }
                        }

                        .dz-progress {
                            margin-bottom: 10px;
                        }

                        .dz-error-message {
                            font-family: $font-light;
                            font-size: em(14px);
                            color: $primary;
                            margin-bottom: 10px;
                        }
                    }
                }

                .board {
                    margin-bottom: 30px;

                    p {
                        font-family: $font-light;
                        color: #BEBEBE;
                        font-size: em(12px);
                    }
                }

                .crop {
                    margin-bottom: 33px;

                    a {
                        background: #36383C url('../images/icon-crop.png') 15px 9px no-repeat;
                        @include image2x('../images/icon-crop@2x.png', 32px, 32px);
                        display: block;
                        height: 50px;
                        text-indent: 57px;
                        color: #FFF;
                        font-family: $font-light;
                        font-size: em(14px);
                        text-decoration: none;
                        line-height: 50px;
                        border-radius: 3px;
                    }
                }

                .buttons {
                    height: 38px;

                    a {
                        float: left;
                        line-height: 38px;
                    }

                    input {
                        float: right;
                    }
                }

                #t-surf-conditions {

                    .option {
                        @include clearfix;
                        background: #36383C;
                        padding: 20px;
                        border-bottom: 1px solid #26272A;
                        border-top: 1px solid #4B4D51;
                        font-family: $font-light;
                        color: #FFF;

                        label {
                            font-size: 16px;
                            text-transform: none;
                        }

                        &.last-option {
                            @include clearfix;
                            background: #4A4C50;
                            border-bottom: none;
                            border-bottom-left-radius: 3px;
                            border-bottom-right-radius: 3px;
                            padding-bottom: 15px;

                            label {
                                float: left;
                                margin-bottom: 0;
                            }

                            .value {
                                position: relative;
                                float: right;
                                font-family: $font-thin;
                                font-size: em(20px);

                                select {
                                    width: 135px;
                                    height: 28px;
                                    margin: 0;
                                    position: relative;
                                    top: -2px;
                                    border-radius: 3px;
                                }
                            }
                        }
                    }

                    .left {
                        width: 180px;
                        float: left;
                    }

                    .right {
                        float: right;
                    }

                    .slider {
                        margin-bottom: 10px;
                    }

                    .range {
                        @include clearfix;
                        margin-bottom: 16px;

                        p {
                            float: left;
                            font-family: $base-font;
                            font-size: em(10px);
                            color: #9B9B9D;

                            &:last-child {
                                float: right;
                            }
                        }
                    }

                    .details {

                        p {
                            color: #9B9B9D;
                            font-size: em(14px);

                            span {
                                color: #FFF;
                                display: inline-block;
                                padding-left: 5px;
                            }
                        }
                    }

                    .size,
                    .time,
                    .strength {
                        text-align: center;

                        .value {
                            font-family: $font-thin;
                            font-size: em(40px);
                            margin-top: 4px;
                        }

                        .unit {
                            font-size: em(10px);
                            text-transform: uppercase;
                        }
                    }

                    .swell {
                        margin-bottom: 30px;

                        .option {

                            &.size-option {
                                border-top: none;
                                border-top-left-radius: 3px;
                                border-top-right-radius: 3px;
                                border-bottom-left-radius: 0px;
                                border-bottom-right-radius: 0px;
                            }
                        }
                    }

                    .wind {
                        margin-bottom: 30px;

                        .strength-option {
                            border-top: none;
                            border-radius: 3px;

                            .details {
                                width: 200px;
                            }
                        }
                    }

                    .tide {
                        margin-bottom: 20px;

                        .option {
                            padding: 0;
                            border-top: none;
                            border-bottom: 0;
                            border-radius: 3px;
                        }

                        .top {
                            height: 32px;
                            font-size: em(16px);
                            color: #9B9B9D;
                            padding: 18px 15px 0 22px;

                            .tide-level {
                                float: left;
                                padding-top: 2px;
                            }

                            .tide-direction {
                                float: right;
                                padding-right: 34px;
                                height: 14px;
                                line-height: 16px;
                                padding-top: 2px;

                                &.tide-incoming {
                                    background: url('../images/icon-edit-surf-tide-incoming.png') top right no-repeat;
                                    @include image2x('../images/icon-edit-surf-tide-incoming@2x.png', 24px, 16px);
                                }

                                &.tide-outgoing {
                                    background: url('../images/icon-edit-surf-tide-outgoing.png') top right no-repeat;
                                    @include image2x('../images/icon-edit-surf-tide-outgoing@2x.png', 24px, 16px);
                                }
                            }
                        }

                        .graph {
                            background: #303236;
                            position: relative;
                            height: 88px;

                            .handle {
                                position: absolute;
                                background: url('../images/icon-slider-handle.png') no-repeat;
                                @include image2x('../images/icon-slider-handle@2x.png', 17px, 18px);
                                width: 17px;
                                height: 18px;
                                top: 22px;
                                left: 15px;
                                z-index: 4;
                            }

                            .chart {
                                background: url('../images/bg-tide-graph.png') no-repeat;
                                @include image2x('../images/bg-tide-graph@2x.png', 280px, 88px);
                                position: absolute;
                                width: 280px;
                                height: 88px;
                                z-index: 2;
                            }

                            .section {
                                @include all-transitions;
                                z-index: 1;

                                &.active {
                                    background: #3D86C8;
                                }
                            }

                            .section,
                            .hover {
                                @include all-transitions;
                                float: left;
                                width: 47px;
                                height: 88px;
                                cursor: pointer;
                                position: absolute;

                                &.one {
                                    width: 46px;
                                    left: 0;
                                    border-bottom-left-radius: 3px;
                                }

                                &.two {
                                    left: 46px;
                                }

                                &.three {
                                    left: 93px;
                                }

                                &.four {
                                    left: 140px;
                                }

                                &.five {
                                    left: 187px;
                                }

                                &.six {
                                    width: 46px;
                                    left: 234px;
                                    border-bottom-right-radius: 3px;
                                }
                            }

                            .hover {
                                z-index: 3;
                            }
                        }
                    }
                }
            }
        }
    }

    #ecom,
    #antavo {
        position: absolute;
        top: 50%;
        left: 50%;
        width: 100%;
        max-width: 505px;
        background-color: #fff;
        transform: translate(-50%, -50%);
        z-index: 3;

        &:not([data-mode]) {
            display: none;
        }

        p {
            margin-top: 16px;
            margin-left: auto;
            margin-right: auto;
            font-size: 22px;
            line-height: 30px;
            font-family: $font-light;
        }

        a.button {
            display: block;
            color: #000;
            font-size: 14px;
            font-family: $font-medium;
            text-decoration: none;
            text-transform: uppercase;
            text-align: center;
            border-radius: 2px;
            background-color: #5BC8E2;
            max-width: 319px;
            margin-left: auto;
            margin-right: auto;
            padding-top: 18px;
            padding-bottom: 18px;
        }
    }

    #ecom {
        text-align: center;
        padding-top: 0px !important;
        padding-bottom: 0px !important;
        width: auto;
        max-width: 988px !important;

        .ecom-wrapper{
            position: relative;

            .top {
                display: block;
                position:absolute;
                top: 10px;
                right: 10px;
                padding: 8px;
                text-transform: uppercase;

                .close {
                    color: #000;
                    text-decoration: none;
                    vertical-align: top;
                    span {
                        display: inline-block;
                        margin-top: 2px;
                        margin-right: 8px;
                    }
                }
            }

            > img {
                width: 100%;
            }

            h2 {
                font-size: 24px;
                font-family: "Stag Sans lighter", Arial;
                margin-top: 32px;
                font-style: normal;
                font-weight: 700;
                font-size: 24px !important;
                line-height: 27px;
                text-align: left;
                margin-left: 32px;
                margin-right: 62px;
            }

            .link {
                display: flex;
                flex-direction:row;
                width: 988px;
                height: 277px;
                background-color: white;
            }
            .link img {
                width: 50% !important;
                height: 100% !important;
            }

            .link .description {
                padding-top: 25px;
                padding-left: 15px;
                padding-right: 15px;
                width: 50% !important;
                height: 100% !important;
                font-style: normal;
                font-weight: 400;
                font-size: 15px !important;
                line-height: 22px;
                text-align: left;
            }

            .link .description p {
                font-style: normal;
                font-weight: 400;
                font-size: 15px !important;
                line-height: 22px;
                text-align: left;
                margin-left: 32px;
                margin-right: 62px;
                margin-bottom: 30px;
            }

            a.button {
                margin-top: 32px;
                + a {
                    margin-top: 0;
                    margin-bottom: 15px;
                    background-color: transparent;
                }
            }

            .black-btn {
                margin: 0px !important;
                padding-left: 20px;
                padding-right: 20px;
                color: #FFF !important;
                font-size: 14px;
                text-decoration: none;
                text-transform: uppercase;
                text-align: center;
                border-radius: 2px;
                background-color: #000 !important;
                width: 151px;
                font-style: normal;
                font-family: "Stag Sans lighter", Arial;
                font-size: 12px;
                line-height: 15px;
                text-transform: uppercase;
            }

            .text-btn {
                margin: 0px !important;
                padding-left: 20px;
                padding-right: 20px;
                color: #000 !important;
                font-size: 14px;
                text-decoration: none;
                text-transform: uppercase;
                text-align: center;
                border-radius: 2px;
                background-color: #FFF !important;
                font-style: normal;
                font-family: "Stag Sans lighter", Arial;
                font-size: 12px;
                line-height: 15px;
                text-transform: uppercase;
            }

            > * {
                display: none;
            }

            .link {
                display: flex;
            }

            .connecting {
                display: block;
                width: 505px;
                height: 300px;
                padding-top: 70px;

                .spinner {
                    background-image: url(../images/ecom/spinner.svg);
                    width: 81px;
                    height: 81px;
                    margin-left: auto;
                    margin-right: auto;
                    animation: spinner 1s infinite linear;
                    -webkit-animation: spinner 1s infinite linear;
                }
                @keyframes spinner {
                    0% {
                        transform: rotate(0deg);
                        -webkit-transform: rotate(0deg);
                    }

                    100% {
                        transform: rotate(360deg);
                        -webkit-transform: rotate(360deg);
                    };
                }

                h2 {
                    font-size: 40px !important;
                    font-family: $font-medium;
                    margin-top: 32px;
                    margin-left: 32px;
                    margin-right: 32px;
                    text-align: center;
                }
            }

            .success {
                display: block;
                width: 505px;
                height: 510px;
                padding-top: 70px;

                .tick {
                    background-image: url(../images/ecom/tick.svg);
                    background-repeat: no-repeat;
                    width: 80px;
                    height: 79px;
                    margin-left: auto;
                    margin-right: auto;
                    margin-top: 12px;
                }

                h2 {
                    font-size: 40px !important;
                    font-family: $font-medium;
                    margin-top: 32px;
                    margin-left: 32px;
                    margin-right: 32px;
                    text-align: center;
                }
        
                p {
                    margin-left: 52px;
                    margin-right: 52px;
                    margin-top: 50px;
                    margin-bottom: 50px;
                    font-size: 22px !important;
                    text-align: center;
                }
            }

            .error {
                display: block;
                width: 505px;
                height: 510px;
                padding-top: 70px;

                .error {
                    background-image: url(../images/ecom/error.svg);
                    width: 80px;
                    height: 79px;
                    margin-left: auto;
                    margin-right: auto;
                    background-repeat: no-repeat;
                }

                h2 {
                    font-size: 40px !important;
                    font-family: $font-medium;
                    margin-top: 32px;
                    margin-left: 32px;
                    margin-right: 32px;
                    text-align: center;
                }
        
                p {
                    margin-left: 52px;
                    margin-right: 52px;
                    margin-top: 50px;
                    margin-bottom: 50px;
                    font-size: 22px !important;
                    text-align: center;
                }
            }
        }
    }

    #antavo {
        &[data-mode="awareness"] .aversion,
        &[data-mode="aversion"] .awareness {
            display: none;
        }
        &[data-mode="aversion"] {
            padding-bottom: 32px;
        }

        .top {
            display: flex;
            justify-content: flex-end;
            padding: 8px;
            text-transform: uppercase;

            .close {
                color: #000;
                text-decoration: none;
                vertical-align: top;
                span {
                    display: inline-block;
                    margin-top: 2px;
                    margin-right: 8px;
                }
            }
        }

        > img {
            width: 100%;
        }

        h2 {
            font-size: 28px;
            font-family: $font-medium;
            margin-top: 32px;
        }

        h2,
        p {
            margin-left: 32px;
            margin-right: 32px;
        }

        a.button {
            margin-top: 32px;
            + a {
                margin-top: 0;
                margin-bottom: 15px;
                background-color: transparent;
            }
        }
    }

    // Profile
    #view-profile {
        position: relative;

        .graph-tooltip {
            position: absolute;
            width: 182px;
            display: none;
            margin-left: -57px;
            padding-top: 5px;
            z-index: 3;

            .arrow {
                background: url('../images/icon-tooltip-arrow.png') top center no-repeat;
                @include image2x('../images/icon-tooltip-arrow@2x.png', 14px, 7px);
                width: 182px;
                height: 7px;
            }

            .content {
                background: #36383C;
                border-radius: 3px;
                box-shadow: 0px 2px 5px 1px rgba(0, 0, 0, 0.15);

                .header {
                    background: #2F3135;
                    height: 34px;
                    padding: 9px 9px 0 17px;
                    border-top-left-radius: 3px;
                    border-top-right-radius: 3px;

                    .stat {
                        float: left;

                        &.max-speed {
                            float: right;
                        }
                    }

                    .value {
                        font-size: em(22px);
                        color: #FFF;
                        font-family: $font-thin;
                    }

                    .label {
                        color: #909091;
                        font-size: em(11px);
                        font-family: $font-light;
                    }
                }

                .inner {
                    max-height: 300px;
                    overflow: hidden;

                    a {
                        @include all-transitions;
                        display: block;
                        padding: 12px 16px;
                        color: #FFF;
                        font-size: em(12px);
                        font-family: $font-light;
                        border-top: 1px solid #4A4C50;
                        text-decoration: none;
                        line-height: 16px;

                        &:last-child {
                            border-bottom-left-radius: 3px;
                            border-bottom-right-radius: 3px;
                        }

                        &:hover {
                            background: #3D86C8;
                            border-color: #3D86C8;
                        }
                    }
                }
            }
        }

        #profile-followers,
        #profile-following {
            padding: 40px 50px;
            overflow: hidden;

            .no-results {
                padding-top: 10px;

                p {
                    font-family: $font-light;
                    font-size: 16px;
                    color: #FFF;
                    margin-bottom: 17px;
                }

                a {
                    background: url("../images/icon-find-to-follow.png") 14px 14px no-repeat;
                    @include image2x('../images/icon-find-to-follow@2x.png', 68px, 68px);
                    width: 200px;
                    height: 48px;
                    line-height: 48px;
                    padding-left: 48px;
                    text-align: left;
                }
            }

            .filter {
                margin-bottom: 20px;

                input {
                    @include all-transitions;
                    background: #2E3034 url('../images/icon-filter.png') 20px 12px no-repeat;
                    @include image2x('../images/icon-filter@2x.png', 15px, 15px);
                    width: 274px;
                    height: 40px;
                    padding: 0 20px 0 46px;
                    border: none;
                    outline: none;
                    color: #BEBEBE;
                    font-size: em(14px);
                    font-family: $font-light;
                    border-radius: 3px;

                    &:focus {
                        background: #2E3034 url('../images/icon-filter-focus.png') 20px 12px no-repeat;
                        @include image2x('../images/icon-filter-focus@2x.png', 15px, 15px);
                    }
                }
            }

            .follow-requests {

                h2 {
                    color: #BEBEBE;
                    font-family: $font-light;
                    font-size: em(20px);
                    margin-bottom: 18px;

                    span {
                        color: #FFF;
                    }
                }
            }

            .requests {
                @include clearfix;
                margin-bottom: 40px;

                .follow-request {
                    background: #2B2D30;
                    border-radius: 3px;
                    width: 283px;
                    height: 98px;
                    padding: 15px 0 0 17px;
                    float: left;
                    margin-right: 20px;

                    .image {
                        width: 44px;
                        height: 44px;
                        border: 2px solid #414245;
                        float: left;
                        margin-right: 10px;
                        border-radius: 100px;

                        img {
                            width: 44px;
                            height: 44px;
                            border-radius: 100px;
                        }
                    }

                    .details {
                        float: left;
                        padding-top: 8px;

                        h3 {
                            font-family: $font-light;
                            font-size: em(16px);
                            margin-bottom: 4px;

                            a {
                                color: #FFF;
                                text-decoration: none;
                            }
                        }

                        p {
                            font-family: $font-light;
                            font-size: em(12px);
                            color: #BEBEBE;
                            margin-bottom: 9px;
                        }
                    }
                }
            }

            .top {
                height: 30px;
                margin-bottom: 19px;
                padding-bottom: 19px;
                border-bottom: 1px solid $primary;

                h1 {
                    font-family: $font-thin;
                    font-size: em(26px);
                    color: #FFF;
                    float: left;
                    padding-top: 15px;
                }
            }

            .find-surfers {

                .top {
                    @include clearfix;
                    border-bottom: none;

                    h1 {
                        float: left;
                    }

                    .btn {
                        float: right;
                        width: auto;
                        display: inline-block;
                        padding: 0 15px;
                    }
                }

                #selected-tags {
                    cursor: text;
                }
            }

            .on-rc-search {
                display: block;

                .buttons {
                    @include clearfix;

                    a {
                        width: 74px;
                        height: 30px;
                        line-height: 32px;
                        padding: 0;
                        display: inline-block;
                        margin-right: 5px;
                        font-size: em(13px);
                        text-transform: uppercase;
                    }
                }
            }
        }

        #user-profile {
            width: 100%;
            height: 100%;

            @include breakpoint($tabletAll) {
                height: auto;

                &.fixed {
                    overflow: hidden;
                    height: 100vh;
                }
            }

            #profile-overlay {
                z-index: 4;
            }

            .profile-tab-section {
                display: none;
            }

            #profile-logbook {
                background: #232528;
                position: absolute;
                z-index: 4;
                top: 0;
                right: -320px;
                height: 100%;
                width: 320px;
                outline: none;

                .jspContainer,
                .jspPane {
                    outline: none;
                }

                .timeline {
                    height: 100%;

                    .days {
                        padding-top: 20px;
                    }
                }

                .header {

                    a {
                        background-image: url('../images/icon-close-arrow-r.png');
                        @include image2x('../images/icon-close-arrow-r@2x.png', 7px, 12px);
                    }
                }
            }

            #cover-photo {
                position: relative;
                width: 30%;
                min-width: 300px;
                max-width: 540px;
                height: 100%;
                float: left;
                overflow: hidden;

                .sign-up {
                    position: absolute;
                    top: 20px;
                    left: 20px;
                    background: rgba(43, 44, 48, 0.8);
                    width: 180px;
                    padding: 20px 20px 20px 20px;
                    z-index: 2;
                    border-radius: 3px;

                    h1 {
                        font-family: $font-thin;
                        font-size: em(18px);
                        color: #FFF;
                        margin: 0 0 14px 0;
                    }

                    .cta {

                        a {
                            font-family: $font-light;
                            background: $primary;
                            display: block;
                            border-bottom: 2px solid #A61500 !important;
                            width: 100%;
                            height: 38px;
                            line-height: 42px;
                            text-align: center;
                            border: none;
                            color: #FFF;
                            text-decoration: none;
                            text-transform: uppercase;
                            border-radius: 3px;
                        }
                    }

                    .text {
                        font-family: $font-light;
                        margin-bottom: 20px;
                        color: #FFF;
                        line-height: 15px;

                        a {
                            color: $primary;
                            text-decoration: none;
                        }
                    }
                }

                @include breakpoint($tabletAll) {
                    float: none;
                    width: auto;
                    min-width: auto;
                    max-width: 100%;
                    height: 549px;
                    margin-left: 80px;
                    overflow: visible;

                    .cover-photo-wrapper {
                        overflow: hidden;
                        height: 549px;

                        .cover-photo {
                            display: none;
                        }

                        .cover-photo-landscape {
                            display: block;
                            min-width: 100%;
                            height: auto;
                        }
                    }
                }

                .cover-photo-landscape {
                    display: none;
                }

                .shadow {
                    background: url('../images/bg-cover-photo-shadow.png') repeat-y;
                    width: 2px;
                    height: 100%;
                    position: absolute;
                    z-index: 2;

                    @include breakpoint($tabletAll) {
                        display: none;
                    }
                }

                img.cover-photo {
                    position: absolute;
                    min-width: 540px;
                    z-index: 1;

                    @include breakpoint($tabletAll) {
                        position: static;
                        width: 100%;
                        height: auto;
                    }
                }

                .user-info {
                    position: absolute;
                    z-index: 2;
                    background: rgba(0, 0, 0, 0.6);
                    bottom: 0;
                    width: 100%;
                    height: 170px;

                    @include breakpoint($tabletAll) {
                        position: absolute;
                        height: 90px;
                    }

                    .inner {
                        position: relative;

                        @include breakpoint($tabletAll) {
                            padding-right: 20px;
                        }

                        .global-follow-button {
                            float: right;
                            margin-top: 52px;
                            display: none;

                            @include breakpoint($tabletAll) {
                                display: block;
                            }

                            .btn {
                                @include remove-all-transitions;
                                background: rgba(0, 0, 0, 0.2);
                                border: 1px solid rgba(255, 255, 255, 0.2);
                                border-radius: 3px;
                                color: #FFF;
                                display: block;
                                width: auto;
                                height: 30px;
                                font-size: em(11px);
                                line-height: 30px;
                                text-transform: uppercase;
                                text-decoration: none;
                                text-align: left;
                                padding: 0 15px;

                                &.follow {
                                    background: url('../images/icon-follow-request.png') 11px 5px no-repeat;
                                    @include image2x('../images/icon-follow-request.png', 23px, 17px);
                                    margin-right: 135px;
                                    padding-left: 39px;

                                    @include breakpoint($tabletAll) {
                                        margin-right: 0;
                                    }
                                }

                                &.unfollow {
                                    background: url('../images/icon-following-tick.png') 14px 10px no-repeat;
                                    @include image2x('../images/icon-following-tick@2x.png', 10px, 9px);
                                    margin-right: 121px;
                                    padding-left: 31px;

                                    @include breakpoint($tabletAll) {
                                        margin-right: 0;
                                    }
                                }

                                &.follow-requested {
                                    background: url('../images/icon-follow-request.png') 11px 5px no-repeat;
                                    @include image2x('../images/icon-follow-request.png', 23px, 17px);
                                    margin-right: 56px;
                                    padding-left: 39px;

                                    @include breakpoint($tabletAll) {
                                        margin-right: 0;
                                    }
                                }
                            }
                        }
                    }

                    .profile-picture {
                        position: absolute;
                        width: 120px;
                        height: 120px;
                        top: -60px;
                        left: 50%;
                        margin-left: -60px;

                        @include breakpoint($tabletAll) {
                            top: 26px;
                            left: 20px;
                            margin-left: 0;
                        }

                        #main-profile-photo {
                            width: 120px;
                            height: 120px;
                            border-radius: 100px;
                            border: 3px solid rgba(255, 255, 255, 0.1);
                            background-size: cover;
                            background-position: center;
                        }

                        .follow-toggle {
                            position: absolute;
                            top: 93px;
                            left: 4px;
                            text-indent: -9999px;

                            &.following {
                                background: url('../images/icon-following.png') no-repeat;
                                @include image2x('../images/icon-following@2x.png', 22px, 22px);
                                width: 22px;
                                height: 22px;
                            }

                            &.not-following {
                                display: none;
                            }
                        }
                    }

                    h1 {
                        font-family: $font-thin;
                        font-size: em(34px);
                        color: #FFF;
                        text-align: center;
                        padding-top: 75px;
                        &.has-antavo-score {
                            padding-top: 65px;

                            @include breakpoint($tabletAll) {
                                + .location .edit-buttons {
                                    margin-top: 4px;
                                }
                            }
                        }

                        @include breakpoint($tabletAll) {
                            text-align: left;
                            margin-left: 170px;
                            padding-top: 22px;
                            &.has-antavo-score {
                                padding-top: 12px;
                            }
                        }

                        .athlete {
                            background: url('../images/icon-probadge.png') no-repeat;
                            @include image2x('../images/icon-probadge@2x.png', 25px, 19px);
                            display: inline-block;
                            width: 25px;
                            height: 19px;
                            margin-left: 11px;
                        }

                        .padlock {
                            background: url('../images/icon-padlock.png') no-repeat;
                            @include image2x('../images/icon-padlock@2x.png', 9px, 12px);
                            width: 9px;
                            height: 12px;
                            display: inline-block;
                        }
                    }

                    .location {
                        font-family: $font-light;
                        font-size: em(12px);
                        color: #FFF;
                        height: 14px;
                        margin-bottom: 18px;
                        text-align: center;

                        @include breakpoint($tabletAll) {
                            display: flex;
                            justify-content: space-between;
                        }

                        .antavo-score {
                            display: block;
                            padding-top: 4px;

                            strong {
                                font-family: $font-medium;
                            }
                        }

                        .actual-value {
                            background: url('../images/icon-location-pin-small-white.png') no-repeat;
                            @include image2x('../images/icon-location-pin-small-white@2x.png', 10px, 14px);
                            height: 10px;
                            padding-left: 16px;
                            padding-top: 4px;
                            margin-right: 18px;
                            display: inline-block;
                            float: left;
                            margin-right: 20px;
                        }

                        .surfer-stats {
                            display: none;
                        }

                        @include breakpoint($tabletAll) {
                            margin: 0;
                            height: auto;
                            text-align: left;

                            .global-follow-button {
                                display: none;
                            }

                            .surfer-stats {
                                display: block;
                                padding-top: 4px;
                            }

                            .value {
                                margin-left: 170px;
                            }

                            .edit-buttons {
                                margin-top: -6px;
                            }
                        }

                        .find-more-surfers {
                            padding: 4px 0 0 0;
                            display: inline-block;

                            @include breakpoint($tabletAll) {
                                padding-top: 0;
                            }

                            a {
                                background: rgba(0, 0, 0, 0.2) url("../images/icon-follow-more-surfers.png") 8px 7px no-repeat;
                                border: 1px solid rgba(255, 255, 255, 0.2);
                                border-radius: 3px;
                                color: #FFF;
                                display: block;
                                height: 28px;
                                font-size: 0.92308em;
                                line-height: 30px;
                                text-transform: uppercase;
                                text-decoration: none;
                                text-align: left;
                                padding-left: 31px;
                                padding-right: 15px;
                            }
                        }

                        .edit {
                            padding: 4px 0 0 0;
                            display: inline-block;

                            @include breakpoint($tabletAll) {
                                padding-top: 0;
                            }

                            a {
                                background: rgba(0, 0, 0, 0.2) url('../images/icon-pencil.png') 11px 8px no-repeat;
                                @include image2x('../images/icon-pencil@2x.png', 14px, 14px);
                                border: 1px solid rgba(255, 255, 255, 0.2);
                                border-radius: 3px;
                                color: #FFF;
                                display: block;
                                width: 88px;
                                height: 28px;
                                font-size: em(12px);
                                line-height: 30px;
                                text-transform: uppercase;
                                text-decoration: none;
                                text-align: left;
                                padding-left: 31px;
                                margin-left: 15px;
                            }
                        }

                        .global-follow-button {
                            float: none;
                            margin: 5px 0 0 0;
                            display: inline-block;

                            @include breakpoint($tabletAll) {
                                display: none;
                            }

                            a {
                                display: inline-block !important;
                                margin: 0 !important;
                            }
                        }
                    }
                }
            }

            #profile-information {
                margin-left: 30%;
                height: calc(100% - 10px);
                outline: none;
                overflow-x: hidden;

                @include breakpoint($tabletPortrait) {
                    overflow: visible;
                }

                @include breakpoint($tabletAll) {
                    margin-left: 81px;
                    height: 100%;
                }

                #profile-wrapper {
                    width: 100% !important;
                    outline: none;

                    @include breakpoint($tabletAll) {
                        width: 100% !important;
                        height: 100% !important;
                    }
                }

                .jspPane {
                    width: 100% !important; // Overwrite inline style
                }

                .tabs {
                    background: #35373B;
                    overflow: hidden;

                    ul {
                        padding-left: 50px;
                        float: left;

                        @include breakpoint($tabletAll) {
                            padding-left: 170px;

                            li:last-child {
                                margin-right: 0;
                            }
                        }

                        li {
                            float: left;
                            margin-right: 37px;

                            a {
                                @include all-transitions;
                                font-family: $font-light;
                                font-size: em(14px);
                                color: #FFF;
                                display: block;
                                height: 48px;
                                line-height: 52px;
                                text-decoration: none;
                                border-bottom: 2px solid transparent;
                            }

                            &.active a {
                                border-bottom: 2px solid $primary;
                            }
                        }
                    }

                    .friend-count {
                        float: right;
                        padding: 0 50px 0 0;

                        @include breakpoint($tabletAll) {
                            padding-right: 20px;

                            .followers {
                                margin-left: 0;
                            }
                        }

                        p {
                            color: #BEBEBE;
                            font-family: $font-light;
                            font-size: em(14px);
                            float: left;
                            margin-left: 25px;

                            a,
                            .fake-link {
                                @include all-transitions;
                                display: block;
                                height: 48px;
                                line-height: 52px;
                                color: #BEBEBE;
                                text-decoration: none;
                                border-bottom: 2px solid transparent;

                                span {
                                    font-family: $font-medium;
                                    color: #FFF;

                                    &.follow-requests {
                                        background: #3D86C8;
                                        border-radius: 3px;
                                        font-family: Arial;
                                        font-size: 12px;
                                        height: 13px;
                                        padding: 2px 4px 0 4px;
                                        margin-right: 4px;
                                    }
                                }

                                &.active {
                                    border-color: $primary;
                                }
                            }
                        }
                    }
                }

                #profile-overview {
                    display: block;

                    .surf-graphs {
                        background: #232528;
                        width: 940px;
                        height: 373px;
                        padding-top: 61px;
                        margin: 0 auto;

                        @include breakpoint($tabletAll) {
                            width: 650px;
                            padding: 60px 20px 0 20px;
                        }

                        .tab-section {
                            display: none;

                            &.graph-28-days {
                                display: block;
                            }
                        }

                        .follow-tabs {
                            margin-bottom: 0;

                            li {
                                font-size: em(16px);
                            }
                        }

                        .graph {
                            background: url('../images/bg-profile-graph.png') repeat-x;
                            position: relative;
                            width: 940px;
                            height: 253px;

                            @include breakpoint($tabletAll) {
                                width: 640px;
                            }

                            &.all_time {

                                .tooltip {
                                    margin-left: -37px;
                                }
                            }

                            &.year {

                                .tooltip {
                                    margin-left: -62px;
                                }
                            }

                            .graph-controls {
                                position: absolute;
                                background: #35373B;
                                border-radius: 3px;
                                height: 21px;
                                padding: 4px;
                                top: 20px;
                                right: 0;

                                a {
                                    float: left;
                                    display: block;
                                    height: 21px;
                                    line-height: 23px;
                                    padding-left: 26px;
                                    color: #FFF;
                                    text-transform: uppercase;
                                    font-family: $font-light;
                                    font-size: em(12px);
                                    text-decoration: none;
                                    margin-right: 21px;

                                    &.wave-count {
                                        background: url('../images/icon-wave-count-blank.png') no-repeat;
                                        @include image2x('../images/icon-wave-count-blank@2x.png', 21px, 21px);

                                        &.only {
                                            margin-right: 4px;
                                        }

                                        &.active {
                                            background: url('../images/icon-wave-count.png') no-repeat;
                                            @include image2x('../images/icon-wave-count@2x.png', 21px, 21px);
                                        }
                                    }

                                    &.swell-height {
                                        background: url('../images/icon-swell-height-blank.png') no-repeat;
                                        @include image2x('../images/icon-swell-height-blank@2x.png', 21px, 21px);
                                        margin-right: 4px;

                                        &.active {
                                            background: url('../images/icon-swell-height.png') no-repeat;
                                            @include image2x('../images/icon-swell-height@2x.png', 21px, 21px);
                                        }
                                    }
                                }
                            }

                            ol {
                                position: absolute;
                                bottom: 0;
                                height: 177px;

                                li {
                                    font-size: em(12px);
                                    font-family: $font-light;
                                    height: 39px;
                                    padding: 0 2px 2px 6px;
                                }

                                &.wave-count {
                                    left: 0;

                                    li {
                                        color: #6297C8;
                                        text-align: left;
                                    }
                                }

                                &.swell-height {
                                    right: 0;

                                    li {
                                        color: #FFF;
                                        text-align: right;
                                    }
                                }

                                &.weeks, &.ytd-months {
                                    width: 100%;
                                    top: 262px;
                                    height: 20px;

                                    li {
                                        width: 223px;

                                        @include breakpoint($tabletAll) {
                                            width: 154px;
                                        }

                                        height: auto;
                                        float: left;
                                        text-transform: uppercase;
                                        color: #FFF;
                                        text-align: left;
                                        font-size: 12px;
                                        font-family: $font-light;
                                        box-sizing: border-box;
                                        padding: 0;
                                    }
                                }

                                &.weeks {

                                    li {

                                        &.first {
                                            padding-left: 20px;
                                        }

                                        &.second {
                                            padding-left: 22px;
                                            width: 216px;

                                            @include breakpoint($tabletAll) {
                                                padding-left: 0;
                                                width: 154px;
                                            }
                                        }
                                    }
                                }

                                &.ytd-months {

                                    li {
                                        width: 78px;

                                        @include breakpoint($tabletAll) {
                                            width: 51px;
                                        }

                                        &.jan {
                                            padding-left: 20px;

                                            @include breakpoint($tabletAll) {
                                                padding-left: 0;
                                                margin-left: 20px;
                                            }
                                        }
                                    }
                                }
                            }

                            &.all_time .bars {
                                overflow: auto;
                                height: 207px;
                                bottom: -40px;
                                outline: none;

                                @include breakpoint($tabletAll) {
                                    width: calc(100% - 21px - 10px) !important;
                                    margin-left: 10px;
                                }

                                .jspHorizontalBar {
                                    display: block !important;
                                    bottom: -5px;
                                }

                                .jspTrack {
                                    background: #35373B;
                                    width: 100% !important;
                                    height: 10px;
                                    padding: 2px;
                                    border-radius: 10px;
                                    box-sizing: border-box;
                                    overflow: hidden;

                                    .jspDrag {
                                        background: #868789;
                                        height: 6px;
                                        border-radius: 10px;
                                        display: block !important;
                                    }
                                }
                            }

                            .bars {
                                width: calc(100% - 21px) !important;
                                height: 167px;
                                position: absolute;
                                bottom: 0;
                                left: 21px;

                                ol {
                                    position: relative;
                                    height: 167px;

                                    .bar {
                                        @include all-transitions;
                                        position: absolute;
                                        bottom: 0;
                                        width: 27px;

                                        @include breakpoint($tabletAll) {
                                            width: 16px;
                                        }

                                        height: 167px;
                                        border-top-left-radius: 2px;
                                        border-top-right-radius: 2px;
                                        padding: 0;

                                        &.no-surfs-day {
                                            opacity: 1;
                                            border-radius: 0;

                                            .wave-count {
                                                background: #972213;
                                                opacity: 1;
                                            }
                                        }

                                        &:hover,
                                        &.hover {
                                            .wave-count,
                                            .swell-height {
                                                opacity: 1;
                                            }
                                        }

                                        span {
                                            @include all-transitions;
                                            display: block;
                                            width: 27px;

                                            @include breakpoint($tabletAll) {
                                                width: 16px;
                                            }

                                            position: absolute;
                                            bottom: 0;
                                            border-top-left-radius: 2px;
                                            border-top-right-radius: 2px;
                                            cursor: pointer;
                                            opacity: 0.4;

                                            &.wave-count {
                                                background: #3D86C8;
                                                z-index: 1;
                                            }

                                            &.swell-height {
                                                background: url('../images/bg-stripe-pattern.png') repeat;
                                                z-index: 2;
                                            }

                                            &.month {
                                                position: absolute;
                                                bottom: -20px;
                                                width: 70px;
                                                height: auto;
                                                text-transform: uppercase;
                                                color: #FFF;
                                                text-align: center;
                                                font-size: 11px;
                                                font-family: $font-light;
                                                opacity: 1;
                                            }
                                        }
                                    }

                                    &.all_time .bar {
                                        width: 70px;

                                        @include breakpoint($tabletAll) {
                                            width: 40px;
                                        }

                                        span {
                                            width: 70px;
                                            @include breakpoint($tabletAll) {
                                                width: 40px;
                                            }
                                        }
                                    }

                                    &.year .bar {
                                        width: 15px;
                                        margin-right: 2px;

                                        @include breakpoint($tabletAll) {
                                            width: 9px;
                                            margin-right: 1px;
                                        }

                                        span {
                                            width: 15px;

                                            @include breakpoint($tabletAll) {
                                                width: 9px;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }

                    .profile-stats {
                        background: #27292C;
                        padding: 46px 0 52px 0;

                        @include breakpoint($tabletLandscape) {
                            width: auto;
                            padding-left: 40px;
                            padding-right: 40px;
                        }

                        @include breakpoint($tabletPortrait) {
                            width: 100%;
                            padding-left: 20px;
                            padding-right: 20px;
                            box-sizing: border-box;
                        }

                        .inner {
                            width: 728px;
                            margin: 0 auto;
                            @include clearfix;

                            @include breakpoint($tabletPortrait) {
                                width: 100%;
                            }

                            @include breakpoint($tabletLandscape) {
                                width: 728px;
                            }
                        }

                        .column {

                            &.wave-stats {
                                width: 292px;
                                float: left;

                                @include breakpoint($tabletAll) {
                                    width: 300px;
                                }

                                @include breakpoint($tabletPortrait) {
                                    width: 300px;
                                }

                                @include breakpoint($tabletLandscape) {

                                }

                                .stat {
                                    @include all-transitions;
                                    @include clearfix;
                                    border-bottom: 1px solid #323437;
                                    padding-top: 24px;

                                    &.total-waves {
                                        padding-top: 0;
                                    }

                                    h2 {
                                        float: left;
                                        width: 88px;
                                        font-family: $font-light;
                                        font-size: em(18px);
                                        line-height: 22px;
                                        color: #8E8F8F;
                                        padding-top: 15px;
                                        padding-left: 10px;
                                    }

                                    .value {
                                        float: right;
                                        font-family: $font-thin;
                                        font-size: em(66px);
                                        color: #FFF;
                                        padding-bottom: 15px;
                                        border-bottom: 2px solid #6A9CCB;
                                        padding-right: 10px;
                                    }

                                    &.longest-wave .value {
                                        border-color: #E1270D;
                                    }

                                    &.longest-wave[data-surf-id],
                                    &.top-speed[data-surf-id] {
                                        cursor: pointer;
                                        &:hover {
                                            background: #36383C;
                                        }
                                    }
                                }
                            }

                            &.surf-stats {
                                float: right;
                                width: 301px;

                                @include breakpoint($tabletAll) {
                                    width: 300px;
                                }

                                .header {
                                    padding-bottom: 10px;
                                    border-bottom: 1px solid #323437;
                                    margin-bottom: 14px;
                                    @include clearfix;

                                    h2 {
                                        float: left;
                                        font-family: $font-light;
                                        font-size: em(16px);
                                        color: #68696A;
                                        padding-top: 10px;
                                    }

                                    a {
                                        background: #232528;
                                        display: inline-block;
                                        border: 1px solid #525456;
                                        border-radius: 3px;
                                        float: right;
                                        width: 109px;
                                        height: 28px;
                                        line-height: 31px;
                                        color: #BCBDBD;
                                        font-family: $font-light;
                                        font-size: em(11px);
                                        text-align: center;
                                        text-decoration: none;
                                        text-transform: uppercase;
                                    }
                                }

                                .stat {
                                    padding-bottom: 15px;
                                    border-bottom: 1px solid #323437;
                                    margin-bottom: 17px;
                                    @include clearfix;

                                    &.surfs h3 {
                                        background: url('../images/icon-profile-surfs.png') no-repeat;
                                        @include image2x('../images/icon-profile-surfs@2x.png', 32px, 32px);
                                    }

                                    &.distance h3 {
                                        background: url('../images/icon-profile-distance.png') no-repeat;
                                        @include image2x('../images/icon-profile-distance@2x.png', 32px, 32px);
                                    }

                                    &.water-time h3 {
                                        background: url('../images/icon-profile-water-time.png') no-repeat;
                                        @include image2x('../images/icon-profile-water-time@2x.png', 32px, 32px);
                                    }

                                    &.days-surfed h3 {
                                        background: url('../images/icon-profile-days-surfed.png') no-repeat;
                                        @include image2x('../images/icon-profile-days-surfed@2x.png', 32px, 32px);
                                        position: relative;

                                        span {
                                            position: absolute;
                                            font-size: 9px;
                                            top: 2px;
                                            left: 8px;
                                            display: block;
                                            width: 14px;
                                            text-align: center;
                                        }
                                    }

                                    h3 {
                                        float: left;
                                        height: 32px;
                                        margin-left: -4px;
                                        font-family: $font-light;
                                        font-size: em(16px);
                                        color: #969797;
                                        line-height: 32px;
                                        padding-left: 40px;
                                    }

                                    .value {
                                        float: right;
                                        color: #FFF;
                                        font-family: $font-light;
                                        font-size: em(20px);
                                        padding: 10px 15px 0 0;

                                        span {
                                            color: #BEBEBE;
                                            font-size: 12px;
                                        }
                                    }
                                }
                            }
                        }
                    }

                    .bottom {
                        width: 940px;
                        margin: 0 auto;
                        padding: 60px;

                        @include breakpoint($tabletAll) {
                            width: 650px;
                            padding-left: 20px;
                            padding-right: 20px;
                        }

                        #profile-latest-surf {
                            position: relative;
                            background: #000;
                            width: 940px;
                            height: 395px;
                            cursor: pointer;

                            @include breakpoint($tabletAll) {
                                width: 650px;
                            }

                            .surf-stats {
                                position: absolute;
                                background: rgba(0, 0, 0, 0.3);
                                width: 934px;
                                height: 60px;
                                padding: 6px 0 0 6px;
                                bottom: 0;
                                left: 0;
                                z-index: 1;

                                @include breakpoint($tabletAll) {
                                    width: 644px;
                                }

                                .photo {
                                    width: 81px;
                                    height: 55px;
                                    float: left;
                                    margin-right: 20px;

                                    img {
                                        width: 81px;
                                        height: 55px;
                                    }
                                }

                                .location {
                                    float: left;
                                    margin-right: 40px;
                                    padding-top: 11px;

                                    .beach-name {
                                        background: url('../images/icon-location-pin-medium-white.png') no-repeat;
                                        @include image2x('../images/icon-location-pin-medium-white@2x.png', 11px, 16px);
                                        height: 12px;
                                        font-family: $font-light;
                                        font-size: em(16px);
                                        color: #FFF;
                                        padding: 4px 0 0 19px;
                                        margin-bottom: 6px;
                                    }

                                    .date-time {
                                        font-size: em(11px);
                                        color: #B8BDBE;
                                    }
                                }

                                .rating {
                                    float: left;
                                    margin: 27px 37px 0 0;
                                }

                                .top-speed {
                                    background: url('../images/icon-lightning-bolt-small.png') bottom 4px left no-repeat;
                                    @include image2x('../images/icon-lightning-bolt-small@2x.png', 8px, 13px);
                                    float: left;
                                    margin-right: 40px;
                                    padding: 19px 0 0 14px;

                                    .value {
                                        font-family: $font-thin;
                                        font-size: em(26px);
                                        color: #FFF;
                                    }

                                    .unit {
                                        font-size: em(11px);
                                        color: #B8BDBE;
                                    }
                                }

                                .total-waves {
                                    float: left;
                                    padding-top: 10px;
                                    text-align: center;

                                    .value {
                                        display: block;
                                        font-family: $font-thin;
                                        font-size: em(36px);
                                        color: #FFF;
                                    }

                                    .label {
                                        display: block;
                                        font-size: em(11px);
                                        color: #B8BDBE;
                                    }
                                }
                            }

                            #profile-latest-surf-map {
                                width: 940px;
                                height: 395px;

                                @include breakpoint($tabletAll) {
                                    width: 650px;
                                }
                            }
                        }
                    }
                }

                #profile-map {
                    position: relative;

                    .zoom-controls {
                        position: absolute;
                        z-index: 1;
                        top: 27px;
                        left: 20px;
                    }

                    .customInfoWindow {
                        background: aqua;
                        border-left: #df1b00 4px;
                        background: url('../images/bg-infoBox-border.png') no-repeat top left rgba(28,29,31,0.8);
                        height: 70px;
                        border-radius: 3px;

                        a {
                            display: block;
                            text-decoration: none;
                            height: 100%;
                            width: 100%;
                        }

                        .main-content {
                            color: #FFFFFF;
                            font: 16px $font-light;
                            padding-top: 10px;
                            padding-left: 20px;
                            position: relative;

                            &.individual {
                                margin-top: -17px;
                            }

                            .surfs {
                                color: #b3b3b3;
                                font: 16px $font-light;
                            }

                            .arrow {
                                background: url('../images/bg-infoBox-arrow.png') no-repeat;
                                position: absolute;
                                height: 7px;
                                width: 14px;
                                display: block;
                                top: 70px;
                                right: 20px;
                            }
                        }
                    }
                }

                #profile-boards {
                    @include clearfix;

                    .boards {
                        padding-top: 51px;
                        padding-left: 80px;
                        @include clearfix;
                        width: 100%;
                        height: 100%;
                        box-sizing: border-box;

                        .add {
                            position: relative;
                            font-family: $font-thin;
                            border: 1px dashed #36383A;
                            width: 298px;
                            height: 218px;
                            float: left;
                            margin: 10px;
                            background: #1F2124 url('../images/icon-add-board.png') 124px 70px no-repeat;
                            @include image2x('../images/icon-add-board.png', 50px, 50px);
                            cursor: pointer;

                            .label {
                                position: absolute;
                                top: 126px;
                                left: 103px;
                                font-family: $font-thin;
                                font-size: 18px;
                                color: #949495;
                            }
                        }

                        .board {
                            @include all-transitions;
                            width: 300px;
                            height: 220px;
                            font-family: $font-thin;
                            position: relative;
                            margin: 10px;
                            float: left;
                            border-top: 2px solid transparent;
                            overflow: hidden;

                            .board-edit {
                                display: none;

                                .select-group {
                                    height: 30px;
                                    margin-bottom: 5px;
                                    clear: both;

                                    label {
                                        margin-right: 10px;
                                    }

                                    select {
                                        width: 93px !important;
                                        float: left !important;
                                        margin-bottom: 0 !important;

                                        &.right-select {
                                            float: right !important;
                                        }
                                    }
                                }
                            }

                            .inner {
                                background-color: #36383C;
                                width: 262px;
                                height: 172px;
                                color: #ffffff;
                                font-size: em(40px);
                                padding-left: 18px;
                                padding-right: 20px;

                                .title {
                                    display: block;
                                    line-height: 36px;
                                    padding-top: 38px;
                                    word-break: break-word;
                                }

                                .dimensions {
                                    color: #a2a3a4;
                                    font-size: em(16px, 40px);
                                    display: block;
                                    position: absolute;
                                    left: 19px;
                                    bottom: 65px;
                                }
                            }

                            .bottom {
                                @include all-transitions;
                                background: #303236;
                                width: 300px;
                                height: 36px;
                                padding-top: 12px;
                                position: absolute;
                                bottom: 0;

                                .waves {
                                    padding-left: 17px;
                                    display: inline-block;

                                    .value {
                                        font-size: em(26px);
                                        color: #FFF;
                                    }

                                    .label {
                                        font-family: Arial;
                                        font-size: em(11px);
                                        color: #BEBEBE;
                                    }
                                }
                                .speed {
                                    display: inline-block;
                                    background: url('../images/icon-lightning-bolt-small.png') 27px 8px no-repeat;
                                    @include image2x('../images/icon-lightning-bolt-small@2x.png', 8px, 13px);
                                    padding-left: 20px;

                                    .value {
                                        color: #ffffff;
                                        font-size: em(26px);
                                        padding-left: 17px;
                                    }

                                    .label {
                                        color: #bebebe;
                                    }

                                    .measurement {
                                        font-family: Arial;
                                        font-size: em(11px);
                                        color: #bebebe;
                                        padding-left: 2px;
                                    }
                                }

                                .edit {
                                    display: inline-block;
                                    background: url('../images/icon-pencil.png') no-repeat;
                                    @include image2x('../images/icon-pencil@2x.png', 14px, 14px);
                                    display: block;
                                    position: absolute;
                                    height: 48px;
                                    width: 48px;
                                    background-position: center;
                                    bottom: 0;
                                    right: 0;

                                    &:hover {
                                        background-color: #36383C;
                                    }
                                }
                            }

                            &:hover {
                                border-color: $primary;

                                .inner {
                                    background: #404246;
                                }

                                .bottom {
                                    background: #393B3F;
                                }
                            }

                            &.edit,
                            &.new-board {
                                border-color: $primary;

                                .inner {
                                    background: #404246;
                                    padding-top: 20px;
                                    height: 152px;

                                    input[type=text],
                                    select {
                                        float: right;
                                        width: 190px;
                                        font-family: Arial;
                                        font-size: em(14px, 40px);
                                        margin-bottom: 5px;
                                        height: 30px;
                                        color: #B8B8B8;
                                    }

                                    input[type=text] {
                                        height: 16px;
                                        border: 0;
                                        padding: 7px 9px;
                                        width: 172px;
                                        border-radius: 3px;
                                        margin: 0 0 5px 0;
                                        height: 16px;
                                    }

                                    select {
                                        text-indent: 9px;
                                    }

                                    label {
                                        font-family: $font-light;
                                        font-size: em(11px, 40px);
                                        text-transform: uppercase;
                                        float: left;
                                        clear: right;
                                        text-align: right;
                                        width: 62px;
                                        padding-top: 11px;
                                    }
                                }

                                .bottom {
                                    background: #393B3F;
                                    height: 39px;
                                    padding-top: 9px;
                                    padding-left: 20px;
                                    width: 280px;

                                    a {
                                        font-family: $font-light;
                                        font-size: em(14px);
                                        width: 78px;
                                        height: 28px;
                                        border: 1px solid #595B5E;
                                        border-radius: 3px;
                                        display: block;
                                        color: #FFF;
                                        background-color: #2B2D30;
                                        text-decoration: none;
                                        text-align: center;
                                        line-height: 28px;
                                        float: left;
                                        margin-right: 11px;

                                        &.last {
                                            margin-right: 0;
                                        }
                                    }
                                }
                            }

                            &.new-board {

                                .bottom {
                                    @include remove-all-transitions;
                                    padding-right: 20px;
                                    width: 260px;

                                    .delete {
                                        display: none;
                                    }

                                    .save {
                                        float: right;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        #edit-profile {
            display: none;
            height: 100%;
            margin-left: 80px;

            .header {
                width: 100%;
                height: 226px;
                position: relative;

                .top {
                    background: rgba(35, 37, 40, 0.95);
                    width: 100%;
                    height: 40px;
                    position: absolute;
                    top: 0;
                    left: 0;
                    z-index: 1;

                    h2 {
                        color: #676A6C;
                        font-family: $font-light;
                        font-size: em(18px);
                        float: left;
                        text-indent: 20px;
                        padding-top: 12px;
                    }

                    .buttons {
                        float: right;
                        padding-right: 42px;

                        a {
                            @include all-transitions;
                            display: inline-block;
                            height: 40px;
                            line-height: 40px;
                            text-decoration: none;
                            color: #FFF;
                            font-family: $font-light;
                            font-size: em(14px);
                            padding: 0 20px;

                            &:hover {
                                background: #36383C;
                            }
                        }
                    }
                }

                #edit-cover-photo {
                    width: 100%;
                    height: 226px;
                    position: absolute;
                    top: 0;
                    left: 0;
                    overflow: hidden;

                    img {
                        width: 100%;
                    }
                }

                .profile-photo {
                    position: absolute;
                    top: 121px;
                    left: 69px;
                    z-index: 2;

                    #profile-photo-placeholder {
                        width: 120px;
                        height: 120px;
                        border-radius: 100px;
                        background-position: center;
                        background-size: cover;
                    }
                }

                .bottom {
                    background: rgba(0, 0, 0, 0.4);
                    position: absolute;
                    bottom: 0;
                    width: 100%;
                    height: 50px;

                    .buttons {
                        padding-left: 241px;

                        span {
                            @include all-transitions;
                            position: relative;
                            display: block;
                            padding: 0 10px;
                            float: left;
                            height: 50px;
                            line-height: 52px;
                            color: #FFF;
                            text-decoration: none;
                            font-family: $font-light;
                            font-size: em(12px);
                            text-transform: uppercase;
                            overflow: hidden;
                            cursor: pointer;

                            &:hover {
                                background: rgba(255, 255, 255, 0.1);
                            }

                            .dz-preview {
                                display: none;
                            }
                        }
                    }
                }
            }

            .edit-profile-form {
                width: 390px;
                margin-left: 150px;
                padding-top: 43px;

                @include breakpoint($tabletLandscape) {
                    padding-bottom: 100px;
                }

                p {
                    margin-bottom: 10px;
                    height: 40px;
                }

                label {
                    color: #BEBEBE;
                    font-family: $font-light;
                    font-size: em(12px);
                    padding-top: 16px;
                    width: 82px;
                    padding-right: 20px;
                    text-align: right;
                    text-transform: uppercase;
                    float: left;
                }

                input.text,
                select {
                    background-color: #FFF;
                    border-radius: 3px;
                    height: 40px;
                    color: #B8B8B8;
                    border: none;
                    outline: none;
                }

                input.text {
                    width: 265px;
                    padding-left: 15px;
                    -webkit-appearance: none;
                }

                .small input.text {
                    width: 55px;
                }

                select {
                    float: left;
                    width: 282px;

                    &.day {
                        width: 80px;
                        margin-right: 5px;
                    }

                    &.month {
                        width: 90px;
                        margin-right: 5px;
                    }

                    &.year {
                        width: 100px;
                    }
                }

                .buttons {
                    height: 38px;
                    padding-left: 101px;

                    .btn {
                        float: left;
                        margin-right: 10px;
                        width: 136px;

                        &.save {
                            margin-right: 0;
                            -webkit-appearance: none;
                        }
                    }
                }
            }
        }
    }

    // Watch
    #view-watch {
        margin-left: 80px;

        .scroll-pane {
            width: 100%;
            height: 100%;
        }

        .card-one {
            background: url('../images/bg-watch-background.png') no-repeat;
            background-size: cover;
            height: 549px;

            .left {
                margin-left: 88px;
                width: 350px;
                float: left;

                .subscribe-button-inner {
                    display: inline-block !important;
                }

                .heading {
                    font: em(60px) $font-thin;
                    color: #2f2f2f;
                    padding-top: 30px;
                    margin-left: -2px;
                    line-height: 55px;
                    text-align: center;
                }

                .description {
                    font: em(14px) $font-light;
                    color: #2f2f2f;
                    padding-top: 11px;
                    line-height: 22px;
                }

                ul {
                    margin: 20px 0;

                    li {
                        font: em(14px) $font-light;
                        color: #2f2f2f;
                        line-height: 22px;
                        background: url('/images/welcome/li.png') 0% 50% no-repeat;
                        padding-left: 15px;
                    }
                }

                .cta {
                    font: em(20px) $font-light;
                    color: #df1b00;
                    display: block;
                    text-decoration: none;
                    margin-top: 14px;
                    padding-top: 3px;
                    width: 150px;

                    background: url('../images/icon-buy-watch-cta.png') no-repeat right 0 top 0;
                    @include image2x('../images/icon-buy-watch-cta@2x.png', 24px, 24px);

                    &:hover {
                        background: url('../images/icon-buy-watch-cta-hover.png') no-repeat right 0 top 0;
                        @include image2x('../images/icon-buy-watch-cta-hover@2x.png', 24px, 24px);
                        text-decoration: underline;
                    }
                }
            }
            .right {
                margin-left: 408px;

                .watches {
                    position: relative;

                    .watch {
                        background: url('/images/welcome/watches.png') no-repeat;
                        @include image2x('../images/welcome/watches@2x.png', 576px, 429px);
                        width: 576px;
                        height: 429px;
                        position: absolute;
                        top: 40px;
                        left: 80px;

                        &.white {
                            background-position: 0px -428px;
                        }
                    }

                    .watch-small {
                        width: 290px;
                        height: 341px;

                        top: 118px;
                        left: 131px;

                        &.black {
                            background: url('../images/buy-watch-small-black.png') no-repeat;
                            @include image2x('../images/buy-watch-small-black.png', 290px, 341px);
                        }
                        &.white {
                            background: url('../images/buy-watch-small-white.png') no-repeat;
                            @include image2x('../images/buy-watch-small-white.png', 290px, 341px);
                        }
                    }

                    .watch-large {
                        width: 377px;
                        height: 381px;
                        position: absolute;
                        top: 100px;
                        left: 245px;

                        &.black {
                            background: url('../images/buy-watch-large-black.png') no-repeat;
                            @include image2x('../images/buy-watch-large-black@2x.png', 377px, 381px);
                        }

                        &.white {
                            background: url('../images/buy-watch-large-white.png') no-repeat;
                            @include image2x('../images/buy-watch-large-white@2x.png', 377px, 381px);
                        }
                    }

                    .color-swatch {
                        font: em(12px) $font-light;
                        color: #2f2f2f;
                        position: absolute;
                        top: 434px;
                        left: 291px;

                        ul {

                            width: 97px;

                            li {
                                display: inline-block;
                                text-align: center;
                                width: 47px;
                                padding-top: 35px;
                                cursor: pointer;
                            }
                            .white {
                                background: url('../images/icon-color-swatch-white.png') no-repeat;
                                @include image2x('../images/icon-color-swatch-white@2x.png', 28px, 28px);
                                background-position: 50% 2px;


                                &:hover,
                                &.active {
                                    background: url('../images/icon-color-swatch-white.png') no-repeat;
                                    @include image2x('../images/icon-color-swatch-white@2x.png', 32px, 32px);
                                    background-position: 50% 0px;
                                }

                                .text {
                                }
                            }

                            .black {
                                background: url('../images/icon-color-swatch-black.png') no-repeat;
                                @include image2x('../images/icon-color-swatch-black@2x.png', 28px, 28px);
                                background-position: 50% 2px;

                                &:hover,
                                &.active {
                                    background: url('../images/icon-color-swatch-black-active.png') no-repeat;
                                    @include image2x('../images/icon-color-swatch-black-active@2x.png', 32px, 32px);
                                    background-position: 50% 0px;
                                }

                                .text {
                                    padding-left:1px;
                                }
                            }
                        }
                    }
                }
            }
        }

        .card-two {
            height: 540px;
            position: relative;
            background-color: #2e3033;

            .left {
                float: left;
                width: 560px;

                .heading {
                    font: em(40px) $font-thin;
                    color: #ffffff;
                    padding-top: 65px;
                    padding-left: 210px;
                }

                .sub-heading {
                    font: em(16px) $font-light;
                    color: #999999;
                    padding-left: 155px;
                    padding-top: 2px;
                }

                .app-store {
                    display: block;
                    width: 127px;
                    height: 36px;
                    background: url('../images/icon-app-store-icon.png') no-repeat;
                    @include image2x('../images/icon-app-store-icon@2x.png', 127px, 36px);
                    margin-top: 24px;
                    margin-left: 238px;
                }

                .bluetooth-connection {
                    width: 348px;
                    height: 313px;
                    background: url('../images/buy-watch-iphone.png') no-repeat;
                    @include image2x('../images/buy-watch-iphone@2x.png', 375px, 313px);
                    margin-left: 113px;
                    margin-top: 20px;
                }

                &:after {
                    display: block;
                    width: 1px;
                    height: 30px;
                    content: ' ';
                    background-color: #58595c;
                    position: absolute;
                    left: 559px;
                    top: 80px;
                    height: 418px;
                }
            }

            .right {
                float: left;
                width: 560px;

                .heading {
                    font: em(40px) $font-thin;
                    color: #ffffff;
                    padding-top: 67px;
                    padding-left: 147px;
                }

                .sub-heading {
                    font: em(16px) $font-light;
                    color: #999999;
                    padding-top: 2px;
                    padding-left: 121px;
                }

                .usb-connection {
                    width: 349px;
                    height: 179px;
                    background: url('../images/usb-connection.png') no-repeat;
                    @include image2x('../images/usb-connection@2x.png', 349px, 179px);
                    margin-top: 46px;
                    margin-left: 65px;
                }

                a {
                    color: #ffffff;
                    border-bottom: 2px solid #486e91;
                    border-radius: 3px;
                    display: block;
                    font: em(14px) $font-light;
                    text-decoration: none;
                    margin: 35px 0 0 114px;
                    padding: 19px 0 14px 65px;
                    width: 190px;

                    &.app-download {
                        background: #6093C2;
                        // cursor: default;
                        text-align: center;
                        padding-left: 0;
                        width: 265px;
                    }

                    &.mac-download {
                        background: #6093C2 url('../images/icon-mac-logo.png') no-repeat 19px 8px;
                        @include image2x('../images/icon-mac-logo@2x.png', 26px, 32px);
                    }

                    &.windows-download {
                        background: #3d86C8 url('../images/icon-windows-logo.png') no-repeat 17px 8px;
                        @include image2x('../images/icon-windows-logo@2x.png', 32px, 32px);
                    }
                }
            }
        }

        .card-three {
            background: #232528;
            min-height: 556px;
            width: 1120px;
            overflow: hidden;

            .left {
                padding-left: 91px;
                padding-top: 154px;
                width: 550px;
                float: left;

                .title {
                    font: em(20px) $font-light;
                    color: #ffffff;
                    padding-bottom: 1px;
                }

                li {
                    font: em(14px) $font-light;
                    color: #999999;
                    background: url('../images/icon-red-bullet-point.png') no-repeat 1px 7px;
                    @include image2x('../images/icon-red-bullet-point@2x.png', 5px, 5px);
                    padding-left: 12px;
                    line-height: 20px;
                }
                ul ul li {
                    margin-left: 12px;
                }
                .system-requirements-ios {
                    background: url(../images/icon-ios-dark.png) no-repeat;
                    @include image2x('../images/icon-ios-dark@2x.png', 37px, 37px);
                    padding-left: 51px;
                    padding-top: 11px;
                    padding-bottom: 11px;
                }

                .system-requirements-mac {
                    background: url(../images/icon-mac-dark.png) no-repeat;
                    @include image2x('../images/icon-mac-dark@2x.png', 37px, 37px);
                    padding-top: 11px;
                    padding-left: 51px;
                    padding-bottom: 27px;
                }

                .system-requirements-windows {
                    background: url(../images/icon-windows-dark.png) no-repeat;
                    @include image2x('../images/icon-windows-dark@2x.png', 37px, 37px);
                    padding-left: 51px;
                    padding-top: 5px;
                }
            }

            .right {
                float: left;
                .heading {
                    font: em(50px) $font-thin;
                    color: #ffffff;
                    padding-top: 165px;
                    padding-left: 58px;
                    padding-bottom: 26px;
                }

                a {
                    font: em(14px) $font-light;
                    background-color: #1f2124;
                    border: 1px solid #4f5153;
                    border-radius: 3px;
                    color: #ffffff;
                    text-decoration: none;
                    margin: 0 0 8px 63px;
                    width: 191px;
                    padding: 18px 0 16px 62px;
                    display: block;

                    .thin {
                        font: em(14px, 14px) $font-thin;
                    }

                    &.support-website {
                        background: url('../images/icon-support.png') no-repeat 16px 9px;
                        @include image2x('../images/icon-support@2x.png', 30px, 30px);
                    }

                    &.watch-manual {
                        background: url('../images/icon-pdf.png') no-repeat 16px 9px;
                        @include image2x('../images/icon-pdf@2x.png', 30px, 30px);
                    }
                }
            }
        }

        .download-box a {
            color: #ffffff;
            border-bottom: 2px solid #486e91;
            border-radius: 3px;
            display: block;
            font: em(14px) $font-light;
            text-decoration: none;
            margin: 35px 0 0 114px;
            padding: 19px 0 14px 65px;
            width: 190px;

            &.app-download {
                background: #6093C2;
                // cursor: default;
                text-align: center;
                padding-left: 0;
                width: 265px;
            }

            &.mac-download {
                background: #6093C2 url('../images/icon-mac-logo.png') no-repeat 19px 8px;
                @include image2x('../images/icon-mac-logo@2x.png', 26px, 32px);
            }

            &.windows-download {
                background: #3d86C8 url('../images/icon-windows-logo.png') no-repeat 17px 8px;
                @include image2x('../images/icon-windows-logo@2x.png', 32px, 32px);
            }
        }
    }

    // Settings
    #view-settings {
        margin-left: 80px;

        .header {

            .top {
                @include clearfix;
                height: 20px;
                padding: 150px 90px 0 90px;
            }

            .title {
                font-family: $font-thin;
                font-size: em(30px);
                color: #FFF;
                float: left;

                .value {
                    color: #BEBEBE;
                }
            }

            .buttons {
                float: right;
            }

            .nav {
                background: #35373B;
                height: 50px;
                margin: 30px 0 49px 0;

                ul {
                    margin-left: 90px;

                    li {
                        float: left;

                        &:first-child a {
                            margin-left: 0;
                        }

                        a {
                            @include all-transitions;
                            display: block;
                            color: #BEBEBE;
                            border-bottom: 2px solid transparent;
                            font-family: $font-light;
                            font-size: em(14px);
                            text-decoration: none;
                            line-height: 50px;
                            margin: 0 20px;
                            height: 48px;

                            &:hover {
                                color: #FFF;
                                border-color: $primary;
                                height: 48px;
                            }
                        }

                        &.active a {
                            color: #FFF;
                            border-color: $primary;
                            height: 48px;
                        }
                    }
                }
            }
        }

        .content {
            padding-left: 90px;

            .tab-section {
                display: none;
            }

            .tab-account {
                display: block;
            }

            .row {
                margin-bottom: 10px;

                &.profile-privacy {
                    margin-bottom: 20px;
                }

                &.profile-privacy,
                &.surf-privacy {
                    height: 142px;
                }
            }

            .tab-about {

                h2 {
                    color: #FFF;
                    font-family: $font-thin;
                    font-size: 24px;
                    margin-bottom: 10px;

                    &.weather {
                        margin-top: 40px;
                    }
                }

                p {
                    color: #BEBEBE;
                    font-size: 14px;
                    line-height: 17px;
                    font-family: $font-light;
                    margin-bottom: 5px;
                }

                img {
                    display: block;
                    margin-bottom: 10px;
                }

                a {
                    color: #FFF;
                }
            }

            .btn {
                width: 280px;
                margin-left: 159px;

                &.support {
                    background: url('../images/icon-support-website.png') 18px 9px no-repeat;
                    @include image2x('../images/icon-support-website@2x.png', 30px, 30px);
                    padding-left: 70px;
                    width: 277px;
                    height: 48px;
                    line-height: 50px;
                    text-align: left;
                }

                &.manual {
                    background: url('../images/icon-manual.png') 18px 9px no-repeat;
                    @include image2x('../images/icon-manual@2x.png', 30px, 30px);
                    padding-left: 70px;
                    width: 277px;
                    height: 48px;
                    line-height: 50px;
                    text-align: left;
                }
            }

            label {
                font-family: $font-light;
                font-size: em(12px);
                text-transform: uppercase;
                color: #BEBEBE;
                float: left;
                text-align: right;
                padding: 14px 20px 0 0;
                width: 140px;
            }

            .text {
                color: #B8B8B8;
                border-radius: 3px;
                border: none;
                width: 267px;
                height: 40px;
                padding-left: 13px;
                outline: none;
                cursor: text;
            }

            .box {
                background: #36383C;
                width: 243px;
                height: 50px;
                padding: 20px 15px 0 22px;
                border-radius: 3px;
                float: left;

                &.public,
                &.private {
                    cursor: pointer;
                }

                .title {
                    display: block;
                    color: #FFF;
                    font-family: $font-light;
                    font-size: em(14px);
                    margin-bottom: 5px;
                }

                .name {
                    color: #9B9B9D;
                    font-family: $font-light;
                    font-size: em(12px);
                }

                .switcher {
                    float: right;
                    cursor: pointer;
                }

                .tick {
                    width: 17px;
                    height: 15px;
                    float: right;
                    margin-top: 10px;

                    &.ticked {
                        background: url('../images/icon-profile-tick.png') no-repeat;
                        @include image2x('../images/icon-profile-tick@2x.png', 17px, 15px);
                    }
                }
            }

            .boxes {
                float: left;

                .box {
                    float: none;
                    border-radius: 3px 3px 0 0;
                    border-bottom: 1px solid #26272A;

                    &.last {
                        border-radius: 0 0 3px 3px;
                        border-bottom: none;
                        border-top: 1px solid #4B4D51;
                    }
                }
            }

            select {
                width: 280px;
                height: 40px;
            }

            .change-password-lightbox,
            .change-email-lightbox {
                z-index: 4;

                .panel {
                    display: block;

                    .form-text {
                        border-radius: 0;

                        &.first {
                            border-radius: 3px 3px 0 0;
                        }

                        &.last {
                            border-radius: 0 0 3px 3px;
                        }
                    }
                }
            }
        }
    }

    // Clubs
    #view-clubs {
        margin-left: 80px;
        position: relative;
        overflow-x: hidden;

        &.club-disabled {
            overflow: hidden;
            height: 100% !important;

            #club {
                overflow: hidden;
                height: 100%;
            }
        }

        .find-surfers {
            display: block;
            padding: 0 90px;

            .no-results {
                h2 {
                    font-family: $font-thin;
                    font-size: 20px;
                    color: #FFF;
                    margin-bottom: 17px;
                }

                p {
                    font-family: $font-light;
                    font-size: 16px;
                    color: #A7A8A9;
                    margin-bottom: 17px;

                    a {
                        color: #FFF;
                        text-decoration: underline;
                    }
                }
            }

            @include breakpoint($tabletAll) {
                padding: 0 40px;
            }

            &.first-time {

                .follow-tabs {
                    padding-top: 20px;
                }

                .go-to-club {
                    float: right;
                    margin-top: -26px;
                }
            }

            .person {
                position: relative;
                height: 203px;
                margin-bottom: 20px;

                .bottom {
                    text-align: center;

                    .profile-info {
                        float: none;

                        .invite,
                        .invited {
                            position: absolute;
                            bottom: 15px;
                            left: 50%;
                            border: 1px solid rgba(255, 255, 255, 0.2);
                            border-radius: 3px;
                            color: #FFF;
                            display: inline-block;
                            width: 30px;
                            height: 20px;
                            font-size: em(11px);
                            line-height: 22px;
                            text-transform: uppercase;
                            text-decoration: none;
                            text-align: center;
                            padding: 0 15px;
                            font-family: $font-light;
                            margin: 10px 0 0 -30px;
                        }

                        .invited {
                            width: 37px;
                            margin-left: -35px;
                            cursor: default;
                        }
                    }
                }
            }
        }

        .club-overlay {
            background: rgba(0, 0, 0, 0.5);
            position: fixed;
            width: 100%;
            height: 100%;
            z-index: 2;
            display: none;
        }

        #club-logbook {
            position: fixed;
            width: 320px;
            height: 100%;
            top: 0;
            right: -400px;
            background: #232528;
            box-shadow: 0 -20px 50px 5px #000;
            z-index: 3;
            outline: none;

            .header a {
                background-image: url('../images/icon-close-arrow-r.png');
                @include image2x('../images/icon-close-arrow-r@2x.png', 7px, 12px);
            }
        }

        .club-section {
            display: none;
        }

        .top-banner {
            height: 111px;
            background-color: #1c1d1f;
            width: 100%;
            margin-bottom: 39px;

            &.no-breadcrumbs {

                .heading {
                    padding-top: 60px;

                    @include breakpoint($tabletAll) {
                        padding-left: 40px;
                    }
                }
            }

            &.members-heading {
                padding-top: 36px;
                box-sizing: border-box;

                .breadcrumbs {
                    padding-top: 10px;
                }
            }

            .heading {
                font: 30px $font-thin;
                color: #FFFFFF;
                display: block;
                padding-left: 86px;

                @include breakpoint($tabletAll) {
                    padding-left: 40px;
                }

                .label {
                    color: #BEBEBE;
                    font-size: 14px;
                    font-family: $font-light;
                }
            }

            .breadcrumbs {
                padding: 18px 0 0 86px;
                margin-bottom: 30px;
                height: 12px;

                @include breakpoint($tabletAll) {
                    padding-left: 0 40px;
                }

                li {
                    background: url('../images/icon-breadcrumb.png') top right no-repeat;
                    @include image2x('../images/icon-breadcrumb.png', 5px, 9px);
                    color: #FFF;
                    font-family: $font-thin;
                    font-size: em(12px);
                    text-transform: uppercase;
                    float: left;
                    padding-right: 15px;
                    margin-right: 13px;

                    &.current {
                        background: none;
                    }

                    a {
                        @include all-transitions;
                        color: #BEBEBE;
                        text-decoration: none;

                        &:hover {
                            color: #FFF;
                            text-decoration: underline;
                        }
                    }
                }
            }
        }

        .members-list {
            margin-top: 16px;
            padding: 0 90px;
            @include clearfix;

            @include breakpoint($tabletAll) {
                padding: 0 40px;
            }

            .strip {
                border-bottom: 1px solid $primary;
                margin-bottom: 40px;
                height: 55px;

                a {
                    background: #1D1F21;
                    float: right;
                    padding: 0 14px 0 14px;
                    width: auto;
                    margin-left: 10px;

                    &.invite-members {
                        background: #1D1F21 url('../images/icon-btn-circle-plus.png') 15px 8px no-repeat;
                        @include image2x('../images/icon-btn-circle-plus.png', 22px, 22px);
                        padding-left: 43px;
                    }
                }
            }
        }

        .clubs {
            @include clearfix;
            padding: 0 86px;

            @include breakpoint($tabletAll) {
                padding: 0 40px;
            }

            height: 100%;

            &.club-no-clubs {
                padding: 0;

                .clubs-initial {
                    background: url('../images/bg-club-cover-photo-default.jpg') no-repeat;
                    background-size: cover;
                    background-position: center;
                    width: 100%;
                    height: 100%;
                    position: relative;

                    .overlay {
                        background: url('../images/bg-image-tile-overlay.png') repeat;
                        position: absolute;
                        z-index: 1;
                        width: 100%;
                        height: 100%;
                    }

                    .inner {
                        position: absolute;
                        z-index: 2;
                        padding: 110px 0 0 90px;

                        @include breakpoint($tabletAll) {
                            padding-left: 40px;
                        }

                        h1 {
                            font-size: em(70px);
                            font-family: $font-thin;
                            color: #000;
                            margin-bottom: 29px;
                        }

                        ul {
                            margin-bottom: 26px;

                            li {
                                background: url('../images/icon-clubs-li.png') 0px 11px no-repeat;
                                @include image2x('../images/icon-clubs-li@2x.png', 6px, 6px);
                                padding-left: 16px;
                                font-family: $font-light;
                                color: #2F2F2F;
                                font-size: em(18px);
                                line-height: 30px;
                            }
                        }

                        a {
                            background: rgba(0, 0, 0, 0.4);
                            border-radius: 3px;
                            display: inline-block;
                            color: #FFF;
                            font-size: em(16px);
                            font-family: $font-light;
                            height: 48px;
                            line-height: 50px;
                            padding: 0 30px;
                            text-transform: uppercase;
                            text-decoration: none;
                        }
                    }
                }
            }

            .club,
            .club-invite {
                background: #1F2124 url('../images/loader-club.gif') left 130px top 80px no-repeat;
                position: relative;
                width: 300px;
                height: 300px;
                float: left;
                margin-right: 20px;
                cursor: pointer;
                margin-bottom: 20px;

                &.add-club {

                    a {
                        background: #1F2124 url('../images/icon-add-club.png') top 105px left 124px no-repeat;
                        @include image2x('../images/icon-add-club@2x.png', 50px, 50px);
                        display: block;
                        width: 296px;
                        height: 134px;
                        padding-top: 162px;
                        border: 2px dashed #393B3E;
                        color: #949495;
                        font-size: em(18px);
                        font-family: $font-thin;
                        text-align: center;
                        text-decoration: none;
                    }
                }

                .cover-photo {
                    position: absolute;
                    width: 300px;
                    height: 300px;
                    background-size: cover;
                    background-repeat: no-repeat;
                }

                .bottom {
                    position: absolute;
                    bottom: 0;
                    width: 300px;

                    .name,
                    .invite-box {
                        background: rgba(0, 0, 0, 0.7);
                        padding: 18px 20px 16px 20px;

                        h2 {
                            color: #FFF;
                            font-family: $font-light;
                            font-size: em(18px);
                            margin-bottom: 8px;
                        }

                        .tagline {
                            color: #BEBEBE;
                            font-family: $font-light;
                            font-size: em(12px);
                        }
                    }

                    .stats {
                        height: 36px;
                        background: #36383C;
                        padding: 16px 20px 0;

                        .num-waves {
                            float: left;
                        }

                        .num-members {
                            float: right;

                            a {
                                display: block;
                                text-decoration: none;
                            }
                        }

                        span {
                            display: inline-block;
                        }

                        .value {
                            font-size: em(30px);
                            color: #FFF;
                            font-family: $font-thin;
                            margin-right: 4px;
                        }

                        .label {
                            font-size: em(12px);
                            font-family: $font-light;
                            color: #A9A9A9;
                        }
                    }
                }
            }

            .club-invite {

                .bottom .name { display: none; }

                .bottom .invite-box {
                    height: 56px;
                    padding: 8px 15px 0 10px;


                    img {
                        width: 44px;
                        height: 44px;
                        border-radius: 100px;
                        border: 2px solid #424547;
                        float: left;
                        margin-right: 8px;
                    }

                    .invite-info {
                        float: left;
                        padding-top: 11px;

                        h2 {
                            width: 160px;
                        }

                        p {
                            color: #BEBEBE;
                            font-size: em(11px);
                            margin-bottom: 4px;
                        }
                    }

                    .buttons {
                        float: right;
                        margin-top: 13px;

                        a {
                            display: block;
                            width: 22px;
                            height: 22px;
                            text-indent: -9999px;
                            float: left;
                            margin-right: 7px;
                        }

                        .decline {
                            background: url('../images/icon-decline-invite.png') no-repeat;
                            @include image2x('../images/icon-decline-invite@2x.png', 22px, 22px);
                        }

                        .accept {
                            background: url('../images/icon-accept-invite.png') no-repeat;
                            @include image2x('../images/icon-accept-invite@2x.png', 22px, 22px);
                            margin-right: 0;
                        }
                    }
                }
            }
        }

        .create-club-wrapper {
            width: 609px;
            margin: 0 auto;

            .create-club,
            .invite-members {
                @include all-transitions;
                font: 14px $font-light;
                color: #FFFFFF;
                background-color: #1F2124;
                border: 1px solid #4F5153;
                border-radius: 3px;
                margin: 0;
                padding-top: 18px;
                padding-bottom: 15px;
                width: 100%;
                outline: none;
                cursor: pointer;

                &:hover {
                    background-color: #6297C8;
                    border-color: #6297C8;
                }
            }

            .step-2 {
                display: none;
                width: 300px;
                margin: 0 auto;

                .drag-and-drop {
                    border: 1px dashed #393b3e;
                    height: 134px;
                    background: #1c1e20 url("../images/icon-upload.png") no-repeat top 106px left 118px;
                    @include image2x('../images/icon-upload@2x.png', 55px, 39px);
                    text-align: center;
                    padding-top: 156px;
                    position: relative;
                    margin-bottom: 10px;

                    .text {
                        font: 16px $font-light;
                        color: #FFFFFF;
                        display: block;
                    }

                    .red {
                        font-size: 14px;
                        color: #df1b00;
                        display: block;
                    }

                    .overlay-upload {
                        position: absolute;
                        top: 0;
                        left: 0;
                        height: 298px;
                        width: 290px;
                        cursor: pointer;
                        z-index: 2;
                        opacity: 0;
                        background: #000;

                        .dz-progress, .dz-success-mark, .dz-error-mark, .dz-error-message, .dz-details .dz-filename, .dz-details .dz-size {
                            display: none;
                        }

                        .dz-image-preview {
                            height: 100%;
                            width: 100%;

                            .dz-details {
                                height: 100%;
                                width: 100%;

                                img {
                                    width: 298px;
                                    height: 290px;
                                }
                            }
                        }
                    }
                }
            }

            .step-1 {
                width: 279px;
                margin: 0 auto;

                .add-name {
                    border: 0;
                    margin: 0;
                    padding: 0;
                    width: 100%;
                    border-radius: 3px 3px 0 0;
                    font: 14px Arial;
                    padding: 15px 14px 9px;
                    box-sizing: border-box;
                    border-bottom: 1px solid #f1f1f1;
                    outline: none;
                }

                .add-description {
                    border: 0;
                    margin: 0;
                    padding: 0;
                    border-radius: 0 0 3px 3px;
                    font: 14px Arial;
                    padding: 12px 14px;
                    box-sizing: border-box;
                    height: 141px;
                    max-height: 141px;
                    max-width: 279px;
                    width: 279px;
                    margin-bottom: 20px;
                    outline: none;
                }

                .ongoing-wrapper {
                    float: left;
                    margin-right: 10px;

                    .ongoing-label {
                        font: 14px $font-light;
                        text-transform: uppercase;
                        color: #999999;
                        padding-top: 4px;
                    }
                }

                .time-based-wrapper {
                    float: left;
                    padding-left: 29px;
                    margin-bottom: 19px;

                    .time-based-label {
                        font: 14px $font-light;
                        text-transform: uppercase;
                        color: #999999;
                        padding-top: 4px;
                    }
                }

                .time-based-date-pickers {
                    float: left;
                    width: 100%;
                    margin-top: 5px;
                    margin-bottom: 10px;
                    display: none;

                    .start {
                        float: left;
                        width: 136px;
                        margin-right: 8px;
                    }

                    .end {
                        margin-left: 146px;
                        .title {
                            padding-left: 1px;
                        }
                    }

                    .title {
                        font: 12px $font-light;
                        text-transform: uppercase;
                        color: #999999;
                        display: block;
                        padding-bottom: 12px;
                    }

                    .datepicker {
                        background: #FFF;
                        border: none;
                        border-radius: 3px;
                        color: #B8B8B8;
                        outline: none;
                        margin: 0;
                        width: 105px;
                        padding: 13px 14px;
                    }
                }
            }
        }

        #club {
            padding-bottom: 70px;

            .top-banner {
                position: relative;
                height: 540px;
                margin-bottom: 44px;

                .breadcrumbs {
                    padding: 0 0 0 90px;

                    .leaderboard {
                        display: none;
                    }
                }

                .cover-photo {
                    position: absolute;
                    background-size: cover;
                    background-position: left center;
                    width: 100%;
                    height: 540px;
                    box-sizing: border-box;
                    padding: 20px 90px 0 0;

                    @include breakpoint($tabletAll) {
                        padding-right: 40px;
                    }
                }

                .club-info {
                    background: rgba(0, 0, 0, 0.8);
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 140px;
                    box-sizing: border-box;
                    padding: 20px 0 0 0;

                    @include breakpoint($tabletAll) {
                        padding: 20px 40px 0 40px;
                    }

                    .members {
                        float: right;
                        padding-top: 10px;
                        padding-right: 90px;

                        a {
                            text-decoration: none;
                        }

                        .value {
                            font-size: em(34px);
                            font-family: $font-thin;
                            color: #FFF;
                        }

                        .label {
                            color: #BEBEBE;
                            font-size: em(14px);
                            font-family: $font-light;
                        }
                    }

                    h1 {
                        font-size: em(34px);
                        font-family: $font-thin;
                        color: #FFF;
                        margin-bottom: 3px;
                        padding-left: 90px;
                    }

                    .tagline {
                        color: #FFF;
                        font-family: $font-light;
                        font-size: em(12px);
                        padding-left: 90px;
                    }
                }
            }

            .club-stats {
                padding: 0 90px;

                @include breakpoint($tabletAll) {
                    padding: 0 40px;
                }
            }

            .top {
                height: 34px;
                margin-bottom: 27px;
                padding: 0 90px;

                @include breakpoint($tabletAll) {
                    padding: 0 40px;
                }

                .num-surfs,
                .total-waves {
                    float: left;
                    margin-right: 68px;

                    a {
                        text-decoration: none;
                    }

                    .value {
                        font-size: em(34px);
                        font-family: $font-thin;
                        color: #FFF;
                    }

                    .label {
                        color: #BEBEBE;
                        font-size: em(14px);
                        font-family: $font-light;
                    }
                }
            }

            .tabs {
                height: 48px;
                border-bottom: 1px solid $primary;
                margin-bottom: 36px;

                ul {
                    @include clearfix;

                    li {
                        list-style: none;
                        float: left;
                        height: 48px;
                        margin-right: 28px;

                        &.active a {
                            background: url('../images/icon-red-arrow-up.png') bottom center no-repeat;
                            @include image2x('../images/icon-red-arrow-up@2x.png', 16px, 8px);
                            color: #FFF;
                        }

                        a {
                            font-family: $font-light;
                            display: block;
                            height: 29px;
                            padding-top: 19px;
                            font-size: em(14px);
                            color: #BEBEBE;
                            text-decoration: none;
                            text-align: center;

                            &:hover {
                                color: #FFF;
                            }
                        }
                    }
                }
            }

            .tab-section {
                display: none;

                .leaderboard {

                    .header {
                        @include clearfix;

                        .lb-max-stat {
                            float: left;
                            margin-bottom: 30px;

                            span {
                                display: block;

                                &.value {
                                    font-family: $font-thin;
                                    font-size: em(56px);
                                    color: #FFF;
                                }

                                &.label {
                                    font-family: $font-light;
                                    font-size: em(14px, $base-font-size);
                                    color: #BEBEBE;
                                }
                            }
                        }

                        .your-ranking {
                            float: right;
                            margin-bottom: 30px;

                            a {
                                text-decoration: none;
                            }

                            span {

                                &.value {
                                    display: block;
                                    font-family: $font-thin;
                                    color: #FFF;
                                    text-align: right;
                                }

                                &.your-position {
                                    font-size: em(56px);
                                }

                                &.total-people {
                                    font-size: em(14px);
                                }

                                &.label {
                                    text-align: right;
                                    display: block;
                                    font-family: $font-light;
                                    font-size: em(14px, $base-font-size);
                                    color: #BEBEBE;
                                }
                            }
                        }
                    }

                    ol {
                        width: 100%;

                        li {
                            position: relative;
                            width: 100%;
                            height: 60px;
                            background: #303236;
                            margin-bottom: 1px;
                            box-sizing: border-box;
                            padding-right: 21px;
                            position: relative;

                            &.is-link {
                                cursor: pointer;
                            }

                            .hover {
                                position: absolute;
                                background: $primary;
                                width: 5px;
                                height: 60px;
                                opacity: 0;
                                top: 0;
                                left: 0;
                            }

                            &:hover {
                                background: #36383C;

                                .hover {
                                    opacity: 1;
                                }
                            }

                            .profile-image {
                                float: left;
                                margin-left: 21px;
                                margin-top: 6px;
                                margin-bottom: 4px;

                                img {
                                    height: 44px;
                                    width: 44px;
                                    border-radius: 48px;
                                    border: 2px solid #45474A;
                                }
                            }

                            &.me {
                                background: #6297C8;

                                &:hover {
                                    background: #6297C8;
                                }

                                .hover {
                                    background: #3D86C8;
                                }

                                .name {
                                    color: #FFF;
                                }

                                .date {
                                    color: #FFF;
                                }
                            }

                            .rank {
                                float: left;
                                font: 18px $font-light;
                                margin-top: 23px;
                                margin-left: 27px;
                                color: #FFF;
                                width: 26px;
                            }

                            .name {
                                float: left;
                                font: 18px $font-light;
                                color: #BEBEBE;
                                margin-top: 23px;
                                margin-left: 19px;
                                width: 248px;

                                @include breakpoint($tabletAll) {
                                    width: 20%;
                                    margin-right: 5%;
                                }

                                @include breakpoint($tabletPortrait) {
                                    width: 30%;
                                }
                            }

                            .distance {
                                float: left;
                                font: 18px $font-light;
                                color: #FFF;
                                margin-top: 23px;

                                .unit {
                                    font: 12px $font-light;
                                    color: #bebebe;
                                }
                            }

                            .date {
                                float: right;
                                font: 18px $font-light;
                                color: #BEBEBE;
                                margin-top: 23px;
                            }
                        }
                    }
                }
            }

            .t-28-days {
                display: block;
            }

            .stats {
                @include clearfix;

                .stat {
                    position: relative;
                    background: #36383C;
                    width: 300px;
                    height: 155px;
                    float: left;
                    margin: 0 20px 20px 0;
                    cursor: pointer;

                    @include breakpoint(0px 768px) {
                        width: 100%;
                    }

                    @include breakpoint(768px 1023px) {
                        width: 293px;

                        &:nth-child(+2n) {
                            float: right;
                            margin-right: 0;
                        }
                    }

                    @include breakpoint(1023px 1024px) {
                        width: 270px;

                        &:nth-child(+3n) {
                            margin-right: 0;
                        }
                    }

                    .value {
                        font-size: em(56px);
                        font-family: $font-thin;
                        color: #FFF;
                        display: block;
                        padding: 18px 0 0 19px;
                    }

                    .label {
                        font-size: em(14px);
                        font-family: $font-light;
                        color: #BEBEBE;
                        padding-left: 21px;
                    }

                    .user {
                        position: absolute;
                        bottom: 0;
                        background: #303236;
                        width: 100%;
                        height: 40px;

                        .profile-photo {
                            width: 48px;
                            height: 48px;
                            margin: -16px 12px 0 16px;
                            float: left;

                            img {
                                width: 44px;
                                height: 44px;
                                border-radius: 100px;
                                border: 2px solid #45474A;
                            }
                        }

                        h2 {
                            font-size: em(14px);
                            font-family: $font-light;
                            padding-top: 15px;
                            float: left;

                            a {
                                color: #FFF;
                                text-decoration: none;
                            }
                        }

                        p {
                            float: right;
                            padding-top: 15px;
                            color: #BEBEBE;
                            font-size: em(14px);
                            font-family: $font-light;
                            padding-right: 14px;
                        }
                    }
                }
            }
        }
    }
}

.ie9-upload {
    display: none;
    margin-bottom: 10px;

    label {
        font-weight: bold;
        color: #FFF;

        input {
            margin-top: 5px;
        }
    }

    .preview {
        width: 300px;
        height: 300px;
    }
}
