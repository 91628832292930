/****************************
 
 ____                    ____                   ___      
/\  _`\   __            /\  _`\                /\_ \     
\ \ \L\ \/\_\  _____    \ \ \/\_\  __  __  _ __\//\ \    
 \ \ ,  /\/\ \/\ '__`\   \ \ \/_/_/\ \/\ \/\`'__\\ \ \   
  \ \ \\ \\ \ \ \ \L\ \   \ \ \L\ \ \ \_\ \ \ \/  \_\ \_ 
   \ \_\ \_\ \_\ \ ,__/    \ \____/\ \____/\ \_\  /\____\
    \/_/\/ /\/_/\ \ \/      \/___/  \/___/  \/_/  \/____/
                 \ \_\                                   
                  \/_/

*****************************/

@import "base";

.swell-slider,
.period-slider,
.strength-slider {
	background: #464A4C;
	width: 180px;
	height: 3px;
	border: none;

	.ui-slider-range {
		background: #6297C8;
	}

	.ui-slider-handle {
		background: url('../images/icon-slider-handle.png') no-repeat;
		@include image2x('../images/icon-slider-handle@2x.png', 17px, 18px);
		width: 17px;
		height: 18px;
		outline: none;
		border: none;
		cursor: pointer;
		top: -6px;
		border-color: transparent;
		border-radius: 3px;
	}
}