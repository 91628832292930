@import "base";

/*! jQuery UI - v1.10.3 - 2013-11-17
* http://jqueryui.com
* Includes: jquery.ui.core.css, jquery.ui.datepicker.css, jquery.ui.theme.css
* To view and modify this theme, visit http://jqueryui.com/themeroller/?ffDefault=Segoe%20UI%2CArial%2Csans-serif&fwDefault=bold&fsDefault=1.1em&cornerRadius=6px&bgColorHeader=333333&bgTextureHeader=gloss_wave&bgImgOpacityHeader=25&borderColorHeader=333333&fcHeader=ffffff&iconColorHeader=ffffff&bgColorContent=000000&bgTextureContent=inset_soft&bgImgOpacityContent=25&borderColorContent=666666&fcContent=ffffff&iconColorContent=cccccc&bgColorDefault=555555&bgTextureDefault=glass&bgImgOpacityDefault=20&borderColorDefault=666666&fcDefault=eeeeee&iconColorDefault=cccccc&bgColorHover=0078a3&bgTextureHover=glass&bgImgOpacityHover=40&borderColorHover=59b4d4&fcHover=ffffff&iconColorHover=ffffff&bgColorActive=f58400&bgTextureActive=inset_soft&bgImgOpacityActive=30&borderColorActive=ffaf0f&fcActive=ffffff&iconColorActive=222222&bgColorHighlight=eeeeee&bgTextureHighlight=highlight_soft&bgImgOpacityHighlight=80&borderColorHighlight=cccccc&fcHighlight=2e7db2&iconColorHighlight=4b8e0b&bgColorError=ffc73d&bgTextureError=glass&bgImgOpacityError=40&borderColorError=ffb73d&fcError=111111&iconColorError=a83300&bgColorOverlay=5c5c5c&bgTextureOverlay=flat&bgImgOpacityOverlay=50&opacityOverlay=80&bgColorShadow=cccccc&bgTextureShadow=flat&bgImgOpacityShadow=30&opacityShadow=60&thicknessShadow=7px&offsetTopShadow=-7px&offsetLeftShadow=-7px&cornerRadiusShadow=8px
* Copyright 2013 jQuery Foundation and other contributors; Licensed MIT */

/* Layout helpers
----------------------------------*/
.ui-helper-hidden {
	display: none;
}

.ui-helper-hidden-accessible {
	border: 0;
	clip: rect(0 0 0 0);
	height: 1px;
	margin: -1px;
	overflow: hidden;
	padding: 0;
	position: absolute;
	width: 1px;
}

.ui-helper-reset {
	margin: 0;
	padding: 0;
	border: 0;
	outline: 0;
	line-height: 1.3;
	text-decoration: none;
	list-style: none;
}

.ui-helper-clearfix:before,
.ui-helper-clearfix:after {
	content: "";
	display: table;
	border-collapse: collapse;
}

.ui-helper-clearfix:after {
	clear: both;
}

.ui-helper-clearfix {
	min-height: 0; /* support: IE7 */
}

.ui-helper-zfix {
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	position: absolute;
	opacity: 0;
	filter: Alpha(Opacity=0);
}

.ui-front {
	z-index: 100;
}

/* Interaction Cues
----------------------------------*/
.ui-state-disabled {
	cursor: default !important;
}


/* Icons
----------------------------------*/

/* states and images */
.ui-icon {
	display: block;
	text-indent: -99999px;
	overflow: hidden;
	background-repeat: no-repeat;
}


/* Misc visuals
----------------------------------*/

/* Overlays */
.ui-widget-overlay {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}
.ui-datepicker {
	width: 224px;
	display: none;
}
.ui-datepicker .ui-datepicker-header {
	position: relative;
}

.ui-datepicker .ui-datepicker-prev,
.ui-datepicker .ui-datepicker-next {
	position: absolute;
	top: 11px;
	width: 7px;
	height: 12px;
	cursor: pointer;
	@include all-transitions;
}

.ui-datepicker .ui-datepicker-prev {
	background: url('../images/icon-datepicker-left.png') no-repeat;
	@include image2x('../images/icon-datepicker-left@2x.png', 7px, 12px);
	left: 9px;
}

.ui-datepicker .ui-datepicker-next {
	background: url('../images/icon-datepicker-right.png') no-repeat;
	@include image2x('../images/icon-datepicker-right@2x.png', 7px, 12px);
	right: 9px;
}
.ui-datepicker .ui-datepicker-prev:hover {
	background: url('../images/icon-datepicker-left-hover.png') no-repeat;
	@include image2x('../images/icon-datepicker-left-hover@2x.png', 7px, 12px);
	border: none;
}

.ui-datepicker .ui-datepicker-next:hover {
	background: url('../images/icon-datepicker-right-hover.png') no-repeat;
	@include image2x('../images/icon-datepicker-right-hover@2x.png', 7px, 12px);
	border: none;
}

.ui-datepicker .ui-datepicker-title {
	height: 30px;
	text-align: center;
	line-height: 32px;
}

.ui-datepicker .ui-datepicker-title select {
	margin: 1px 0;
}

.ui-datepicker select.ui-datepicker-month-year {
	width: 100%;
}

.ui-datepicker select.ui-datepicker-month,
.ui-datepicker select.ui-datepicker-year {
	width: 49%;
	background-color: rgba(54, 56, 60, 0.9);
	border:0;
	color: white !important;
}

.ui-datepicker table {
	width: 231px !important;
	border-collapse: collapse;
	margin: 0 0 .4em;
}

.ui-datepicker th {
	padding: .7em .3em;
	text-align: center;
	border: 0;
}

.ui-datepicker td {
	border: 0;
	padding: 1px;
}

.ui-datepicker td span,
.ui-datepicker td a {
	display: block;
	text-decoration: none;
}

.ui-datepicker .ui-datepicker-buttonpane {
	background-image: none;
	margin: .7em 0 0 0;
	padding: 0 .2em;
	border-left: 0;
	border-right: 0;
	border-bottom: 0;
}

.ui-datepicker .ui-datepicker-buttonpane button {
	float: right;
	margin: .5em .2em .4em;
	cursor: pointer;
	padding: .2em .6em .3em .6em;
	width: auto;
	overflow: visible;
}

.ui-datepicker .ui-datepicker-buttonpane button.ui-datepicker-current {
	float: left;
}

/* with multiple calendars */
.ui-datepicker.ui-datepicker-multi {
	width: auto;
}

.ui-datepicker-multi .ui-datepicker-group {
	float: left;
}

.ui-datepicker-multi .ui-datepicker-group table {
	width: 95%;
	margin: 0 auto .4em;
}

.ui-datepicker-multi-2 .ui-datepicker-group {
	width: 50%;
}

.ui-datepicker-multi-3 .ui-datepicker-group {
	width: 33.3%;
}

.ui-datepicker-multi-4 .ui-datepicker-group {
	width: 25%;
}

.ui-datepicker-multi .ui-datepicker-group-last .ui-datepicker-header,
.ui-datepicker-multi .ui-datepicker-group-middle .ui-datepicker-header {
	border-left-width: 0;
}

.ui-datepicker-multi .ui-datepicker-buttonpane {
	clear: left;
}

.ui-datepicker-row-break {
	clear: both;
	width: 100%;
}

/* RTL support */
.ui-datepicker-rtl {
	direction: rtl;
}

.ui-datepicker-rtl .ui-datepicker-prev {
	right: 2px;
	left: auto;
}

.ui-datepicker-rtl .ui-datepicker-next {
	left: 2px;
	right: auto;
}

.ui-datepicker-rtl .ui-datepicker-prev:hover {
	right: 1px;
	left: auto;
}

.ui-datepicker-rtl .ui-datepicker-next:hover {
	left: 1px;
	right: auto;
}

.ui-datepicker-rtl .ui-datepicker-buttonpane {
	clear: right;
}

.ui-datepicker-rtl .ui-datepicker-buttonpane button {
	float: left;
}

.ui-datepicker-rtl .ui-datepicker-buttonpane button.ui-datepicker-current,
.ui-datepicker-rtl .ui-datepicker-group {
	float: right;
}

.ui-datepicker-rtl .ui-datepicker-group-last .ui-datepicker-header,
.ui-datepicker-rtl .ui-datepicker-group-middle .ui-datepicker-header {
	border-right-width: 0;
	border-left-width: 1px;
}

/* Component containers */

.ui-widget-header {
	background: rgba(54, 56, 60, 0.9);
	color: #FFF;
	font-family: $font-light;
	font-size: em(12px);
	border-top-left-radius: 3px;
	border-top-right-radius: 3px;
	text-transform: uppercase;
}

.ui-widget-header a {
	color: #ffffff;
}

/* Overlays */
.ui-widget-overlay {
	background: #5c5c5c;
	opacity: .8;
	filter: Alpha(Opacity=80);
}

.ui-widget-shadow {
	margin: -7px 0 0 -7px;
	padding: 7px;
	background: #cccccc;
	opacity: .6;
	filter: Alpha(Opacity=60);
}

.ui-datepicker-calendar {

	thead {
		background: rgba(54, 56, 60, 0.9);
		color: #FFF;
		font-family: $font-light;
		font-size: em(12px);
	}

	tbody {
		background: rgba(28, 29, 31, 0.95);
		border-bottom-left-radius: 3px;
		border-bottom-right-radius: 3px;

		a {
			@include all-transitions;
			color: #FFF;
			font-family: $font-light;
			font-size: 12px;
			border-radius: 50px;
			display: block;
			width: 26px;
			height: 26px;
			text-align: center;
			line-height: 26px;

			&:hover {
				background: #2E3033;
			}
		}

		.ui-datepicker-current-day a {
			background: #3D86C8;
		}
	}
}

.ui-widget-content {
	background: none;
	border: none;
}

.ui-state-default, .ui-widget-content .ui-state-default, .ui-widget-header .ui-state-default {
	background: none;
	border: none;
	color: #FFF;
}

.ui-datepicker .ui-datepicker-header {
	width: 231px;
	padding: 0;
	border-radius: 3px 3px 0 0;
	border: none;
}

.ui-datepicker .ui-datepicker-title {
	font-size: 12px;
}

.ui-datepicker .ui-datepicker-unselectable .ui-state-default {
    font-weight: normal;
}