#modal-overlay,
#lightbox-overlay,
#profile-overlay,
#loading-overlay,
#antavo-overlay {
	background: rgba(0, 0, 0, 0.5);
	position: fixed;
	width: 100%;
	height: 100%;
	z-index: 3;
	display: none;

    &#loading-overlay {
        z-index: 10;
    }

    #loading-hud {
        background: #000;
        width: 100px;
        height: 35px;
        border-radius: 10px;
        color: #FFF;
        position: absolute;
        top: 40%;
        left: 50%;
        margin: -50px 0 0 -60px;
        text-align: center;
        padding-top: 95px;
        font-size: em(12px);
        font-family: $font-light;

        .spinner {
            margin-bottom: 10px;
            left: 50px !important;
            top: -40px !important;
        }
    }
}
