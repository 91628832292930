/*
    Browser fixes
*/
.ie10 {

    ::-ms-clear { display: none; }

    a:active {
        background-color: transparent;
    }

    #welcome #page-selector ul li.active {
        position: relative;
        left: -80px;
    }

    #app {

        #view-profile,
        #panel-edit-surf {

            select {
                background-image: none !important;
                padding-left: 15px !important;
                -ms-appearance: none;
            }
        }
    }
}

#edit-profile .ie9-upload-cover,
#edit-profile .ie9-upload-photo {
    display: none;
}

.ie9 {

    .ios-switch.on .handle {
        left: 33px;
    }

    #app #view-profile #edit-profile .header .bottom .buttons span {
        width: 100px;
        text-align: center;
    }

    #edit-profile #edit-cover-photo .buttons {
        display: none;
    }

    #edit-profile .ie9-upload-cover,
    #edit-profile .ie9-upload-photo {
        display: block;
    }

    #app #view-surf #right-sidebar #panel-edit-surf .drag-drop .dropzone {
        display: none;
    }

    #app #view-surf #right-sidebar #panel-edit-surf .drag-drop .ie9-upload {
        display: block;
        height: 90px;
    }
}

.ie9,
.ie10 {

    body {
        overflow-x: hidden;
    }

    #app {

        &.page-surf {
            overflow: hidden;
        }
    }
}