@charset "UTF-8";
/****************************

 ____                    ____                   ___
/\  _`\   __            /\  _`\                /\_ \
\ \ \L\ \/\_\  _____    \ \ \/\_\  __  __  _ __\//\ \
 \ \ ,  /\/\ \/\ '__`\   \ \ \/_/_/\ \/\ \/\`'__\\ \ \
  \ \ \\ \\ \ \ \ \L\ \   \ \ \L\ \ \ \_\ \ \ \/  \_\ \_
   \ \_\ \_\ \_\ \ ,__/    \ \____/\ \____/\ \_\  /\____\
    \/_/\/ /\/_/\ \ \/      \/___/  \/___/  \/_/  \/____/
                 \ \_\
                  \/_/

*****************************/
@import "jquery.scrollpane.css";
@import "jquery-ui.custom.min.css";
/****************************

 ____                    ____                   ___
/\  _`\   __            /\  _`\                /\_ \
\ \ \L\ \/\_\  _____    \ \ \/\_\  __  __  _ __\//\ \
 \ \ ,  /\/\ \/\ '__`\   \ \ \/_/_/\ \/\ \/\`'__\\ \ \
  \ \ \\ \\ \ \ \ \L\ \   \ \ \L\ \ \ \_\ \ \ \/  \_\ \_
   \ \_\ \_\ \_\ \ ,__/    \ \____/\ \____/\ \_\  /\____\
    \/_/\/ /\/_/\ \ \/      \/___/  \/___/  \/_/  \/____/
                 \ \_\
                  \/_/

*****************************/
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font: inherit;
  font-size: 100%;
  vertical-align: baseline;
}

html {
  line-height: 1;
}

ol, ul {
  list-style: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

caption, th, td {
  text-align: left;
  font-weight: normal;
  vertical-align: middle;
}

q, blockquote {
  quotes: none;
}
q:before, q:after, blockquote:before, blockquote:after {
  content: "";
  content: none;
}

a img {
  border: none;
}

article, aside, details, figcaption, figure, footer, header, hgroup, main, menu, nav, section, summary {
  display: block;
}

/*@function use-prefix($prefix, $capability, $threshold, $capability-options: $default-capability-options) {
  $usage: prefix-usage($prefix, $capability, $capability-options);
  @if $usage > $threshold {
    @return $usage;
  } @else {
    @each $browser in browsers($prefix) {
      @if index($supported-browsers, $browser) {
        $min-version: map-get($browser-minimum-versions, $browser);
        @if $min-version {
          $actual-prefix: browser-requires-prefix($browser, $min-version, $capability, $capability-options);
          @if $actual-prefix and $prefix == $actual-prefix {
            @return $browser $min-version;
          }
        }
      }
    }
  }
  @return false;
}*/
/*@function use-prefix($prefix, $capability, $threshold, $capability-options: $default-capability-options) {
  $usage: prefix-usage($prefix, $capability, $capability-options);
  @if $usage > $threshold {
    @return $usage;
  } @else {
    @each $browser in browsers($prefix) {
      @if index($supported-browsers, $browser) {
        $min-version: map-get($browser-minimum-versions, $browser);
        @if $min-version {
          $actual-prefix: browser-requires-prefix($browser, $min-version, $capability, $capability-options);
          @if $actual-prefix and $prefix == $actual-prefix {
            @return $browser $min-version;
          }
        }
      }
    }
  }
  @return false;
}*/
/*@function use-prefix($prefix, $capability, $threshold, $capability-options: $default-capability-options) {
  $usage: prefix-usage($prefix, $capability, $capability-options);
  @if $usage > $threshold {
    @return $usage;
  } @else {
    @each $browser in browsers($prefix) {
      @if index($supported-browsers, $browser) {
        $min-version: map-get($browser-minimum-versions, $browser);
        @if $min-version {
          $actual-prefix: browser-requires-prefix($browser, $min-version, $capability, $capability-options);
          @if $actual-prefix and $prefix == $actual-prefix {
            @return $browser $min-version;
          }
        }
      }
    }
  }
  @return false;
}*/
/*@function use-prefix($prefix, $capability, $threshold, $capability-options: $default-capability-options) {
  $usage: prefix-usage($prefix, $capability, $capability-options);
  @if $usage > $threshold {
    @return $usage;
  } @else {
    @each $browser in browsers($prefix) {
      @if index($supported-browsers, $browser) {
        $min-version: map-get($browser-minimum-versions, $browser);
        @if $min-version {
          $actual-prefix: browser-requires-prefix($browser, $min-version, $capability, $capability-options);
          @if $actual-prefix and $prefix == $actual-prefix {
            @return $browser $min-version;
          }
        }
      }
    }
  }
  @return false;
}*/
/*@function use-prefix($prefix, $capability, $threshold, $capability-options: $default-capability-options) {
  $usage: prefix-usage($prefix, $capability, $capability-options);
  @if $usage > $threshold {
    @return $usage;
  } @else {
    @each $browser in browsers($prefix) {
      @if index($supported-browsers, $browser) {
        $min-version: map-get($browser-minimum-versions, $browser);
        @if $min-version {
          $actual-prefix: browser-requires-prefix($browser, $min-version, $capability, $capability-options);
          @if $actual-prefix and $prefix == $actual-prefix {
            @return $browser $min-version;
          }
        }
      }
    }
  }
  @return false;
}*/
/*@function use-prefix($prefix, $capability, $threshold, $capability-options: $default-capability-options) {
  $usage: prefix-usage($prefix, $capability, $capability-options);
  @if $usage > $threshold {
    @return $usage;
  } @else {
    @each $browser in browsers($prefix) {
      @if index($supported-browsers, $browser) {
        $min-version: map-get($browser-minimum-versions, $browser);
        @if $min-version {
          $actual-prefix: browser-requires-prefix($browser, $min-version, $capability, $capability-options);
          @if $actual-prefix and $prefix == $actual-prefix {
            @return $browser $min-version;
          }
        }
      }
    }
  }
  @return false;
}*/
/*@function use-prefix($prefix, $capability, $threshold, $capability-options: $default-capability-options) {
  $usage: prefix-usage($prefix, $capability, $capability-options);
  @if $usage > $threshold {
    @return $usage;
  } @else {
    @each $browser in browsers($prefix) {
      @if index($supported-browsers, $browser) {
        $min-version: map-get($browser-minimum-versions, $browser);
        @if $min-version {
          $actual-prefix: browser-requires-prefix($browser, $min-version, $capability, $capability-options);
          @if $actual-prefix and $prefix == $actual-prefix {
            @return $browser $min-version;
          }
        }
      }
    }
  }
  @return false;
}*/
/*@function use-prefix($prefix, $capability, $threshold, $capability-options: $default-capability-options) {
  $usage: prefix-usage($prefix, $capability, $capability-options);
  @if $usage > $threshold {
    @return $usage;
  } @else {
    @each $browser in browsers($prefix) {
      @if index($supported-browsers, $browser) {
        $min-version: map-get($browser-minimum-versions, $browser);
        @if $min-version {
          $actual-prefix: browser-requires-prefix($browser, $min-version, $capability, $capability-options);
          @if $actual-prefix and $prefix == $actual-prefix {
            @return $browser $min-version;
          }
        }
      }
    }
  }
  @return false;
}*/
/*@function use-prefix($prefix, $capability, $threshold, $capability-options: $default-capability-options) {
  $usage: prefix-usage($prefix, $capability, $capability-options);
  @if $usage > $threshold {
    @return $usage;
  } @else {
    @each $browser in browsers($prefix) {
      @if index($supported-browsers, $browser) {
        $min-version: map-get($browser-minimum-versions, $browser);
        @if $min-version {
          $actual-prefix: browser-requires-prefix($browser, $min-version, $capability, $capability-options);
          @if $actual-prefix and $prefix == $actual-prefix {
            @return $browser $min-version;
          }
        }
      }
    }
  }
  @return false;
}*/
/*@function use-prefix($prefix, $capability, $threshold, $capability-options: $default-capability-options) {
  $usage: prefix-usage($prefix, $capability, $capability-options);
  @if $usage > $threshold {
    @return $usage;
  } @else {
    @each $browser in browsers($prefix) {
      @if index($supported-browsers, $browser) {
        $min-version: map-get($browser-minimum-versions, $browser);
        @if $min-version {
          $actual-prefix: browser-requires-prefix($browser, $min-version, $capability, $capability-options);
          @if $actual-prefix and $prefix == $actual-prefix {
            @return $browser $min-version;
          }
        }
      }
    }
  }
  @return false;
}*/
/*@function use-prefix($prefix, $capability, $threshold, $capability-options: $default-capability-options) {
  $usage: prefix-usage($prefix, $capability, $capability-options);
  @if $usage > $threshold {
    @return $usage;
  } @else {
    @each $browser in browsers($prefix) {
      @if index($supported-browsers, $browser) {
        $min-version: map-get($browser-minimum-versions, $browser);
        @if $min-version {
          $actual-prefix: browser-requires-prefix($browser, $min-version, $capability, $capability-options);
          @if $actual-prefix and $prefix == $actual-prefix {
            @return $browser $min-version;
          }
        }
      }
    }
  }
  @return false;
}*/
/*@function use-prefix($prefix, $capability, $threshold, $capability-options: $default-capability-options) {
  $usage: prefix-usage($prefix, $capability, $capability-options);
  @if $usage > $threshold {
    @return $usage;
  } @else {
    @each $browser in browsers($prefix) {
      @if index($supported-browsers, $browser) {
        $min-version: map-get($browser-minimum-versions, $browser);
        @if $min-version {
          $actual-prefix: browser-requires-prefix($browser, $min-version, $capability, $capability-options);
          @if $actual-prefix and $prefix == $actual-prefix {
            @return $browser $min-version;
          }
        }
      }
    }
  }
  @return false;
}*/
/*@function use-prefix($prefix, $capability, $threshold, $capability-options: $default-capability-options) {
  $usage: prefix-usage($prefix, $capability, $capability-options);
  @if $usage > $threshold {
    @return $usage;
  } @else {
    @each $browser in browsers($prefix) {
      @if index($supported-browsers, $browser) {
        $min-version: map-get($browser-minimum-versions, $browser);
        @if $min-version {
          $actual-prefix: browser-requires-prefix($browser, $min-version, $capability, $capability-options);
          @if $actual-prefix and $prefix == $actual-prefix {
            @return $browser $min-version;
          }
        }
      }
    }
  }
  @return false;
}*/
/*@function use-prefix($prefix, $capability, $threshold, $capability-options: $default-capability-options) {
  $usage: prefix-usage($prefix, $capability, $capability-options);
  @if $usage > $threshold {
    @return $usage;
  } @else {
    @each $browser in browsers($prefix) {
      @if index($supported-browsers, $browser) {
        $min-version: map-get($browser-minimum-versions, $browser);
        @if $min-version {
          $actual-prefix: browser-requires-prefix($browser, $min-version, $capability, $capability-options);
          @if $actual-prefix and $prefix == $actual-prefix {
            @return $browser $min-version;
          }
        }
      }
    }
  }
  @return false;
}*/
/*@function use-prefix($prefix, $capability, $threshold, $capability-options: $default-capability-options) {
  $usage: prefix-usage($prefix, $capability, $capability-options);
  @if $usage > $threshold {
    @return $usage;
  } @else {
    @each $browser in browsers($prefix) {
      @if index($supported-browsers, $browser) {
        $min-version: map-get($browser-minimum-versions, $browser);
        @if $min-version {
          $actual-prefix: browser-requires-prefix($browser, $min-version, $capability, $capability-options);
          @if $actual-prefix and $prefix == $actual-prefix {
            @return $browser $min-version;
          }
        }
      }
    }
  }
  @return false;
}*/
/*@function use-prefix($prefix, $capability, $threshold, $capability-options: $default-capability-options) {
  $usage: prefix-usage($prefix, $capability, $capability-options);
  @if $usage > $threshold {
    @return $usage;
  } @else {
    @each $browser in browsers($prefix) {
      @if index($supported-browsers, $browser) {
        $min-version: map-get($browser-minimum-versions, $browser);
        @if $min-version {
          $actual-prefix: browser-requires-prefix($browser, $min-version, $capability, $capability-options);
          @if $actual-prefix and $prefix == $actual-prefix {
            @return $browser $min-version;
          }
        }
      }
    }
  }
  @return false;
}*/
/*@function use-prefix($prefix, $capability, $threshold, $capability-options: $default-capability-options) {
  $usage: prefix-usage($prefix, $capability, $capability-options);
  @if $usage > $threshold {
    @return $usage;
  } @else {
    @each $browser in browsers($prefix) {
      @if index($supported-browsers, $browser) {
        $min-version: map-get($browser-minimum-versions, $browser);
        @if $min-version {
          $actual-prefix: browser-requires-prefix($browser, $min-version, $capability, $capability-options);
          @if $actual-prefix and $prefix == $actual-prefix {
            @return $browser $min-version;
          }
        }
      }
    }
  }
  @return false;
}*/
/*@function use-prefix($prefix, $capability, $threshold, $capability-options: $default-capability-options) {
  $usage: prefix-usage($prefix, $capability, $capability-options);
  @if $usage > $threshold {
    @return $usage;
  } @else {
    @each $browser in browsers($prefix) {
      @if index($supported-browsers, $browser) {
        $min-version: map-get($browser-minimum-versions, $browser);
        @if $min-version {
          $actual-prefix: browser-requires-prefix($browser, $min-version, $capability, $capability-options);
          @if $actual-prefix and $prefix == $actual-prefix {
            @return $browser $min-version;
          }
        }
      }
    }
  }
  @return false;
}*/
/*@function use-prefix($prefix, $capability, $threshold, $capability-options: $default-capability-options) {
  $usage: prefix-usage($prefix, $capability, $capability-options);
  @if $usage > $threshold {
    @return $usage;
  } @else {
    @each $browser in browsers($prefix) {
      @if index($supported-browsers, $browser) {
        $min-version: map-get($browser-minimum-versions, $browser);
        @if $min-version {
          $actual-prefix: browser-requires-prefix($browser, $min-version, $capability, $capability-options);
          @if $actual-prefix and $prefix == $actual-prefix {
            @return $browser $min-version;
          }
        }
      }
    }
  }
  @return false;
}*/
/*@function use-prefix($prefix, $capability, $threshold, $capability-options: $default-capability-options) {
  $usage: prefix-usage($prefix, $capability, $capability-options);
  @if $usage > $threshold {
    @return $usage;
  } @else {
    @each $browser in browsers($prefix) {
      @if index($supported-browsers, $browser) {
        $min-version: map-get($browser-minimum-versions, $browser);
        @if $min-version {
          $actual-prefix: browser-requires-prefix($browser, $min-version, $capability, $capability-options);
          @if $actual-prefix and $prefix == $actual-prefix {
            @return $browser $min-version;
          }
        }
      }
    }
  }
  @return false;
}*/
/*@function use-prefix($prefix, $capability, $threshold, $capability-options: $default-capability-options) {
  $usage: prefix-usage($prefix, $capability, $capability-options);
  @if $usage > $threshold {
    @return $usage;
  } @else {
    @each $browser in browsers($prefix) {
      @if index($supported-browsers, $browser) {
        $min-version: map-get($browser-minimum-versions, $browser);
        @if $min-version {
          $actual-prefix: browser-requires-prefix($browser, $min-version, $capability, $capability-options);
          @if $actual-prefix and $prefix == $actual-prefix {
            @return $browser $min-version;
          }
        }
      }
    }
  }
  @return false;
}*/
/*@function use-prefix($prefix, $capability, $threshold, $capability-options: $default-capability-options) {
  $usage: prefix-usage($prefix, $capability, $capability-options);
  @if $usage > $threshold {
    @return $usage;
  } @else {
    @each $browser in browsers($prefix) {
      @if index($supported-browsers, $browser) {
        $min-version: map-get($browser-minimum-versions, $browser);
        @if $min-version {
          $actual-prefix: browser-requires-prefix($browser, $min-version, $capability, $capability-options);
          @if $actual-prefix and $prefix == $actual-prefix {
            @return $browser $min-version;
          }
        }
      }
    }
  }
  @return false;
}*/
/*@function use-prefix($prefix, $capability, $threshold, $capability-options: $default-capability-options) {
  $usage: prefix-usage($prefix, $capability, $capability-options);
  @if $usage > $threshold {
    @return $usage;
  } @else {
    @each $browser in browsers($prefix) {
      @if index($supported-browsers, $browser) {
        $min-version: map-get($browser-minimum-versions, $browser);
        @if $min-version {
          $actual-prefix: browser-requires-prefix($browser, $min-version, $capability, $capability-options);
          @if $actual-prefix and $prefix == $actual-prefix {
            @return $browser $min-version;
          }
        }
      }
    }
  }
  @return false;
}*/
/*@function use-prefix($prefix, $capability, $threshold, $capability-options: $default-capability-options) {
  $usage: prefix-usage($prefix, $capability, $capability-options);
  @if $usage > $threshold {
    @return $usage;
  } @else {
    @each $browser in browsers($prefix) {
      @if index($supported-browsers, $browser) {
        $min-version: map-get($browser-minimum-versions, $browser);
        @if $min-version {
          $actual-prefix: browser-requires-prefix($browser, $min-version, $capability, $capability-options);
          @if $actual-prefix and $prefix == $actual-prefix {
            @return $browser $min-version;
          }
        }
      }
    }
  }
  @return false;
}*/
@font-face {
  font-family: "Stag Sans Light";
  src: url("../fonts/Stag Sans-Light.ttf");
  src: local("☺"), url("../fonts/Stag Sans-Light.woff") format("woff"), url("../fonts/Stag Sans-Light.ttf") format("truetype"), url("../fonts/Stag Sans-Light.svg") format("svg");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Stag Sans Thin";
  src: url("../fonts/Stag Sans-Thin.ttf");
  src: local("☺"), url("../fonts/Stag Sans-Thin.woff") format("woff"), url("../fonts/Stag Sans-Thin.ttf") format("truetype"), url("../fonts/Stag Sans-Thin.svg") format("svg");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Stag Sans Medium";
  src: url("../fonts/Stag-Sans-Medium.ttf");
  src: local("☺"), url("../fonts/Stag-Sans-Medium.woff") format("woff"), url("../fonts/Stag-Sans-Medium.ttf") format("truetype"), url("../fonts/Stag-Sans-Medium.svg") format("svg");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "HelveticaNeueBlackCon";
  src: url("../fonts/HelveticaNeueLTPro-BlkCn.ttf");
  src: local("☺"), url("../fonts/HelveticaNeueLTPro-BlkCn.woff") format("woff"), url("../fonts/HelveticaNeueLTPro-BlkCn.ttf") format("truetype"), url("../fonts/HelveticaNeueLTPro-BlkCn.svg") format("svg");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "HelveticaNeueBoldCon";
  src: url("../fonts/HelveticaNeueLTPro-BdCn.ttf");
  src: local("☺"), url("../fonts/HelveticaNeueLTPro-BdCn.woff") format("woff"), url("../fonts/HelveticaNeueLTPro-BdCn.ttf") format("truetype"), url("../fonts/HelveticaNeueLTPro-BdCn.svg") format("svg");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Stag Sans Light";
  src: url("../fonts/Stag Sans-Light.ttf?#iefix"), format("embedded-opentype"), url("../fonts/Stag Sans-Light.woff") format("woff"), url("../fonts/Stag Sans-Light.ttf") format("truetype"), url("../fonts/Stag Sans-Light.svg") format("svg");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Stag Sans Thin";
  src: url("../fonts/Stag Sans-Thin.ttf?#iefix") format("embedded-opentype"), url("../fonts/Stag Sans-Thin.woff") format("woff"), url("../fonts/Stag Sans-Thin.ttf") format("truetype"), url("../fonts/Stag Sans-Thin.svg") format("svg");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Stag Sans Medium";
  src: url("../fonts/Stag-Sans-Medium.ttf?#iefix"), format("embedded-opentype"), url("../fonts/Stag-Sans-Medium.woff") format("woff"), url("../fonts/Stag-Sans-Medium.ttf") format("truetype"), url("../fonts/Stag-Sans-Medium.svg") format("svg");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "HelveticaNeueBlackCon";
  src: url("../fonts/HelveticaNeueLTPro-BlkCn.ttf?#iefix"), format("embedded-opentype"), url("../fonts/HelveticaNeueLTPro-BlkCn.woff") format("woff"), url("../fonts/HelveticaNeueLTPro-BlkCn.ttf") format("truetype"), url("../fonts/HelveticaNeueLTPro-BlkCn.svg") format("svg");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "HelveticaNeueBoldCon";
  src: url("../fonts/HelveticaNeueLTPro-BdCn.ttf?#iefix"), format("embedded-opentype"), url("../fonts/HelveticaNeueLTPro-BdCn.woff") format("woff"), url("../fonts/HelveticaNeueLTPro-BdCn.ttf") format("truetype"), url("../fonts/HelveticaNeueLTPro-BdCn.svg") format("svg");
  font-weight: normal;
  font-style: normal;
}
/****************************

 ____                    ____                   ___
/\  _`\   __            /\  _`\                /\_ \
\ \ \L\ \/\_\  _____    \ \ \/\_\  __  __  _ __\//\ \
 \ \ ,  /\/\ \/\ '__`\   \ \ \/_/_/\ \/\ \/\`'__\\ \ \
  \ \ \\ \\ \ \ \ \L\ \   \ \ \L\ \ \ \_\ \ \ \/  \_\ \_
   \ \_\ \_\ \_\ \ ,__/    \ \____/\ \____/\ \_\  /\____\
    \/_/\/ /\/_/\ \ \/      \/___/  \/___/  \/_/  \/____/
                 \ \_\
                  \/_/

*****************************/
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font: inherit;
  font-size: 100%;
  vertical-align: baseline;
}

html {
  line-height: 1;
}

ol, ul {
  list-style: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

caption, th, td {
  text-align: left;
  font-weight: normal;
  vertical-align: middle;
}

q, blockquote {
  quotes: none;
}
q:before, q:after, blockquote:before, blockquote:after {
  content: "";
  content: none;
}

a img {
  border: none;
}

article, aside, details, figcaption, figure, footer, header, hgroup, main, menu, nav, section, summary {
  display: block;
}

/*@function use-prefix($prefix, $capability, $threshold, $capability-options: $default-capability-options) {
  $usage: prefix-usage($prefix, $capability, $capability-options);
  @if $usage > $threshold {
    @return $usage;
  } @else {
    @each $browser in browsers($prefix) {
      @if index($supported-browsers, $browser) {
        $min-version: map-get($browser-minimum-versions, $browser);
        @if $min-version {
          $actual-prefix: browser-requires-prefix($browser, $min-version, $capability, $capability-options);
          @if $actual-prefix and $prefix == $actual-prefix {
            @return $browser $min-version;
          }
        }
      }
    }
  }
  @return false;
}*/
/*@function use-prefix($prefix, $capability, $threshold, $capability-options: $default-capability-options) {
  $usage: prefix-usage($prefix, $capability, $capability-options);
  @if $usage > $threshold {
    @return $usage;
  } @else {
    @each $browser in browsers($prefix) {
      @if index($supported-browsers, $browser) {
        $min-version: map-get($browser-minimum-versions, $browser);
        @if $min-version {
          $actual-prefix: browser-requires-prefix($browser, $min-version, $capability, $capability-options);
          @if $actual-prefix and $prefix == $actual-prefix {
            @return $browser $min-version;
          }
        }
      }
    }
  }
  @return false;
}*/
/*@function use-prefix($prefix, $capability, $threshold, $capability-options: $default-capability-options) {
  $usage: prefix-usage($prefix, $capability, $capability-options);
  @if $usage > $threshold {
    @return $usage;
  } @else {
    @each $browser in browsers($prefix) {
      @if index($supported-browsers, $browser) {
        $min-version: map-get($browser-minimum-versions, $browser);
        @if $min-version {
          $actual-prefix: browser-requires-prefix($browser, $min-version, $capability, $capability-options);
          @if $actual-prefix and $prefix == $actual-prefix {
            @return $browser $min-version;
          }
        }
      }
    }
  }
  @return false;
}*/
/*@function use-prefix($prefix, $capability, $threshold, $capability-options: $default-capability-options) {
  $usage: prefix-usage($prefix, $capability, $capability-options);
  @if $usage > $threshold {
    @return $usage;
  } @else {
    @each $browser in browsers($prefix) {
      @if index($supported-browsers, $browser) {
        $min-version: map-get($browser-minimum-versions, $browser);
        @if $min-version {
          $actual-prefix: browser-requires-prefix($browser, $min-version, $capability, $capability-options);
          @if $actual-prefix and $prefix == $actual-prefix {
            @return $browser $min-version;
          }
        }
      }
    }
  }
  @return false;
}*/
/*@function use-prefix($prefix, $capability, $threshold, $capability-options: $default-capability-options) {
  $usage: prefix-usage($prefix, $capability, $capability-options);
  @if $usage > $threshold {
    @return $usage;
  } @else {
    @each $browser in browsers($prefix) {
      @if index($supported-browsers, $browser) {
        $min-version: map-get($browser-minimum-versions, $browser);
        @if $min-version {
          $actual-prefix: browser-requires-prefix($browser, $min-version, $capability, $capability-options);
          @if $actual-prefix and $prefix == $actual-prefix {
            @return $browser $min-version;
          }
        }
      }
    }
  }
  @return false;
}*/
/*@function use-prefix($prefix, $capability, $threshold, $capability-options: $default-capability-options) {
  $usage: prefix-usage($prefix, $capability, $capability-options);
  @if $usage > $threshold {
    @return $usage;
  } @else {
    @each $browser in browsers($prefix) {
      @if index($supported-browsers, $browser) {
        $min-version: map-get($browser-minimum-versions, $browser);
        @if $min-version {
          $actual-prefix: browser-requires-prefix($browser, $min-version, $capability, $capability-options);
          @if $actual-prefix and $prefix == $actual-prefix {
            @return $browser $min-version;
          }
        }
      }
    }
  }
  @return false;
}*/
/*@function use-prefix($prefix, $capability, $threshold, $capability-options: $default-capability-options) {
  $usage: prefix-usage($prefix, $capability, $capability-options);
  @if $usage > $threshold {
    @return $usage;
  } @else {
    @each $browser in browsers($prefix) {
      @if index($supported-browsers, $browser) {
        $min-version: map-get($browser-minimum-versions, $browser);
        @if $min-version {
          $actual-prefix: browser-requires-prefix($browser, $min-version, $capability, $capability-options);
          @if $actual-prefix and $prefix == $actual-prefix {
            @return $browser $min-version;
          }
        }
      }
    }
  }
  @return false;
}*/
/*@function use-prefix($prefix, $capability, $threshold, $capability-options: $default-capability-options) {
  $usage: prefix-usage($prefix, $capability, $capability-options);
  @if $usage > $threshold {
    @return $usage;
  } @else {
    @each $browser in browsers($prefix) {
      @if index($supported-browsers, $browser) {
        $min-version: map-get($browser-minimum-versions, $browser);
        @if $min-version {
          $actual-prefix: browser-requires-prefix($browser, $min-version, $capability, $capability-options);
          @if $actual-prefix and $prefix == $actual-prefix {
            @return $browser $min-version;
          }
        }
      }
    }
  }
  @return false;
}*/
/*@function use-prefix($prefix, $capability, $threshold, $capability-options: $default-capability-options) {
  $usage: prefix-usage($prefix, $capability, $capability-options);
  @if $usage > $threshold {
    @return $usage;
  } @else {
    @each $browser in browsers($prefix) {
      @if index($supported-browsers, $browser) {
        $min-version: map-get($browser-minimum-versions, $browser);
        @if $min-version {
          $actual-prefix: browser-requires-prefix($browser, $min-version, $capability, $capability-options);
          @if $actual-prefix and $prefix == $actual-prefix {
            @return $browser $min-version;
          }
        }
      }
    }
  }
  @return false;
}*/
/*@function use-prefix($prefix, $capability, $threshold, $capability-options: $default-capability-options) {
  $usage: prefix-usage($prefix, $capability, $capability-options);
  @if $usage > $threshold {
    @return $usage;
  } @else {
    @each $browser in browsers($prefix) {
      @if index($supported-browsers, $browser) {
        $min-version: map-get($browser-minimum-versions, $browser);
        @if $min-version {
          $actual-prefix: browser-requires-prefix($browser, $min-version, $capability, $capability-options);
          @if $actual-prefix and $prefix == $actual-prefix {
            @return $browser $min-version;
          }
        }
      }
    }
  }
  @return false;
}*/
/*@function use-prefix($prefix, $capability, $threshold, $capability-options: $default-capability-options) {
  $usage: prefix-usage($prefix, $capability, $capability-options);
  @if $usage > $threshold {
    @return $usage;
  } @else {
    @each $browser in browsers($prefix) {
      @if index($supported-browsers, $browser) {
        $min-version: map-get($browser-minimum-versions, $browser);
        @if $min-version {
          $actual-prefix: browser-requires-prefix($browser, $min-version, $capability, $capability-options);
          @if $actual-prefix and $prefix == $actual-prefix {
            @return $browser $min-version;
          }
        }
      }
    }
  }
  @return false;
}*/
/*@function use-prefix($prefix, $capability, $threshold, $capability-options: $default-capability-options) {
  $usage: prefix-usage($prefix, $capability, $capability-options);
  @if $usage > $threshold {
    @return $usage;
  } @else {
    @each $browser in browsers($prefix) {
      @if index($supported-browsers, $browser) {
        $min-version: map-get($browser-minimum-versions, $browser);
        @if $min-version {
          $actual-prefix: browser-requires-prefix($browser, $min-version, $capability, $capability-options);
          @if $actual-prefix and $prefix == $actual-prefix {
            @return $browser $min-version;
          }
        }
      }
    }
  }
  @return false;
}*/
/*@function use-prefix($prefix, $capability, $threshold, $capability-options: $default-capability-options) {
  $usage: prefix-usage($prefix, $capability, $capability-options);
  @if $usage > $threshold {
    @return $usage;
  } @else {
    @each $browser in browsers($prefix) {
      @if index($supported-browsers, $browser) {
        $min-version: map-get($browser-minimum-versions, $browser);
        @if $min-version {
          $actual-prefix: browser-requires-prefix($browser, $min-version, $capability, $capability-options);
          @if $actual-prefix and $prefix == $actual-prefix {
            @return $browser $min-version;
          }
        }
      }
    }
  }
  @return false;
}*/
/*@function use-prefix($prefix, $capability, $threshold, $capability-options: $default-capability-options) {
  $usage: prefix-usage($prefix, $capability, $capability-options);
  @if $usage > $threshold {
    @return $usage;
  } @else {
    @each $browser in browsers($prefix) {
      @if index($supported-browsers, $browser) {
        $min-version: map-get($browser-minimum-versions, $browser);
        @if $min-version {
          $actual-prefix: browser-requires-prefix($browser, $min-version, $capability, $capability-options);
          @if $actual-prefix and $prefix == $actual-prefix {
            @return $browser $min-version;
          }
        }
      }
    }
  }
  @return false;
}*/
/*@function use-prefix($prefix, $capability, $threshold, $capability-options: $default-capability-options) {
  $usage: prefix-usage($prefix, $capability, $capability-options);
  @if $usage > $threshold {
    @return $usage;
  } @else {
    @each $browser in browsers($prefix) {
      @if index($supported-browsers, $browser) {
        $min-version: map-get($browser-minimum-versions, $browser);
        @if $min-version {
          $actual-prefix: browser-requires-prefix($browser, $min-version, $capability, $capability-options);
          @if $actual-prefix and $prefix == $actual-prefix {
            @return $browser $min-version;
          }
        }
      }
    }
  }
  @return false;
}*/
/*@function use-prefix($prefix, $capability, $threshold, $capability-options: $default-capability-options) {
  $usage: prefix-usage($prefix, $capability, $capability-options);
  @if $usage > $threshold {
    @return $usage;
  } @else {
    @each $browser in browsers($prefix) {
      @if index($supported-browsers, $browser) {
        $min-version: map-get($browser-minimum-versions, $browser);
        @if $min-version {
          $actual-prefix: browser-requires-prefix($browser, $min-version, $capability, $capability-options);
          @if $actual-prefix and $prefix == $actual-prefix {
            @return $browser $min-version;
          }
        }
      }
    }
  }
  @return false;
}*/
/*@function use-prefix($prefix, $capability, $threshold, $capability-options: $default-capability-options) {
  $usage: prefix-usage($prefix, $capability, $capability-options);
  @if $usage > $threshold {
    @return $usage;
  } @else {
    @each $browser in browsers($prefix) {
      @if index($supported-browsers, $browser) {
        $min-version: map-get($browser-minimum-versions, $browser);
        @if $min-version {
          $actual-prefix: browser-requires-prefix($browser, $min-version, $capability, $capability-options);
          @if $actual-prefix and $prefix == $actual-prefix {
            @return $browser $min-version;
          }
        }
      }
    }
  }
  @return false;
}*/
/*@function use-prefix($prefix, $capability, $threshold, $capability-options: $default-capability-options) {
  $usage: prefix-usage($prefix, $capability, $capability-options);
  @if $usage > $threshold {
    @return $usage;
  } @else {
    @each $browser in browsers($prefix) {
      @if index($supported-browsers, $browser) {
        $min-version: map-get($browser-minimum-versions, $browser);
        @if $min-version {
          $actual-prefix: browser-requires-prefix($browser, $min-version, $capability, $capability-options);
          @if $actual-prefix and $prefix == $actual-prefix {
            @return $browser $min-version;
          }
        }
      }
    }
  }
  @return false;
}*/
/*@function use-prefix($prefix, $capability, $threshold, $capability-options: $default-capability-options) {
  $usage: prefix-usage($prefix, $capability, $capability-options);
  @if $usage > $threshold {
    @return $usage;
  } @else {
    @each $browser in browsers($prefix) {
      @if index($supported-browsers, $browser) {
        $min-version: map-get($browser-minimum-versions, $browser);
        @if $min-version {
          $actual-prefix: browser-requires-prefix($browser, $min-version, $capability, $capability-options);
          @if $actual-prefix and $prefix == $actual-prefix {
            @return $browser $min-version;
          }
        }
      }
    }
  }
  @return false;
}*/
/*@function use-prefix($prefix, $capability, $threshold, $capability-options: $default-capability-options) {
  $usage: prefix-usage($prefix, $capability, $capability-options);
  @if $usage > $threshold {
    @return $usage;
  } @else {
    @each $browser in browsers($prefix) {
      @if index($supported-browsers, $browser) {
        $min-version: map-get($browser-minimum-versions, $browser);
        @if $min-version {
          $actual-prefix: browser-requires-prefix($browser, $min-version, $capability, $capability-options);
          @if $actual-prefix and $prefix == $actual-prefix {
            @return $browser $min-version;
          }
        }
      }
    }
  }
  @return false;
}*/
/*@function use-prefix($prefix, $capability, $threshold, $capability-options: $default-capability-options) {
  $usage: prefix-usage($prefix, $capability, $capability-options);
  @if $usage > $threshold {
    @return $usage;
  } @else {
    @each $browser in browsers($prefix) {
      @if index($supported-browsers, $browser) {
        $min-version: map-get($browser-minimum-versions, $browser);
        @if $min-version {
          $actual-prefix: browser-requires-prefix($browser, $min-version, $capability, $capability-options);
          @if $actual-prefix and $prefix == $actual-prefix {
            @return $browser $min-version;
          }
        }
      }
    }
  }
  @return false;
}*/
/*@function use-prefix($prefix, $capability, $threshold, $capability-options: $default-capability-options) {
  $usage: prefix-usage($prefix, $capability, $capability-options);
  @if $usage > $threshold {
    @return $usage;
  } @else {
    @each $browser in browsers($prefix) {
      @if index($supported-browsers, $browser) {
        $min-version: map-get($browser-minimum-versions, $browser);
        @if $min-version {
          $actual-prefix: browser-requires-prefix($browser, $min-version, $capability, $capability-options);
          @if $actual-prefix and $prefix == $actual-prefix {
            @return $browser $min-version;
          }
        }
      }
    }
  }
  @return false;
}*/
/*@function use-prefix($prefix, $capability, $threshold, $capability-options: $default-capability-options) {
  $usage: prefix-usage($prefix, $capability, $capability-options);
  @if $usage > $threshold {
    @return $usage;
  } @else {
    @each $browser in browsers($prefix) {
      @if index($supported-browsers, $browser) {
        $min-version: map-get($browser-minimum-versions, $browser);
        @if $min-version {
          $actual-prefix: browser-requires-prefix($browser, $min-version, $capability, $capability-options);
          @if $actual-prefix and $prefix == $actual-prefix {
            @return $browser $min-version;
          }
        }
      }
    }
  }
  @return false;
}*/
/*@function use-prefix($prefix, $capability, $threshold, $capability-options: $default-capability-options) {
  $usage: prefix-usage($prefix, $capability, $capability-options);
  @if $usage > $threshold {
    @return $usage;
  } @else {
    @each $browser in browsers($prefix) {
      @if index($supported-browsers, $browser) {
        $min-version: map-get($browser-minimum-versions, $browser);
        @if $min-version {
          $actual-prefix: browser-requires-prefix($browser, $min-version, $capability, $capability-options);
          @if $actual-prefix and $prefix == $actual-prefix {
            @return $browser $min-version;
          }
        }
      }
    }
  }
  @return false;
}*/
@font-face {
  font-family: "Stag Sans Light";
  src: url("../fonts/Stag Sans-Light.ttf");
  src: local("☺"), url("../fonts/Stag Sans-Light.woff") format("woff"), url("../fonts/Stag Sans-Light.ttf") format("truetype"), url("../fonts/Stag Sans-Light.svg") format("svg");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Stag Sans Thin";
  src: url("../fonts/Stag Sans-Thin.ttf");
  src: local("☺"), url("../fonts/Stag Sans-Thin.woff") format("woff"), url("../fonts/Stag Sans-Thin.ttf") format("truetype"), url("../fonts/Stag Sans-Thin.svg") format("svg");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Stag Sans Medium";
  src: url("../fonts/Stag-Sans-Medium.ttf");
  src: local("☺"), url("../fonts/Stag-Sans-Medium.woff") format("woff"), url("../fonts/Stag-Sans-Medium.ttf") format("truetype"), url("../fonts/Stag-Sans-Medium.svg") format("svg");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "HelveticaNeueBlackCon";
  src: url("../fonts/HelveticaNeueLTPro-BlkCn.ttf");
  src: local("☺"), url("../fonts/HelveticaNeueLTPro-BlkCn.woff") format("woff"), url("../fonts/HelveticaNeueLTPro-BlkCn.ttf") format("truetype"), url("../fonts/HelveticaNeueLTPro-BlkCn.svg") format("svg");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "HelveticaNeueBoldCon";
  src: url("../fonts/HelveticaNeueLTPro-BdCn.ttf");
  src: local("☺"), url("../fonts/HelveticaNeueLTPro-BdCn.woff") format("woff"), url("../fonts/HelveticaNeueLTPro-BdCn.ttf") format("truetype"), url("../fonts/HelveticaNeueLTPro-BdCn.svg") format("svg");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Stag Sans Light";
  src: url("../fonts/Stag Sans-Light.ttf?#iefix"), format("embedded-opentype"), url("../fonts/Stag Sans-Light.woff") format("woff"), url("../fonts/Stag Sans-Light.ttf") format("truetype"), url("../fonts/Stag Sans-Light.svg") format("svg");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Stag Sans Thin";
  src: url("../fonts/Stag Sans-Thin.ttf?#iefix") format("embedded-opentype"), url("../fonts/Stag Sans-Thin.woff") format("woff"), url("../fonts/Stag Sans-Thin.ttf") format("truetype"), url("../fonts/Stag Sans-Thin.svg") format("svg");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Stag Sans Medium";
  src: url("../fonts/Stag-Sans-Medium.ttf?#iefix"), format("embedded-opentype"), url("../fonts/Stag-Sans-Medium.woff") format("woff"), url("../fonts/Stag-Sans-Medium.ttf") format("truetype"), url("../fonts/Stag-Sans-Medium.svg") format("svg");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "HelveticaNeueBlackCon";
  src: url("../fonts/HelveticaNeueLTPro-BlkCn.ttf?#iefix"), format("embedded-opentype"), url("../fonts/HelveticaNeueLTPro-BlkCn.woff") format("woff"), url("../fonts/HelveticaNeueLTPro-BlkCn.ttf") format("truetype"), url("../fonts/HelveticaNeueLTPro-BlkCn.svg") format("svg");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "HelveticaNeueBoldCon";
  src: url("../fonts/HelveticaNeueLTPro-BdCn.ttf?#iefix"), format("embedded-opentype"), url("../fonts/HelveticaNeueLTPro-BdCn.woff") format("woff"), url("../fonts/HelveticaNeueLTPro-BdCn.ttf") format("truetype"), url("../fonts/HelveticaNeueLTPro-BdCn.svg") format("svg");
  font-weight: normal;
  font-style: normal;
}
/*! jQuery UI - v1.10.3 - 2013-11-17
* http://jqueryui.com
* Includes: jquery.ui.core.css, jquery.ui.datepicker.css, jquery.ui.theme.css
* To view and modify this theme, visit http://jqueryui.com/themeroller/?ffDefault=Segoe%20UI%2CArial%2Csans-serif&fwDefault=bold&fsDefault=1.1em&cornerRadius=6px&bgColorHeader=333333&bgTextureHeader=gloss_wave&bgImgOpacityHeader=25&borderColorHeader=333333&fcHeader=ffffff&iconColorHeader=ffffff&bgColorContent=000000&bgTextureContent=inset_soft&bgImgOpacityContent=25&borderColorContent=666666&fcContent=ffffff&iconColorContent=cccccc&bgColorDefault=555555&bgTextureDefault=glass&bgImgOpacityDefault=20&borderColorDefault=666666&fcDefault=eeeeee&iconColorDefault=cccccc&bgColorHover=0078a3&bgTextureHover=glass&bgImgOpacityHover=40&borderColorHover=59b4d4&fcHover=ffffff&iconColorHover=ffffff&bgColorActive=f58400&bgTextureActive=inset_soft&bgImgOpacityActive=30&borderColorActive=ffaf0f&fcActive=ffffff&iconColorActive=222222&bgColorHighlight=eeeeee&bgTextureHighlight=highlight_soft&bgImgOpacityHighlight=80&borderColorHighlight=cccccc&fcHighlight=2e7db2&iconColorHighlight=4b8e0b&bgColorError=ffc73d&bgTextureError=glass&bgImgOpacityError=40&borderColorError=ffb73d&fcError=111111&iconColorError=a83300&bgColorOverlay=5c5c5c&bgTextureOverlay=flat&bgImgOpacityOverlay=50&opacityOverlay=80&bgColorShadow=cccccc&bgTextureShadow=flat&bgImgOpacityShadow=30&opacityShadow=60&thicknessShadow=7px&offsetTopShadow=-7px&offsetLeftShadow=-7px&cornerRadiusShadow=8px
* Copyright 2013 jQuery Foundation and other contributors; Licensed MIT */
/* Layout helpers
----------------------------------*/
.ui-helper-hidden {
  display: none;
}

.ui-helper-hidden-accessible {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

.ui-helper-reset {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  line-height: 1.3;
  text-decoration: none;
  list-style: none;
}

.ui-helper-clearfix:before,
.ui-helper-clearfix:after {
  content: "";
  display: table;
  border-collapse: collapse;
}

.ui-helper-clearfix:after {
  clear: both;
}

.ui-helper-clearfix {
  min-height: 0;
  /* support: IE7 */
}

.ui-helper-zfix {
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  position: absolute;
  opacity: 0;
  filter: Alpha(Opacity=0);
}

.ui-front {
  z-index: 100;
}

/* Interaction Cues
----------------------------------*/
.ui-state-disabled {
  cursor: default !important;
}

/* Icons
----------------------------------*/
/* states and images */
.ui-icon {
  display: block;
  text-indent: -99999px;
  overflow: hidden;
  background-repeat: no-repeat;
}

/* Misc visuals
----------------------------------*/
/* Overlays */
.ui-widget-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.ui-datepicker {
  width: 224px;
  display: none;
}

.ui-datepicker .ui-datepicker-header {
  position: relative;
}

.ui-datepicker .ui-datepicker-prev,
.ui-datepicker .ui-datepicker-next {
  position: absolute;
  top: 11px;
  width: 7px;
  height: 12px;
  cursor: pointer;
  -webkit-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}

.ui-datepicker .ui-datepicker-prev {
  background: url("../images/icon-datepicker-left.png") no-repeat;
  left: 9px;
}
@media (min--moz-device-pixel-ratio: 1.3), (-o-min-device-pixel-ratio: 2.6/2), (-webkit-min-device-pixel-ratio: 1.3), (min-device-pixel-ratio: 1.3), (min-resolution: 1.3dppx) {
  .ui-datepicker .ui-datepicker-prev {
    background-image: url("../images/icon-datepicker-left@2x.png");
    background-size: 7px 12px;
  }
}

.ui-datepicker .ui-datepicker-next {
  background: url("../images/icon-datepicker-right.png") no-repeat;
  right: 9px;
}
@media (min--moz-device-pixel-ratio: 1.3), (-o-min-device-pixel-ratio: 2.6/2), (-webkit-min-device-pixel-ratio: 1.3), (min-device-pixel-ratio: 1.3), (min-resolution: 1.3dppx) {
  .ui-datepicker .ui-datepicker-next {
    background-image: url("../images/icon-datepicker-right@2x.png");
    background-size: 7px 12px;
  }
}

.ui-datepicker .ui-datepicker-prev:hover {
  background: url("../images/icon-datepicker-left-hover.png") no-repeat;
  border: none;
}
@media (min--moz-device-pixel-ratio: 1.3), (-o-min-device-pixel-ratio: 2.6/2), (-webkit-min-device-pixel-ratio: 1.3), (min-device-pixel-ratio: 1.3), (min-resolution: 1.3dppx) {
  .ui-datepicker .ui-datepicker-prev:hover {
    background-image: url("../images/icon-datepicker-left-hover@2x.png");
    background-size: 7px 12px;
  }
}

.ui-datepicker .ui-datepicker-next:hover {
  background: url("../images/icon-datepicker-right-hover.png") no-repeat;
  border: none;
}
@media (min--moz-device-pixel-ratio: 1.3), (-o-min-device-pixel-ratio: 2.6/2), (-webkit-min-device-pixel-ratio: 1.3), (min-device-pixel-ratio: 1.3), (min-resolution: 1.3dppx) {
  .ui-datepicker .ui-datepicker-next:hover {
    background-image: url("../images/icon-datepicker-right-hover@2x.png");
    background-size: 7px 12px;
  }
}

.ui-datepicker .ui-datepicker-title {
  height: 30px;
  text-align: center;
  line-height: 32px;
}

.ui-datepicker .ui-datepicker-title select {
  margin: 1px 0;
}

.ui-datepicker select.ui-datepicker-month-year {
  width: 100%;
}

.ui-datepicker select.ui-datepicker-month,
.ui-datepicker select.ui-datepicker-year {
  width: 49%;
  background-color: rgba(54, 56, 60, 0.9);
  border: 0;
  color: white !important;
}

.ui-datepicker table {
  width: 231px !important;
  border-collapse: collapse;
  margin: 0 0 0.4em;
}

.ui-datepicker th {
  padding: 0.7em 0.3em;
  text-align: center;
  border: 0;
}

.ui-datepicker td {
  border: 0;
  padding: 1px;
}

.ui-datepicker td span,
.ui-datepicker td a {
  display: block;
  text-decoration: none;
}

.ui-datepicker .ui-datepicker-buttonpane {
  background-image: none;
  margin: 0.7em 0 0 0;
  padding: 0 0.2em;
  border-left: 0;
  border-right: 0;
  border-bottom: 0;
}

.ui-datepicker .ui-datepicker-buttonpane button {
  float: right;
  margin: 0.5em 0.2em 0.4em;
  cursor: pointer;
  padding: 0.2em 0.6em 0.3em 0.6em;
  width: auto;
  overflow: visible;
}

.ui-datepicker .ui-datepicker-buttonpane button.ui-datepicker-current {
  float: left;
}

/* with multiple calendars */
.ui-datepicker.ui-datepicker-multi {
  width: auto;
}

.ui-datepicker-multi .ui-datepicker-group {
  float: left;
}

.ui-datepicker-multi .ui-datepicker-group table {
  width: 95%;
  margin: 0 auto 0.4em;
}

.ui-datepicker-multi-2 .ui-datepicker-group {
  width: 50%;
}

.ui-datepicker-multi-3 .ui-datepicker-group {
  width: 33.3%;
}

.ui-datepicker-multi-4 .ui-datepicker-group {
  width: 25%;
}

.ui-datepicker-multi .ui-datepicker-group-last .ui-datepicker-header,
.ui-datepicker-multi .ui-datepicker-group-middle .ui-datepicker-header {
  border-left-width: 0;
}

.ui-datepicker-multi .ui-datepicker-buttonpane {
  clear: left;
}

.ui-datepicker-row-break {
  clear: both;
  width: 100%;
}

/* RTL support */
.ui-datepicker-rtl {
  direction: rtl;
}

.ui-datepicker-rtl .ui-datepicker-prev {
  right: 2px;
  left: auto;
}

.ui-datepicker-rtl .ui-datepicker-next {
  left: 2px;
  right: auto;
}

.ui-datepicker-rtl .ui-datepicker-prev:hover {
  right: 1px;
  left: auto;
}

.ui-datepicker-rtl .ui-datepicker-next:hover {
  left: 1px;
  right: auto;
}

.ui-datepicker-rtl .ui-datepicker-buttonpane {
  clear: right;
}

.ui-datepicker-rtl .ui-datepicker-buttonpane button {
  float: left;
}

.ui-datepicker-rtl .ui-datepicker-buttonpane button.ui-datepicker-current,
.ui-datepicker-rtl .ui-datepicker-group {
  float: right;
}

.ui-datepicker-rtl .ui-datepicker-group-last .ui-datepicker-header,
.ui-datepicker-rtl .ui-datepicker-group-middle .ui-datepicker-header {
  border-right-width: 0;
  border-left-width: 1px;
}

/* Component containers */
.ui-widget-header {
  background: rgba(54, 56, 60, 0.9);
  color: #FFF;
  font-family: "Stag Sans Light", Arial;
  font-size: 0.9230769231em;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  text-transform: uppercase;
}

.ui-widget-header a {
  color: #ffffff;
}

/* Overlays */
.ui-widget-overlay {
  background: #5c5c5c;
  opacity: 0.8;
  filter: Alpha(Opacity=80);
}

.ui-widget-shadow {
  margin: -7px 0 0 -7px;
  padding: 7px;
  background: #cccccc;
  opacity: 0.6;
  filter: Alpha(Opacity=60);
}

.ui-datepicker-calendar thead {
  background: rgba(54, 56, 60, 0.9);
  color: #FFF;
  font-family: "Stag Sans Light", Arial;
  font-size: 0.9230769231em;
}
.ui-datepicker-calendar tbody {
  background: rgba(28, 29, 31, 0.95);
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
}
.ui-datepicker-calendar tbody a {
  -webkit-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  color: #FFF;
  font-family: "Stag Sans Light", Arial;
  font-size: 12px;
  border-radius: 50px;
  display: block;
  width: 26px;
  height: 26px;
  text-align: center;
  line-height: 26px;
}
.ui-datepicker-calendar tbody a:hover {
  background: #2E3033;
}
.ui-datepicker-calendar tbody .ui-datepicker-current-day a {
  background: #3D86C8;
}

.ui-widget-content {
  background: none;
  border: none;
}

.ui-state-default, .ui-widget-content .ui-state-default, .ui-widget-header .ui-state-default {
  background: none;
  border: none;
  color: #FFF;
}

.ui-datepicker .ui-datepicker-header {
  width: 231px;
  padding: 0;
  border-radius: 3px 3px 0 0;
  border: none;
}

.ui-datepicker .ui-datepicker-title {
  font-size: 12px;
}

.ui-datepicker .ui-datepicker-unselectable .ui-state-default {
  font-weight: normal;
}

/****************************

 ____                    ____                   ___      
/\  _`\   __            /\  _`\                /\_ \     
\ \ \L\ \/\_\  _____    \ \ \/\_\  __  __  _ __\//\ \    
 \ \ ,  /\/\ \/\ '__`\   \ \ \/_/_/\ \/\ \/\`'__\\ \ \   
  \ \ \\ \\ \ \ \ \L\ \   \ \ \L\ \ \ \_\ \ \ \/  \_\ \_ 
   \ \_\ \_\ \_\ \ ,__/    \ \____/\ \____/\ \_\  /\____\
    \/_/\/ /\/_/\ \ \/      \/___/  \/___/  \/_/  \/____/
                 \ \_\                                   
                  \/_/

*****************************/
/****************************

 ____                    ____                   ___
/\  _`\   __            /\  _`\                /\_ \
\ \ \L\ \/\_\  _____    \ \ \/\_\  __  __  _ __\//\ \
 \ \ ,  /\/\ \/\ '__`\   \ \ \/_/_/\ \/\ \/\`'__\\ \ \
  \ \ \\ \\ \ \ \ \L\ \   \ \ \L\ \ \ \_\ \ \ \/  \_\ \_
   \ \_\ \_\ \_\ \ ,__/    \ \____/\ \____/\ \_\  /\____\
    \/_/\/ /\/_/\ \ \/      \/___/  \/___/  \/_/  \/____/
                 \ \_\
                  \/_/

*****************************/
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font: inherit;
  font-size: 100%;
  vertical-align: baseline;
}

html {
  line-height: 1;
}

ol, ul {
  list-style: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

caption, th, td {
  text-align: left;
  font-weight: normal;
  vertical-align: middle;
}

q, blockquote {
  quotes: none;
}
q:before, q:after, blockquote:before, blockquote:after {
  content: "";
  content: none;
}

a img {
  border: none;
}

article, aside, details, figcaption, figure, footer, header, hgroup, main, menu, nav, section, summary {
  display: block;
}

/*@function use-prefix($prefix, $capability, $threshold, $capability-options: $default-capability-options) {
  $usage: prefix-usage($prefix, $capability, $capability-options);
  @if $usage > $threshold {
    @return $usage;
  } @else {
    @each $browser in browsers($prefix) {
      @if index($supported-browsers, $browser) {
        $min-version: map-get($browser-minimum-versions, $browser);
        @if $min-version {
          $actual-prefix: browser-requires-prefix($browser, $min-version, $capability, $capability-options);
          @if $actual-prefix and $prefix == $actual-prefix {
            @return $browser $min-version;
          }
        }
      }
    }
  }
  @return false;
}*/
/*@function use-prefix($prefix, $capability, $threshold, $capability-options: $default-capability-options) {
  $usage: prefix-usage($prefix, $capability, $capability-options);
  @if $usage > $threshold {
    @return $usage;
  } @else {
    @each $browser in browsers($prefix) {
      @if index($supported-browsers, $browser) {
        $min-version: map-get($browser-minimum-versions, $browser);
        @if $min-version {
          $actual-prefix: browser-requires-prefix($browser, $min-version, $capability, $capability-options);
          @if $actual-prefix and $prefix == $actual-prefix {
            @return $browser $min-version;
          }
        }
      }
    }
  }
  @return false;
}*/
/*@function use-prefix($prefix, $capability, $threshold, $capability-options: $default-capability-options) {
  $usage: prefix-usage($prefix, $capability, $capability-options);
  @if $usage > $threshold {
    @return $usage;
  } @else {
    @each $browser in browsers($prefix) {
      @if index($supported-browsers, $browser) {
        $min-version: map-get($browser-minimum-versions, $browser);
        @if $min-version {
          $actual-prefix: browser-requires-prefix($browser, $min-version, $capability, $capability-options);
          @if $actual-prefix and $prefix == $actual-prefix {
            @return $browser $min-version;
          }
        }
      }
    }
  }
  @return false;
}*/
/*@function use-prefix($prefix, $capability, $threshold, $capability-options: $default-capability-options) {
  $usage: prefix-usage($prefix, $capability, $capability-options);
  @if $usage > $threshold {
    @return $usage;
  } @else {
    @each $browser in browsers($prefix) {
      @if index($supported-browsers, $browser) {
        $min-version: map-get($browser-minimum-versions, $browser);
        @if $min-version {
          $actual-prefix: browser-requires-prefix($browser, $min-version, $capability, $capability-options);
          @if $actual-prefix and $prefix == $actual-prefix {
            @return $browser $min-version;
          }
        }
      }
    }
  }
  @return false;
}*/
/*@function use-prefix($prefix, $capability, $threshold, $capability-options: $default-capability-options) {
  $usage: prefix-usage($prefix, $capability, $capability-options);
  @if $usage > $threshold {
    @return $usage;
  } @else {
    @each $browser in browsers($prefix) {
      @if index($supported-browsers, $browser) {
        $min-version: map-get($browser-minimum-versions, $browser);
        @if $min-version {
          $actual-prefix: browser-requires-prefix($browser, $min-version, $capability, $capability-options);
          @if $actual-prefix and $prefix == $actual-prefix {
            @return $browser $min-version;
          }
        }
      }
    }
  }
  @return false;
}*/
/*@function use-prefix($prefix, $capability, $threshold, $capability-options: $default-capability-options) {
  $usage: prefix-usage($prefix, $capability, $capability-options);
  @if $usage > $threshold {
    @return $usage;
  } @else {
    @each $browser in browsers($prefix) {
      @if index($supported-browsers, $browser) {
        $min-version: map-get($browser-minimum-versions, $browser);
        @if $min-version {
          $actual-prefix: browser-requires-prefix($browser, $min-version, $capability, $capability-options);
          @if $actual-prefix and $prefix == $actual-prefix {
            @return $browser $min-version;
          }
        }
      }
    }
  }
  @return false;
}*/
/*@function use-prefix($prefix, $capability, $threshold, $capability-options: $default-capability-options) {
  $usage: prefix-usage($prefix, $capability, $capability-options);
  @if $usage > $threshold {
    @return $usage;
  } @else {
    @each $browser in browsers($prefix) {
      @if index($supported-browsers, $browser) {
        $min-version: map-get($browser-minimum-versions, $browser);
        @if $min-version {
          $actual-prefix: browser-requires-prefix($browser, $min-version, $capability, $capability-options);
          @if $actual-prefix and $prefix == $actual-prefix {
            @return $browser $min-version;
          }
        }
      }
    }
  }
  @return false;
}*/
/*@function use-prefix($prefix, $capability, $threshold, $capability-options: $default-capability-options) {
  $usage: prefix-usage($prefix, $capability, $capability-options);
  @if $usage > $threshold {
    @return $usage;
  } @else {
    @each $browser in browsers($prefix) {
      @if index($supported-browsers, $browser) {
        $min-version: map-get($browser-minimum-versions, $browser);
        @if $min-version {
          $actual-prefix: browser-requires-prefix($browser, $min-version, $capability, $capability-options);
          @if $actual-prefix and $prefix == $actual-prefix {
            @return $browser $min-version;
          }
        }
      }
    }
  }
  @return false;
}*/
/*@function use-prefix($prefix, $capability, $threshold, $capability-options: $default-capability-options) {
  $usage: prefix-usage($prefix, $capability, $capability-options);
  @if $usage > $threshold {
    @return $usage;
  } @else {
    @each $browser in browsers($prefix) {
      @if index($supported-browsers, $browser) {
        $min-version: map-get($browser-minimum-versions, $browser);
        @if $min-version {
          $actual-prefix: browser-requires-prefix($browser, $min-version, $capability, $capability-options);
          @if $actual-prefix and $prefix == $actual-prefix {
            @return $browser $min-version;
          }
        }
      }
    }
  }
  @return false;
}*/
/*@function use-prefix($prefix, $capability, $threshold, $capability-options: $default-capability-options) {
  $usage: prefix-usage($prefix, $capability, $capability-options);
  @if $usage > $threshold {
    @return $usage;
  } @else {
    @each $browser in browsers($prefix) {
      @if index($supported-browsers, $browser) {
        $min-version: map-get($browser-minimum-versions, $browser);
        @if $min-version {
          $actual-prefix: browser-requires-prefix($browser, $min-version, $capability, $capability-options);
          @if $actual-prefix and $prefix == $actual-prefix {
            @return $browser $min-version;
          }
        }
      }
    }
  }
  @return false;
}*/
/*@function use-prefix($prefix, $capability, $threshold, $capability-options: $default-capability-options) {
  $usage: prefix-usage($prefix, $capability, $capability-options);
  @if $usage > $threshold {
    @return $usage;
  } @else {
    @each $browser in browsers($prefix) {
      @if index($supported-browsers, $browser) {
        $min-version: map-get($browser-minimum-versions, $browser);
        @if $min-version {
          $actual-prefix: browser-requires-prefix($browser, $min-version, $capability, $capability-options);
          @if $actual-prefix and $prefix == $actual-prefix {
            @return $browser $min-version;
          }
        }
      }
    }
  }
  @return false;
}*/
/*@function use-prefix($prefix, $capability, $threshold, $capability-options: $default-capability-options) {
  $usage: prefix-usage($prefix, $capability, $capability-options);
  @if $usage > $threshold {
    @return $usage;
  } @else {
    @each $browser in browsers($prefix) {
      @if index($supported-browsers, $browser) {
        $min-version: map-get($browser-minimum-versions, $browser);
        @if $min-version {
          $actual-prefix: browser-requires-prefix($browser, $min-version, $capability, $capability-options);
          @if $actual-prefix and $prefix == $actual-prefix {
            @return $browser $min-version;
          }
        }
      }
    }
  }
  @return false;
}*/
/*@function use-prefix($prefix, $capability, $threshold, $capability-options: $default-capability-options) {
  $usage: prefix-usage($prefix, $capability, $capability-options);
  @if $usage > $threshold {
    @return $usage;
  } @else {
    @each $browser in browsers($prefix) {
      @if index($supported-browsers, $browser) {
        $min-version: map-get($browser-minimum-versions, $browser);
        @if $min-version {
          $actual-prefix: browser-requires-prefix($browser, $min-version, $capability, $capability-options);
          @if $actual-prefix and $prefix == $actual-prefix {
            @return $browser $min-version;
          }
        }
      }
    }
  }
  @return false;
}*/
/*@function use-prefix($prefix, $capability, $threshold, $capability-options: $default-capability-options) {
  $usage: prefix-usage($prefix, $capability, $capability-options);
  @if $usage > $threshold {
    @return $usage;
  } @else {
    @each $browser in browsers($prefix) {
      @if index($supported-browsers, $browser) {
        $min-version: map-get($browser-minimum-versions, $browser);
        @if $min-version {
          $actual-prefix: browser-requires-prefix($browser, $min-version, $capability, $capability-options);
          @if $actual-prefix and $prefix == $actual-prefix {
            @return $browser $min-version;
          }
        }
      }
    }
  }
  @return false;
}*/
/*@function use-prefix($prefix, $capability, $threshold, $capability-options: $default-capability-options) {
  $usage: prefix-usage($prefix, $capability, $capability-options);
  @if $usage > $threshold {
    @return $usage;
  } @else {
    @each $browser in browsers($prefix) {
      @if index($supported-browsers, $browser) {
        $min-version: map-get($browser-minimum-versions, $browser);
        @if $min-version {
          $actual-prefix: browser-requires-prefix($browser, $min-version, $capability, $capability-options);
          @if $actual-prefix and $prefix == $actual-prefix {
            @return $browser $min-version;
          }
        }
      }
    }
  }
  @return false;
}*/
/*@function use-prefix($prefix, $capability, $threshold, $capability-options: $default-capability-options) {
  $usage: prefix-usage($prefix, $capability, $capability-options);
  @if $usage > $threshold {
    @return $usage;
  } @else {
    @each $browser in browsers($prefix) {
      @if index($supported-browsers, $browser) {
        $min-version: map-get($browser-minimum-versions, $browser);
        @if $min-version {
          $actual-prefix: browser-requires-prefix($browser, $min-version, $capability, $capability-options);
          @if $actual-prefix and $prefix == $actual-prefix {
            @return $browser $min-version;
          }
        }
      }
    }
  }
  @return false;
}*/
/*@function use-prefix($prefix, $capability, $threshold, $capability-options: $default-capability-options) {
  $usage: prefix-usage($prefix, $capability, $capability-options);
  @if $usage > $threshold {
    @return $usage;
  } @else {
    @each $browser in browsers($prefix) {
      @if index($supported-browsers, $browser) {
        $min-version: map-get($browser-minimum-versions, $browser);
        @if $min-version {
          $actual-prefix: browser-requires-prefix($browser, $min-version, $capability, $capability-options);
          @if $actual-prefix and $prefix == $actual-prefix {
            @return $browser $min-version;
          }
        }
      }
    }
  }
  @return false;
}*/
/*@function use-prefix($prefix, $capability, $threshold, $capability-options: $default-capability-options) {
  $usage: prefix-usage($prefix, $capability, $capability-options);
  @if $usage > $threshold {
    @return $usage;
  } @else {
    @each $browser in browsers($prefix) {
      @if index($supported-browsers, $browser) {
        $min-version: map-get($browser-minimum-versions, $browser);
        @if $min-version {
          $actual-prefix: browser-requires-prefix($browser, $min-version, $capability, $capability-options);
          @if $actual-prefix and $prefix == $actual-prefix {
            @return $browser $min-version;
          }
        }
      }
    }
  }
  @return false;
}*/
/*@function use-prefix($prefix, $capability, $threshold, $capability-options: $default-capability-options) {
  $usage: prefix-usage($prefix, $capability, $capability-options);
  @if $usage > $threshold {
    @return $usage;
  } @else {
    @each $browser in browsers($prefix) {
      @if index($supported-browsers, $browser) {
        $min-version: map-get($browser-minimum-versions, $browser);
        @if $min-version {
          $actual-prefix: browser-requires-prefix($browser, $min-version, $capability, $capability-options);
          @if $actual-prefix and $prefix == $actual-prefix {
            @return $browser $min-version;
          }
        }
      }
    }
  }
  @return false;
}*/
/*@function use-prefix($prefix, $capability, $threshold, $capability-options: $default-capability-options) {
  $usage: prefix-usage($prefix, $capability, $capability-options);
  @if $usage > $threshold {
    @return $usage;
  } @else {
    @each $browser in browsers($prefix) {
      @if index($supported-browsers, $browser) {
        $min-version: map-get($browser-minimum-versions, $browser);
        @if $min-version {
          $actual-prefix: browser-requires-prefix($browser, $min-version, $capability, $capability-options);
          @if $actual-prefix and $prefix == $actual-prefix {
            @return $browser $min-version;
          }
        }
      }
    }
  }
  @return false;
}*/
/*@function use-prefix($prefix, $capability, $threshold, $capability-options: $default-capability-options) {
  $usage: prefix-usage($prefix, $capability, $capability-options);
  @if $usage > $threshold {
    @return $usage;
  } @else {
    @each $browser in browsers($prefix) {
      @if index($supported-browsers, $browser) {
        $min-version: map-get($browser-minimum-versions, $browser);
        @if $min-version {
          $actual-prefix: browser-requires-prefix($browser, $min-version, $capability, $capability-options);
          @if $actual-prefix and $prefix == $actual-prefix {
            @return $browser $min-version;
          }
        }
      }
    }
  }
  @return false;
}*/
/*@function use-prefix($prefix, $capability, $threshold, $capability-options: $default-capability-options) {
  $usage: prefix-usage($prefix, $capability, $capability-options);
  @if $usage > $threshold {
    @return $usage;
  } @else {
    @each $browser in browsers($prefix) {
      @if index($supported-browsers, $browser) {
        $min-version: map-get($browser-minimum-versions, $browser);
        @if $min-version {
          $actual-prefix: browser-requires-prefix($browser, $min-version, $capability, $capability-options);
          @if $actual-prefix and $prefix == $actual-prefix {
            @return $browser $min-version;
          }
        }
      }
    }
  }
  @return false;
}*/
/*@function use-prefix($prefix, $capability, $threshold, $capability-options: $default-capability-options) {
  $usage: prefix-usage($prefix, $capability, $capability-options);
  @if $usage > $threshold {
    @return $usage;
  } @else {
    @each $browser in browsers($prefix) {
      @if index($supported-browsers, $browser) {
        $min-version: map-get($browser-minimum-versions, $browser);
        @if $min-version {
          $actual-prefix: browser-requires-prefix($browser, $min-version, $capability, $capability-options);
          @if $actual-prefix and $prefix == $actual-prefix {
            @return $browser $min-version;
          }
        }
      }
    }
  }
  @return false;
}*/
/*@function use-prefix($prefix, $capability, $threshold, $capability-options: $default-capability-options) {
  $usage: prefix-usage($prefix, $capability, $capability-options);
  @if $usage > $threshold {
    @return $usage;
  } @else {
    @each $browser in browsers($prefix) {
      @if index($supported-browsers, $browser) {
        $min-version: map-get($browser-minimum-versions, $browser);
        @if $min-version {
          $actual-prefix: browser-requires-prefix($browser, $min-version, $capability, $capability-options);
          @if $actual-prefix and $prefix == $actual-prefix {
            @return $browser $min-version;
          }
        }
      }
    }
  }
  @return false;
}*/
@font-face {
  font-family: "Stag Sans Light";
  src: url("../fonts/Stag Sans-Light.ttf");
  src: local("☺"), url("../fonts/Stag Sans-Light.woff") format("woff"), url("../fonts/Stag Sans-Light.ttf") format("truetype"), url("../fonts/Stag Sans-Light.svg") format("svg");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Stag Sans Thin";
  src: url("../fonts/Stag Sans-Thin.ttf");
  src: local("☺"), url("../fonts/Stag Sans-Thin.woff") format("woff"), url("../fonts/Stag Sans-Thin.ttf") format("truetype"), url("../fonts/Stag Sans-Thin.svg") format("svg");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Stag Sans Medium";
  src: url("../fonts/Stag-Sans-Medium.ttf");
  src: local("☺"), url("../fonts/Stag-Sans-Medium.woff") format("woff"), url("../fonts/Stag-Sans-Medium.ttf") format("truetype"), url("../fonts/Stag-Sans-Medium.svg") format("svg");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "HelveticaNeueBlackCon";
  src: url("../fonts/HelveticaNeueLTPro-BlkCn.ttf");
  src: local("☺"), url("../fonts/HelveticaNeueLTPro-BlkCn.woff") format("woff"), url("../fonts/HelveticaNeueLTPro-BlkCn.ttf") format("truetype"), url("../fonts/HelveticaNeueLTPro-BlkCn.svg") format("svg");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "HelveticaNeueBoldCon";
  src: url("../fonts/HelveticaNeueLTPro-BdCn.ttf");
  src: local("☺"), url("../fonts/HelveticaNeueLTPro-BdCn.woff") format("woff"), url("../fonts/HelveticaNeueLTPro-BdCn.ttf") format("truetype"), url("../fonts/HelveticaNeueLTPro-BdCn.svg") format("svg");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Stag Sans Light";
  src: url("../fonts/Stag Sans-Light.ttf?#iefix"), format("embedded-opentype"), url("../fonts/Stag Sans-Light.woff") format("woff"), url("../fonts/Stag Sans-Light.ttf") format("truetype"), url("../fonts/Stag Sans-Light.svg") format("svg");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Stag Sans Thin";
  src: url("../fonts/Stag Sans-Thin.ttf?#iefix") format("embedded-opentype"), url("../fonts/Stag Sans-Thin.woff") format("woff"), url("../fonts/Stag Sans-Thin.ttf") format("truetype"), url("../fonts/Stag Sans-Thin.svg") format("svg");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Stag Sans Medium";
  src: url("../fonts/Stag-Sans-Medium.ttf?#iefix"), format("embedded-opentype"), url("../fonts/Stag-Sans-Medium.woff") format("woff"), url("../fonts/Stag-Sans-Medium.ttf") format("truetype"), url("../fonts/Stag-Sans-Medium.svg") format("svg");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "HelveticaNeueBlackCon";
  src: url("../fonts/HelveticaNeueLTPro-BlkCn.ttf?#iefix"), format("embedded-opentype"), url("../fonts/HelveticaNeueLTPro-BlkCn.woff") format("woff"), url("../fonts/HelveticaNeueLTPro-BlkCn.ttf") format("truetype"), url("../fonts/HelveticaNeueLTPro-BlkCn.svg") format("svg");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "HelveticaNeueBoldCon";
  src: url("../fonts/HelveticaNeueLTPro-BdCn.ttf?#iefix"), format("embedded-opentype"), url("../fonts/HelveticaNeueLTPro-BdCn.woff") format("woff"), url("../fonts/HelveticaNeueLTPro-BdCn.ttf") format("truetype"), url("../fonts/HelveticaNeueLTPro-BdCn.svg") format("svg");
  font-weight: normal;
  font-style: normal;
}
.swell-slider,
.period-slider,
.strength-slider {
  background: #464A4C;
  width: 180px;
  height: 3px;
  border: none;
}
.swell-slider .ui-slider-range,
.period-slider .ui-slider-range,
.strength-slider .ui-slider-range {
  background: #6297C8;
}
.swell-slider .ui-slider-handle,
.period-slider .ui-slider-handle,
.strength-slider .ui-slider-handle {
  background: url("../images/icon-slider-handle.png") no-repeat;
  width: 17px;
  height: 18px;
  outline: none;
  border: none;
  cursor: pointer;
  top: -6px;
  border-color: transparent;
  border-radius: 3px;
}
@media (min--moz-device-pixel-ratio: 1.3), (-o-min-device-pixel-ratio: 2.6/2), (-webkit-min-device-pixel-ratio: 1.3), (min-device-pixel-ratio: 1.3), (min-resolution: 1.3dppx) {
  .swell-slider .ui-slider-handle,
.period-slider .ui-slider-handle,
.strength-slider .ui-slider-handle {
    background-image: url("../images/icon-slider-handle@2x.png");
    background-size: 17px 18px;
  }
}

.ios-switch {
  width: 58px;
  height: 25px;
  position: relative;
  background-color: #E5E5E5;
  border-radius: 100px;
  backface-visibility: hidden;
}
.ios-switch .label {
  color: #FFF;
  font-size: 11px;
  position: absolute;
  top: 8px;
  left: 8px;
}
.ios-switch.on {
  background: #6297C8;
}

.ios-switch .background-fill {
  width: 100%;
  height: 100%;
  border-radius: 100px;
  position: absolute;
  left: 0;
  top: 0;
}

.ios-switch .state-background {
  border-radius: 100px;
  z-index: 2;
  background: #BEBEBE;
  -o-box-sizing: border-box;
  -ms-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -o-transform: scale(1);
  -ms-transform: scale(1);
  -moz-transform: scale(1);
  -webkit-transform: scale(1);
  transform: scale(1);
  -o-transition: 0.4s;
  -ms-transition: 0.4s;
  -moz-transition: 0.4s;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}
.ios-switch .state-background .label {
  right: 8px;
  left: auto;
}

.ios-switch .handle {
  width: 23px;
  height: 23px;
  background: #464A4C;
  cursor: pointer;
  top: 1px;
  left: 1px;
  position: absolute;
  border-radius: 20px;
  z-index: 3;
  -o-transition: -o-transform 0.3s 0.25s cubic-bezier(0.455, 0.03, 0.215, 1.33);
  -ms-transition: -ms-transform 0.3s 0.25s cubic-bezier(0.455, 0.03, 0.215, 1.33);
  -moz-transition: -moz-transform 0.3s 0.25s cubic-bezier(0.455, 0.03, 0.215, 1.33);
  -webkit-transition: -webkit-transform 0.3s 0.25s cubic-bezier(0.455, 0.03, 0.215, 1.33);
  -o-transition: transform 0.3s 0.25s cubic-bezier(0.455, 0.03, 0.215, 1.33);
  -ms-transition: transform 0.3s 0.25s cubic-bezier(0.455, 0.03, 0.215, 1.33);
  -moz-transition: transform 0.3s 0.25s cubic-bezier(0.455, 0.03, 0.215, 1.33);
  -webkit-transition: transform 0.3s 0.25s cubic-bezier(0.455, 0.03, 0.215, 1.33);
  transition: transform 0.3s 0.25s cubic-bezier(0.455, 0.03, 0.215, 1.33);
}

.ios-switch.off .handle {
  -o-animation: expand-off 0.3s 0.2s;
  -ms-animation: expand-off 0.3s 0.2s;
  -moz-animation: expand-off 0.3s 0.2s;
  -webkit-animation: expand-off 0.3s 0.2s;
  animation: expand-off 0.3s 0.2s;
  -o-transform: translate3d(0px, 0, 0);
  -ms-transform: translate3d(0px, 0, 0);
  -moz-transform: translate3d(0px, 0, 0);
  -webkit-transform: translate3d(0px, 0, 0);
  transform: translate3d(0px, 0, 0);
}

.ios-switch.off .on-background {
  -o-transition: 0.3s 0s;
  -ms-transition: 0.3s 0s;
  -moz-transition: 0.3s 0s;
  -webkit-transition: 0.3s 0s;
  transition: 0.3s 0s;
}

.ios-switch.off .state-background {
  -o-transition: 0.4s 0.25s;
  -ms-transition: 0.4s 0.25s;
  -moz-transition: 0.4s 0.25s;
  -webkit-transition: 0.4s 0.25s;
  transition: 0.4s 0.25s;
}

.ios-switch.on .handle {
  -o-animation: expand-on 0.3s 0.2s;
  -ms-animation: expand-on 0.3s 0.2s;
  -moz-animation: expand-on 0.3s 0.2s;
  -webkit-animation: expand-on 0.3s 0.2s;
  animation: expand-on 0.3s 0.2s;
  -o-transform: translate3d(33px, 0, 0);
  -ms-transform: translate3d(33px, 0, 0);
  -moz-transform: translate3d(33px, 0, 0);
  -webkit-transform: translate3d(33px, 0, 0);
  transform: translate3d(33px, 0, 0);
}

.ios-switch.on .on-background {
  opacity: 1;
  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
  filter: alpha(opacity=100);
}

.ios-switch.on .state-background {
  -o-transform: scale(0);
  -ms-transform: scale(0);
  -moz-transform: scale(0);
  -webkit-transform: scale(0);
  transform: scale(0);
}

@keyframes expand-on {
  0% {
    width: 23px;
  }
  40% {
    width: 23px;
  }
  100% {
    width: 23px;
  }
}
@keyframes expand-off {
  0% {
    width: 23px;
    left: 1px;
  }
  40% {
    width: 23px;
    left: 1px;
  }
  100% {
    width: 23px;
    left: 1px;
  }
}
@-o-keyframes expand-on {
  0% {
    width: 23px;
  }
  40% {
    width: 23px;
  }
  100% {
    width: 23px;
  }
}
@-ms-keyframes expand-on {
  0% {
    width: 23px;
  }
  40% {
    width: 23px;
  }
  100% {
    width: 23px;
  }
}
@-moz-keyframes expand-on {
  0% {
    width: 23px;
  }
  40% {
    width: 23px;
  }
  100% {
    width: 23px;
  }
}
@-webkit-keyframes expand-on {
  0% {
    width: 23px;
  }
  40% {
    width: 23px;
  }
  100% {
    width: 23px;
  }
}
@-o-keyframes expand-off {
  0% {
    width: 23px;
    left: 1px;
  }
  40% {
    width: 23px;
    left: 1px;
  }
  100% {
    width: 23px;
    left: 1px;
  }
}
@-ms-keyframes expand-off {
  0% {
    width: 23px;
    left: 1px;
  }
  40% {
    width: 23px;
    left: 1px;
  }
  100% {
    width: 23px;
    left: 1px;
  }
}
@-moz-keyframes expand-off {
  0% {
    width: 23px;
    left: 1px;
  }
  40% {
    width: 23px;
    left: 1px;
  }
  100% {
    width: 23px;
    left: 1px;
  }
}
@-webkit-keyframes expand-off {
  0% {
    width: 23px;
    left: 1px;
  }
  40% {
    width: 23px;
    left: 1px;
  }
  100% {
    width: 23px;
    left: 1px;
  }
}
/****************************

 ____                    ____                   ___      
/\  _`\   __            /\  _`\                /\_ \     
\ \ \L\ \/\_\  _____    \ \ \/\_\  __  __  _ __\//\ \    
 \ \ ,  /\/\ \/\ '__`\   \ \ \/_/_/\ \/\ \/\`'__\\ \ \   
  \ \ \\ \\ \ \ \ \L\ \   \ \ \L\ \ \ \_\ \ \ \/  \_\ \_ 
   \ \_\ \_\ \_\ \ ,__/    \ \____/\ \____/\ \_\  /\____\
    \/_/\/ /\/_/\ \ \/      \/___/  \/___/  \/_/  \/____/
                 \ \_\                                   
                  \/_/

*****************************/
/****************************

 ____                    ____                   ___
/\  _`\   __            /\  _`\                /\_ \
\ \ \L\ \/\_\  _____    \ \ \/\_\  __  __  _ __\//\ \
 \ \ ,  /\/\ \/\ '__`\   \ \ \/_/_/\ \/\ \/\`'__\\ \ \
  \ \ \\ \\ \ \ \ \L\ \   \ \ \L\ \ \ \_\ \ \ \/  \_\ \_
   \ \_\ \_\ \_\ \ ,__/    \ \____/\ \____/\ \_\  /\____\
    \/_/\/ /\/_/\ \ \/      \/___/  \/___/  \/_/  \/____/
                 \ \_\
                  \/_/

*****************************/
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font: inherit;
  font-size: 100%;
  vertical-align: baseline;
}

html {
  line-height: 1;
}

ol, ul {
  list-style: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

caption, th, td {
  text-align: left;
  font-weight: normal;
  vertical-align: middle;
}

q, blockquote {
  quotes: none;
}
q:before, q:after, blockquote:before, blockquote:after {
  content: "";
  content: none;
}

a img {
  border: none;
}

article, aside, details, figcaption, figure, footer, header, hgroup, main, menu, nav, section, summary {
  display: block;
}

/*@function use-prefix($prefix, $capability, $threshold, $capability-options: $default-capability-options) {
  $usage: prefix-usage($prefix, $capability, $capability-options);
  @if $usage > $threshold {
    @return $usage;
  } @else {
    @each $browser in browsers($prefix) {
      @if index($supported-browsers, $browser) {
        $min-version: map-get($browser-minimum-versions, $browser);
        @if $min-version {
          $actual-prefix: browser-requires-prefix($browser, $min-version, $capability, $capability-options);
          @if $actual-prefix and $prefix == $actual-prefix {
            @return $browser $min-version;
          }
        }
      }
    }
  }
  @return false;
}*/
/*@function use-prefix($prefix, $capability, $threshold, $capability-options: $default-capability-options) {
  $usage: prefix-usage($prefix, $capability, $capability-options);
  @if $usage > $threshold {
    @return $usage;
  } @else {
    @each $browser in browsers($prefix) {
      @if index($supported-browsers, $browser) {
        $min-version: map-get($browser-minimum-versions, $browser);
        @if $min-version {
          $actual-prefix: browser-requires-prefix($browser, $min-version, $capability, $capability-options);
          @if $actual-prefix and $prefix == $actual-prefix {
            @return $browser $min-version;
          }
        }
      }
    }
  }
  @return false;
}*/
/*@function use-prefix($prefix, $capability, $threshold, $capability-options: $default-capability-options) {
  $usage: prefix-usage($prefix, $capability, $capability-options);
  @if $usage > $threshold {
    @return $usage;
  } @else {
    @each $browser in browsers($prefix) {
      @if index($supported-browsers, $browser) {
        $min-version: map-get($browser-minimum-versions, $browser);
        @if $min-version {
          $actual-prefix: browser-requires-prefix($browser, $min-version, $capability, $capability-options);
          @if $actual-prefix and $prefix == $actual-prefix {
            @return $browser $min-version;
          }
        }
      }
    }
  }
  @return false;
}*/
/*@function use-prefix($prefix, $capability, $threshold, $capability-options: $default-capability-options) {
  $usage: prefix-usage($prefix, $capability, $capability-options);
  @if $usage > $threshold {
    @return $usage;
  } @else {
    @each $browser in browsers($prefix) {
      @if index($supported-browsers, $browser) {
        $min-version: map-get($browser-minimum-versions, $browser);
        @if $min-version {
          $actual-prefix: browser-requires-prefix($browser, $min-version, $capability, $capability-options);
          @if $actual-prefix and $prefix == $actual-prefix {
            @return $browser $min-version;
          }
        }
      }
    }
  }
  @return false;
}*/
/*@function use-prefix($prefix, $capability, $threshold, $capability-options: $default-capability-options) {
  $usage: prefix-usage($prefix, $capability, $capability-options);
  @if $usage > $threshold {
    @return $usage;
  } @else {
    @each $browser in browsers($prefix) {
      @if index($supported-browsers, $browser) {
        $min-version: map-get($browser-minimum-versions, $browser);
        @if $min-version {
          $actual-prefix: browser-requires-prefix($browser, $min-version, $capability, $capability-options);
          @if $actual-prefix and $prefix == $actual-prefix {
            @return $browser $min-version;
          }
        }
      }
    }
  }
  @return false;
}*/
/*@function use-prefix($prefix, $capability, $threshold, $capability-options: $default-capability-options) {
  $usage: prefix-usage($prefix, $capability, $capability-options);
  @if $usage > $threshold {
    @return $usage;
  } @else {
    @each $browser in browsers($prefix) {
      @if index($supported-browsers, $browser) {
        $min-version: map-get($browser-minimum-versions, $browser);
        @if $min-version {
          $actual-prefix: browser-requires-prefix($browser, $min-version, $capability, $capability-options);
          @if $actual-prefix and $prefix == $actual-prefix {
            @return $browser $min-version;
          }
        }
      }
    }
  }
  @return false;
}*/
/*@function use-prefix($prefix, $capability, $threshold, $capability-options: $default-capability-options) {
  $usage: prefix-usage($prefix, $capability, $capability-options);
  @if $usage > $threshold {
    @return $usage;
  } @else {
    @each $browser in browsers($prefix) {
      @if index($supported-browsers, $browser) {
        $min-version: map-get($browser-minimum-versions, $browser);
        @if $min-version {
          $actual-prefix: browser-requires-prefix($browser, $min-version, $capability, $capability-options);
          @if $actual-prefix and $prefix == $actual-prefix {
            @return $browser $min-version;
          }
        }
      }
    }
  }
  @return false;
}*/
/*@function use-prefix($prefix, $capability, $threshold, $capability-options: $default-capability-options) {
  $usage: prefix-usage($prefix, $capability, $capability-options);
  @if $usage > $threshold {
    @return $usage;
  } @else {
    @each $browser in browsers($prefix) {
      @if index($supported-browsers, $browser) {
        $min-version: map-get($browser-minimum-versions, $browser);
        @if $min-version {
          $actual-prefix: browser-requires-prefix($browser, $min-version, $capability, $capability-options);
          @if $actual-prefix and $prefix == $actual-prefix {
            @return $browser $min-version;
          }
        }
      }
    }
  }
  @return false;
}*/
/*@function use-prefix($prefix, $capability, $threshold, $capability-options: $default-capability-options) {
  $usage: prefix-usage($prefix, $capability, $capability-options);
  @if $usage > $threshold {
    @return $usage;
  } @else {
    @each $browser in browsers($prefix) {
      @if index($supported-browsers, $browser) {
        $min-version: map-get($browser-minimum-versions, $browser);
        @if $min-version {
          $actual-prefix: browser-requires-prefix($browser, $min-version, $capability, $capability-options);
          @if $actual-prefix and $prefix == $actual-prefix {
            @return $browser $min-version;
          }
        }
      }
    }
  }
  @return false;
}*/
/*@function use-prefix($prefix, $capability, $threshold, $capability-options: $default-capability-options) {
  $usage: prefix-usage($prefix, $capability, $capability-options);
  @if $usage > $threshold {
    @return $usage;
  } @else {
    @each $browser in browsers($prefix) {
      @if index($supported-browsers, $browser) {
        $min-version: map-get($browser-minimum-versions, $browser);
        @if $min-version {
          $actual-prefix: browser-requires-prefix($browser, $min-version, $capability, $capability-options);
          @if $actual-prefix and $prefix == $actual-prefix {
            @return $browser $min-version;
          }
        }
      }
    }
  }
  @return false;
}*/
/*@function use-prefix($prefix, $capability, $threshold, $capability-options: $default-capability-options) {
  $usage: prefix-usage($prefix, $capability, $capability-options);
  @if $usage > $threshold {
    @return $usage;
  } @else {
    @each $browser in browsers($prefix) {
      @if index($supported-browsers, $browser) {
        $min-version: map-get($browser-minimum-versions, $browser);
        @if $min-version {
          $actual-prefix: browser-requires-prefix($browser, $min-version, $capability, $capability-options);
          @if $actual-prefix and $prefix == $actual-prefix {
            @return $browser $min-version;
          }
        }
      }
    }
  }
  @return false;
}*/
/*@function use-prefix($prefix, $capability, $threshold, $capability-options: $default-capability-options) {
  $usage: prefix-usage($prefix, $capability, $capability-options);
  @if $usage > $threshold {
    @return $usage;
  } @else {
    @each $browser in browsers($prefix) {
      @if index($supported-browsers, $browser) {
        $min-version: map-get($browser-minimum-versions, $browser);
        @if $min-version {
          $actual-prefix: browser-requires-prefix($browser, $min-version, $capability, $capability-options);
          @if $actual-prefix and $prefix == $actual-prefix {
            @return $browser $min-version;
          }
        }
      }
    }
  }
  @return false;
}*/
/*@function use-prefix($prefix, $capability, $threshold, $capability-options: $default-capability-options) {
  $usage: prefix-usage($prefix, $capability, $capability-options);
  @if $usage > $threshold {
    @return $usage;
  } @else {
    @each $browser in browsers($prefix) {
      @if index($supported-browsers, $browser) {
        $min-version: map-get($browser-minimum-versions, $browser);
        @if $min-version {
          $actual-prefix: browser-requires-prefix($browser, $min-version, $capability, $capability-options);
          @if $actual-prefix and $prefix == $actual-prefix {
            @return $browser $min-version;
          }
        }
      }
    }
  }
  @return false;
}*/
/*@function use-prefix($prefix, $capability, $threshold, $capability-options: $default-capability-options) {
  $usage: prefix-usage($prefix, $capability, $capability-options);
  @if $usage > $threshold {
    @return $usage;
  } @else {
    @each $browser in browsers($prefix) {
      @if index($supported-browsers, $browser) {
        $min-version: map-get($browser-minimum-versions, $browser);
        @if $min-version {
          $actual-prefix: browser-requires-prefix($browser, $min-version, $capability, $capability-options);
          @if $actual-prefix and $prefix == $actual-prefix {
            @return $browser $min-version;
          }
        }
      }
    }
  }
  @return false;
}*/
/*@function use-prefix($prefix, $capability, $threshold, $capability-options: $default-capability-options) {
  $usage: prefix-usage($prefix, $capability, $capability-options);
  @if $usage > $threshold {
    @return $usage;
  } @else {
    @each $browser in browsers($prefix) {
      @if index($supported-browsers, $browser) {
        $min-version: map-get($browser-minimum-versions, $browser);
        @if $min-version {
          $actual-prefix: browser-requires-prefix($browser, $min-version, $capability, $capability-options);
          @if $actual-prefix and $prefix == $actual-prefix {
            @return $browser $min-version;
          }
        }
      }
    }
  }
  @return false;
}*/
/*@function use-prefix($prefix, $capability, $threshold, $capability-options: $default-capability-options) {
  $usage: prefix-usage($prefix, $capability, $capability-options);
  @if $usage > $threshold {
    @return $usage;
  } @else {
    @each $browser in browsers($prefix) {
      @if index($supported-browsers, $browser) {
        $min-version: map-get($browser-minimum-versions, $browser);
        @if $min-version {
          $actual-prefix: browser-requires-prefix($browser, $min-version, $capability, $capability-options);
          @if $actual-prefix and $prefix == $actual-prefix {
            @return $browser $min-version;
          }
        }
      }
    }
  }
  @return false;
}*/
/*@function use-prefix($prefix, $capability, $threshold, $capability-options: $default-capability-options) {
  $usage: prefix-usage($prefix, $capability, $capability-options);
  @if $usage > $threshold {
    @return $usage;
  } @else {
    @each $browser in browsers($prefix) {
      @if index($supported-browsers, $browser) {
        $min-version: map-get($browser-minimum-versions, $browser);
        @if $min-version {
          $actual-prefix: browser-requires-prefix($browser, $min-version, $capability, $capability-options);
          @if $actual-prefix and $prefix == $actual-prefix {
            @return $browser $min-version;
          }
        }
      }
    }
  }
  @return false;
}*/
/*@function use-prefix($prefix, $capability, $threshold, $capability-options: $default-capability-options) {
  $usage: prefix-usage($prefix, $capability, $capability-options);
  @if $usage > $threshold {
    @return $usage;
  } @else {
    @each $browser in browsers($prefix) {
      @if index($supported-browsers, $browser) {
        $min-version: map-get($browser-minimum-versions, $browser);
        @if $min-version {
          $actual-prefix: browser-requires-prefix($browser, $min-version, $capability, $capability-options);
          @if $actual-prefix and $prefix == $actual-prefix {
            @return $browser $min-version;
          }
        }
      }
    }
  }
  @return false;
}*/
/*@function use-prefix($prefix, $capability, $threshold, $capability-options: $default-capability-options) {
  $usage: prefix-usage($prefix, $capability, $capability-options);
  @if $usage > $threshold {
    @return $usage;
  } @else {
    @each $browser in browsers($prefix) {
      @if index($supported-browsers, $browser) {
        $min-version: map-get($browser-minimum-versions, $browser);
        @if $min-version {
          $actual-prefix: browser-requires-prefix($browser, $min-version, $capability, $capability-options);
          @if $actual-prefix and $prefix == $actual-prefix {
            @return $browser $min-version;
          }
        }
      }
    }
  }
  @return false;
}*/
/*@function use-prefix($prefix, $capability, $threshold, $capability-options: $default-capability-options) {
  $usage: prefix-usage($prefix, $capability, $capability-options);
  @if $usage > $threshold {
    @return $usage;
  } @else {
    @each $browser in browsers($prefix) {
      @if index($supported-browsers, $browser) {
        $min-version: map-get($browser-minimum-versions, $browser);
        @if $min-version {
          $actual-prefix: browser-requires-prefix($browser, $min-version, $capability, $capability-options);
          @if $actual-prefix and $prefix == $actual-prefix {
            @return $browser $min-version;
          }
        }
      }
    }
  }
  @return false;
}*/
/*@function use-prefix($prefix, $capability, $threshold, $capability-options: $default-capability-options) {
  $usage: prefix-usage($prefix, $capability, $capability-options);
  @if $usage > $threshold {
    @return $usage;
  } @else {
    @each $browser in browsers($prefix) {
      @if index($supported-browsers, $browser) {
        $min-version: map-get($browser-minimum-versions, $browser);
        @if $min-version {
          $actual-prefix: browser-requires-prefix($browser, $min-version, $capability, $capability-options);
          @if $actual-prefix and $prefix == $actual-prefix {
            @return $browser $min-version;
          }
        }
      }
    }
  }
  @return false;
}*/
/*@function use-prefix($prefix, $capability, $threshold, $capability-options: $default-capability-options) {
  $usage: prefix-usage($prefix, $capability, $capability-options);
  @if $usage > $threshold {
    @return $usage;
  } @else {
    @each $browser in browsers($prefix) {
      @if index($supported-browsers, $browser) {
        $min-version: map-get($browser-minimum-versions, $browser);
        @if $min-version {
          $actual-prefix: browser-requires-prefix($browser, $min-version, $capability, $capability-options);
          @if $actual-prefix and $prefix == $actual-prefix {
            @return $browser $min-version;
          }
        }
      }
    }
  }
  @return false;
}*/
/*@function use-prefix($prefix, $capability, $threshold, $capability-options: $default-capability-options) {
  $usage: prefix-usage($prefix, $capability, $capability-options);
  @if $usage > $threshold {
    @return $usage;
  } @else {
    @each $browser in browsers($prefix) {
      @if index($supported-browsers, $browser) {
        $min-version: map-get($browser-minimum-versions, $browser);
        @if $min-version {
          $actual-prefix: browser-requires-prefix($browser, $min-version, $capability, $capability-options);
          @if $actual-prefix and $prefix == $actual-prefix {
            @return $browser $min-version;
          }
        }
      }
    }
  }
  @return false;
}*/
/*@function use-prefix($prefix, $capability, $threshold, $capability-options: $default-capability-options) {
  $usage: prefix-usage($prefix, $capability, $capability-options);
  @if $usage > $threshold {
    @return $usage;
  } @else {
    @each $browser in browsers($prefix) {
      @if index($supported-browsers, $browser) {
        $min-version: map-get($browser-minimum-versions, $browser);
        @if $min-version {
          $actual-prefix: browser-requires-prefix($browser, $min-version, $capability, $capability-options);
          @if $actual-prefix and $prefix == $actual-prefix {
            @return $browser $min-version;
          }
        }
      }
    }
  }
  @return false;
}*/
@font-face {
  font-family: "Stag Sans Light";
  src: url("../fonts/Stag Sans-Light.ttf");
  src: local("☺"), url("../fonts/Stag Sans-Light.woff") format("woff"), url("../fonts/Stag Sans-Light.ttf") format("truetype"), url("../fonts/Stag Sans-Light.svg") format("svg");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Stag Sans Thin";
  src: url("../fonts/Stag Sans-Thin.ttf");
  src: local("☺"), url("../fonts/Stag Sans-Thin.woff") format("woff"), url("../fonts/Stag Sans-Thin.ttf") format("truetype"), url("../fonts/Stag Sans-Thin.svg") format("svg");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Stag Sans Medium";
  src: url("../fonts/Stag-Sans-Medium.ttf");
  src: local("☺"), url("../fonts/Stag-Sans-Medium.woff") format("woff"), url("../fonts/Stag-Sans-Medium.ttf") format("truetype"), url("../fonts/Stag-Sans-Medium.svg") format("svg");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "HelveticaNeueBlackCon";
  src: url("../fonts/HelveticaNeueLTPro-BlkCn.ttf");
  src: local("☺"), url("../fonts/HelveticaNeueLTPro-BlkCn.woff") format("woff"), url("../fonts/HelveticaNeueLTPro-BlkCn.ttf") format("truetype"), url("../fonts/HelveticaNeueLTPro-BlkCn.svg") format("svg");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "HelveticaNeueBoldCon";
  src: url("../fonts/HelveticaNeueLTPro-BdCn.ttf");
  src: local("☺"), url("../fonts/HelveticaNeueLTPro-BdCn.woff") format("woff"), url("../fonts/HelveticaNeueLTPro-BdCn.ttf") format("truetype"), url("../fonts/HelveticaNeueLTPro-BdCn.svg") format("svg");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Stag Sans Light";
  src: url("../fonts/Stag Sans-Light.ttf?#iefix"), format("embedded-opentype"), url("../fonts/Stag Sans-Light.woff") format("woff"), url("../fonts/Stag Sans-Light.ttf") format("truetype"), url("../fonts/Stag Sans-Light.svg") format("svg");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Stag Sans Thin";
  src: url("../fonts/Stag Sans-Thin.ttf?#iefix") format("embedded-opentype"), url("../fonts/Stag Sans-Thin.woff") format("woff"), url("../fonts/Stag Sans-Thin.ttf") format("truetype"), url("../fonts/Stag Sans-Thin.svg") format("svg");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Stag Sans Medium";
  src: url("../fonts/Stag-Sans-Medium.ttf?#iefix"), format("embedded-opentype"), url("../fonts/Stag-Sans-Medium.woff") format("woff"), url("../fonts/Stag-Sans-Medium.ttf") format("truetype"), url("../fonts/Stag-Sans-Medium.svg") format("svg");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "HelveticaNeueBlackCon";
  src: url("../fonts/HelveticaNeueLTPro-BlkCn.ttf?#iefix"), format("embedded-opentype"), url("../fonts/HelveticaNeueLTPro-BlkCn.woff") format("woff"), url("../fonts/HelveticaNeueLTPro-BlkCn.ttf") format("truetype"), url("../fonts/HelveticaNeueLTPro-BlkCn.svg") format("svg");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "HelveticaNeueBoldCon";
  src: url("../fonts/HelveticaNeueLTPro-BdCn.ttf?#iefix"), format("embedded-opentype"), url("../fonts/HelveticaNeueLTPro-BdCn.woff") format("woff"), url("../fonts/HelveticaNeueLTPro-BdCn.ttf") format("truetype"), url("../fonts/HelveticaNeueLTPro-BdCn.svg") format("svg");
  font-weight: normal;
  font-style: normal;
}
.rateit {
  position: relative;
  display: inline-block;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -o-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-touch-callout: none;
  cursor: pointer;
}
.rateit .rateit-range {
  position: relative;
  display: -moz-inline-box;
  display: inline-block;
  background: url("../images/icon-star-full.png") 0 0;
  height: 17px;
  outline: none;
}
@media (min--moz-device-pixel-ratio: 1.3), (-o-min-device-pixel-ratio: 2.6/2), (-webkit-min-device-pixel-ratio: 1.3), (min-device-pixel-ratio: 1.3), (min-resolution: 1.3dppx) {
  .rateit .rateit-range {
    background-image: url("../images/icon-star-full@2x.png");
    background-size: 19px 34px;
  }
}
.rateit .rateit-range * {
  display: block;
}
.rateit .rateit-hover,
.rateit .rateit-selected {
  position: absolute;
  left: 0px;
}
.rateit .rateit-hover {
  background: url("../images/icon-star-full.png") left -17px;
}
@media (min--moz-device-pixel-ratio: 1.3), (-o-min-device-pixel-ratio: 2.6/2), (-webkit-min-device-pixel-ratio: 1.3), (min-device-pixel-ratio: 1.3), (min-resolution: 1.3dppx) {
  .rateit .rateit-hover {
    background-image: url("../images/icon-star-full@2x.png");
    background-size: 19px 34px;
  }
}
.rateit .rateit-selected {
  background: url("../images/icon-star-full.png") left -17px;
}
@media (min--moz-device-pixel-ratio: 1.3), (-o-min-device-pixel-ratio: 2.6/2), (-webkit-min-device-pixel-ratio: 1.3), (min-device-pixel-ratio: 1.3), (min-resolution: 1.3dppx) {
  .rateit .rateit-selected {
    background-image: url("../images/icon-star-full@2x.png");
    background-size: 19px 34px;
  }
}
.rateit .rateit-preset {
  background: url("../images/icon-star-full.png") left -17px;
}
@media (min--moz-device-pixel-ratio: 1.3), (-o-min-device-pixel-ratio: 2.6/2), (-webkit-min-device-pixel-ratio: 1.3), (min-device-pixel-ratio: 1.3), (min-resolution: 1.3dppx) {
  .rateit .rateit-preset {
    background-image: url("../images/icon-star-full@2x.png");
    background-size: 19px 34px;
  }
}
.rateit button.rateit-reset {
  display: none !important;
}
.rateit button.rateit-reset:hover,
.rateit button.rateit-reset:focus {
  background-position: 0 -17px;
}

/****************************

 ____                    ____                   ___      
/\  _`\   __            /\  _`\                /\_ \     
\ \ \L\ \/\_\  _____    \ \ \/\_\  __  __  _ __\//\ \    
 \ \ ,  /\/\ \/\ '__`\   \ \ \/_/_/\ \/\ \/\`'__\\ \ \   
  \ \ \\ \\ \ \ \ \L\ \   \ \ \L\ \ \ \_\ \ \ \/  \_\ \_ 
   \ \_\ \_\ \_\ \ ,__/    \ \____/\ \____/\ \_\  /\____\
    \/_/\/ /\/_/\ \ \/      \/___/  \/___/  \/_/  \/____/
                 \ \_\                                   
                  \/_/

*****************************/
/****************************

 ____                    ____                   ___
/\  _`\   __            /\  _`\                /\_ \
\ \ \L\ \/\_\  _____    \ \ \/\_\  __  __  _ __\//\ \
 \ \ ,  /\/\ \/\ '__`\   \ \ \/_/_/\ \/\ \/\`'__\\ \ \
  \ \ \\ \\ \ \ \ \L\ \   \ \ \L\ \ \ \_\ \ \ \/  \_\ \_
   \ \_\ \_\ \_\ \ ,__/    \ \____/\ \____/\ \_\  /\____\
    \/_/\/ /\/_/\ \ \/      \/___/  \/___/  \/_/  \/____/
                 \ \_\
                  \/_/

*****************************/
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font: inherit;
  font-size: 100%;
  vertical-align: baseline;
}

html {
  line-height: 1;
}

ol, ul {
  list-style: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

caption, th, td {
  text-align: left;
  font-weight: normal;
  vertical-align: middle;
}

q, blockquote {
  quotes: none;
}
q:before, q:after, blockquote:before, blockquote:after {
  content: "";
  content: none;
}

a img {
  border: none;
}

article, aside, details, figcaption, figure, footer, header, hgroup, main, menu, nav, section, summary {
  display: block;
}

/*@function use-prefix($prefix, $capability, $threshold, $capability-options: $default-capability-options) {
  $usage: prefix-usage($prefix, $capability, $capability-options);
  @if $usage > $threshold {
    @return $usage;
  } @else {
    @each $browser in browsers($prefix) {
      @if index($supported-browsers, $browser) {
        $min-version: map-get($browser-minimum-versions, $browser);
        @if $min-version {
          $actual-prefix: browser-requires-prefix($browser, $min-version, $capability, $capability-options);
          @if $actual-prefix and $prefix == $actual-prefix {
            @return $browser $min-version;
          }
        }
      }
    }
  }
  @return false;
}*/
/*@function use-prefix($prefix, $capability, $threshold, $capability-options: $default-capability-options) {
  $usage: prefix-usage($prefix, $capability, $capability-options);
  @if $usage > $threshold {
    @return $usage;
  } @else {
    @each $browser in browsers($prefix) {
      @if index($supported-browsers, $browser) {
        $min-version: map-get($browser-minimum-versions, $browser);
        @if $min-version {
          $actual-prefix: browser-requires-prefix($browser, $min-version, $capability, $capability-options);
          @if $actual-prefix and $prefix == $actual-prefix {
            @return $browser $min-version;
          }
        }
      }
    }
  }
  @return false;
}*/
/*@function use-prefix($prefix, $capability, $threshold, $capability-options: $default-capability-options) {
  $usage: prefix-usage($prefix, $capability, $capability-options);
  @if $usage > $threshold {
    @return $usage;
  } @else {
    @each $browser in browsers($prefix) {
      @if index($supported-browsers, $browser) {
        $min-version: map-get($browser-minimum-versions, $browser);
        @if $min-version {
          $actual-prefix: browser-requires-prefix($browser, $min-version, $capability, $capability-options);
          @if $actual-prefix and $prefix == $actual-prefix {
            @return $browser $min-version;
          }
        }
      }
    }
  }
  @return false;
}*/
/*@function use-prefix($prefix, $capability, $threshold, $capability-options: $default-capability-options) {
  $usage: prefix-usage($prefix, $capability, $capability-options);
  @if $usage > $threshold {
    @return $usage;
  } @else {
    @each $browser in browsers($prefix) {
      @if index($supported-browsers, $browser) {
        $min-version: map-get($browser-minimum-versions, $browser);
        @if $min-version {
          $actual-prefix: browser-requires-prefix($browser, $min-version, $capability, $capability-options);
          @if $actual-prefix and $prefix == $actual-prefix {
            @return $browser $min-version;
          }
        }
      }
    }
  }
  @return false;
}*/
/*@function use-prefix($prefix, $capability, $threshold, $capability-options: $default-capability-options) {
  $usage: prefix-usage($prefix, $capability, $capability-options);
  @if $usage > $threshold {
    @return $usage;
  } @else {
    @each $browser in browsers($prefix) {
      @if index($supported-browsers, $browser) {
        $min-version: map-get($browser-minimum-versions, $browser);
        @if $min-version {
          $actual-prefix: browser-requires-prefix($browser, $min-version, $capability, $capability-options);
          @if $actual-prefix and $prefix == $actual-prefix {
            @return $browser $min-version;
          }
        }
      }
    }
  }
  @return false;
}*/
/*@function use-prefix($prefix, $capability, $threshold, $capability-options: $default-capability-options) {
  $usage: prefix-usage($prefix, $capability, $capability-options);
  @if $usage > $threshold {
    @return $usage;
  } @else {
    @each $browser in browsers($prefix) {
      @if index($supported-browsers, $browser) {
        $min-version: map-get($browser-minimum-versions, $browser);
        @if $min-version {
          $actual-prefix: browser-requires-prefix($browser, $min-version, $capability, $capability-options);
          @if $actual-prefix and $prefix == $actual-prefix {
            @return $browser $min-version;
          }
        }
      }
    }
  }
  @return false;
}*/
/*@function use-prefix($prefix, $capability, $threshold, $capability-options: $default-capability-options) {
  $usage: prefix-usage($prefix, $capability, $capability-options);
  @if $usage > $threshold {
    @return $usage;
  } @else {
    @each $browser in browsers($prefix) {
      @if index($supported-browsers, $browser) {
        $min-version: map-get($browser-minimum-versions, $browser);
        @if $min-version {
          $actual-prefix: browser-requires-prefix($browser, $min-version, $capability, $capability-options);
          @if $actual-prefix and $prefix == $actual-prefix {
            @return $browser $min-version;
          }
        }
      }
    }
  }
  @return false;
}*/
/*@function use-prefix($prefix, $capability, $threshold, $capability-options: $default-capability-options) {
  $usage: prefix-usage($prefix, $capability, $capability-options);
  @if $usage > $threshold {
    @return $usage;
  } @else {
    @each $browser in browsers($prefix) {
      @if index($supported-browsers, $browser) {
        $min-version: map-get($browser-minimum-versions, $browser);
        @if $min-version {
          $actual-prefix: browser-requires-prefix($browser, $min-version, $capability, $capability-options);
          @if $actual-prefix and $prefix == $actual-prefix {
            @return $browser $min-version;
          }
        }
      }
    }
  }
  @return false;
}*/
/*@function use-prefix($prefix, $capability, $threshold, $capability-options: $default-capability-options) {
  $usage: prefix-usage($prefix, $capability, $capability-options);
  @if $usage > $threshold {
    @return $usage;
  } @else {
    @each $browser in browsers($prefix) {
      @if index($supported-browsers, $browser) {
        $min-version: map-get($browser-minimum-versions, $browser);
        @if $min-version {
          $actual-prefix: browser-requires-prefix($browser, $min-version, $capability, $capability-options);
          @if $actual-prefix and $prefix == $actual-prefix {
            @return $browser $min-version;
          }
        }
      }
    }
  }
  @return false;
}*/
/*@function use-prefix($prefix, $capability, $threshold, $capability-options: $default-capability-options) {
  $usage: prefix-usage($prefix, $capability, $capability-options);
  @if $usage > $threshold {
    @return $usage;
  } @else {
    @each $browser in browsers($prefix) {
      @if index($supported-browsers, $browser) {
        $min-version: map-get($browser-minimum-versions, $browser);
        @if $min-version {
          $actual-prefix: browser-requires-prefix($browser, $min-version, $capability, $capability-options);
          @if $actual-prefix and $prefix == $actual-prefix {
            @return $browser $min-version;
          }
        }
      }
    }
  }
  @return false;
}*/
/*@function use-prefix($prefix, $capability, $threshold, $capability-options: $default-capability-options) {
  $usage: prefix-usage($prefix, $capability, $capability-options);
  @if $usage > $threshold {
    @return $usage;
  } @else {
    @each $browser in browsers($prefix) {
      @if index($supported-browsers, $browser) {
        $min-version: map-get($browser-minimum-versions, $browser);
        @if $min-version {
          $actual-prefix: browser-requires-prefix($browser, $min-version, $capability, $capability-options);
          @if $actual-prefix and $prefix == $actual-prefix {
            @return $browser $min-version;
          }
        }
      }
    }
  }
  @return false;
}*/
/*@function use-prefix($prefix, $capability, $threshold, $capability-options: $default-capability-options) {
  $usage: prefix-usage($prefix, $capability, $capability-options);
  @if $usage > $threshold {
    @return $usage;
  } @else {
    @each $browser in browsers($prefix) {
      @if index($supported-browsers, $browser) {
        $min-version: map-get($browser-minimum-versions, $browser);
        @if $min-version {
          $actual-prefix: browser-requires-prefix($browser, $min-version, $capability, $capability-options);
          @if $actual-prefix and $prefix == $actual-prefix {
            @return $browser $min-version;
          }
        }
      }
    }
  }
  @return false;
}*/
/*@function use-prefix($prefix, $capability, $threshold, $capability-options: $default-capability-options) {
  $usage: prefix-usage($prefix, $capability, $capability-options);
  @if $usage > $threshold {
    @return $usage;
  } @else {
    @each $browser in browsers($prefix) {
      @if index($supported-browsers, $browser) {
        $min-version: map-get($browser-minimum-versions, $browser);
        @if $min-version {
          $actual-prefix: browser-requires-prefix($browser, $min-version, $capability, $capability-options);
          @if $actual-prefix and $prefix == $actual-prefix {
            @return $browser $min-version;
          }
        }
      }
    }
  }
  @return false;
}*/
/*@function use-prefix($prefix, $capability, $threshold, $capability-options: $default-capability-options) {
  $usage: prefix-usage($prefix, $capability, $capability-options);
  @if $usage > $threshold {
    @return $usage;
  } @else {
    @each $browser in browsers($prefix) {
      @if index($supported-browsers, $browser) {
        $min-version: map-get($browser-minimum-versions, $browser);
        @if $min-version {
          $actual-prefix: browser-requires-prefix($browser, $min-version, $capability, $capability-options);
          @if $actual-prefix and $prefix == $actual-prefix {
            @return $browser $min-version;
          }
        }
      }
    }
  }
  @return false;
}*/
/*@function use-prefix($prefix, $capability, $threshold, $capability-options: $default-capability-options) {
  $usage: prefix-usage($prefix, $capability, $capability-options);
  @if $usage > $threshold {
    @return $usage;
  } @else {
    @each $browser in browsers($prefix) {
      @if index($supported-browsers, $browser) {
        $min-version: map-get($browser-minimum-versions, $browser);
        @if $min-version {
          $actual-prefix: browser-requires-prefix($browser, $min-version, $capability, $capability-options);
          @if $actual-prefix and $prefix == $actual-prefix {
            @return $browser $min-version;
          }
        }
      }
    }
  }
  @return false;
}*/
/*@function use-prefix($prefix, $capability, $threshold, $capability-options: $default-capability-options) {
  $usage: prefix-usage($prefix, $capability, $capability-options);
  @if $usage > $threshold {
    @return $usage;
  } @else {
    @each $browser in browsers($prefix) {
      @if index($supported-browsers, $browser) {
        $min-version: map-get($browser-minimum-versions, $browser);
        @if $min-version {
          $actual-prefix: browser-requires-prefix($browser, $min-version, $capability, $capability-options);
          @if $actual-prefix and $prefix == $actual-prefix {
            @return $browser $min-version;
          }
        }
      }
    }
  }
  @return false;
}*/
/*@function use-prefix($prefix, $capability, $threshold, $capability-options: $default-capability-options) {
  $usage: prefix-usage($prefix, $capability, $capability-options);
  @if $usage > $threshold {
    @return $usage;
  } @else {
    @each $browser in browsers($prefix) {
      @if index($supported-browsers, $browser) {
        $min-version: map-get($browser-minimum-versions, $browser);
        @if $min-version {
          $actual-prefix: browser-requires-prefix($browser, $min-version, $capability, $capability-options);
          @if $actual-prefix and $prefix == $actual-prefix {
            @return $browser $min-version;
          }
        }
      }
    }
  }
  @return false;
}*/
/*@function use-prefix($prefix, $capability, $threshold, $capability-options: $default-capability-options) {
  $usage: prefix-usage($prefix, $capability, $capability-options);
  @if $usage > $threshold {
    @return $usage;
  } @else {
    @each $browser in browsers($prefix) {
      @if index($supported-browsers, $browser) {
        $min-version: map-get($browser-minimum-versions, $browser);
        @if $min-version {
          $actual-prefix: browser-requires-prefix($browser, $min-version, $capability, $capability-options);
          @if $actual-prefix and $prefix == $actual-prefix {
            @return $browser $min-version;
          }
        }
      }
    }
  }
  @return false;
}*/
/*@function use-prefix($prefix, $capability, $threshold, $capability-options: $default-capability-options) {
  $usage: prefix-usage($prefix, $capability, $capability-options);
  @if $usage > $threshold {
    @return $usage;
  } @else {
    @each $browser in browsers($prefix) {
      @if index($supported-browsers, $browser) {
        $min-version: map-get($browser-minimum-versions, $browser);
        @if $min-version {
          $actual-prefix: browser-requires-prefix($browser, $min-version, $capability, $capability-options);
          @if $actual-prefix and $prefix == $actual-prefix {
            @return $browser $min-version;
          }
        }
      }
    }
  }
  @return false;
}*/
/*@function use-prefix($prefix, $capability, $threshold, $capability-options: $default-capability-options) {
  $usage: prefix-usage($prefix, $capability, $capability-options);
  @if $usage > $threshold {
    @return $usage;
  } @else {
    @each $browser in browsers($prefix) {
      @if index($supported-browsers, $browser) {
        $min-version: map-get($browser-minimum-versions, $browser);
        @if $min-version {
          $actual-prefix: browser-requires-prefix($browser, $min-version, $capability, $capability-options);
          @if $actual-prefix and $prefix == $actual-prefix {
            @return $browser $min-version;
          }
        }
      }
    }
  }
  @return false;
}*/
/*@function use-prefix($prefix, $capability, $threshold, $capability-options: $default-capability-options) {
  $usage: prefix-usage($prefix, $capability, $capability-options);
  @if $usage > $threshold {
    @return $usage;
  } @else {
    @each $browser in browsers($prefix) {
      @if index($supported-browsers, $browser) {
        $min-version: map-get($browser-minimum-versions, $browser);
        @if $min-version {
          $actual-prefix: browser-requires-prefix($browser, $min-version, $capability, $capability-options);
          @if $actual-prefix and $prefix == $actual-prefix {
            @return $browser $min-version;
          }
        }
      }
    }
  }
  @return false;
}*/
/*@function use-prefix($prefix, $capability, $threshold, $capability-options: $default-capability-options) {
  $usage: prefix-usage($prefix, $capability, $capability-options);
  @if $usage > $threshold {
    @return $usage;
  } @else {
    @each $browser in browsers($prefix) {
      @if index($supported-browsers, $browser) {
        $min-version: map-get($browser-minimum-versions, $browser);
        @if $min-version {
          $actual-prefix: browser-requires-prefix($browser, $min-version, $capability, $capability-options);
          @if $actual-prefix and $prefix == $actual-prefix {
            @return $browser $min-version;
          }
        }
      }
    }
  }
  @return false;
}*/
/*@function use-prefix($prefix, $capability, $threshold, $capability-options: $default-capability-options) {
  $usage: prefix-usage($prefix, $capability, $capability-options);
  @if $usage > $threshold {
    @return $usage;
  } @else {
    @each $browser in browsers($prefix) {
      @if index($supported-browsers, $browser) {
        $min-version: map-get($browser-minimum-versions, $browser);
        @if $min-version {
          $actual-prefix: browser-requires-prefix($browser, $min-version, $capability, $capability-options);
          @if $actual-prefix and $prefix == $actual-prefix {
            @return $browser $min-version;
          }
        }
      }
    }
  }
  @return false;
}*/
/*@function use-prefix($prefix, $capability, $threshold, $capability-options: $default-capability-options) {
  $usage: prefix-usage($prefix, $capability, $capability-options);
  @if $usage > $threshold {
    @return $usage;
  } @else {
    @each $browser in browsers($prefix) {
      @if index($supported-browsers, $browser) {
        $min-version: map-get($browser-minimum-versions, $browser);
        @if $min-version {
          $actual-prefix: browser-requires-prefix($browser, $min-version, $capability, $capability-options);
          @if $actual-prefix and $prefix == $actual-prefix {
            @return $browser $min-version;
          }
        }
      }
    }
  }
  @return false;
}*/
@font-face {
  font-family: "Stag Sans Light";
  src: url("../fonts/Stag Sans-Light.ttf");
  src: local("☺"), url("../fonts/Stag Sans-Light.woff") format("woff"), url("../fonts/Stag Sans-Light.ttf") format("truetype"), url("../fonts/Stag Sans-Light.svg") format("svg");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Stag Sans Thin";
  src: url("../fonts/Stag Sans-Thin.ttf");
  src: local("☺"), url("../fonts/Stag Sans-Thin.woff") format("woff"), url("../fonts/Stag Sans-Thin.ttf") format("truetype"), url("../fonts/Stag Sans-Thin.svg") format("svg");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Stag Sans Medium";
  src: url("../fonts/Stag-Sans-Medium.ttf");
  src: local("☺"), url("../fonts/Stag-Sans-Medium.woff") format("woff"), url("../fonts/Stag-Sans-Medium.ttf") format("truetype"), url("../fonts/Stag-Sans-Medium.svg") format("svg");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "HelveticaNeueBlackCon";
  src: url("../fonts/HelveticaNeueLTPro-BlkCn.ttf");
  src: local("☺"), url("../fonts/HelveticaNeueLTPro-BlkCn.woff") format("woff"), url("../fonts/HelveticaNeueLTPro-BlkCn.ttf") format("truetype"), url("../fonts/HelveticaNeueLTPro-BlkCn.svg") format("svg");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "HelveticaNeueBoldCon";
  src: url("../fonts/HelveticaNeueLTPro-BdCn.ttf");
  src: local("☺"), url("../fonts/HelveticaNeueLTPro-BdCn.woff") format("woff"), url("../fonts/HelveticaNeueLTPro-BdCn.ttf") format("truetype"), url("../fonts/HelveticaNeueLTPro-BdCn.svg") format("svg");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Stag Sans Light";
  src: url("../fonts/Stag Sans-Light.ttf?#iefix"), format("embedded-opentype"), url("../fonts/Stag Sans-Light.woff") format("woff"), url("../fonts/Stag Sans-Light.ttf") format("truetype"), url("../fonts/Stag Sans-Light.svg") format("svg");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Stag Sans Thin";
  src: url("../fonts/Stag Sans-Thin.ttf?#iefix") format("embedded-opentype"), url("../fonts/Stag Sans-Thin.woff") format("woff"), url("../fonts/Stag Sans-Thin.ttf") format("truetype"), url("../fonts/Stag Sans-Thin.svg") format("svg");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Stag Sans Medium";
  src: url("../fonts/Stag-Sans-Medium.ttf?#iefix"), format("embedded-opentype"), url("../fonts/Stag-Sans-Medium.woff") format("woff"), url("../fonts/Stag-Sans-Medium.ttf") format("truetype"), url("../fonts/Stag-Sans-Medium.svg") format("svg");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "HelveticaNeueBlackCon";
  src: url("../fonts/HelveticaNeueLTPro-BlkCn.ttf?#iefix"), format("embedded-opentype"), url("../fonts/HelveticaNeueLTPro-BlkCn.woff") format("woff"), url("../fonts/HelveticaNeueLTPro-BlkCn.ttf") format("truetype"), url("../fonts/HelveticaNeueLTPro-BlkCn.svg") format("svg");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "HelveticaNeueBoldCon";
  src: url("../fonts/HelveticaNeueLTPro-BdCn.ttf?#iefix"), format("embedded-opentype"), url("../fonts/HelveticaNeueLTPro-BdCn.woff") format("woff"), url("../fonts/HelveticaNeueLTPro-BdCn.ttf") format("truetype"), url("../fonts/HelveticaNeueLTPro-BdCn.svg") format("svg");
  font-weight: normal;
  font-style: normal;
}
.console-buttons {
  position: absolute;
  top: 20px;
  right: 20px;
  z-index: 3;
}
.console-buttons a {
  background: #DF1B00;
  display: block;
  color: #FFF;
  padding: 8px;
  border-radius: 3px;
  text-decoration: none;
  margin-bottom: 4px;
  font-size: 1.0769230769em;
  border: 1px solid rgba(255, 255, 255, 0.3);
}
.console-buttons a:hover {
  background: #C81800;
}

.log-messages {
  background: #000;
  padding: 20px;
  color: #32d600;
  z-index: 3;
  width: auto;
  display: none;
}
.log-messages h2 {
  font-weight: bold;
  font-size: 16px;
  margin-bottom: 10px;
}
.log-messages li {
  color: green;
  padding-bottom: 5px;
  border-bottom: 1px dashed #CCC;
  margin-bottom: 5px;
  line-height: 18px;
  word-wrap: break-word;
}

html {
  background: #232528 url("../images/bg-global-nav.png") repeat-y;
}
html#page-welcome {
  background: none;
}

html, body {
  height: 100%;
}

body {
  overflow: hidden;
  font-family: Arial;
  font-size: 13px;
}
body.page-profile, body.page-watch, body.page-settings {
  overflow-y: auto;
}

textarea {
  resize: none;
}

select {
  background: #FFF url("../images/icon-dropdown.png") top 50% right 10px no-repeat;
  border: none;
  width: 100%;
  height: 50px;
  margin-bottom: 10px;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
  text-indent: 12px;
  color: #B8B8B8;
  border-radius: 3px;
}
@media (min--moz-device-pixel-ratio: 1.3), (-o-min-device-pixel-ratio: 2.6/2), (-webkit-min-device-pixel-ratio: 1.3), (min-device-pixel-ratio: 1.3), (min-resolution: 1.3dppx) {
  select {
    background-image: url("../images/icon-dropdown@2x.png");
    background-size: 11px 8px;
  }
}

#modal-overlay,
#lightbox-overlay,
#profile-overlay,
#loading-overlay,
#antavo-overlay {
  background: rgba(0, 0, 0, 0.5);
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 3;
  display: none;
}
#modal-overlay#loading-overlay,
#lightbox-overlay#loading-overlay,
#profile-overlay#loading-overlay,
#loading-overlay#loading-overlay,
#antavo-overlay#loading-overlay {
  z-index: 10;
}
#modal-overlay #loading-hud,
#lightbox-overlay #loading-hud,
#profile-overlay #loading-hud,
#loading-overlay #loading-hud,
#antavo-overlay #loading-hud {
  background: #000;
  width: 100px;
  height: 35px;
  border-radius: 10px;
  color: #FFF;
  position: absolute;
  top: 40%;
  left: 50%;
  margin: -50px 0 0 -60px;
  text-align: center;
  padding-top: 95px;
  font-size: 0.9230769231em;
  font-family: "Stag Sans Light", Arial;
}
#modal-overlay #loading-hud .spinner,
#lightbox-overlay #loading-hud .spinner,
#profile-overlay #loading-hud .spinner,
#loading-overlay #loading-hud .spinner,
#antavo-overlay #loading-hud .spinner {
  margin-bottom: 10px;
  left: 50px !important;
  top: -40px !important;
}

#ui-datepicker-div {
  margin: 5px 0 0 -20px;
}

.form-type-checkbox .skinned-checkbox-wrapper {
  background: url("../images/bg-checkbox.png") no-repeat;
  width: 20px;
  height: 20px;
  cursor: pointer;
  float: left;
  margin-right: 9px;
}
@media (min--moz-device-pixel-ratio: 1.3), (-o-min-device-pixel-ratio: 2.6/2), (-webkit-min-device-pixel-ratio: 1.3), (min-device-pixel-ratio: 1.3), (min-resolution: 1.3dppx) {
  .form-type-checkbox .skinned-checkbox-wrapper {
    background-image: url("../images/bg-checkbox@2x.png");
    background-size: 20px 40px;
  }
}
.form-type-checkbox input[type=radio]:checked + .skinned-checkbox-wrapper {
  background-position: 0 -20px;
}
.form-type-checkbox label {
  cursor: pointer;
}
.form-type-checkbox label + a {
  padding-top: 2px;
  display: inline-block;
}

.btn {
  -webkit-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  background: #1D1F21;
  display: block;
  width: 132px;
  height: 38px;
  line-height: 38px;
  border: 1px solid #555759;
  border-radius: 3px;
  text-align: center;
  color: #FFF;
  font-family: "Stag Sans Light", Arial;
  font-size: 1.0769230769em;
  text-decoration: none;
  outline: none;
  box-sizing: border-box;
  cursor: pointer;
}
.btn:hover {
  background: #212326;
}

input.btn {
  line-height: 34px;
}

.lightbox {
  background: #292C2F;
  position: absolute;
  border-radius: 3px;
  border-top: 6px solid #DF1B00;
  left: 50%;
  top: 25%;
  z-index: 4;
  display: block;
}
.lightbox.hidden {
  display: none;
}
.lightbox.visible {
  display: block;
}
.lightbox.confirm {
  width: 280px;
  margin-left: -140px;
}
.lightbox.confirm p {
  width: 170px;
  margin: 0 auto;
  font-family: "Stag Sans Light", Arial;
  text-align: center;
  color: #FFF;
  font-size: 1.2307692308em;
  margin-bottom: 54px;
  line-height: 18px;
}
.lightbox.confirm .buttons {
  width: 240px;
  margin: 0 auto;
  padding-bottom: 23px;
  zoom: 1;
}
.lightbox.confirm .buttons:before, .lightbox.confirm .buttons:after {
  content: " ";
  display: block;
  height: 0;
  overflow: hidden;
}
.lightbox.confirm .buttons:after {
  clear: both;
}
.lightbox.confirm .buttons a {
  width: 115px;
}
.lightbox.confirm .buttons a.no {
  float: left;
}
.lightbox.confirm .buttons a.yes {
  float: right;
}
.lightbox .close {
  background: url("../images/icon-close-x.png") 50% 50% no-repeat;
  display: block;
  width: 13px;
  height: 13px;
  text-indent: -9999px;
  position: absolute;
  top: 9px;
  right: 9px;
  padding: 6px;
  border-radius: 3px;
}
@media (min--moz-device-pixel-ratio: 1.3), (-o-min-device-pixel-ratio: 2.6/2), (-webkit-min-device-pixel-ratio: 1.3), (min-device-pixel-ratio: 1.3), (min-resolution: 1.3dppx) {
  .lightbox .close {
    background-image: url("../images/icon-close-x@2x.png");
    background-size: 13px 13px;
  }
}
.lightbox .close:hover {
  background-color: rgba(255, 255, 255, 0.1);
}
.lightbox h2 {
  color: #FFF;
  font-family: "Stag Sans Thin", Arial;
  font-size: 2.6153846154em;
  padding: 64px 0 29px 0;
  text-align: center;
}

.alert {
  background: #383A3D;
  border-radius: 3px;
  border-top: 6px solid #DF1B00;
  width: 300px;
  position: fixed;
  left: 50%;
  top: 25%;
  margin-left: -150px;
  z-index: 3;
  display: none;
}
.alert .inner {
  position: relative;
}
.alert .inner .close {
  background: url("../images/icon-close-x.png") 50% 50% no-repeat;
  display: block;
  width: 13px;
  height: 13px;
  text-indent: -9999px;
  position: absolute;
  top: 9px;
  right: 9px;
  padding: 6px;
  border-radius: 3px;
}
@media (min--moz-device-pixel-ratio: 1.3), (-o-min-device-pixel-ratio: 2.6/2), (-webkit-min-device-pixel-ratio: 1.3), (min-device-pixel-ratio: 1.3), (min-resolution: 1.3dppx) {
  .alert .inner .close {
    background-image: url("../images/icon-close-x@2x.png");
    background-size: 13px 13px;
  }
}
.alert .inner .close:hover {
  background-color: rgba(255, 255, 255, 0.1);
}
.alert .inner h2 {
  color: #FFF;
  font-family: "Stag Sans Thin", Arial;
  font-size: 2.6153846154em;
  padding: 45px 0 34px 0;
  text-align: center;
}
.alert .inner .text {
  padding: 40px 20px 20px 20px;
  text-align: center;
}
.alert .inner .text p {
  color: #FFF;
  line-height: 20px;
  margin-bottom: 20px;
  font-size: 16px;
  font-family: "Stag Sans Light", Arial;
}
.alert.confirm .buttons {
  width: 300px;
  margin-left: -20px;
  height: 48px;
}
.alert.confirm .buttons a {
  background: #DF1B00;
  width: 100%;
  height: 48px;
  color: #FFF;
  text-align: center;
  text-transform: uppercase;
  font-size: 1.2307692308em;
  font-family: "Stag Sans Light", Arial;
  border-radius: 3px;
  border: none;
  border-bottom: 2px solid #A71400;
  cursor: pointer;
  line-height: 48px;
  outline: none;
  display: block;
  width: 100px;
  float: left;
  margin-right: 20px;
  text-decoration: none;
  line-height: 50px;
}
.alert.confirm .buttons a:hover {
  background: #C81800;
}
.alert.confirm .buttons a.no {
  margin-left: 40px;
}

.login-box {
  background: #383A3D;
  border-radius: 3px;
  border-top: 6px solid #DF1B00;
  width: 300px;
  position: fixed;
  left: 50%;
  top: 15%;
  margin-left: -160px;
  z-index: 3;
  display: none;
}
.login-box .sign-up-here {
  text-align: center;
  margin-bottom: 20px;
  color: #FFF;
  font-family: "Stag Sans Light", Arial;
  font-size: 1.0769230769em;
}
.login-box .sign-up-here a {
  color: #DF1B00;
  text-decoration: none;
}
.login-box.show-reset-password #ripcurl-login .close {
  display: none;
}
.login-box.show-reset-password .panel-reset-password {
  display: block;
}
.login-box.show-login .panel-login {
  display: block;
}
.login-box .panel-forgot-password .form-text {
  border-radius: 3px;
}
.login-box .panel {
  display: none;
}
.login-box .inner {
  position: relative;
  padding: 0 20px 1px;
}
.login-box .inner .separator {
  position: relative;
  border-bottom: 1px solid #45474A;
  width: 300px;
  height: 11px;
  margin: 0 0 27px -20px;
}
.login-box .inner .separator span {
  background: #383A3D;
  padding: 0 20px;
  position: absolute;
  top: 4px;
  left: 120px;
  font-family: "Stag Sans Light", Arial;
  color: #FFF;
  text-transform: uppercase;
  font-size: 1.2307692308em;
}
.login-box .inner .close {
  background: url("../images/icon-close-x.png") 50% 50% no-repeat;
  display: block;
  width: 13px;
  height: 13px;
  text-indent: -9999px;
  position: absolute;
  top: 9px;
  right: 9px;
  padding: 6px;
  border-radius: 3px;
}
@media (min--moz-device-pixel-ratio: 1.3), (-o-min-device-pixel-ratio: 2.6/2), (-webkit-min-device-pixel-ratio: 1.3), (min-device-pixel-ratio: 1.3), (min-resolution: 1.3dppx) {
  .login-box .inner .close {
    background-image: url("../images/icon-close-x@2x.png");
    background-size: 13px 13px;
  }
}
.login-box .inner .close:hover {
  background-color: rgba(255, 255, 255, 0.1);
}
.login-box .inner h2 {
  color: #FFF;
  font-family: "Stag Sans Thin", Arial;
  font-size: 2.6153846154em;
  padding: 45px 0 34px 0;
  text-align: center;
}
.login-box .inner .facebook-login {
  background: url("../images/btn-bg-facebook-login-small.png") no-repeat;
  display: block;
  color: #FFF;
  width: 260px;
  height: 50px;
  line-height: 50px;
  text-transform: uppercase;
  font-family: "Stag Sans Light", Arial;
  text-decoration: none;
  text-indent: 60px;
  margin-bottom: 24px;
  font-size: 1.2307692308em;
}
@media (min--moz-device-pixel-ratio: 1.3), (-o-min-device-pixel-ratio: 2.6/2), (-webkit-min-device-pixel-ratio: 1.3), (min-device-pixel-ratio: 1.3), (min-resolution: 1.3dppx) {
  .login-box .inner .facebook-login {
    background-image: url("../images/btn-bg-facebook-login-small@2x.png");
    background-size: 260px 100px;
  }
}
.login-box .inner .facebook-login:hover {
  background-position: 0px -50px;
}
.login-box .inner label.error {
  display: none !important;
}
.login-box .inner .form-items input:first-child {
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
}
.login-box .inner .form-items input:last-child {
  border-bottom: none;
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
}
.login-box .inner .message-text {
  color: #FFF;
  text-align: center;
  font-family: "Stag Sans Light", Arial;
  font-size: 1.0769230769em;
  margin-bottom: 9px;
  display: none;
}
.login-box .inner .message-text.error {
  color: #DF1B00;
}
.login-box .inner input:-webkit-autofill {
  background: #FFF !important;
}
.login-box .inner .form-text {
  background: #FFF;
  width: 230px;
  height: 51px;
  outline: none;
  padding: 0 15px;
  border: none;
  font-size: 1.2307692308em;
  font-family: "Stag Sans Light", Arial;
  border-bottom: 1px solid #BABABA;
}
.login-box .inner .form-text.error {
  background: #F9D1CC;
  border-bottom-color: #F4ADA3;
}
.login-box .inner .remember-me {
  zoom: 1;
  margin: 18px 0 0 48px;
  font-size: 1.2307692308em;
  font-family: "Stag Sans Light", Arial;
  color: #FFF;
  text-align: center;
}
.login-box .inner .remember-me:before, .login-box .inner .remember-me:after {
  content: " ";
  display: block;
  height: 0;
  overflow: hidden;
}
.login-box .inner .remember-me:after {
  clear: both;
}
.login-box .inner .form-submit {
  background: #DF1B00;
  width: 100%;
  height: 48px;
  color: #FFF;
  text-align: center;
  text-transform: uppercase;
  font-size: 1.2307692308em;
  font-family: "Stag Sans Light", Arial;
  border-radius: 3px;
  border: none;
  border-bottom: 2px solid #A71400;
  cursor: pointer;
  line-height: 48px;
  outline: none;
  width: 260px;
  margin: 18px 0 21px 0;
}
.login-box .inner .form-submit:hover {
  background: #C81800;
}
.login-box .inner .bottom {
  background: #26282B;
  width: 298px;
  height: 48px;
  border-radius: 0 0 3px 3px;
  font-size: 1.2307692308em;
  font-family: "Stag Sans Light", Arial;
  text-align: center;
  line-height: 48px;
  margin-left: -19px;
  color: #FFF;
}
.login-box .inner .bottom a {
  color: #DF1B00;
  text-decoration: none;
}
.login-box .inner .bottom a:hover {
  text-decoration: underline;
}
.login-box .inner .bottom.terms {
  font-size: 0.9230769231em;
}

.download-box {
  padding-bottom: 28px;
  text-align: center;
  color: #ffffff;
}
.download-box a {
  color: #3d86C8;
}
.download-box a:hover {
  color: #6093C2;
}
.download-box .buttons {
  margin-bottom: 14px;
}
.download-box .buttons a {
  color: #ffffff;
  border-bottom: 2px solid #486e91;
  border-radius: 3px;
  display: block;
  font: 1.0769230769em "Stag Sans Light", Arial;
  text-decoration: none;
  margin: 10px 0 0 0px;
  padding: 19px 9px 14px 56px;
  width: 190px;
}
.download-box .buttons a.mac-download {
  background: #3d86C8 url("../images/icon-mac-logo.png") no-repeat 19px 8px;
}
@media (min--moz-device-pixel-ratio: 1.3), (-o-min-device-pixel-ratio: 2.6/2), (-webkit-min-device-pixel-ratio: 1.3), (min-device-pixel-ratio: 1.3), (min-resolution: 1.3dppx) {
  .download-box .buttons a.mac-download {
    background-image: url("../images/icon-mac-logo@2x.png");
    background-size: 26px 32px;
  }
}
.download-box .buttons a.windows-download {
  background: #3d86C8 url("../images/icon-windows-logo.png") no-repeat 17px 8px;
}
@media (min--moz-device-pixel-ratio: 1.3), (-o-min-device-pixel-ratio: 2.6/2), (-webkit-min-device-pixel-ratio: 1.3), (min-device-pixel-ratio: 1.3), (min-resolution: 1.3dppx) {
  .download-box .buttons a.windows-download {
    background-image: url("../images/icon-windows-logo@2x.png");
    background-size: 32px 32px;
  }
}
.download-box .buttons a:hover {
  background-color: #6093C2;
}

.person {
  -webkit-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  float: left;
  width: 220px;
  height: 173px;
  padding: 16px 0 0 0;
  border-radius: 5px;
  margin-right: 17px;
  border: 1px solid transparent;
}
.person:hover {
  background: #2E3033;
  border: 1px solid #212325;
  border-bottom-color: #1C1E20;
}
.person.pending {
  -webkit-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
.person.pending .picture .pending {
  display: block;
}
.person.is-self {
  text-align: center;
}
.person.is-self .bottom .profile-info {
  float: none;
}
.person .picture {
  position: relative;
  width: 108px;
  height: 108px;
  margin: 0 auto 12px auto;
}
.person .picture .pending {
  -webkit-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  position: absolute;
  top: 4px;
  left: 4px;
  display: none;
  width: 100px;
  height: 100px;
  font-family: "Stag Sans Light", Arial;
  text-align: center;
  color: #FFF;
  line-height: 105px;
  font-size: 1.2307692308em;
  background: rgba(0, 0, 0, 0.5);
  border-radius: 100px;
  text-transform: uppercase;
}
.person .picture .pending a {
  display: block;
  width: 100px;
  height: 100px;
  font-family: "Stag Sans Light", Arial;
  text-align: center;
  color: #FFF;
  line-height: 105px;
  font-size: 1.2307692308em;
  text-decoration: none;
}
.person .picture img {
  width: 100px;
  height: 100px;
  border: 4px solid #393B3E;
  border-radius: 100px;
}
.person .picture .pro {
  position: absolute;
  top: 81px;
  left: 73px;
  background: url("../images/icon-probadge.png") no-repeat;
  width: 25px;
  height: 19px;
}
@media (min--moz-device-pixel-ratio: 1.3), (-o-min-device-pixel-ratio: 2.6/2), (-webkit-min-device-pixel-ratio: 1.3), (min-device-pixel-ratio: 1.3), (min-resolution: 1.3dppx) {
  .person .picture .pro {
    background-image: url("../images/icon-probadge@2x.png");
    background-size: 25px 19px;
  }
}
.person .bottom {
  zoom: 1;
}
.person .bottom:before, .person .bottom:after {
  content: " ";
  display: block;
  height: 0;
  overflow: hidden;
}
.person .bottom:after {
  clear: both;
}
.person .bottom .follow-user-toggle {
  float: left;
  width: 34px;
  margin: 0 10px 0 20px;
}
.person .bottom .follow-toggle.following {
  background: url("../images/icon-already-following-user.png") no-repeat;
  width: 22px;
  height: 22px;
  margin-left: 8px;
  margin-top: -2px;
}
@media (min--moz-device-pixel-ratio: 1.3), (-o-min-device-pixel-ratio: 2.6/2), (-webkit-min-device-pixel-ratio: 1.3), (min-device-pixel-ratio: 1.3), (min-resolution: 1.3dppx) {
  .person .bottom .follow-toggle.following {
    background-image: url("../images/icon-already-following-user@2x.png");
    background-size: 22px 22px;
  }
}
.person .bottom .follow-toggle.pending {
  background: url("../images/icon-user-pending.png") no-repeat;
  width: 34px;
  height: 34px;
}
@media (min--moz-device-pixel-ratio: 1.3), (-o-min-device-pixel-ratio: 2.6/2), (-webkit-min-device-pixel-ratio: 1.3), (min-device-pixel-ratio: 1.3), (min-resolution: 1.3dppx) {
  .person .bottom .follow-toggle.pending {
    background-image: url("../images/icon-user-pending@2x.png");
    background-size: 34px 34px;
  }
}
.person .bottom .follow-toggle.not-following {
  background: url("../images/icon-follow-user.png") no-repeat;
  width: 34px;
  height: 34px;
}
@media (min--moz-device-pixel-ratio: 1.3), (-o-min-device-pixel-ratio: 2.6/2), (-webkit-min-device-pixel-ratio: 1.3), (min-device-pixel-ratio: 1.3), (min-resolution: 1.3dppx) {
  .person .bottom .follow-toggle.not-following {
    background-image: url("../images/icon-follow-user@2x.png");
    background-size: 34px 34px;
  }
}
.person .bottom .follow-toggle a {
  display: block;
  width: 34px;
  height: 34px;
  text-indent: -9999px;
}
.person .bottom .profile-info {
  float: left;
}
.person .bottom .profile-info h2 {
  font-family: "Stag Sans Light", Arial;
  font-size: 1.3846153846em;
  margin-bottom: 2px;
  color: #FFF;
}
.person .bottom .profile-info h2 a {
  color: #FFF;
  text-decoration: none;
}
.person .bottom .profile-info .location {
  background: url("../images/icon-location-pin-small.png") no-repeat;
  color: #BCBDBD;
  display: inline-block;
  height: 12px;
  padding: 2px 0 0 14px;
  font-family: "Stag Sans Light", Arial;
  font-size: 0.9230769231em;
}
@media (min--moz-device-pixel-ratio: 1.3), (-o-min-device-pixel-ratio: 2.6/2), (-webkit-min-device-pixel-ratio: 1.3), (min-device-pixel-ratio: 1.3), (min-resolution: 1.3dppx) {
  .person .bottom .profile-info .location {
    background-image: url("../images/icon-location-pin-small@2x.png");
    background-size: 10px 14px;
  }
}

.find-surfers {
  display: none;
}
.find-surfers .top {
  border-bottom: none;
  margin-bottom: 33px;
  padding-bottom: 0;
}
.find-surfers .tab-section {
  display: none;
}
.find-surfers .tab-section.active {
  display: block;
}
.find-surfers .no-friends p {
  color: #BEBEBE;
  font-size: 1.2307692308em;
  margin-bottom: 15px;
  font-family: "Stag Sans Light", Arial;
}
.find-surfers .no-friends .btn {
  width: auto;
  display: inline-block;
  padding: 0 15px;
}
.find-surfers #invite-friends-input {
  height: 40px;
}
.find-surfers #invite-friends-input ul {
  background-color: #FFFFFF;
  width: 420px;
  border-radius: 3px;
  padding: 2px 2px;
  margin-left: 3px;
  box-sizing: border-box;
  float: left;
  min-height: 40px;
}
.find-surfers #invite-friends-input ul li {
  display: inline-block;
}
.find-surfers #invite-friends-input ul li.tag {
  background-color: #6297c8;
  border-radius: 3px;
  padding: 9px 12px 9px 13px;
  position: relative;
  margin-bottom: 3px;
  margin-right: 3px;
}
.find-surfers #invite-friends-input ul li.tag .text {
  display: block;
  color: #FFFFFF;
  font: 14px Arial;
  padding-right: 22px;
}
.find-surfers #invite-friends-input ul li.tag .delete {
  position: absolute;
  right: 12px;
  top: 8px;
  background: url("../images/icon-tag-close.png") no-repeat;
  height: 18px;
  width: 18px;
}
@media (min--moz-device-pixel-ratio: 1.3), (-o-min-device-pixel-ratio: 2.6/2), (-webkit-min-device-pixel-ratio: 1.3), (min-device-pixel-ratio: 1.3), (min-resolution: 1.3dppx) {
  .find-surfers #invite-friends-input ul li.tag .delete {
    background-image: url("../images/icon-tag-close@2x.png");
    background-size: 18px 18px;
  }
}
.find-surfers #invite-friends-input ul li.tag .delete a {
  display: block;
  height: 100%;
  width: 100%;
}
.find-surfers #invite-friends-input ul li.new-item {
  height: 100%;
  width: 230px;
}
.find-surfers #invite-friends-input ul li.new-item input {
  border: 0;
  margin: 0;
  color: #b8b8b8;
  font: 14px Arial;
  outline: none;
  width: 100%;
  padding: 10px;
  box-sizing: border-box;
}
.find-surfers #invite-friends-input .send {
  border: 1px solid #4F5153;
  background-color: #1F2124;
  color: #ffffff;
  font: 1.0769230769em "Stag Sans Light", Arial;
  margin: 0 0 0 10px;
  padding: 13px 51px 10px 51px;
  border-radius: 3px;
  cursor: pointer;
  outline: none;
}
.find-surfers .results {
  display: none;
  margin-top: 20px;
  clear: both;
  padding-top: 20px;
  zoom: 1;
}
.find-surfers .results:before, .find-surfers .results:after {
  content: " ";
  display: block;
  height: 0;
  overflow: hidden;
}
.find-surfers .results:after {
  clear: both;
}
.find-surfers .results p {
  color: #BCBDBD;
  font-size: 1.2307692308em;
  font-family: "Stag Sans Light", Arial;
  margin-bottom: 20px;
}
.find-surfers .results .email {
  background: #35373B url("../images/icon-email-invite-success.png") 12px 6px no-repeat;
  border-radius: 3px;
  height: 34px;
  line-height: 34px;
  color: #FFF;
  padding: 0 15px 0 43px;
  margin-bottom: 3px;
  display: table;
}
@media (min--moz-device-pixel-ratio: 1.3), (-o-min-device-pixel-ratio: 2.6/2), (-webkit-min-device-pixel-ratio: 1.3), (min-device-pixel-ratio: 1.3), (min-resolution: 1.3dppx) {
  .find-surfers .results .email {
    background-image: url("../images/icon-email-invite-success@2x.png");
    background-size: 22px 22px;
  }
}
.find-surfers #find-surfers-input .text {
  color: #333;
  border-radius: 3px;
  border: none;
  width: 267px;
  height: 36px;
  padding-left: 13px;
  outline: none;
  cursor: text;
  float: left;
}
.find-surfers #find-surfers-input .text.invalid {
  color: #ff0000;
}
.find-surfers #find-surfers-input .search {
  display: inline-block;
  margin: 0 0 0 10px;
}

.follow-tabs {
  background: none;
  border-bottom: 1px solid #DF1B00;
  height: 26px;
  margin-bottom: 40px;
}
.follow-tabs ul {
  padding-left: 0;
}
.follow-tabs ul li {
  font-size: 1.2307692308em;
  margin-right: 28px;
  float: left;
}
.follow-tabs ul li a {
  font-family: "Stag Sans Light", Arial;
  color: #BCBDBD;
  display: block;
  height: 26px;
  line-height: normal;
  border: none;
  text-decoration: none;
}
.follow-tabs ul li.active a {
  background: url("../images/icon-red-tab-triangle.png") bottom center no-repeat;
  color: #FFF;
}
@media (min--moz-device-pixel-ratio: 1.3), (-o-min-device-pixel-ratio: 2.6/2), (-webkit-min-device-pixel-ratio: 1.3), (min-device-pixel-ratio: 1.3), (min-resolution: 1.3dppx) {
  .follow-tabs ul li.active a {
    background-image: url("../images/icon-red-tab-triangle@2x.png");
    background-size: 14px 7px;
  }
}

.zoom-controls {
  background: #232528 url("../images/bg-map-zoom-divider.png") 50% 50% no-repeat;
  border-radius: 3px;
  width: 29px;
  height: 57px;
}
.zoom-controls a {
  display: block;
  text-indent: -9999px;
  width: 29px;
  height: 28px;
}
.zoom-controls a.in {
  background: url("../images/icon-map-zoom-plus.png") 50% 50% no-repeat;
  margin-bottom: 1px;
}
@media (min--moz-device-pixel-ratio: 1.3), (-o-min-device-pixel-ratio: 2.6/2), (-webkit-min-device-pixel-ratio: 1.3), (min-device-pixel-ratio: 1.3), (min-resolution: 1.3dppx) {
  .zoom-controls a.in {
    background-image: url("../images/icon-map-zoom-plus@2x.png");
    background-size: 11px 11px;
  }
}
.zoom-controls a.out {
  background: url("../images/icon-map-zoom-minus.png") 50% 50% no-repeat;
  height: 28px;
}
@media (min--moz-device-pixel-ratio: 1.3), (-o-min-device-pixel-ratio: 2.6/2), (-webkit-min-device-pixel-ratio: 1.3), (min-device-pixel-ratio: 1.3), (min-resolution: 1.3dppx) {
  .zoom-controls a.out {
    background-image: url("../images/icon-map-zoom-minus@2x.png");
    background-size: 11px 11px;
  }
}

.offscreen {
  position: absolute;
  visibility: hidden;
  width: 320px;
  height: 320px;
}

#unsupported-box #ripcurl-unsupported p.white-text {
  color: #bebebe;
  font: 16px "Stag Sans Light", Arial;
  text-align: center;
}
#unsupported-box #ripcurl-unsupported p.white-text.spacer {
  padding-bottom: 20px;
}
#unsupported-box #ripcurl-unsupported .browser-details {
  color: #FFFFFF;
}

.ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  -o-text-overflow: ellipsis;
}

#login-buttons .fb-login-btn {
  background: url("../images/btn-bg-facebook-login.png") no-repeat;
  display: block;
  width: 217px;
  height: 36px;
  padding: 24px 0 0 94px;
  color: #FFF;
  text-transform: uppercase;
  font-family: "Stag Sans Light", Arial;
  text-decoration: none;
  font-size: 1.2307692308em;
  margin-bottom: 10px;
}
@media (min--moz-device-pixel-ratio: 1.3), (-o-min-device-pixel-ratio: 2.6/2), (-webkit-min-device-pixel-ratio: 1.3), (min-device-pixel-ratio: 1.3), (min-resolution: 1.3dppx) {
  #login-buttons .fb-login-btn {
    background-image: url("../images/btn-bg-facebook-login@2x.png");
    background-size: 311px 60px;
  }
}
#login-buttons .ripcurl-signup-btn {
  background: rgba(0, 0, 0, 0.3);
  display: block;
  width: 310px;
  height: 50px;
  color: #FFF;
  font-size: 1.0769230769em;
  text-transform: uppercase;
  font-family: "Stag Sans Light", Arial;
  text-decoration: none;
  border-radius: 3px;
  text-align: center;
  line-height: 54px;
  margin-bottom: 10px;
}

#global-messages {
  position: absolute;
  width: 100%;
  z-index: 99;
  left: 80px;
}
#global-messages .message {
  display: none;
  height: 80px;
  color: #FFF;
  line-height: 82px;
  font-family: "Stag Sans Light", Arial;
  font-size: 1.3846153846em;
  text-indent: 62px;
}
#global-messages .message.error {
  background: #AC1500 url("../images/icon-message-alert.png") 19px 24px no-repeat;
}
@media (min--moz-device-pixel-ratio: 1.3), (-o-min-device-pixel-ratio: 2.6/2), (-webkit-min-device-pixel-ratio: 1.3), (min-device-pixel-ratio: 1.3), (min-resolution: 1.3dppx) {
  #global-messages .message.error {
    background-image: url("../images/icon-message-alert@2x.png");
    background-size: 32px 32px;
  }
}
#global-messages .message.success {
  background: #3D86C8 url("../images/icon-message-tick.png") 19px 24px no-repeat;
}
@media (min--moz-device-pixel-ratio: 1.3), (-o-min-device-pixel-ratio: 2.6/2), (-webkit-min-device-pixel-ratio: 1.3), (min-device-pixel-ratio: 1.3), (min-resolution: 1.3dppx) {
  #global-messages .message.success {
    background-image: url("../images/icon-message-tick@2x.png");
    background-size: 32px 32px;
  }
}

#surf-photo-lightbox {
  position: fixed;
  z-index: 5;
  width: 100%;
  height: 100%;
  left: 80px;
  display: none;
}
#surf-photo-lightbox.portrait {
  background: #1C1D1F;
}
#surf-photo-lightbox.portrait .inner {
  position: fixed;
  width: 600px;
  left: 50%;
  margin-left: -300px;
}
#surf-photo-lightbox.portrait .inner .overlay {
  width: 600px;
}
#surf-photo-lightbox .inner {
  position: relative;
}
#surf-photo-lightbox .inner .close {
  position: fixed;
  top: 0;
  right: 0;
  z-index: 2;
}
#surf-photo-lightbox .inner .close a {
  -webkit-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  background: rgba(0, 0, 0, 0.2) url("../images/icon-close-panel.png") 50% 50% no-repeat;
  display: block;
  width: 40px;
  height: 40px;
  text-indent: -9999px;
}
@media (min--moz-device-pixel-ratio: 1.3), (-o-min-device-pixel-ratio: 2.6/2), (-webkit-min-device-pixel-ratio: 1.3), (min-device-pixel-ratio: 1.3), (min-resolution: 1.3dppx) {
  #surf-photo-lightbox .inner .close a {
    background-image: url("../images/icon-close-panel@2x.png");
    background-size: 14px 14px;
  }
}
#surf-photo-lightbox .inner .close a:hover {
  background-color: rgba(0, 0, 0, 0.6);
}
#surf-photo-lightbox .inner .overlay {
  background: url("../images/bg-image-tile-overlay.png") repeat;
  position: fixed;
  width: 100%;
  height: 100%;
}
#surf-photo-lightbox .inner img {
  width: 100%;
}

.lazyLoad {
  width: 100px;
  margin: 20px auto;
}

/* Modifiers */
.white {
  color: #fff;
}

#app {
  position: relative;
  width: 100%;
  height: 100%;
}
#app.page-surf {
  overflow-y: hidden;
  overflow-x: hidden;
}
#app.page-profile {
  height: calc(100vh - 15px);
}
@media (min-width: 881px) and (max-width: 1550px) {
  #app.page-profile {
    position: static;
    height: auto;
  }
  #app.page-profile #site-menu {
    position: fixed;
  }
}
#app .view {
  width: 100%;
  height: 100%;
}
#app .view#view-watch, #app .view#view-settings {
  width: auto;
  height: auto;
}
#app .view#view-clubs {
  width: auto;
}
#app .view#view-clubs #club-list {
  height: 100%;
}
#app .logbook {
  background: #232528 url("../images/bg-logbook.png") repeat-y;
  position: relative;
  height: 100%;
  float: left;
  z-index: 2;
}
#app .logbook .logbook-title {
  padding: 10px 20px 29px 20px;
}
#app .logbook .logbook-title .value {
  font-size: 4.3076923077em;
  font-family: "Stag Sans Thin", Arial;
  color: #FFF;
}
#app .logbook .logbook-title .label {
  font-size: 1.6923076923em;
  font-family: "Stag Sans Thin", Arial;
  color: #898A8B;
  text-transform: lowercase;
}
#app .logbook .wrapper {
  width: 320px;
}
#app .logbook .feed-panel {
  display: none;
  outline: none;
}
#app .logbook #feed-no-surfs {
  padding-left: 20px;
}
#app .logbook #feed-no-surfs h2 {
  color: #FFF;
  font-family: "Stag Sans Thin", Arial;
  font-size: 20px;
  margin-bottom: 19px;
}
#app .logbook #feed-no-surfs p {
  color: #A7A8A9;
  font-size: 16px;
  font-family: "Stag Sans Thin", Arial;
  width: 215px;
  line-height: 20px;
}
#app .logbook #feed-no-surfs p a {
  color: #FFF;
}
#app .logbook .header {
  height: 25px;
  border-bottom: 1px solid #4F5153;
  padding: 14px 23px 0 21px;
  margin-bottom: 24px;
}
#app .logbook .header h2 {
  font-family: "Stag Sans Light", Arial;
  color: #666768;
  font-size: 1.3846153846em;
  float: left;
}
#app .logbook .header a {
  background: url("../images/icon-close-arrow.png") no-repeat;
  display: block;
  float: right;
  width: 7px;
  height: 12px;
  text-indent: -9999px;
}
@media (min--moz-device-pixel-ratio: 1.3), (-o-min-device-pixel-ratio: 2.6/2), (-webkit-min-device-pixel-ratio: 1.3), (min-device-pixel-ratio: 1.3), (min-resolution: 1.3dppx) {
  #app .logbook .header a {
    background-image: url("../images/icon-close-arrow@2x.png");
    background-size: 7px 12px;
  }
}
#app .logbook .tabs {
  height: 48px;
  border-bottom: 1px solid #DF1B00;
}
#app .logbook .tabs ul {
  zoom: 1;
}
#app .logbook .tabs ul:before, #app .logbook .tabs ul:after {
  content: " ";
  display: block;
  height: 0;
  overflow: hidden;
}
#app .logbook .tabs ul:after {
  clear: both;
}
#app .logbook .tabs ul li {
  list-style: none;
  float: left;
  height: 48px;
}
#app .logbook .tabs ul li.active a {
  background: url("../images/icon-red-arrow-up.png") bottom center no-repeat;
  color: #FFF;
}
@media (min--moz-device-pixel-ratio: 1.3), (-o-min-device-pixel-ratio: 2.6/2), (-webkit-min-device-pixel-ratio: 1.3), (min-device-pixel-ratio: 1.3), (min-resolution: 1.3dppx) {
  #app .logbook .tabs ul li.active a {
    background-image: url("../images/icon-red-arrow-up@2x.png");
    background-size: 16px 8px;
  }
}
#app .logbook .tabs ul li a {
  font-family: "Stag Sans Light", Arial;
  display: block;
  height: 29px;
  padding-top: 19px;
  font-size: 1.0769230769em;
  color: #BEBEBE;
  text-decoration: none;
  text-align: center;
}
#app .logbook .tabs ul li a:hover {
  color: #FFF;
}
#app .logbook .tabs ul li.t-my-surfs {
  width: 117px;
}
#app .logbook .tabs ul li.t-clubs {
  width: 89px;
}
#app .logbook .tabs ul li.t-following {
  width: 98px;
}
#app .logbook .filter {
  margin-bottom: 27px;
  position: relative;
}
#app .logbook .filter #club-feed-select {
  -webkit-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  position: relative;
}
#app .logbook .filter #club-feed-select .button {
  display: block;
  background: transparent url("../images/icon-select-down-white.png") top 22px right 17px no-repeat;
  border-radius: 0;
  font-size: 1.0769230769em;
  border-bottom: 1px solid #4F5153;
  text-indent: 17px;
  font-family: "Stag Sans Light", Arial;
  height: 50px;
  color: #FFF;
  line-height: 52px;
  cursor: pointer;
  color: #B8B8B8;
}
@media (min--moz-device-pixel-ratio: 1.3), (-o-min-device-pixel-ratio: 2.6/2), (-webkit-min-device-pixel-ratio: 1.3), (min-device-pixel-ratio: 1.3), (min-resolution: 1.3dppx) {
  #app .logbook .filter #club-feed-select .button {
    background-image: url("../images/icon-select-down-white@2x.png");
    background-size: 13px 6px;
  }
}
#app .logbook .filter #club-feed-select .button:hover {
  color: #FFF;
}
#app .logbook .filter #club-feed-select .options {
  background: rgba(28, 29, 31, 0.95);
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
  position: absolute;
  width: 100%;
  z-index: 2;
  display: none;
}
#app .logbook .filter #club-feed-select .options .option {
  -webkit-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  font-family: "Stag Sans Light", Arial;
  font-size: 1.0769230769em;
  color: #FFF;
  padding: 5px;
  cursor: pointer;
  height: 20px;
  line-height: 20px;
  text-indent: 13px;
}
#app .logbook .filter #club-feed-select .options .option:last-child {
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
}
#app .logbook .filter #club-feed-select .options .option:hover {
  background: #3D86C8;
}
#app .logbook .filter .close {
  -webkit-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  position: absolute;
  background: url("../images/icon-clear-search.png") no-repeat;
  width: 18px;
  height: 18px;
  display: none;
  text-indent: -9999px;
  top: 19px;
  right: 20px;
  opacity: 0.6;
}
@media (min--moz-device-pixel-ratio: 1.3), (-o-min-device-pixel-ratio: 2.6/2), (-webkit-min-device-pixel-ratio: 1.3), (min-device-pixel-ratio: 1.3), (min-resolution: 1.3dppx) {
  #app .logbook .filter .close {
    background-image: url("../images/icon-clear-search@2x.png");
    background-size: 18px 18px;
  }
}
#app .logbook .filter .close:hover {
  opacity: 1;
}
#app .logbook .filter input {
  -webkit-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  background: #1F2124 url("../images/icon-filter.png") 20px 19px no-repeat;
  width: 254px;
  height: 54px;
  padding: 0 20px 0 46px;
  border: none;
  outline: none;
  color: #BEBEBE;
  font-size: 1.0769230769em;
  font-family: "Stag Sans Light", Arial;
}
@media (min--moz-device-pixel-ratio: 1.3), (-o-min-device-pixel-ratio: 2.6/2), (-webkit-min-device-pixel-ratio: 1.3), (min-device-pixel-ratio: 1.3), (min-resolution: 1.3dppx) {
  #app .logbook .filter input {
    background-image: url("../images/icon-filter@2x.png");
    background-size: 15px 15px;
  }
}
#app .logbook .filter input:focus {
  background: #36383C url("../images/icon-filter-focus.png") 20px 19px no-repeat;
}
@media (min--moz-device-pixel-ratio: 1.3), (-o-min-device-pixel-ratio: 2.6/2), (-webkit-min-device-pixel-ratio: 1.3), (min-device-pixel-ratio: 1.3), (min-resolution: 1.3dppx) {
  #app .logbook .filter input:focus {
    background-image: url("../images/icon-filter-focus@2x.png");
    background-size: 15px 15px;
  }
}
#app .logbook .timeline {
  background: url("../images/bg-timeline-line.png") 40px 0px repeat-y;
  height: auto;
  zoom: 1;
}
#app .logbook .timeline:before, #app .logbook .timeline:after {
  content: " ";
  display: block;
  height: 0;
  overflow: hidden;
}
#app .logbook .timeline:after {
  clear: both;
}
#app .logbook .timeline .sort {
  float: right;
  position: relative;
  padding-bottom: 20px;
}
#app .logbook .timeline .sort .toggle {
  background: url("../images/icon-triangle-down.png") center right no-repeat;
  color: #737475;
  font-family: "Stag Sans Light", Arial;
  font-size: 0.9230769231em;
  padding-right: 11px;
  text-transform: uppercase;
  text-decoration: none;
  margin-right: 28px;
}
@media (min--moz-device-pixel-ratio: 1.3), (-o-min-device-pixel-ratio: 2.6/2), (-webkit-min-device-pixel-ratio: 1.3), (min-device-pixel-ratio: 1.3), (min-resolution: 1.3dppx) {
  #app .logbook .timeline .sort .toggle {
    background-image: url("../images/icon-triangle-down@2x.png");
    background-size: 8px 4px;
  }
}
#app .logbook .timeline .sort .dropdown {
  background: rgba(28, 29, 31, 0.95);
  border-radius: 3px;
  width: 119px;
  position: absolute;
  top: 19px;
  right: 20px;
  z-index: 1;
  display: none;
}
#app .logbook .timeline .sort .dropdown li a {
  display: block;
  height: 28px;
  font-family: "Stag Sans Light", Arial;
  font-size: 0.9230769231em;
  border-bottom: 1px solid #181818;
  color: #FFF;
  border-radius: 3px 3px 0 0;
  line-height: 30px;
  text-decoration: none;
  padding-left: 10px;
}
#app .logbook .timeline .sort .dropdown li:last-child a {
  border-bottom: none;
  border-radius: 0 0 3px 3px;
}
#app .logbook .timeline .sort .dropdown li.active a {
  background: url("../images/icon-tick-blue.png") 6px 9px no-repeat;
  padding-left: 21px;
}
@media (min--moz-device-pixel-ratio: 1.3), (-o-min-device-pixel-ratio: 2.6/2), (-webkit-min-device-pixel-ratio: 1.3), (min-device-pixel-ratio: 1.3), (min-resolution: 1.3dppx) {
  #app .logbook .timeline .sort .dropdown li.active a {
    background-image: url("../images/icon-tick-blue@2x.png");
    background-size: 10px 9px;
  }
}
#app .logbook .timeline .sort .dropdown li:hover {
  background-color: #36383C;
}
#app .logbook .timeline .days #feedsLoading {
  cursor: wait;
  background-color: rgba(0, 0, 0, 0);
  opacity: 0.8;
  border-radius: 50px;
  margin: 5% auto;
  animation: loading 0.5s infinite linear;
  -moz-animation: loading 0.5s infinite linear;
  -webkit-animation: loading 0.5s infinite linear;
}
#app .logbook .timeline .days .spin-1 {
  border: 5px solid #fff;
  border-top: 5px solid rgba(0, 0, 0, 0);
  border-left: 5px solid rgba(0, 0, 0, 0);
  width: 30px;
  height: 30px;
}
#app .logbook .timeline .day {
  width: 319px;
  margin-bottom: 30px;
}
#app .logbook .timeline .day .date {
  background: url("../images/icon-timline-day-indicator.png") no-repeat;
  text-transform: uppercase;
  color: #AAAAAB;
  padding-left: 21px;
  margin: 0 0 25px 34px;
  font-family: "Stag Sans Light", Arial;
  font-size: 0.9230769231em;
}
@media (min--moz-device-pixel-ratio: 1.3), (-o-min-device-pixel-ratio: 2.6/2), (-webkit-min-device-pixel-ratio: 1.3), (min-device-pixel-ratio: 1.3), (min-resolution: 1.3dppx) {
  #app .logbook .timeline .day .date {
    background-image: url("../images/icon-timline-day-indicator@2x.png");
    background-size: 13px 13px;
  }
}
#app .logbook .timeline .surf {
  clear: both;
  zoom: 1;
  -webkit-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  position: relative;
  background: #27292C url("../images/bg-timeline-line-surf.png") 39px 0 repeat-y;
  border-left: 1px solid #1F2124;
  border-right: 1px solid #1F2124;
  border-radius: 3px;
  margin-bottom: 10px;
  padding: 0 20px 0 16px;
  cursor: pointer;
}
#app .logbook .timeline .surf:before, #app .logbook .timeline .surf:after {
  content: " ";
  display: block;
  height: 0;
  overflow: hidden;
}
#app .logbook .timeline .surf:after {
  clear: both;
}
#app .logbook .timeline .surf:hover, #app .logbook .timeline .surf.active, #app .logbook .timeline .surf.first {
  background-color: #36383C;
}
#app .logbook .timeline .surf.has-photo .bottom {
  margin-left: -55px;
}
#app .logbook .timeline .surf.has-photo .image {
  float: left;
  margin-right: 16px;
}
#app .logbook .timeline .surf.has-photo .image img {
  width: 39px;
  height: 31px;
  border-radius: 3px;
}
#app .logbook .timeline .surf.has-photo .surf-star-rating,
#app .logbook .timeline .surf.has-photo .top-speed {
  margin-top: 8px;
}
#app .logbook .timeline .surf .static-map {
  position: absolute;
  top: 6px;
  left: 17px;
  width: 44px;
  height: 44px;
  border-radius: 100px;
  border: 2px solid #4A4C50;
  background-size: cover;
  background-position: center;
}
#app .logbook .timeline .surf .surf-info {
  padding: 14px 14px 13px 60px;
  float: left;
}
#app .logbook .timeline .surf .surf-info .bottom {
  zoom: 1;
  margin-top: 12px;
}
#app .logbook .timeline .surf .surf-info .bottom:before, #app .logbook .timeline .surf .surf-info .bottom:after {
  content: " ";
  display: block;
  height: 0;
  overflow: hidden;
}
#app .logbook .timeline .surf .surf-info .bottom:after {
  clear: both;
}
#app .logbook .timeline .surf .surf-info h3 {
  font-family: "Stag Sans Light", Arial;
  font-size: 1.2307692308em;
  color: #FFF;
  margin-bottom: 2px;
  word-wrap: break-word;
  width: 155px;
  vertical-align: top;
}
#app .logbook .timeline .surf .surf-info h3 .pro-badge {
  background: url("../images/icon-probadge-feed.png") no-repeat;
  display: inline-block;
  width: 25px;
  height: 19px;
  margin-left: 6px;
}
@media (min--moz-device-pixel-ratio: 1.3), (-o-min-device-pixel-ratio: 2.6/2), (-webkit-min-device-pixel-ratio: 1.3), (min-device-pixel-ratio: 1.3), (min-resolution: 1.3dppx) {
  #app .logbook .timeline .surf .surf-info h3 .pro-badge {
    background-image: url("../images/icon-probadge-feed@2x.png");
    background-size: 25px 19px;
  }
}
#app .logbook .timeline .surf .surf-info .location {
  background: url("../images/icon-surf.png") no-repeat;
  background-position: left 2px;
  min-height: 14px;
  font-size: 0.8461538462em;
  color: #BEBEBE;
  padding: 4px 0 0 18px;
  width: 130px;
  line-height: 13px;
}
@media (min--moz-device-pixel-ratio: 1.3), (-o-min-device-pixel-ratio: 2.6/2), (-webkit-min-device-pixel-ratio: 1.3), (min-device-pixel-ratio: 1.3), (min-resolution: 1.3dppx) {
  #app .logbook .timeline .surf .surf-info .location {
    background-image: url("../images/icon-surf-2x.png");
    background-size: 17px 14px;
  }
}
#app .logbook .timeline .surf .surf-info .location.activity {
  background: url("../images/icon-location-pin-small.png") no-repeat;
  padding: 4px 0 0 16px;
}
@media (min--moz-device-pixel-ratio: 1.3), (-o-min-device-pixel-ratio: 2.6/2), (-webkit-min-device-pixel-ratio: 1.3), (min-device-pixel-ratio: 1.3), (min-resolution: 1.3dppx) {
  #app .logbook .timeline .surf .surf-info .location.activity {
    background-image: url("../images/icon-location-pin-small@2x.png");
    background-size: 10px 14px;
  }
}
#app .logbook .timeline .surf .surf-info .surf-star-rating {
  float: left;
  margin-right: 13px;
}
#app .logbook .timeline .surf .surf-info .top-speed {
  background: url("../images/icon-lightning-bolt-small.png") no-repeat;
  padding-left: 12px;
  color: #FFF;
  font-size: 1.2307692308em;
  margin-bottom: 7px;
  font-family: "Stag Sans Light", Arial;
  float: left;
}
@media (min--moz-device-pixel-ratio: 1.3), (-o-min-device-pixel-ratio: 2.6/2), (-webkit-min-device-pixel-ratio: 1.3), (min-device-pixel-ratio: 1.3), (min-resolution: 1.3dppx) {
  #app .logbook .timeline .surf .surf-info .top-speed {
    background-image: url("../images/icon-lightning-bolt-small@2x.png");
    background-size: 8px 13px;
  }
}
#app .logbook .timeline .surf .surf-info .top-speed .measurement {
  color: #c0c0c1;
  font-size: 11px;
  font-family: Arial;
}
#app .logbook .timeline .surf .num-waves {
  position: absolute;
  top: 20px;
  right: 20px;
}
#app .logbook .timeline .surf .num-waves .label {
  margin: 0 7px 0 10px;
  color: #C0C0C1;
  font-size: 0.8461538462em;
  text-align: center;
}
#app .logbook .timeline .surf .num-waves .number {
  font-family: "Stag Sans Thin", Arial;
  font-size: 3.5384615385em;
  color: #FFF;
  text-align: center;
}
#app .surf-star-rating {
  background: url("../images/sprite-star-rating.png") no-repeat;
  width: 73px;
  height: 13px;
  text-indent: -9999px;
}
@media (min--moz-device-pixel-ratio: 1.3), (-o-min-device-pixel-ratio: 2.6/2), (-webkit-min-device-pixel-ratio: 1.3), (min-device-pixel-ratio: 1.3), (min-resolution: 1.3dppx) {
  #app .surf-star-rating {
    background-image: url("../images/sprite-star-rating@2x.png");
    background-size: 73px 143px;
  }
}
#app .surf-star-rating.half-star {
  background-position: 0px -13px;
}
#app .surf-star-rating.one-star {
  background-position: 0px -26px;
}
#app .surf-star-rating.one-star-half {
  background-position: 0px -39px;
}
#app .surf-star-rating.two-star {
  background-position: 0px -52px;
}
#app .surf-star-rating.two-star-half {
  background-position: 0px -65px;
}
#app .surf-star-rating.three-star {
  background-position: 0px -78px;
}
#app .surf-star-rating.three-star-half {
  background-position: 0px -91px;
}
#app .surf-star-rating.four-star {
  background-position: 0px -104px;
}
#app .surf-star-rating.four-star-half {
  background-position: 0px -117px;
}
#app .surf-star-rating.five-star {
  background-position: 0px -130px;
}
#app #site-menu {
  background: #2A2C2F;
  width: 80px;
  height: 100%;
  float: left;
  position: relative;
  z-index: 3;
}
#app #site-menu #logo {
  padding: 34px 0 0 18px;
  margin-bottom: 30px;
}
#app #site-menu #logo a {
  background: url("../images/ripcurl-logo.png") no-repeat;
  display: block;
  width: 41px;
  height: 21px;
  text-indent: -9999px;
}
@media (min--moz-device-pixel-ratio: 1.3), (-o-min-device-pixel-ratio: 2.6/2), (-webkit-min-device-pixel-ratio: 1.3), (min-device-pixel-ratio: 1.3), (min-resolution: 1.3dppx) {
  #app #site-menu #logo a {
    background-image: url("../images/ripcurl-logo@2x.png");
    background-size: 41px 21px;
  }
}
#app #site-menu nav {
  display: none;
  width: 42px;
  margin: 0 auto;
}
#app #site-menu nav ul {
  margin: 0;
}
#app #site-menu nav ul li {
  list-style: none;
  margin-bottom: 34px;
}
#app #site-menu nav ul li a {
  color: #979798;
  text-decoration: none;
  font-size: 0.8461538462em;
}
#app #site-menu nav ul li a .icon {
  background: url("../images/sprite-site-menu-icons.png") no-repeat;
  display: block;
  width: 32px;
  height: 32px;
  margin: 0 auto 7px auto;
  border-radius: 50%;
}
@media (min--moz-device-pixel-ratio: 1.3), (-o-min-device-pixel-ratio: 2.6/2), (-webkit-min-device-pixel-ratio: 1.3), (min-device-pixel-ratio: 1.3), (min-resolution: 1.3dppx) {
  #app #site-menu nav ul li a .icon {
    background-image: url("../images/sprite-site-menu-icons@2x.png");
    background-size: 96px 193px;
  }
}
#app #site-menu nav ul li a .label {
  display: block;
  text-align: center;
}
#app #site-menu nav ul li a:hover {
  color: #FFF;
}
#app #site-menu nav ul li .count {
  background: #3D86C8;
  position: absolute;
  top: 9px;
  right: -4px;
  display: none;
  height: 15px;
  font-size: 0.9230769231em;
  line-height: 17px;
  padding: 0 4px;
  border-radius: 3px;
  color: #FFF;
}
#app #site-menu nav ul li.active a {
  color: #FFF;
}
#app #site-menu nav ul li.n-logbook .icon {
  background-position: 0px 0px;
}
#app #site-menu nav ul li.n-logbook a:hover .icon {
  background-position: -32px 0px;
}
#app #site-menu nav ul li.n-logbook.active .icon, #app #site-menu nav ul li.n-logbook.active a:hover .icon {
  background-position: -64px 0px;
}
#app #site-menu nav ul li.n-profile {
  position: relative;
  background: none;
  text-indent: 0;
}
#app #site-menu nav ul li.n-profile .icon {
  background-position: 0px -32px;
}
#app #site-menu nav ul li.n-profile a:hover .icon {
  background-position: -32px -32px;
}
#app #site-menu nav ul li.n-profile.active .icon, #app #site-menu nav ul li.n-profile.active a:hover .icon {
  background-position: -64px -32px;
}
#app #site-menu nav ul li.n-clubs {
  position: relative;
}
#app #site-menu nav ul li.n-clubs .icon {
  background-position: 0px -64px;
}
#app #site-menu nav ul li.n-clubs a:hover .icon {
  background-position: -32px -64px;
}
#app #site-menu nav ul li.n-clubs.active .icon, #app #site-menu nav ul li.n-clubs.active a:hover .icon {
  background-position: -64px -64px;
}
#app #site-menu nav ul li.n-watch .icon {
  background-position: 0px -96px;
}
#app #site-menu nav ul li.n-watch a:hover .icon {
  background-position: -32px -96px;
}
#app #site-menu nav ul li.n-watch.active .icon, #app #site-menu nav ul li.n-watch.active a:hover .icon {
  background-position: -64px -96px;
}
#app #site-menu nav ul li.n-settings .icon {
  background-position: 0px -128px;
}
#app #site-menu nav ul li.n-settings a:hover .icon {
  background-position: -32px -128px;
}
#app #site-menu nav ul li.n-settings.active .icon, #app #site-menu nav ul li.n-settings.active a:hover .icon {
  background-position: -64px -128px;
}
#app #site-menu nav ul li.n-logout .icon {
  background-position: 0px -160px;
}
#app #site-menu nav ul li.n-logout a:hover .icon {
  background-position: -32px -160px;
}
#app #site-menu nav ul li.n-logout.active .icon, #app #site-menu nav ul li.n-logout.active a:hover .icon {
  background-position: -64px -160px;
}
#app #view-surf.no-surfs {
  display: none;
  background: url("../images/bg-no-surfs.jpg") top right no-repeat;
  background-size: cover;
  width: 100%;
  height: 100%;
}
#app #view-surf #view-surf-view {
  width: 100%;
  height: 100%;
}
#app #view-surf #map {
  position: relative;
  height: 100%;
  float: left;
}
#app #view-surf #map #map-controls {
  position: absolute;
  top: 25px;
  left: 20px;
  z-index: 1;
}
#app #view-surf #map #map-controls .map-options {
  position: absolute;
  top: 0;
  left: 39px;
}
#app #view-surf #map #map-controls .map-options a {
  display: block;
}
#app #view-surf #map #map-controls .map-options a.toggle {
  background: #232528 url("../images/bg-map-control-view-arrow.png") 62px 22px no-repeat;
  color: #FFF;
  font-family: "Stag Sans Light", Arial;
  font-size: 1.0769230769em;
  width: 59px;
  height: 29px;
  line-height: 32px;
  border-radius: 3px;
  padding-left: 10px;
  text-decoration: none;
}
@media (min--moz-device-pixel-ratio: 1.3), (-o-min-device-pixel-ratio: 2.6/2), (-webkit-min-device-pixel-ratio: 1.3), (min-device-pixel-ratio: 1.3), (min-resolution: 1.3dppx) {
  #app #view-surf #map #map-controls .map-options a.toggle {
    background-image: url("../images/bg-map-control-view-arrow@2x.png");
    background-size: 5px 5px;
  }
}
#app #view-surf #map #map-controls .map-options ul {
  background: #232528;
  display: none;
  position: absolute;
  top: 0;
  width: 99px;
  border-radius: 3px;
}
#app #view-surf #map #map-controls .map-options ul li {
  border-radius: 3px;
}
#app #view-surf #map #map-controls .map-options ul li a {
  display: block;
  height: 28px;
  font-family: "Stag Sans Light", Arial;
  font-size: 0.9230769231em;
  border-bottom: 1px solid #181818;
  color: #FFF;
  border-radius: 3px 3px 0 0;
  line-height: 30px;
  text-decoration: none;
  padding-left: 10px;
}
#app #view-surf #map #map-controls .map-options ul li:hover {
  background-color: #36383C;
}
#app #view-surf #map #map-controls .map-options ul li:last-child a {
  border-bottom: none;
  border-radius: 0 0 3px 3px;
}
#app #view-surf #map #map-controls .map-options ul li.active a {
  background: url("../images/icon-tick-blue.png") 6px 9px no-repeat;
  padding-left: 21px;
}
@media (min--moz-device-pixel-ratio: 1.3), (-o-min-device-pixel-ratio: 2.6/2), (-webkit-min-device-pixel-ratio: 1.3), (min-device-pixel-ratio: 1.3), (min-resolution: 1.3dppx) {
  #app #view-surf #map #map-controls .map-options ul li.active a {
    background-image: url("../images/icon-tick-blue@2x.png");
    background-size: 10px 9px;
  }
}
#app #view-surf #map #surf-stats {
  display: none;
}
#app #view-surf #map #surf-stats .inner {
  background: rgba(0, 0, 0, 0.4);
  min-width: 634px;
  height: 68px;
  border-radius: 3px;
  position: absolute;
  bottom: 30px;
  left: 30px;
  z-index: 1;
  padding: 5px 10px 5px 5px;
  display: none;
}
@media (min-width: 0) and (max-width: 880px) {
  #app #view-surf #map #surf-stats .inner {
    width: 270px;
    min-width: 284px;
    height: 135px;
  }
}
#app #view-surf #map #surf-stats .buttons {
  float: left;
}
@media (min-width: 0) and (max-width: 880px) {
  #app #view-surf #map #surf-stats .buttons {
    display: none;
  }
}
#app #view-surf #map #surf-stats .buttons a {
  -webkit-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  display: block;
  text-indent: -9999px;
  width: 60px;
  height: 68px;
  float: left;
}
#app #view-surf #map #surf-stats .buttons a:first-child {
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
}
#app #view-surf #map #surf-stats .buttons a.play {
  background: #36383C url("../images/icon-play.png") 50% 50% no-repeat;
  margin-right: 2px;
}
@media (min--moz-device-pixel-ratio: 1.3), (-o-min-device-pixel-ratio: 2.6/2), (-webkit-min-device-pixel-ratio: 1.3), (min-device-pixel-ratio: 1.3), (min-resolution: 1.3dppx) {
  #app #view-surf #map #surf-stats .buttons a.play {
    background-image: url("../images/icon-play@2x.png");
    background-size: 11px 15px;
  }
}
#app #view-surf #map #surf-stats .buttons a.play:hover {
  background-color: #DF1B00;
}
#app #view-surf #map #surf-stats .buttons a.crop {
  background: #36383C url("../images/icon-crop-surf.png") 50% 50% no-repeat;
  /*border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;*/
  border-radius: 3px;
}
@media (min--moz-device-pixel-ratio: 1.3), (-o-min-device-pixel-ratio: 2.6/2), (-webkit-min-device-pixel-ratio: 1.3), (min-device-pixel-ratio: 1.3), (min-resolution: 1.3dppx) {
  #app #view-surf #map #surf-stats .buttons a.crop {
    background-image: url("../images/icon-crop-surf@2x.png");
    background-size: 19px 20px;
  }
}
#app #view-surf #map #surf-stats .buttons a:hover {
  background-color: #404246;
}
#app #view-surf #map #surf-stats .stats {
  float: left;
  height: 51px;
  padding: 17px 0 0 34px;
}
@media (min-width: 0) and (max-width: 880px) {
  #app #view-surf #map #surf-stats .stats {
    height: auto;
    width: 270px;
    padding-left: 20px;
  }
}
#app #view-surf #map #surf-stats .stats .stat {
  float: left;
  text-align: center;
}
#app #view-surf #map #surf-stats .stats .stat .value {
  font-family: "Stag Sans Thin", Arial;
  font-size: 2.3076923077em;
  color: #FFF;
  margin-bottom: 2px;
}
#app #view-surf #map #surf-stats .stats .stat .value .loader {
  margin-bottom: 15px;
  height: 15px;
}
#app #view-surf #map #surf-stats .stats .stat .value span {
  font-family: "Stag Sans Light", Arial;
  font-size: 12px;
  display: inline-block;
  padding-left: 3px;
}
#app #view-surf #map #surf-stats .stats .stat .label {
  color: #BEBEBE;
  font-size: 0.9230769231em;
}
#app #view-surf #map #surf-stats .stats .stat.waves {
  margin-right: 48px;
}
#app #view-surf #map #surf-stats .stats .stat.longest {
  margin-right: 38px;
}
#app #view-surf #map #surf-stats .stats .stat.speed {
  margin-right: 30px;
}
#app #view-surf #map #surf-stats .stats .stat.paddled {
  margin-right: 35px;
}
#app #view-surf #map #surf-stats .stats .stat.time {
  margin-right: 48px;
}
@media (min-width: 0) and (max-width: 880px) {
  #app #view-surf #map #surf-stats .stats .stat {
    margin-right: 20px !important;
    margin-bottom: 15px;
  }
  #app #view-surf #map #surf-stats .stats .stat.speed, #app #view-surf #map #surf-stats .stats .stat.distance {
    margin-right: 0;
  }
}
#app #view-surf #map #surf-stats.isActivity .inner {
  min-width: 300px;
}
#app #view-surf #map #surf-stats.isActivity .waves, #app #view-surf #map #surf-stats.isActivity .longest, #app #view-surf #map #surf-stats.isActivity .paddled, #app #view-surf #map #surf-stats.isActivity .buttons .play {
  display: none !important;
}
#app #view-surf #map #map-canvas {
  width: 100%;
  height: 100%;
}
#app #view-surf #map #replay-surf,
#app #view-surf #map #crop-surf {
  position: absolute;
  background: url("../images/bg-replay-surf.png") repeat-x;
  width: 100%;
  height: 150px;
  bottom: -150px;
  z-index: 1;
  display: none;
}
#app #view-surf #map #replay-surf #replay-graph,
#app #view-surf #map #replay-surf #crop-graph,
#app #view-surf #map #crop-surf #replay-graph,
#app #view-surf #map #crop-surf #crop-graph {
  width: 950px;
  height: 100px;
  margin: 0 auto;
  position: relative;
  border-left: 1px solid rgba(255, 255, 255, 0.2);
  border-right: 1px solid rgba(255, 255, 255, 0.2);
}
#app #view-surf #map #replay-surf #replay-graph canvas,
#app #view-surf #map #replay-surf #crop-graph canvas,
#app #view-surf #map #crop-surf #replay-graph canvas,
#app #view-surf #map #crop-surf #crop-graph canvas {
  margin-top: 7px;
}
#app #view-surf #map #replay-surf #replay-graph .scrubber,
#app #view-surf #map #replay-surf #crop-graph .scrubber,
#app #view-surf #map #crop-surf #replay-graph .scrubber,
#app #view-surf #map #crop-surf #crop-graph .scrubber {
  background: #FFF;
  position: absolute;
  width: 1px;
  height: 100px;
  border-left: 1px solid rgba(0, 0, 0, 0.75);
  border-right: 1px solid rgba(0, 0, 0, 0.75);
  top: 0;
  left: 0;
  display: none;
}
#app #view-surf #map #replay-surf #replay-graph .graph,
#app #view-surf #map #replay-surf #crop-graph .graph,
#app #view-surf #map #crop-surf #replay-graph .graph,
#app #view-surf #map #crop-surf #crop-graph .graph {
  height: 100px;
}
#app #view-surf #map #replay-surf #replay-graph .graph .line,
#app #view-surf #map #replay-surf #crop-graph .graph .line,
#app #view-surf #map #crop-surf #replay-graph .graph .line,
#app #view-surf #map #crop-surf #crop-graph .graph .line {
  width: 1px;
  float: left;
  margin-right: 1px;
  max-height: 100px;
}
#app #view-surf #map #replay-surf #replay-graph .graph .line.paddle,
#app #view-surf #map #replay-surf #crop-graph .graph .line.paddle,
#app #view-surf #map #crop-surf #replay-graph .graph .line.paddle,
#app #view-surf #map #crop-surf #crop-graph .graph .line.paddle {
  background: rgba(255, 255, 255, 0.4);
}
#app #view-surf #map #replay-surf #replay-graph .graph .line.paddle.active,
#app #view-surf #map #replay-surf #crop-graph .graph .line.paddle.active,
#app #view-surf #map #crop-surf #replay-graph .graph .line.paddle.active,
#app #view-surf #map #crop-surf #crop-graph .graph .line.paddle.active {
  background: #6297C8;
}
#app #view-surf #map #replay-surf #replay-graph .graph .line.wave,
#app #view-surf #map #replay-surf #crop-graph .graph .line.wave,
#app #view-surf #map #crop-surf #replay-graph .graph .line.wave,
#app #view-surf #map #crop-surf #crop-graph .graph .line.wave {
  background: rgba(255, 255, 255, 0.8);
}
#app #view-surf #map #replay-surf #replay-graph .graph .line.wave.active,
#app #view-surf #map #replay-surf #crop-graph .graph .line.wave.active,
#app #view-surf #map #crop-surf #replay-graph .graph .line.wave.active,
#app #view-surf #map #crop-surf #crop-graph .graph .line.wave.active {
  background: #A1C1DE;
}
#app #view-surf #map #replay-surf #replay-controls,
#app #view-surf #map #replay-surf #crop-controls,
#app #view-surf #map #crop-surf #replay-controls,
#app #view-surf #map #crop-surf #crop-controls {
  width: 950px;
  height: 45px;
  margin: 0 auto;
  padding-top: 5px;
}
#app #view-surf #map #replay-surf #replay-controls .speed-control,
#app #view-surf #map #replay-surf #crop-controls .speed-control,
#app #view-surf #map #crop-surf #replay-controls .speed-control,
#app #view-surf #map #crop-surf #crop-controls .speed-control {
  padding-top: 6px;
  float: left;
  margin-right: 345px;
}
#app #view-surf #map #replay-surf #replay-controls .speed-control a,
#app #view-surf #map #replay-surf #crop-controls .speed-control a,
#app #view-surf #map #crop-surf #replay-controls .speed-control a,
#app #view-surf #map #crop-surf #crop-controls .speed-control a {
  width: 43px;
  text-align: center;
  padding: 0;
}
#app #view-surf #map #replay-surf #replay-controls .playback-controls,
#app #view-surf #map #replay-surf #crop-controls .playback-controls,
#app #view-surf #map #crop-surf #replay-controls .playback-controls,
#app #view-surf #map #crop-surf #crop-controls .playback-controls {
  float: left;
  margin-right: 345px;
}
#app #view-surf #map #replay-surf #replay-controls .playback-controls a,
#app #view-surf #map #replay-surf #crop-controls .playback-controls a,
#app #view-surf #map #crop-surf #replay-controls .playback-controls a,
#app #view-surf #map #crop-surf #crop-controls .playback-controls a {
  float: left;
  border-radius: 0;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  text-indent: -9999px;
  padding: 0;
  height: 40px;
}
#app #view-surf #map #replay-surf #replay-controls .playback-controls a.prev,
#app #view-surf #map #replay-surf #crop-controls .playback-controls a.prev,
#app #view-surf #map #crop-surf #replay-controls .playback-controls a.prev,
#app #view-surf #map #crop-surf #crop-controls .playback-controls a.prev {
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
  border-right: 1px solid #1C1D1F;
  width: 40px;
  background-image: url("../images/icon-rewind.png");
}
@media (min--moz-device-pixel-ratio: 1.3), (-o-min-device-pixel-ratio: 2.6/2), (-webkit-min-device-pixel-ratio: 1.3), (min-device-pixel-ratio: 1.3), (min-resolution: 1.3dppx) {
  #app #view-surf #map #replay-surf #replay-controls .playback-controls a.prev,
#app #view-surf #map #replay-surf #crop-controls .playback-controls a.prev,
#app #view-surf #map #crop-surf #replay-controls .playback-controls a.prev,
#app #view-surf #map #crop-surf #crop-controls .playback-controls a.prev {
    background-image: url("../images/icon-rewind@2x.png");
    background-size: 17px 11px;
  }
}
#app #view-surf #map #replay-surf #replay-controls .playback-controls a.play,
#app #view-surf #map #replay-surf #crop-controls .playback-controls a.play,
#app #view-surf #map #crop-surf #replay-controls .playback-controls a.play,
#app #view-surf #map #crop-surf #crop-controls .playback-controls a.play {
  width: 50px;
  background-image: url("../images/icon-play.png");
  border-radius: 3px;
}
@media (min--moz-device-pixel-ratio: 1.3), (-o-min-device-pixel-ratio: 2.6/2), (-webkit-min-device-pixel-ratio: 1.3), (min-device-pixel-ratio: 1.3), (min-resolution: 1.3dppx) {
  #app #view-surf #map #replay-surf #replay-controls .playback-controls a.play,
#app #view-surf #map #replay-surf #crop-controls .playback-controls a.play,
#app #view-surf #map #crop-surf #replay-controls .playback-controls a.play,
#app #view-surf #map #crop-surf #crop-controls .playback-controls a.play {
    background-image: url("../images/icon-play@2x.png");
    background-size: 11px 15px;
  }
}
#app #view-surf #map #replay-surf #replay-controls .playback-controls a.pause,
#app #view-surf #map #replay-surf #crop-controls .playback-controls a.pause,
#app #view-surf #map #crop-surf #replay-controls .playback-controls a.pause,
#app #view-surf #map #crop-surf #crop-controls .playback-controls a.pause {
  width: 50px;
  background-image: url("../images/icon-pause.png");
  border-radius: 3px;
}
@media (min--moz-device-pixel-ratio: 1.3), (-o-min-device-pixel-ratio: 2.6/2), (-webkit-min-device-pixel-ratio: 1.3), (min-device-pixel-ratio: 1.3), (min-resolution: 1.3dppx) {
  #app #view-surf #map #replay-surf #replay-controls .playback-controls a.pause,
#app #view-surf #map #replay-surf #crop-controls .playback-controls a.pause,
#app #view-surf #map #crop-surf #replay-controls .playback-controls a.pause,
#app #view-surf #map #crop-surf #crop-controls .playback-controls a.pause {
    background-image: url("../images/icon-pause@2x.png");
    background-size: 13px 16px;
  }
}
#app #view-surf #map #replay-surf #replay-controls .playback-controls a.next,
#app #view-surf #map #replay-surf #crop-controls .playback-controls a.next,
#app #view-surf #map #crop-surf #replay-controls .playback-controls a.next,
#app #view-surf #map #crop-surf #crop-controls .playback-controls a.next {
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
  border-left: 1px solid #1C1D1F;
  width: 40px;
  background-image: url("../images/icon-fastforward.png");
}
@media (min--moz-device-pixel-ratio: 1.3), (-o-min-device-pixel-ratio: 2.6/2), (-webkit-min-device-pixel-ratio: 1.3), (min-device-pixel-ratio: 1.3), (min-resolution: 1.3dppx) {
  #app #view-surf #map #replay-surf #replay-controls .playback-controls a.next,
#app #view-surf #map #replay-surf #crop-controls .playback-controls a.next,
#app #view-surf #map #crop-surf #replay-controls .playback-controls a.next,
#app #view-surf #map #crop-surf #crop-controls .playback-controls a.next {
    background-image: url("../images/icon-fastforward@2x.png");
    background-size: 17px 11px;
  }
}
#app #view-surf #map #replay-surf #replay-controls .playback-controls a.disabled,
#app #view-surf #map #replay-surf #crop-controls .playback-controls a.disabled,
#app #view-surf #map #crop-surf #replay-controls .playback-controls a.disabled,
#app #view-surf #map #crop-surf #crop-controls .playback-controls a.disabled {
  cursor: default;
  opacity: 0;
}
#app #view-surf #map #replay-surf #replay-controls .playback-controls a.disabled:hover,
#app #view-surf #map #replay-surf #crop-controls .playback-controls a.disabled:hover,
#app #view-surf #map #crop-surf #replay-controls .playback-controls a.disabled:hover,
#app #view-surf #map #crop-surf #crop-controls .playback-controls a.disabled:hover {
  background-color: #232528;
}
#app #view-surf #map #replay-surf #replay-controls .close-control,
#app #view-surf #map #replay-surf #crop-controls .close-control,
#app #view-surf #map #crop-surf #replay-controls .close-control,
#app #view-surf #map #crop-surf #crop-controls .close-control {
  padding-top: 6px;
  float: right;
}
#app #view-surf #map #replay-surf #replay-controls .close-control a,
#app #view-surf #map #replay-surf #crop-controls .close-control a,
#app #view-surf #map #crop-surf #replay-controls .close-control a,
#app #view-surf #map #crop-surf #crop-controls .close-control a {
  background-image: url("../images/icon-replay-close.png");
  background-position: 49px 11px;
  background-repeat: no-repeat;
  padding-right: 20px;
}
@media (min--moz-device-pixel-ratio: 1.3), (-o-min-device-pixel-ratio: 2.6/2), (-webkit-min-device-pixel-ratio: 1.3), (min-device-pixel-ratio: 1.3), (min-resolution: 1.3dppx) {
  #app #view-surf #map #replay-surf #replay-controls .close-control a,
#app #view-surf #map #replay-surf #crop-controls .close-control a,
#app #view-surf #map #crop-surf #replay-controls .close-control a,
#app #view-surf #map #crop-surf #crop-controls .close-control a {
    background-image: url("../images/icon-replay-close@2x.png");
    background-size: 8px 8px;
  }
}
#app #view-surf #map #replay-surf #replay-controls a,
#app #view-surf #map #replay-surf #crop-controls a,
#app #view-surf #map #crop-surf #replay-controls a,
#app #view-surf #map #crop-surf #crop-controls a {
  -webkit-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  background-color: #232528;
  display: block;
  color: #FFF;
  font-family: "Stag Sans Light", Arial;
  font-size: 1.0769230769em;
  border-radius: 3px;
  text-align: center;
  height: 29px;
  line-height: 31px;
  padding: 0 10px;
  text-decoration: none;
}
#app #view-surf #map #replay-surf #replay-controls a:hover,
#app #view-surf #map #replay-surf #crop-controls a:hover,
#app #view-surf #map #crop-surf #replay-controls a:hover,
#app #view-surf #map #crop-surf #crop-controls a:hover {
  background-color: #36383C;
}
#app #view-surf #map #crop-surf .noUi-base {
  background: rgba(98, 151, 200, 0.6);
  top: 0;
}
#app #view-surf #map #crop-surf .noUi-state-blocked {
  background: none !important;
}
#app #view-surf #right-sidebar {
  position: absolute;
  top: 0;
  right: -360px;
  height: 100%;
  z-index: 1;
}
#app #view-surf #right-sidebar .scroll-pane {
  height: auto;
  position: relative;
  padding-bottom: 20px;
}
#app #view-surf #right-sidebar .nav {
  margin-top: 20px;
  float: left;
}
#app #view-surf #right-sidebar .nav ul li {
  margin-bottom: 1px;
}
#app #view-surf #right-sidebar .nav ul li a {
  -webkit-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  background-color: rgba(0, 0, 0, 0.2);
  background-repeat: no-repeat;
  background-position: 50% 50%;
  display: block;
  width: 40px;
  height: 40px;
  text-indent: -9999px;
}
#app #view-surf #right-sidebar .nav ul li a:hover {
  background-color: rgba(0, 0, 0, 0.6);
}
#app #view-surf #right-sidebar .nav ul li.active a {
  background-color: #3D86C8;
}
#app #view-surf #right-sidebar .nav ul li.n-surf-details a {
  background-image: url("../images/icon-surf-details.png");
}
@media (min--moz-device-pixel-ratio: 1.3), (-o-min-device-pixel-ratio: 2.6/2), (-webkit-min-device-pixel-ratio: 1.3), (min-device-pixel-ratio: 1.3), (min-resolution: 1.3dppx) {
  #app #view-surf #right-sidebar .nav ul li.n-surf-details a {
    background-image: url("../images/icon-surf-details@2x.png");
    background-size: 20px 4px;
  }
}
#app #view-surf #right-sidebar .nav ul li.n-edit-surf a {
  background-image: url("../images/icon-edit-surf.png");
}
@media (min--moz-device-pixel-ratio: 1.3), (-o-min-device-pixel-ratio: 2.6/2), (-webkit-min-device-pixel-ratio: 1.3), (min-device-pixel-ratio: 1.3), (min-resolution: 1.3dppx) {
  #app #view-surf #right-sidebar .nav ul li.n-edit-surf a {
    background-image: url("../images/icon-edit-surf@2x.png");
    background-size: 16px 16px;
  }
}
#app #view-surf #right-sidebar .nav ul li.n-share-surf a {
  background-image: url("../images/icon-share-surf.png");
}
@media (min--moz-device-pixel-ratio: 1.3), (-o-min-device-pixel-ratio: 2.6/2), (-webkit-min-device-pixel-ratio: 1.3), (min-device-pixel-ratio: 1.3), (min-resolution: 1.3dppx) {
  #app #view-surf #right-sidebar .nav ul li.n-share-surf a {
    background-image: url("../images/icon-share-surf@2x.png");
    background-size: 18px 21px;
  }
}
#app #view-surf #right-sidebar .nav ul li.n-close {
  display: none;
}
#app #view-surf #right-sidebar .nav ul li.n-close a {
  background-image: url("../images/icon-close-panel.png");
}
@media (min--moz-device-pixel-ratio: 1.3), (-o-min-device-pixel-ratio: 2.6/2), (-webkit-min-device-pixel-ratio: 1.3), (min-device-pixel-ratio: 1.3), (min-resolution: 1.3dppx) {
  #app #view-surf #right-sidebar .nav ul li.n-close a {
    background-image: url("../images/icon-close-panel@2x.png");
    background-size: 14px 14px;
  }
}
#app #view-surf #right-sidebar .rhs-panel {
  background: #2a2c2f;
  float: left;
  width: 320px;
  height: 100%;
  display: none;
  padding-bottom: 20px;
}
#app #view-surf #right-sidebar .sign-up {
  background: #292D30;
  padding: 29px 42px 31px 20px;
}
#app #view-surf #right-sidebar .sign-up .cta {
  float: left;
  margin-right: 15px;
}
#app #view-surf #right-sidebar .sign-up .cta a {
  font-family: "Stag Sans Light", Arial;
  background: #DF1B00;
  display: block;
  border-bottom: 2px solid #A61500 !important;
  width: 87px;
  height: 38px;
  line-height: 42px;
  text-align: center;
  border: none;
  color: #FFF;
  text-decoration: none;
  text-transform: uppercase;
  border-radius: 3px;
}
#app #view-surf #right-sidebar .sign-up .text {
  padding-top: 4px;
}
#app #view-surf #right-sidebar .sign-up .text p {
  font-family: "Stag Sans Light", Arial;
  color: #FFF;
  line-height: 15px;
}
#app #view-surf #right-sidebar .sign-up .text a {
  font-family: Arial;
  color: #DF1B00;
  text-decoration: none;
}
#app #view-surf #right-sidebar #panel-surf-details .jspScrollable {
  outline: none;
  height: 100% !important;
}
#app #view-surf #right-sidebar #panel-surf-details .jspPane {
  position: absolute;
  width: 320px !important;
}
#app #view-surf #right-sidebar #panel-surf-details .surf-photo {
  background: #000;
  -webkit-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
}
#app #view-surf #right-sidebar #panel-surf-details .surf-photo img {
  -webkit-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  width: 100%;
  display: block;
}
#app #view-surf #right-sidebar #panel-surf-details .surf-photo img.loading {
  width: 30px;
  padding: 100px 0;
  margin: 0 auto;
}
#app #view-surf #right-sidebar #panel-surf-details .surf-location {
  background: rgba(0, 0, 0, 0.1);
  padding: 16px 23px 16px 20px;
  margin-bottom: 30px;
}
#app #view-surf #right-sidebar #panel-surf-details .surf-location p {
  background: url("../images/icon-clock-small.png") no-repeat;
  min-height: 16px;
  font-family: "Stag Sans Light", Arial;
  font-size: 1.0769230769em;
  color: #BEBEBE;
  padding: 2px 0 0 22px;
}
@media (min--moz-device-pixel-ratio: 1.3), (-o-min-device-pixel-ratio: 2.6/2), (-webkit-min-device-pixel-ratio: 1.3), (min-device-pixel-ratio: 1.3), (min-resolution: 1.3dppx) {
  #app #view-surf #right-sidebar #panel-surf-details .surf-location p {
    background-image: url("../images/icon-clock-small@2x.png");
    background-size: 16px 16px;
  }
}
#app #view-surf #right-sidebar #panel-surf-details .wrapper {
  padding: 0 20px;
}
#app #view-surf #right-sidebar #panel-surf-details .surf-description {
  color: #BEBEBE;
  line-height: 20px;
  margin-bottom: 17px;
  word-wrap: break-word;
}
#app #view-surf #right-sidebar #panel-surf-details .surf-board {
  color: #BEBEBE;
  margin-bottom: 17px;
}
#app #view-surf #right-sidebar #panel-surf-details .surf-board strong {
  color: #FFF;
}
#app #view-surf #right-sidebar #panel-surf-details .has_overridden_weather {
  color: #9EA2A4;
  font-family: "Stag Sans Light", Arial;
  margin: 10px 0 0 0;
}
#app #view-surf #right-sidebar #panel-surf-details .conditions {
  height: 150px;
  margin: 13px 0 0 0;
  overflow: visible;
}
#app #view-surf #right-sidebar #panel-surf-details .conditions h2 {
  color: #FFF;
  font-size: 1.2307692308em;
  font-family: "Stag Sans Light", Arial;
  padding-bottom: 9px;
  border-bottom: 1px solid #545759;
  margin-bottom: 10px;
}
#app #view-surf #right-sidebar #panel-surf-details .conditions .condition {
  position: relative;
  background: rgba(0, 0, 0, 0.1);
  border-radius: 3px;
  width: 87px;
  height: 113px;
  padding: 0 1px;
  margin-right: 6px;
  float: left;
  position: relative;
}
#app #view-surf #right-sidebar #panel-surf-details .conditions .condition:hover .tooltip {
  display: block;
}
#app #view-surf #right-sidebar #panel-surf-details .conditions .condition .tooltip {
  -webkit-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  position: absolute;
  background: url("../images/bg-tooltip.png") no-repeat;
  width: 110px;
  height: 105px;
  top: -104px;
  left: -10px;
  color: #FFF;
  text-align: center;
  padding-top: 17px;
  display: none;
}
@media (min--moz-device-pixel-ratio: 1.3), (-o-min-device-pixel-ratio: 2.6/2), (-webkit-min-device-pixel-ratio: 1.3), (min-device-pixel-ratio: 1.3), (min-resolution: 1.3dppx) {
  #app #view-surf #right-sidebar #panel-surf-details .conditions .condition .tooltip {
    background-image: url("../images/bg-tooltip@2x.png");
    background-size: 110px 122px;
  }
}
#app #view-surf #right-sidebar #panel-surf-details .conditions .condition .tooltip img {
  margin-bottom: 5px;
}
#app #view-surf #right-sidebar #panel-surf-details .conditions .condition .tooltip p {
  font-size: 0.9230769231em;
  margin-top: 0;
  text-transform: uppercase;
}
#app #view-surf #right-sidebar #panel-surf-details .conditions .condition .tooltip p.bottom {
  font-family: "Stag Sans Light", Arial;
}
#app #view-surf #right-sidebar #panel-surf-details .conditions .condition .icon {
  margin: 0 auto;
  width: 21px;
  padding-top: 17px;
}
#app #view-surf #right-sidebar #panel-surf-details .conditions .condition .icon img {
  width: 100%;
}
#app #view-surf #right-sidebar #panel-surf-details .conditions .condition .icon img.loading {
  width: auto;
}
#app #view-surf #right-sidebar #panel-surf-details .conditions .condition .icon img.direction {
  display: none;
}
#app #view-surf #right-sidebar #panel-surf-details .conditions .condition .icon img.null {
  width: 13px;
  margin: -34px 0 10px 37px;
}
#app #view-surf #right-sidebar #panel-surf-details .conditions .condition .icon .label {
  color: #BDBDBE;
  font-size: 0.7692307692em;
  text-align: center;
  margin-top: 7px;
  text-transform: uppercase;
}
#app #view-surf #right-sidebar #panel-surf-details .conditions .condition .icon.unknown {
  width: 100%;
  padding-top: 29px;
}
#app #view-surf #right-sidebar #panel-surf-details .conditions .condition .icon.unknown .label {
  margin-top: 0;
  font-size: 16px;
  letter-spacing: 5px;
}
#app #view-surf #right-sidebar #panel-surf-details .conditions .condition.swell .tooltip img {
  width: 48px;
  margin-bottom: 11px;
}
#app #view-surf #right-sidebar #panel-surf-details .conditions .condition.swell .tooltip .bottom {
  margin-top: 17px;
}
#app #view-surf #right-sidebar #panel-surf-details .conditions .condition.wind .tooltip img {
  width: 36px;
}
#app #view-surf #right-sidebar #panel-surf-details .conditions .condition.wind .bottom, #app #view-surf #right-sidebar #panel-surf-details .conditions .condition.swell .bottom {
  margin-top: 19px;
  text-align: center;
  padding-top: 10px;
  font-size: 1.7692307692em;
  font-family: "Stag Sans Light", Arial;
  color: #FFF;
  text-transform: uppercase;
}
#app #view-surf #right-sidebar #panel-surf-details .conditions .condition.wind .bottom .measurement, #app #view-surf #right-sidebar #panel-surf-details .conditions .condition.swell .bottom .measurement {
  top: 0;
  color: #CCC;
  font-size: 12px;
  padding-left: 2px;
  text-transform: uppercase;
  display: inline-block;
  position: relative;
  top: -7px;
}
#app #view-surf #right-sidebar #panel-surf-details .conditions .condition.tide {
  margin-right: 0;
}
#app #view-surf #right-sidebar #panel-surf-details .conditions .condition.tide .tooltip img {
  width: 33px;
}
#app #view-surf #right-sidebar #panel-surf-details .conditions .condition.tide .tooltip p {
  margin-top: 0;
}
#app #view-surf #right-sidebar #panel-surf-details .conditions .condition.tide .tooltip p.bottom {
  font-size: 1.0769230769em;
  margin-top: 33px;
}
#app #view-surf #right-sidebar #panel-surf-details .conditions .condition.tide .data {
  font-size: 1.0769230769em;
  height: 24px;
  padding-top: 15px;
}
#app #view-surf #right-sidebar #panel-surf-details .conditions .condition.tide .icon {
  width: auto;
  padding-top: 20px;
}
#app #view-surf #right-sidebar #panel-surf-details .conditions .condition.tide .icon img {
  width: 36px;
  margin: 0 auto;
}
#app #view-surf #right-sidebar #panel-surf-details .conditions .condition.tide .icon img.loading {
  width: 24px;
  padding: 0 30px;
}
#app #view-surf #right-sidebar #panel-surf-details .conditions .condition.tide .label {
  margin-top: 14px;
}
#app #view-surf #right-sidebar #panel-surf-details .conditions .condition .data {
  position: absolute;
  bottom: 0;
  border-top: 1px solid #303336;
  width: 85px;
  height: 29px;
  text-align: center;
  padding-top: 10px;
  font-size: 1.7692307692em;
  font-family: "Stag Sans Light", Arial;
  color: #FFF;
  text-transform: uppercase;
}
#app #view-surf #right-sidebar #panel-surf-details .conditions .condition .data .measurement {
  top: 0;
  color: #CCC;
  font-size: 12px;
  padding-left: 2px;
  text-transform: uppercase;
  display: inline-block;
  position: relative;
  top: -7px;
}
#app #view-surf #right-sidebar #panel-surf-details .surf-rating {
  padding-top: 27px;
}
#app #view-surf #right-sidebar #panel-surf-details .surf-rating .title {
  zoom: 1;
  padding-bottom: 9px;
  border-bottom: 1px solid #545759;
}
#app #view-surf #right-sidebar #panel-surf-details .surf-rating .title:before, #app #view-surf #right-sidebar #panel-surf-details .surf-rating .title:after {
  content: " ";
  display: block;
  height: 0;
  overflow: hidden;
}
#app #view-surf #right-sidebar #panel-surf-details .surf-rating .title:after {
  clear: both;
}
#app #view-surf #right-sidebar #panel-surf-details .surf-rating .title h2 {
  float: left;
  color: #FFF;
  font-size: 1.2307692308em;
  font-family: "Stag Sans Light", Arial;
}
#app #view-surf #right-sidebar #panel-surf-details .surf-rating .title #rhs-surf-rating {
  float: right;
}
#app #view-surf #right-sidebar #panel-surf-details .wave-graph {
  padding: 0 2px;
  margin-top: 35px;
}
#app #view-surf #right-sidebar #panel-surf-details .wave-graph .columns {
  height: 9px;
  margin-bottom: 5px;
}
#app #view-surf #right-sidebar #panel-surf-details .wave-graph .columns ol li {
  font-family: "Stag Sans Light", Arial;
  font-size: 0.7692307692em;
  color: #FFF;
  float: left;
}
#app #view-surf #right-sidebar #panel-surf-details .wave-graph .columns ol li.first {
  width: 62px;
}
#app #view-surf #right-sidebar #panel-surf-details .wave-graph .columns ol li.second {
  width: 69px;
}
#app #view-surf #right-sidebar #panel-surf-details .wave-graph .columns ol li.third {
  width: 71px;
}
#app #view-surf #right-sidebar #panel-surf-details .wave-graph .columns ol li.fourth {
  width: 43px;
}
#app #view-surf #right-sidebar #panel-surf-details .wave-graph .columns ol li.fourth.big-number {
  margin-left: -10px;
}
#app #view-surf #right-sidebar #panel-surf-details .wave-graph .columns ol li.fifth {
  width: 28px;
  text-align: right;
}
#app #view-surf #right-sidebar #panel-surf-details .wave-graph .columns ol li.fifth.big-number {
  margin-left: 0;
}
#app #view-surf #right-sidebar #panel-surf-details .wave-graph .graph {
  margin-bottom: 10px;
}
#app #view-surf #right-sidebar #panel-surf-details .wave-graph .graph li {
  height: 27px;
  padding: 1px 2px 2px 2px;
  border-top: 1px solid #1C1F21;
  background: #25282B url("../images/bg-wave-graph-dashed-lines.png") no-repeat;
  margin-bottom: 2px;
}
#app #view-surf #right-sidebar #panel-surf-details .wave-graph .graph li .wave {
  background: #3D86C8;
  width: 0px;
  max-width: 274px;
  height: 27px;
  color: #FFF;
  font-size: 1.2307692308em;
  line-height: 31px;
  border-radius: 3px;
  position: relative;
}
#app #view-surf #right-sidebar #panel-surf-details .wave-graph .graph li .wave.longest {
  background-color: #DF1B00;
}
#app #view-surf #right-sidebar #panel-surf-details .wave-graph .graph li .wave span {
  display: none;
}
#app #view-surf #right-sidebar #panel-surf-details .wave-graph .graph li .wave .number {
  margin-right: 15px;
  position: absolute;
  padding-left: 6px;
  top: 0;
  left: 0;
  font-family: "Stag Sans Medium", Arial;
}
#app #view-surf #right-sidebar #panel-surf-details .wave-graph .graph li .wave .speed {
  font-family: "Stag Sans Light", Arial;
  position: absolute;
  top: 0;
  left: 31px;
}
#app #view-surf #right-sidebar #panel-surf-details .wave-graph .graph li .wave .icon {
  position: absolute;
  top: 0;
  right: -10px;
}
#app #view-surf #right-sidebar #panel-surf-details .wave-graph .graph li .wave .icon img {
  width: 7px;
}
#app #view-surf #right-sidebar #panel-surf-details .wave-graph .footer {
  font-family: "Stag Sans Light", Arial;
  font-size: 0.7692307692em;
  color: #FFF;
}
#app #view-surf #right-sidebar.isActivity .wrapper, #app #view-surf #right-sidebar.isActivity .board, #app #view-surf #right-sidebar.isActivity .t-surf-conditions {
  display: none !important;
}
#app #view-surf #right-sidebar #panel-share-surf {
  background: #292d30;
}
#app #view-surf #right-sidebar #panel-share-surf .google-maps-image {
  height: 320px;
  width: 320px;
  background-color: rgba(0, 0, 0, 0.5);
  color: #FFFFFF;
  position: relative;
  overflow: hidden;
}
#app #view-surf #right-sidebar #panel-share-surf .google-maps-image .google-maps-image-stats {
  position: absolute;
  height: 320px;
  width: 320px;
  z-index: 1;
}
#app #view-surf #right-sidebar #panel-share-surf .google-maps-image .google-maps-image-stats .top {
  position: absolute;
  height: 74px;
  width: 320px;
  background-color: rgba(0, 0, 0, 0.7);
  top: 0;
  text-shadow: 0px 1px 0px rgba(0, 0, 0, 0.45);
  text-align: center;
}
#app #view-surf #right-sidebar #panel-share-surf .google-maps-image .google-maps-image-stats .top .waves {
  font: 36px "Stag Sans Thin", Arial;
  color: #FFFFFF;
  width: 106.666666667px;
  float: left;
  padding-top: 10px;
}
#app #view-surf #right-sidebar #panel-share-surf .google-maps-image .google-maps-image-stats .top .waves .text {
  font: 12px "Stag Sans Thin", Arial;
  color: #979ea0;
}
#app #view-surf #right-sidebar #panel-share-surf .google-maps-image .google-maps-image-stats .top .longest-wave,
#app #view-surf #right-sidebar #panel-share-surf .google-maps-image .google-maps-image-stats .top .top-speed {
  font: 36px "Stag Sans Thin", Arial;
  color: #FFFFFF;
  width: 106.666666667px;
  float: left;
  padding-top: 10px;
}
#app #view-surf #right-sidebar #panel-share-surf .google-maps-image .google-maps-image-stats .top .longest-wave .measurement-unit,
#app #view-surf #right-sidebar #panel-share-surf .google-maps-image .google-maps-image-stats .top .top-speed .measurement-unit {
  font: 14px "Stag Sans Thin", Arial;
}
#app #view-surf #right-sidebar #panel-share-surf .google-maps-image .google-maps-image-stats .top .longest-wave .text,
#app #view-surf #right-sidebar #panel-share-surf .google-maps-image .google-maps-image-stats .top .top-speed .text {
  font: 12px "Stag Sans Thin", Arial;
  color: #979ea0;
}
#app #view-surf #right-sidebar #panel-share-surf .google-maps-image .google-maps-image-stats .bottom {
  position: absolute;
  height: 54px;
  width: 320px;
  bottom: 0;
  background: url("../images/icon-mini-map-ripcurl.png") no-repeat #27292C top 15px right 10px;
}
@media (min--moz-device-pixel-ratio: 1.3), (-o-min-device-pixel-ratio: 2.6/2), (-webkit-min-device-pixel-ratio: 1.3), (min-device-pixel-ratio: 1.3), (min-resolution: 1.3dppx) {
  #app #view-surf #right-sidebar #panel-share-surf .google-maps-image .google-maps-image-stats .bottom {
    background-image: url("../images/icon-mini-map-ripcurl@2x.png");
    background-size: 43px 23px;
  }
}
#app #view-surf #right-sidebar #panel-share-surf .google-maps-image .google-maps-image-stats .bottom .profile-picture {
  width: 66px;
  height: 66px;
  border-radius: 70px;
  border: 2px solid rgba(255, 255, 255, 0.4);
  margin-left: 13px;
  margin-top: -26px;
  float: left;
}
#app #view-surf #right-sidebar #panel-share-surf .google-maps-image .google-maps-image-stats .bottom .profile-picture #main-profile-photo {
  height: 100%;
  width: 100%;
  border-radius: 70px;
  background-size: cover;
  background-position: center;
}
#app #view-surf #right-sidebar #panel-share-surf .google-maps-image .google-maps-image-stats .bottom .profile-name {
  font: 16px "Stag Sans Thin", Arial;
  color: #FFFFFF;
  margin-left: 94px;
  margin-top: 10px;
}
#app #view-surf #right-sidebar #panel-share-surf .google-maps-image .google-maps-image-stats .bottom .location {
  font: 12px "Stag Sans Thin", Arial;
  color: #bebebe;
  margin-left: 94px;
  background: url("../images/icon-mini-map-marker.png") no-repeat;
  padding-left: 13px;
  padding-top: 2px;
}
@media (min--moz-device-pixel-ratio: 1.3), (-o-min-device-pixel-ratio: 2.6/2), (-webkit-min-device-pixel-ratio: 1.3), (min-device-pixel-ratio: 1.3), (min-resolution: 1.3dppx) {
  #app #view-surf #right-sidebar #panel-share-surf .google-maps-image .google-maps-image-stats .bottom .location {
    background-image: url("../images/icon-mini-map-marker@2x.png");
    background-size: 10px 14px;
  }
}
#app #view-surf #right-sidebar #panel-share-surf #share-surf-form {
  padding: 20px;
}
#app #view-surf #right-sidebar #panel-share-surf #share-surf-form textarea {
  box-sizing: border-box;
  color: #8d8d8d;
  font: 14px Arial;
  display: block;
  border: 0;
  border-radius: 3px;
  min-height: 95px;
  padding: 12px 14px;
  width: 100%;
  outline: none;
}
#app #view-surf #right-sidebar #panel-share-surf #share-surf-form input[type=submit] {
  box-sizing: border-box;
  border: 1px solid #535759;
  border-radius: 3px;
  background-color: #24282b;
  color: #FFFFFF;
  font: 16px "Stag Sans Light", Arial;
  text-transform: uppercase;
  margin: 20px auto 0;
  display: block;
  padding-top: 11px;
  padding-bottom: 10px;
  width: 100%;
  cursor: pointer;
  outline: none;
}
#app #view-surf #right-sidebar #panel-share-surf #share-surf-form input[type=submit]:hover {
  background-color: #202426;
}
#app #view-surf #right-sidebar #panel-edit-surf .scroll-pane {
  height: 100%;
}
#app #view-surf #right-sidebar #panel-edit-surf .tab-panel {
  display: none;
}
#app #view-surf #right-sidebar #panel-edit-surf #t-surf-details {
  display: block;
}
#app #view-surf #right-sidebar #panel-edit-surf .jspScrollable {
  outline: none;
}
#app #view-surf #right-sidebar #panel-edit-surf .jspPane {
  position: absolute;
  width: 320px !important;
}
#app #view-surf #right-sidebar #panel-edit-surf .header {
  height: 39px;
  border-bottom: 1px solid #4F5153;
  padding-left: 21px;
  margin-bottom: 43px;
}
#app #view-surf #right-sidebar #panel-edit-surf .header h2 {
  font-family: "Stag Sans Light", Arial;
  color: #666768;
  font-size: 1.3846153846em;
  float: left;
  padding-top: 14px;
}
#app #view-surf #right-sidebar #panel-edit-surf .header input {
  -webkit-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  font-family: "Stag Sans Light", Arial;
  color: #FFF;
  font-size: 1.0769230769em;
  float: right;
  border: none;
  cursor: pointer;
  background: none;
  -webkit-appearance: none;
  padding: 0 18px 0 21px;
  margin: 0;
  outline: none;
  height: 39px;
  line-height: 42px;
}
#app #view-surf #right-sidebar #panel-edit-surf .header input:hover {
  background: #36383C;
}
#app #view-surf #right-sidebar #panel-edit-surf .tabs {
  height: 29px;
  border-bottom: 1px solid #DF1B00;
  margin-bottom: 27px;
}
#app #view-surf #right-sidebar #panel-edit-surf .tabs ul {
  zoom: 1;
}
#app #view-surf #right-sidebar #panel-edit-surf .tabs ul:before, #app #view-surf #right-sidebar #panel-edit-surf .tabs ul:after {
  content: " ";
  display: block;
  height: 0;
  overflow: hidden;
}
#app #view-surf #right-sidebar #panel-edit-surf .tabs ul:after {
  clear: both;
}
#app #view-surf #right-sidebar #panel-edit-surf .tabs ul li {
  list-style: none;
  float: left;
  width: 50%;
  height: 29px;
}
#app #view-surf #right-sidebar #panel-edit-surf .tabs ul li.active a {
  background: url("../images/icon-red-arrow-up.png") bottom center no-repeat;
  color: #FFF;
}
@media (min--moz-device-pixel-ratio: 1.3), (-o-min-device-pixel-ratio: 2.6/2), (-webkit-min-device-pixel-ratio: 1.3), (min-device-pixel-ratio: 1.3), (min-resolution: 1.3dppx) {
  #app #view-surf #right-sidebar #panel-edit-surf .tabs ul li.active a {
    background-image: url("../images/icon-red-arrow-up@2x.png");
    background-size: 16px 8px;
  }
}
#app #view-surf #right-sidebar #panel-edit-surf .tabs ul li a {
  font-family: "Stag Sans Light", Arial;
  display: block;
  height: 29px;
  font-size: 1.0769230769em;
  color: #BEBEBE;
  text-decoration: none;
  text-align: center;
}
#app #view-surf #right-sidebar #panel-edit-surf .tabs ul li a:hover {
  color: #FFF;
}
#app #view-surf #right-sidebar #panel-edit-surf label {
  font-family: "Stag Sans Light", Arial;
  font-size: 0.9230769231em;
  color: #BEBEBE;
  text-transform: uppercase;
  margin-bottom: 14px;
  display: block;
}
#app #view-surf #right-sidebar #panel-edit-surf .form-text,
#app #view-surf #right-sidebar #panel-edit-surf textarea {
  background: #FFF;
  border: none;
  border-radius: 3px;
  color: #B8B8B8;
  outline: none;
  margin: 0;
  width: 252px;
  padding: 0 14px;
}
#app #view-surf #right-sidebar #panel-edit-surf .form-text:focus,
#app #view-surf #right-sidebar #panel-edit-surf textarea:focus {
  color: #585858;
}
#app #view-surf #right-sidebar #panel-edit-surf .form-text {
  height: 40px;
}
#app #view-surf #right-sidebar #panel-edit-surf textarea {
  padding-top: 16px;
  padding-bottom: 16px;
  height: 48px;
}
#app #view-surf #right-sidebar #panel-edit-surf .row {
  padding: 0 20px;
}
#app #view-surf #right-sidebar #panel-edit-surf .row.half .items {
  zoom: 1;
}
#app #view-surf #right-sidebar #panel-edit-surf .row.half .items:before, #app #view-surf #right-sidebar #panel-edit-surf .row.half .items:after {
  content: " ";
  display: block;
  height: 0;
  overflow: hidden;
}
#app #view-surf #right-sidebar #panel-edit-surf .row.half .items:after {
  clear: both;
}
#app #view-surf #right-sidebar #panel-edit-surf .row.half .items .form-text {
  float: left;
  width: 107px;
}
#app #view-surf #right-sidebar #panel-edit-surf .row.half .items .form-text:last-child {
  float: right;
}
#app #view-surf #right-sidebar #panel-edit-surf .spot {
  margin-bottom: 21px;
}
#app #view-surf #right-sidebar #panel-edit-surf .surf-rating {
  background: #36383C;
  border-radius: 3px;
  height: 53px;
  width: 250px;
  margin: 0 auto 28px auto;
  padding: 0 14px 0 15px;
}
#app #view-surf #right-sidebar #panel-edit-surf .surf-rating label {
  text-transform: none;
  float: left;
  font-size: 1.0769230769em;
  padding-top: 20px;
  color: #FFF;
}
#app #view-surf #right-sidebar #panel-edit-surf .surf-rating .edit-star-rating {
  float: right;
  margin-top: 17px;
}
#app #view-surf #right-sidebar #panel-edit-surf .time-date {
  margin-bottom: 39px;
}
#app #view-surf #right-sidebar #panel-edit-surf .time-date .items {
  position: relative;
}
#app #view-surf #right-sidebar #panel-edit-surf .time-date .form-text {
  text-transform: uppercase;
}
#app #view-surf #right-sidebar #panel-edit-surf .time-date .time-pickers {
  zoom: 1;
  width: 125px;
  float: left;
}
#app #view-surf #right-sidebar #panel-edit-surf .time-date .time-pickers:before, #app #view-surf #right-sidebar #panel-edit-surf .time-date .time-pickers:after {
  content: " ";
  display: block;
  height: 0;
  overflow: hidden;
}
#app #view-surf #right-sidebar #panel-edit-surf .time-date .time-pickers:after {
  clear: both;
}
#app #view-surf #right-sidebar #panel-edit-surf .time-date .time-pickers select {
  width: 60px;
  float: left;
  height: 40px;
  border-radius: 3px;
}
#app #view-surf #right-sidebar #panel-edit-surf .time-date .time-pickers select.time-picker-hours {
  margin-right: 5px;
}
#app #view-surf #right-sidebar #panel-edit-surf .surf-visibility {
  margin-bottom: 39px;
}
#app #view-surf #right-sidebar #panel-edit-surf .surf-visibility .option {
  background: #36383C;
  height: 48px;
  padding: 22px 15px 0 20px;
  border-bottom: 1px solid #26272A;
  border-top: 1px solid #4B4D51;
  border-radius: 3px;
}
#app #view-surf #right-sidebar #panel-edit-surf .surf-visibility .option:first-child {
  border-top: none;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
#app #view-surf #right-sidebar #panel-edit-surf .surf-visibility .option label {
  text-transform: none;
  float: left;
}
#app #view-surf #right-sidebar #panel-edit-surf .surf-visibility .option label span {
  font-size: 12px;
  color: #9B9B9D;
  display: block;
  margin-top: 10px;
}
#app #view-surf #right-sidebar #panel-edit-surf .surf-visibility .option .switcher {
  float: right;
  cursor: pointer;
}
#app #view-surf #right-sidebar #panel-edit-surf .notes {
  margin-bottom: 29px;
}
#app #view-surf #right-sidebar #panel-edit-surf .notes label span {
  float: right;
}
#app #view-surf #right-sidebar #panel-edit-surf .notes label span.error {
  color: #DF1B00;
}
#app #view-surf #right-sidebar #panel-edit-surf .drag-drop {
  margin-bottom: 30px;
}
#app #view-surf #right-sidebar #panel-edit-surf .drag-drop .dropzone {
  -webkit-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  background: #25282A url("../images/bg-dropzone.png") 106px 18px no-repeat;
  border: 1px dashed #555;
  border-radius: 3px;
  width: 278px;
  min-height: 51px;
  text-align: center;
  padding-top: 70px;
  cursor: pointer;
}
@media (min--moz-device-pixel-ratio: 1.3), (-o-min-device-pixel-ratio: 2.6/2), (-webkit-min-device-pixel-ratio: 1.3), (min-device-pixel-ratio: 1.3), (min-resolution: 1.3dppx) {
  #app #view-surf #right-sidebar #panel-edit-surf .drag-drop .dropzone {
    background-image: url("../images/bg-dropzone@2x.png");
    background-size: 54px 38px;
  }
}
#app #view-surf #right-sidebar #panel-edit-surf .drag-drop .dropzone .dz-default,
#app #view-surf #right-sidebar #panel-edit-surf .drag-drop .dropzone .dz-success-mark,
#app #view-surf #right-sidebar #panel-edit-surf .drag-drop .dropzone .dz-error-mark,
#app #view-surf #right-sidebar #panel-edit-surf .drag-drop .dropzone .dz-size {
  display: none;
}
#app #view-surf #right-sidebar #panel-edit-surf .drag-drop .dropzone p {
  font-family: "Stag Sans Light", Arial;
  font-size: 1.2307692308em;
  color: #FFF;
  cursor: default;
}
#app #view-surf #right-sidebar #panel-edit-surf .drag-drop .dropzone p.browse {
  font-size: 14px;
  margin: 4px 0 10px 0;
  color: #DF1B00;
  display: block;
  cursor: pointer;
}
#app #view-surf #right-sidebar #panel-edit-surf .drag-drop .dropzone .dz-details {
  font-family: "Stag Sans Light", Arial;
  font-size: 1.0769230769em;
  color: #FFF;
}
#app #view-surf #right-sidebar #panel-edit-surf .drag-drop .dropzone .dz-details .dz-filename {
  margin-bottom: 10px;
  display: inline;
}
#app #view-surf #right-sidebar #panel-edit-surf .drag-drop .dropzone .dz-details img {
  display: block;
  margin: 20px auto;
  border-radius: 3px;
}
#app #view-surf #right-sidebar #panel-edit-surf .drag-drop .dropzone .dz-progress {
  margin-bottom: 10px;
}
#app #view-surf #right-sidebar #panel-edit-surf .drag-drop .dropzone .dz-error-message {
  font-family: "Stag Sans Light", Arial;
  font-size: 1.0769230769em;
  color: #DF1B00;
  margin-bottom: 10px;
}
#app #view-surf #right-sidebar #panel-edit-surf .board {
  margin-bottom: 30px;
}
#app #view-surf #right-sidebar #panel-edit-surf .board p {
  font-family: "Stag Sans Light", Arial;
  color: #BEBEBE;
  font-size: 0.9230769231em;
}
#app #view-surf #right-sidebar #panel-edit-surf .crop {
  margin-bottom: 33px;
}
#app #view-surf #right-sidebar #panel-edit-surf .crop a {
  background: #36383C url("../images/icon-crop.png") 15px 9px no-repeat;
  display: block;
  height: 50px;
  text-indent: 57px;
  color: #FFF;
  font-family: "Stag Sans Light", Arial;
  font-size: 1.0769230769em;
  text-decoration: none;
  line-height: 50px;
  border-radius: 3px;
}
@media (min--moz-device-pixel-ratio: 1.3), (-o-min-device-pixel-ratio: 2.6/2), (-webkit-min-device-pixel-ratio: 1.3), (min-device-pixel-ratio: 1.3), (min-resolution: 1.3dppx) {
  #app #view-surf #right-sidebar #panel-edit-surf .crop a {
    background-image: url("../images/icon-crop@2x.png");
    background-size: 32px 32px;
  }
}
#app #view-surf #right-sidebar #panel-edit-surf .buttons {
  height: 38px;
}
#app #view-surf #right-sidebar #panel-edit-surf .buttons a {
  float: left;
  line-height: 38px;
}
#app #view-surf #right-sidebar #panel-edit-surf .buttons input {
  float: right;
}
#app #view-surf #right-sidebar #panel-edit-surf #t-surf-conditions .option {
  zoom: 1;
  background: #36383C;
  padding: 20px;
  border-bottom: 1px solid #26272A;
  border-top: 1px solid #4B4D51;
  font-family: "Stag Sans Light", Arial;
  color: #FFF;
}
#app #view-surf #right-sidebar #panel-edit-surf #t-surf-conditions .option:before, #app #view-surf #right-sidebar #panel-edit-surf #t-surf-conditions .option:after {
  content: " ";
  display: block;
  height: 0;
  overflow: hidden;
}
#app #view-surf #right-sidebar #panel-edit-surf #t-surf-conditions .option:after {
  clear: both;
}
#app #view-surf #right-sidebar #panel-edit-surf #t-surf-conditions .option label {
  font-size: 16px;
  text-transform: none;
}
#app #view-surf #right-sidebar #panel-edit-surf #t-surf-conditions .option.last-option {
  zoom: 1;
  background: #4A4C50;
  border-bottom: none;
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
  padding-bottom: 15px;
}
#app #view-surf #right-sidebar #panel-edit-surf #t-surf-conditions .option.last-option:before, #app #view-surf #right-sidebar #panel-edit-surf #t-surf-conditions .option.last-option:after {
  content: " ";
  display: block;
  height: 0;
  overflow: hidden;
}
#app #view-surf #right-sidebar #panel-edit-surf #t-surf-conditions .option.last-option:after {
  clear: both;
}
#app #view-surf #right-sidebar #panel-edit-surf #t-surf-conditions .option.last-option label {
  float: left;
  margin-bottom: 0;
}
#app #view-surf #right-sidebar #panel-edit-surf #t-surf-conditions .option.last-option .value {
  position: relative;
  float: right;
  font-family: "Stag Sans Thin", Arial;
  font-size: 1.5384615385em;
}
#app #view-surf #right-sidebar #panel-edit-surf #t-surf-conditions .option.last-option .value select {
  width: 135px;
  height: 28px;
  margin: 0;
  position: relative;
  top: -2px;
  border-radius: 3px;
}
#app #view-surf #right-sidebar #panel-edit-surf #t-surf-conditions .left {
  width: 180px;
  float: left;
}
#app #view-surf #right-sidebar #panel-edit-surf #t-surf-conditions .right {
  float: right;
}
#app #view-surf #right-sidebar #panel-edit-surf #t-surf-conditions .slider {
  margin-bottom: 10px;
}
#app #view-surf #right-sidebar #panel-edit-surf #t-surf-conditions .range {
  zoom: 1;
  margin-bottom: 16px;
}
#app #view-surf #right-sidebar #panel-edit-surf #t-surf-conditions .range:before, #app #view-surf #right-sidebar #panel-edit-surf #t-surf-conditions .range:after {
  content: " ";
  display: block;
  height: 0;
  overflow: hidden;
}
#app #view-surf #right-sidebar #panel-edit-surf #t-surf-conditions .range:after {
  clear: both;
}
#app #view-surf #right-sidebar #panel-edit-surf #t-surf-conditions .range p {
  float: left;
  font-family: "Arial";
  font-size: 0.7692307692em;
  color: #9B9B9D;
}
#app #view-surf #right-sidebar #panel-edit-surf #t-surf-conditions .range p:last-child {
  float: right;
}
#app #view-surf #right-sidebar #panel-edit-surf #t-surf-conditions .details p {
  color: #9B9B9D;
  font-size: 1.0769230769em;
}
#app #view-surf #right-sidebar #panel-edit-surf #t-surf-conditions .details p span {
  color: #FFF;
  display: inline-block;
  padding-left: 5px;
}
#app #view-surf #right-sidebar #panel-edit-surf #t-surf-conditions .size,
#app #view-surf #right-sidebar #panel-edit-surf #t-surf-conditions .time,
#app #view-surf #right-sidebar #panel-edit-surf #t-surf-conditions .strength {
  text-align: center;
}
#app #view-surf #right-sidebar #panel-edit-surf #t-surf-conditions .size .value,
#app #view-surf #right-sidebar #panel-edit-surf #t-surf-conditions .time .value,
#app #view-surf #right-sidebar #panel-edit-surf #t-surf-conditions .strength .value {
  font-family: "Stag Sans Thin", Arial;
  font-size: 3.0769230769em;
  margin-top: 4px;
}
#app #view-surf #right-sidebar #panel-edit-surf #t-surf-conditions .size .unit,
#app #view-surf #right-sidebar #panel-edit-surf #t-surf-conditions .time .unit,
#app #view-surf #right-sidebar #panel-edit-surf #t-surf-conditions .strength .unit {
  font-size: 0.7692307692em;
  text-transform: uppercase;
}
#app #view-surf #right-sidebar #panel-edit-surf #t-surf-conditions .swell {
  margin-bottom: 30px;
}
#app #view-surf #right-sidebar #panel-edit-surf #t-surf-conditions .swell .option.size-option {
  border-top: none;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
}
#app #view-surf #right-sidebar #panel-edit-surf #t-surf-conditions .wind {
  margin-bottom: 30px;
}
#app #view-surf #right-sidebar #panel-edit-surf #t-surf-conditions .wind .strength-option {
  border-top: none;
  border-radius: 3px;
}
#app #view-surf #right-sidebar #panel-edit-surf #t-surf-conditions .wind .strength-option .details {
  width: 200px;
}
#app #view-surf #right-sidebar #panel-edit-surf #t-surf-conditions .tide {
  margin-bottom: 20px;
}
#app #view-surf #right-sidebar #panel-edit-surf #t-surf-conditions .tide .option {
  padding: 0;
  border-top: none;
  border-bottom: 0;
  border-radius: 3px;
}
#app #view-surf #right-sidebar #panel-edit-surf #t-surf-conditions .tide .top {
  height: 32px;
  font-size: 1.2307692308em;
  color: #9B9B9D;
  padding: 18px 15px 0 22px;
}
#app #view-surf #right-sidebar #panel-edit-surf #t-surf-conditions .tide .top .tide-level {
  float: left;
  padding-top: 2px;
}
#app #view-surf #right-sidebar #panel-edit-surf #t-surf-conditions .tide .top .tide-direction {
  float: right;
  padding-right: 34px;
  height: 14px;
  line-height: 16px;
  padding-top: 2px;
}
#app #view-surf #right-sidebar #panel-edit-surf #t-surf-conditions .tide .top .tide-direction.tide-incoming {
  background: url("../images/icon-edit-surf-tide-incoming.png") top right no-repeat;
}
@media (min--moz-device-pixel-ratio: 1.3), (-o-min-device-pixel-ratio: 2.6/2), (-webkit-min-device-pixel-ratio: 1.3), (min-device-pixel-ratio: 1.3), (min-resolution: 1.3dppx) {
  #app #view-surf #right-sidebar #panel-edit-surf #t-surf-conditions .tide .top .tide-direction.tide-incoming {
    background-image: url("../images/icon-edit-surf-tide-incoming@2x.png");
    background-size: 24px 16px;
  }
}
#app #view-surf #right-sidebar #panel-edit-surf #t-surf-conditions .tide .top .tide-direction.tide-outgoing {
  background: url("../images/icon-edit-surf-tide-outgoing.png") top right no-repeat;
}
@media (min--moz-device-pixel-ratio: 1.3), (-o-min-device-pixel-ratio: 2.6/2), (-webkit-min-device-pixel-ratio: 1.3), (min-device-pixel-ratio: 1.3), (min-resolution: 1.3dppx) {
  #app #view-surf #right-sidebar #panel-edit-surf #t-surf-conditions .tide .top .tide-direction.tide-outgoing {
    background-image: url("../images/icon-edit-surf-tide-outgoing@2x.png");
    background-size: 24px 16px;
  }
}
#app #view-surf #right-sidebar #panel-edit-surf #t-surf-conditions .tide .graph {
  background: #303236;
  position: relative;
  height: 88px;
}
#app #view-surf #right-sidebar #panel-edit-surf #t-surf-conditions .tide .graph .handle {
  position: absolute;
  background: url("../images/icon-slider-handle.png") no-repeat;
  width: 17px;
  height: 18px;
  top: 22px;
  left: 15px;
  z-index: 4;
}
@media (min--moz-device-pixel-ratio: 1.3), (-o-min-device-pixel-ratio: 2.6/2), (-webkit-min-device-pixel-ratio: 1.3), (min-device-pixel-ratio: 1.3), (min-resolution: 1.3dppx) {
  #app #view-surf #right-sidebar #panel-edit-surf #t-surf-conditions .tide .graph .handle {
    background-image: url("../images/icon-slider-handle@2x.png");
    background-size: 17px 18px;
  }
}
#app #view-surf #right-sidebar #panel-edit-surf #t-surf-conditions .tide .graph .chart {
  background: url("../images/bg-tide-graph.png") no-repeat;
  position: absolute;
  width: 280px;
  height: 88px;
  z-index: 2;
}
@media (min--moz-device-pixel-ratio: 1.3), (-o-min-device-pixel-ratio: 2.6/2), (-webkit-min-device-pixel-ratio: 1.3), (min-device-pixel-ratio: 1.3), (min-resolution: 1.3dppx) {
  #app #view-surf #right-sidebar #panel-edit-surf #t-surf-conditions .tide .graph .chart {
    background-image: url("../images/bg-tide-graph@2x.png");
    background-size: 280px 88px;
  }
}
#app #view-surf #right-sidebar #panel-edit-surf #t-surf-conditions .tide .graph .section {
  -webkit-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  z-index: 1;
}
#app #view-surf #right-sidebar #panel-edit-surf #t-surf-conditions .tide .graph .section.active {
  background: #3D86C8;
}
#app #view-surf #right-sidebar #panel-edit-surf #t-surf-conditions .tide .graph .section,
#app #view-surf #right-sidebar #panel-edit-surf #t-surf-conditions .tide .graph .hover {
  -webkit-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  float: left;
  width: 47px;
  height: 88px;
  cursor: pointer;
  position: absolute;
}
#app #view-surf #right-sidebar #panel-edit-surf #t-surf-conditions .tide .graph .section.one,
#app #view-surf #right-sidebar #panel-edit-surf #t-surf-conditions .tide .graph .hover.one {
  width: 46px;
  left: 0;
  border-bottom-left-radius: 3px;
}
#app #view-surf #right-sidebar #panel-edit-surf #t-surf-conditions .tide .graph .section.two,
#app #view-surf #right-sidebar #panel-edit-surf #t-surf-conditions .tide .graph .hover.two {
  left: 46px;
}
#app #view-surf #right-sidebar #panel-edit-surf #t-surf-conditions .tide .graph .section.three,
#app #view-surf #right-sidebar #panel-edit-surf #t-surf-conditions .tide .graph .hover.three {
  left: 93px;
}
#app #view-surf #right-sidebar #panel-edit-surf #t-surf-conditions .tide .graph .section.four,
#app #view-surf #right-sidebar #panel-edit-surf #t-surf-conditions .tide .graph .hover.four {
  left: 140px;
}
#app #view-surf #right-sidebar #panel-edit-surf #t-surf-conditions .tide .graph .section.five,
#app #view-surf #right-sidebar #panel-edit-surf #t-surf-conditions .tide .graph .hover.five {
  left: 187px;
}
#app #view-surf #right-sidebar #panel-edit-surf #t-surf-conditions .tide .graph .section.six,
#app #view-surf #right-sidebar #panel-edit-surf #t-surf-conditions .tide .graph .hover.six {
  width: 46px;
  left: 234px;
  border-bottom-right-radius: 3px;
}
#app #view-surf #right-sidebar #panel-edit-surf #t-surf-conditions .tide .graph .hover {
  z-index: 3;
}
#app #ecom,
#app #antavo {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  max-width: 505px;
  background-color: #fff;
  transform: translate(-50%, -50%);
  z-index: 3;
}
#app #ecom:not([data-mode]),
#app #antavo:not([data-mode]) {
  display: none;
}
#app #ecom p,
#app #antavo p {
  margin-top: 16px;
  margin-left: auto;
  margin-right: auto;
  font-size: 22px;
  line-height: 30px;
  font-family: "Stag Sans Light", Arial;
}
#app #ecom a.button,
#app #antavo a.button {
  display: block;
  color: #000;
  font-size: 14px;
  font-family: "Stag Sans Medium", Arial;
  text-decoration: none;
  text-transform: uppercase;
  text-align: center;
  border-radius: 2px;
  background-color: #5BC8E2;
  max-width: 319px;
  margin-left: auto;
  margin-right: auto;
  padding-top: 18px;
  padding-bottom: 18px;
}
#app #ecom {
  text-align: center;
  padding-top: 0px !important;
  padding-bottom: 0px !important;
  width: auto;
  max-width: 988px !important;
}
#app #ecom .ecom-wrapper {
  position: relative;
}
#app #ecom .ecom-wrapper .top {
  display: block;
  position: absolute;
  top: 10px;
  right: 10px;
  padding: 8px;
  text-transform: uppercase;
}
#app #ecom .ecom-wrapper .top .close {
  color: #000;
  text-decoration: none;
  vertical-align: top;
}
#app #ecom .ecom-wrapper .top .close span {
  display: inline-block;
  margin-top: 2px;
  margin-right: 8px;
}
#app #ecom .ecom-wrapper > img {
  width: 100%;
}
#app #ecom .ecom-wrapper h2 {
  font-size: 24px;
  font-family: "Stag Sans lighter", Arial;
  margin-top: 32px;
  font-style: normal;
  font-weight: 700;
  font-size: 24px !important;
  line-height: 27px;
  text-align: left;
  margin-left: 32px;
  margin-right: 62px;
}
#app #ecom .ecom-wrapper .link {
  display: flex;
  flex-direction: row;
  width: 988px;
  height: 277px;
  background-color: white;
}
#app #ecom .ecom-wrapper .link img {
  width: 50% !important;
  height: 100% !important;
}
#app #ecom .ecom-wrapper .link .description {
  padding-top: 25px;
  padding-left: 15px;
  padding-right: 15px;
  width: 50% !important;
  height: 100% !important;
  font-style: normal;
  font-weight: 400;
  font-size: 15px !important;
  line-height: 22px;
  text-align: left;
}
#app #ecom .ecom-wrapper .link .description p {
  font-style: normal;
  font-weight: 400;
  font-size: 15px !important;
  line-height: 22px;
  text-align: left;
  margin-left: 32px;
  margin-right: 62px;
  margin-bottom: 30px;
}
#app #ecom .ecom-wrapper a.button {
  margin-top: 32px;
}
#app #ecom .ecom-wrapper a.button + a {
  margin-top: 0;
  margin-bottom: 15px;
  background-color: transparent;
}
#app #ecom .ecom-wrapper .black-btn {
  margin: 0px !important;
  padding-left: 20px;
  padding-right: 20px;
  color: #FFF !important;
  font-size: 14px;
  text-decoration: none;
  text-transform: uppercase;
  text-align: center;
  border-radius: 2px;
  background-color: #000 !important;
  width: 151px;
  font-style: normal;
  font-family: "Stag Sans lighter", Arial;
  font-size: 12px;
  line-height: 15px;
  text-transform: uppercase;
}
#app #ecom .ecom-wrapper .text-btn {
  margin: 0px !important;
  padding-left: 20px;
  padding-right: 20px;
  color: #000 !important;
  font-size: 14px;
  text-decoration: none;
  text-transform: uppercase;
  text-align: center;
  border-radius: 2px;
  background-color: #FFF !important;
  font-style: normal;
  font-family: "Stag Sans lighter", Arial;
  font-size: 12px;
  line-height: 15px;
  text-transform: uppercase;
}
#app #ecom .ecom-wrapper > * {
  display: none;
}
#app #ecom .ecom-wrapper .link {
  display: flex;
}
#app #ecom .ecom-wrapper .connecting {
  display: block;
  width: 505px;
  height: 300px;
  padding-top: 70px;
}
#app #ecom .ecom-wrapper .connecting .spinner {
  background-image: url(../images/ecom/spinner.svg);
  width: 81px;
  height: 81px;
  margin-left: auto;
  margin-right: auto;
  animation: spinner 1s infinite linear;
  -webkit-animation: spinner 1s infinite linear;
}
@keyframes spinner {
  0% {
    transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
  }
}
#app #ecom .ecom-wrapper .connecting h2 {
  font-size: 40px !important;
  font-family: "Stag Sans Medium", Arial;
  margin-top: 32px;
  margin-left: 32px;
  margin-right: 32px;
  text-align: center;
}
#app #ecom .ecom-wrapper .success {
  display: block;
  width: 505px;
  height: 510px;
  padding-top: 70px;
}
#app #ecom .ecom-wrapper .success .tick {
  background-image: url(../images/ecom/tick.svg);
  background-repeat: no-repeat;
  width: 80px;
  height: 79px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 12px;
}
#app #ecom .ecom-wrapper .success h2 {
  font-size: 40px !important;
  font-family: "Stag Sans Medium", Arial;
  margin-top: 32px;
  margin-left: 32px;
  margin-right: 32px;
  text-align: center;
}
#app #ecom .ecom-wrapper .success p {
  margin-left: 52px;
  margin-right: 52px;
  margin-top: 50px;
  margin-bottom: 50px;
  font-size: 22px !important;
  text-align: center;
}
#app #ecom .ecom-wrapper .error {
  display: block;
  width: 505px;
  height: 510px;
  padding-top: 70px;
}
#app #ecom .ecom-wrapper .error .error {
  background-image: url(../images/ecom/error.svg);
  width: 80px;
  height: 79px;
  margin-left: auto;
  margin-right: auto;
  background-repeat: no-repeat;
}
#app #ecom .ecom-wrapper .error h2 {
  font-size: 40px !important;
  font-family: "Stag Sans Medium", Arial;
  margin-top: 32px;
  margin-left: 32px;
  margin-right: 32px;
  text-align: center;
}
#app #ecom .ecom-wrapper .error p {
  margin-left: 52px;
  margin-right: 52px;
  margin-top: 50px;
  margin-bottom: 50px;
  font-size: 22px !important;
  text-align: center;
}
#app #antavo[data-mode=awareness] .aversion, #app #antavo[data-mode=aversion] .awareness {
  display: none;
}
#app #antavo[data-mode=aversion] {
  padding-bottom: 32px;
}
#app #antavo .top {
  display: flex;
  justify-content: flex-end;
  padding: 8px;
  text-transform: uppercase;
}
#app #antavo .top .close {
  color: #000;
  text-decoration: none;
  vertical-align: top;
}
#app #antavo .top .close span {
  display: inline-block;
  margin-top: 2px;
  margin-right: 8px;
}
#app #antavo > img {
  width: 100%;
}
#app #antavo h2 {
  font-size: 28px;
  font-family: "Stag Sans Medium", Arial;
  margin-top: 32px;
}
#app #antavo h2,
#app #antavo p {
  margin-left: 32px;
  margin-right: 32px;
}
#app #antavo a.button {
  margin-top: 32px;
}
#app #antavo a.button + a {
  margin-top: 0;
  margin-bottom: 15px;
  background-color: transparent;
}
#app #view-profile {
  position: relative;
}
#app #view-profile .graph-tooltip {
  position: absolute;
  width: 182px;
  display: none;
  margin-left: -57px;
  padding-top: 5px;
  z-index: 3;
}
#app #view-profile .graph-tooltip .arrow {
  background: url("../images/icon-tooltip-arrow.png") top center no-repeat;
  width: 182px;
  height: 7px;
}
@media (min--moz-device-pixel-ratio: 1.3), (-o-min-device-pixel-ratio: 2.6/2), (-webkit-min-device-pixel-ratio: 1.3), (min-device-pixel-ratio: 1.3), (min-resolution: 1.3dppx) {
  #app #view-profile .graph-tooltip .arrow {
    background-image: url("../images/icon-tooltip-arrow@2x.png");
    background-size: 14px 7px;
  }
}
#app #view-profile .graph-tooltip .content {
  background: #36383C;
  border-radius: 3px;
  box-shadow: 0px 2px 5px 1px rgba(0, 0, 0, 0.15);
}
#app #view-profile .graph-tooltip .content .header {
  background: #2F3135;
  height: 34px;
  padding: 9px 9px 0 17px;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
}
#app #view-profile .graph-tooltip .content .header .stat {
  float: left;
}
#app #view-profile .graph-tooltip .content .header .stat.max-speed {
  float: right;
}
#app #view-profile .graph-tooltip .content .header .value {
  font-size: 1.6923076923em;
  color: #FFF;
  font-family: "Stag Sans Thin", Arial;
}
#app #view-profile .graph-tooltip .content .header .label {
  color: #909091;
  font-size: 0.8461538462em;
  font-family: "Stag Sans Light", Arial;
}
#app #view-profile .graph-tooltip .content .inner {
  max-height: 300px;
  overflow: hidden;
}
#app #view-profile .graph-tooltip .content .inner a {
  -webkit-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  display: block;
  padding: 12px 16px;
  color: #FFF;
  font-size: 0.9230769231em;
  font-family: "Stag Sans Light", Arial;
  border-top: 1px solid #4A4C50;
  text-decoration: none;
  line-height: 16px;
}
#app #view-profile .graph-tooltip .content .inner a:last-child {
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
}
#app #view-profile .graph-tooltip .content .inner a:hover {
  background: #3D86C8;
  border-color: #3D86C8;
}
#app #view-profile #profile-followers,
#app #view-profile #profile-following {
  padding: 40px 50px;
  overflow: hidden;
}
#app #view-profile #profile-followers .no-results,
#app #view-profile #profile-following .no-results {
  padding-top: 10px;
}
#app #view-profile #profile-followers .no-results p,
#app #view-profile #profile-following .no-results p {
  font-family: "Stag Sans Light", Arial;
  font-size: 16px;
  color: #FFF;
  margin-bottom: 17px;
}
#app #view-profile #profile-followers .no-results a,
#app #view-profile #profile-following .no-results a {
  background: url("../images/icon-find-to-follow.png") 14px 14px no-repeat;
  width: 200px;
  height: 48px;
  line-height: 48px;
  padding-left: 48px;
  text-align: left;
}
@media (min--moz-device-pixel-ratio: 1.3), (-o-min-device-pixel-ratio: 2.6/2), (-webkit-min-device-pixel-ratio: 1.3), (min-device-pixel-ratio: 1.3), (min-resolution: 1.3dppx) {
  #app #view-profile #profile-followers .no-results a,
#app #view-profile #profile-following .no-results a {
    background-image: url("../images/icon-find-to-follow@2x.png");
    background-size: 68px 68px;
  }
}
#app #view-profile #profile-followers .filter,
#app #view-profile #profile-following .filter {
  margin-bottom: 20px;
}
#app #view-profile #profile-followers .filter input,
#app #view-profile #profile-following .filter input {
  -webkit-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  background: #2E3034 url("../images/icon-filter.png") 20px 12px no-repeat;
  width: 274px;
  height: 40px;
  padding: 0 20px 0 46px;
  border: none;
  outline: none;
  color: #BEBEBE;
  font-size: 1.0769230769em;
  font-family: "Stag Sans Light", Arial;
  border-radius: 3px;
}
@media (min--moz-device-pixel-ratio: 1.3), (-o-min-device-pixel-ratio: 2.6/2), (-webkit-min-device-pixel-ratio: 1.3), (min-device-pixel-ratio: 1.3), (min-resolution: 1.3dppx) {
  #app #view-profile #profile-followers .filter input,
#app #view-profile #profile-following .filter input {
    background-image: url("../images/icon-filter@2x.png");
    background-size: 15px 15px;
  }
}
#app #view-profile #profile-followers .filter input:focus,
#app #view-profile #profile-following .filter input:focus {
  background: #2E3034 url("../images/icon-filter-focus.png") 20px 12px no-repeat;
}
@media (min--moz-device-pixel-ratio: 1.3), (-o-min-device-pixel-ratio: 2.6/2), (-webkit-min-device-pixel-ratio: 1.3), (min-device-pixel-ratio: 1.3), (min-resolution: 1.3dppx) {
  #app #view-profile #profile-followers .filter input:focus,
#app #view-profile #profile-following .filter input:focus {
    background-image: url("../images/icon-filter-focus@2x.png");
    background-size: 15px 15px;
  }
}
#app #view-profile #profile-followers .follow-requests h2,
#app #view-profile #profile-following .follow-requests h2 {
  color: #BEBEBE;
  font-family: "Stag Sans Light", Arial;
  font-size: 1.5384615385em;
  margin-bottom: 18px;
}
#app #view-profile #profile-followers .follow-requests h2 span,
#app #view-profile #profile-following .follow-requests h2 span {
  color: #FFF;
}
#app #view-profile #profile-followers .requests,
#app #view-profile #profile-following .requests {
  zoom: 1;
  margin-bottom: 40px;
}
#app #view-profile #profile-followers .requests:before, #app #view-profile #profile-followers .requests:after,
#app #view-profile #profile-following .requests:before,
#app #view-profile #profile-following .requests:after {
  content: " ";
  display: block;
  height: 0;
  overflow: hidden;
}
#app #view-profile #profile-followers .requests:after,
#app #view-profile #profile-following .requests:after {
  clear: both;
}
#app #view-profile #profile-followers .requests .follow-request,
#app #view-profile #profile-following .requests .follow-request {
  background: #2B2D30;
  border-radius: 3px;
  width: 283px;
  height: 98px;
  padding: 15px 0 0 17px;
  float: left;
  margin-right: 20px;
}
#app #view-profile #profile-followers .requests .follow-request .image,
#app #view-profile #profile-following .requests .follow-request .image {
  width: 44px;
  height: 44px;
  border: 2px solid #414245;
  float: left;
  margin-right: 10px;
  border-radius: 100px;
}
#app #view-profile #profile-followers .requests .follow-request .image img,
#app #view-profile #profile-following .requests .follow-request .image img {
  width: 44px;
  height: 44px;
  border-radius: 100px;
}
#app #view-profile #profile-followers .requests .follow-request .details,
#app #view-profile #profile-following .requests .follow-request .details {
  float: left;
  padding-top: 8px;
}
#app #view-profile #profile-followers .requests .follow-request .details h3,
#app #view-profile #profile-following .requests .follow-request .details h3 {
  font-family: "Stag Sans Light", Arial;
  font-size: 1.2307692308em;
  margin-bottom: 4px;
}
#app #view-profile #profile-followers .requests .follow-request .details h3 a,
#app #view-profile #profile-following .requests .follow-request .details h3 a {
  color: #FFF;
  text-decoration: none;
}
#app #view-profile #profile-followers .requests .follow-request .details p,
#app #view-profile #profile-following .requests .follow-request .details p {
  font-family: "Stag Sans Light", Arial;
  font-size: 0.9230769231em;
  color: #BEBEBE;
  margin-bottom: 9px;
}
#app #view-profile #profile-followers .top,
#app #view-profile #profile-following .top {
  height: 30px;
  margin-bottom: 19px;
  padding-bottom: 19px;
  border-bottom: 1px solid #DF1B00;
}
#app #view-profile #profile-followers .top h1,
#app #view-profile #profile-following .top h1 {
  font-family: "Stag Sans Thin", Arial;
  font-size: 2em;
  color: #FFF;
  float: left;
  padding-top: 15px;
}
#app #view-profile #profile-followers .find-surfers .top,
#app #view-profile #profile-following .find-surfers .top {
  zoom: 1;
  border-bottom: none;
}
#app #view-profile #profile-followers .find-surfers .top:before, #app #view-profile #profile-followers .find-surfers .top:after,
#app #view-profile #profile-following .find-surfers .top:before,
#app #view-profile #profile-following .find-surfers .top:after {
  content: " ";
  display: block;
  height: 0;
  overflow: hidden;
}
#app #view-profile #profile-followers .find-surfers .top:after,
#app #view-profile #profile-following .find-surfers .top:after {
  clear: both;
}
#app #view-profile #profile-followers .find-surfers .top h1,
#app #view-profile #profile-following .find-surfers .top h1 {
  float: left;
}
#app #view-profile #profile-followers .find-surfers .top .btn,
#app #view-profile #profile-following .find-surfers .top .btn {
  float: right;
  width: auto;
  display: inline-block;
  padding: 0 15px;
}
#app #view-profile #profile-followers .find-surfers #selected-tags,
#app #view-profile #profile-following .find-surfers #selected-tags {
  cursor: text;
}
#app #view-profile #profile-followers .on-rc-search,
#app #view-profile #profile-following .on-rc-search {
  display: block;
}
#app #view-profile #profile-followers .on-rc-search .buttons,
#app #view-profile #profile-following .on-rc-search .buttons {
  zoom: 1;
}
#app #view-profile #profile-followers .on-rc-search .buttons:before, #app #view-profile #profile-followers .on-rc-search .buttons:after,
#app #view-profile #profile-following .on-rc-search .buttons:before,
#app #view-profile #profile-following .on-rc-search .buttons:after {
  content: " ";
  display: block;
  height: 0;
  overflow: hidden;
}
#app #view-profile #profile-followers .on-rc-search .buttons:after,
#app #view-profile #profile-following .on-rc-search .buttons:after {
  clear: both;
}
#app #view-profile #profile-followers .on-rc-search .buttons a,
#app #view-profile #profile-following .on-rc-search .buttons a {
  width: 74px;
  height: 30px;
  line-height: 32px;
  padding: 0;
  display: inline-block;
  margin-right: 5px;
  font-size: 1em;
  text-transform: uppercase;
}
#app #view-profile #user-profile {
  width: 100%;
  height: 100%;
}
@media (min-width: 0) and (max-width: 1550px) {
  #app #view-profile #user-profile {
    height: auto;
  }
  #app #view-profile #user-profile.fixed {
    overflow: hidden;
    height: 100vh;
  }
}
#app #view-profile #user-profile #profile-overlay {
  z-index: 4;
}
#app #view-profile #user-profile .profile-tab-section {
  display: none;
}
#app #view-profile #user-profile #profile-logbook {
  background: #232528;
  position: absolute;
  z-index: 4;
  top: 0;
  right: -320px;
  height: 100%;
  width: 320px;
  outline: none;
}
#app #view-profile #user-profile #profile-logbook .jspContainer,
#app #view-profile #user-profile #profile-logbook .jspPane {
  outline: none;
}
#app #view-profile #user-profile #profile-logbook .timeline {
  height: 100%;
}
#app #view-profile #user-profile #profile-logbook .timeline .days {
  padding-top: 20px;
}
#app #view-profile #user-profile #profile-logbook .header a {
  background-image: url("../images/icon-close-arrow-r.png");
}
@media (min--moz-device-pixel-ratio: 1.3), (-o-min-device-pixel-ratio: 2.6/2), (-webkit-min-device-pixel-ratio: 1.3), (min-device-pixel-ratio: 1.3), (min-resolution: 1.3dppx) {
  #app #view-profile #user-profile #profile-logbook .header a {
    background-image: url("../images/icon-close-arrow-r@2x.png");
    background-size: 7px 12px;
  }
}
#app #view-profile #user-profile #cover-photo {
  position: relative;
  width: 30%;
  min-width: 300px;
  max-width: 540px;
  height: 100%;
  float: left;
  overflow: hidden;
}
#app #view-profile #user-profile #cover-photo .sign-up {
  position: absolute;
  top: 20px;
  left: 20px;
  background: rgba(43, 44, 48, 0.8);
  width: 180px;
  padding: 20px 20px 20px 20px;
  z-index: 2;
  border-radius: 3px;
}
#app #view-profile #user-profile #cover-photo .sign-up h1 {
  font-family: "Stag Sans Thin", Arial;
  font-size: 1.3846153846em;
  color: #FFF;
  margin: 0 0 14px 0;
}
#app #view-profile #user-profile #cover-photo .sign-up .cta a {
  font-family: "Stag Sans Light", Arial;
  background: #DF1B00;
  display: block;
  border-bottom: 2px solid #A61500 !important;
  width: 100%;
  height: 38px;
  line-height: 42px;
  text-align: center;
  border: none;
  color: #FFF;
  text-decoration: none;
  text-transform: uppercase;
  border-radius: 3px;
}
#app #view-profile #user-profile #cover-photo .sign-up .text {
  font-family: "Stag Sans Light", Arial;
  margin-bottom: 20px;
  color: #FFF;
  line-height: 15px;
}
#app #view-profile #user-profile #cover-photo .sign-up .text a {
  color: #DF1B00;
  text-decoration: none;
}
@media (min-width: 0) and (max-width: 1550px) {
  #app #view-profile #user-profile #cover-photo {
    float: none;
    width: auto;
    min-width: auto;
    max-width: 100%;
    height: 549px;
    margin-left: 80px;
    overflow: visible;
  }
  #app #view-profile #user-profile #cover-photo .cover-photo-wrapper {
    overflow: hidden;
    height: 549px;
  }
  #app #view-profile #user-profile #cover-photo .cover-photo-wrapper .cover-photo {
    display: none;
  }
  #app #view-profile #user-profile #cover-photo .cover-photo-wrapper .cover-photo-landscape {
    display: block;
    min-width: 100%;
    height: auto;
  }
}
#app #view-profile #user-profile #cover-photo .cover-photo-landscape {
  display: none;
}
#app #view-profile #user-profile #cover-photo .shadow {
  background: url("../images/bg-cover-photo-shadow.png") repeat-y;
  width: 2px;
  height: 100%;
  position: absolute;
  z-index: 2;
}
@media (min-width: 0) and (max-width: 1550px) {
  #app #view-profile #user-profile #cover-photo .shadow {
    display: none;
  }
}
#app #view-profile #user-profile #cover-photo img.cover-photo {
  position: absolute;
  min-width: 540px;
  z-index: 1;
}
@media (min-width: 0) and (max-width: 1550px) {
  #app #view-profile #user-profile #cover-photo img.cover-photo {
    position: static;
    width: 100%;
    height: auto;
  }
}
#app #view-profile #user-profile #cover-photo .user-info {
  position: absolute;
  z-index: 2;
  background: rgba(0, 0, 0, 0.6);
  bottom: 0;
  width: 100%;
  height: 170px;
}
@media (min-width: 0) and (max-width: 1550px) {
  #app #view-profile #user-profile #cover-photo .user-info {
    position: absolute;
    height: 90px;
  }
}
#app #view-profile #user-profile #cover-photo .user-info .inner {
  position: relative;
}
@media (min-width: 0) and (max-width: 1550px) {
  #app #view-profile #user-profile #cover-photo .user-info .inner {
    padding-right: 20px;
  }
}
#app #view-profile #user-profile #cover-photo .user-info .inner .global-follow-button {
  float: right;
  margin-top: 52px;
  display: none;
}
@media (min-width: 0) and (max-width: 1550px) {
  #app #view-profile #user-profile #cover-photo .user-info .inner .global-follow-button {
    display: block;
  }
}
#app #view-profile #user-profile #cover-photo .user-info .inner .global-follow-button .btn {
  -webkit-transition: all 0s ease-in-out;
  -o-transition: all 0s ease-in-out;
  transition: all 0s ease-in-out;
  background: rgba(0, 0, 0, 0.2);
  border: 1px solid rgba(255, 255, 255, 0.2);
  border-radius: 3px;
  color: #FFF;
  display: block;
  width: auto;
  height: 30px;
  font-size: 0.8461538462em;
  line-height: 30px;
  text-transform: uppercase;
  text-decoration: none;
  text-align: left;
  padding: 0 15px;
}
#app #view-profile #user-profile #cover-photo .user-info .inner .global-follow-button .btn.follow {
  background: url("../images/icon-follow-request.png") 11px 5px no-repeat;
  margin-right: 135px;
  padding-left: 39px;
}
@media (min--moz-device-pixel-ratio: 1.3), (-o-min-device-pixel-ratio: 2.6/2), (-webkit-min-device-pixel-ratio: 1.3), (min-device-pixel-ratio: 1.3), (min-resolution: 1.3dppx) {
  #app #view-profile #user-profile #cover-photo .user-info .inner .global-follow-button .btn.follow {
    background-image: url("../images/icon-follow-request.png");
    background-size: 23px 17px;
  }
}
@media (min-width: 0) and (max-width: 1550px) {
  #app #view-profile #user-profile #cover-photo .user-info .inner .global-follow-button .btn.follow {
    margin-right: 0;
  }
}
#app #view-profile #user-profile #cover-photo .user-info .inner .global-follow-button .btn.unfollow {
  background: url("../images/icon-following-tick.png") 14px 10px no-repeat;
  margin-right: 121px;
  padding-left: 31px;
}
@media (min--moz-device-pixel-ratio: 1.3), (-o-min-device-pixel-ratio: 2.6/2), (-webkit-min-device-pixel-ratio: 1.3), (min-device-pixel-ratio: 1.3), (min-resolution: 1.3dppx) {
  #app #view-profile #user-profile #cover-photo .user-info .inner .global-follow-button .btn.unfollow {
    background-image: url("../images/icon-following-tick@2x.png");
    background-size: 10px 9px;
  }
}
@media (min-width: 0) and (max-width: 1550px) {
  #app #view-profile #user-profile #cover-photo .user-info .inner .global-follow-button .btn.unfollow {
    margin-right: 0;
  }
}
#app #view-profile #user-profile #cover-photo .user-info .inner .global-follow-button .btn.follow-requested {
  background: url("../images/icon-follow-request.png") 11px 5px no-repeat;
  margin-right: 56px;
  padding-left: 39px;
}
@media (min--moz-device-pixel-ratio: 1.3), (-o-min-device-pixel-ratio: 2.6/2), (-webkit-min-device-pixel-ratio: 1.3), (min-device-pixel-ratio: 1.3), (min-resolution: 1.3dppx) {
  #app #view-profile #user-profile #cover-photo .user-info .inner .global-follow-button .btn.follow-requested {
    background-image: url("../images/icon-follow-request.png");
    background-size: 23px 17px;
  }
}
@media (min-width: 0) and (max-width: 1550px) {
  #app #view-profile #user-profile #cover-photo .user-info .inner .global-follow-button .btn.follow-requested {
    margin-right: 0;
  }
}
#app #view-profile #user-profile #cover-photo .user-info .profile-picture {
  position: absolute;
  width: 120px;
  height: 120px;
  top: -60px;
  left: 50%;
  margin-left: -60px;
}
@media (min-width: 0) and (max-width: 1550px) {
  #app #view-profile #user-profile #cover-photo .user-info .profile-picture {
    top: 26px;
    left: 20px;
    margin-left: 0;
  }
}
#app #view-profile #user-profile #cover-photo .user-info .profile-picture #main-profile-photo {
  width: 120px;
  height: 120px;
  border-radius: 100px;
  border: 3px solid rgba(255, 255, 255, 0.1);
  background-size: cover;
  background-position: center;
}
#app #view-profile #user-profile #cover-photo .user-info .profile-picture .follow-toggle {
  position: absolute;
  top: 93px;
  left: 4px;
  text-indent: -9999px;
}
#app #view-profile #user-profile #cover-photo .user-info .profile-picture .follow-toggle.following {
  background: url("../images/icon-following.png") no-repeat;
  width: 22px;
  height: 22px;
}
@media (min--moz-device-pixel-ratio: 1.3), (-o-min-device-pixel-ratio: 2.6/2), (-webkit-min-device-pixel-ratio: 1.3), (min-device-pixel-ratio: 1.3), (min-resolution: 1.3dppx) {
  #app #view-profile #user-profile #cover-photo .user-info .profile-picture .follow-toggle.following {
    background-image: url("../images/icon-following@2x.png");
    background-size: 22px 22px;
  }
}
#app #view-profile #user-profile #cover-photo .user-info .profile-picture .follow-toggle.not-following {
  display: none;
}
#app #view-profile #user-profile #cover-photo .user-info h1 {
  font-family: "Stag Sans Thin", Arial;
  font-size: 2.6153846154em;
  color: #FFF;
  text-align: center;
  padding-top: 75px;
}
#app #view-profile #user-profile #cover-photo .user-info h1.has-antavo-score {
  padding-top: 65px;
}
@media (min-width: 0) and (max-width: 1550px) {
  #app #view-profile #user-profile #cover-photo .user-info h1.has-antavo-score + .location .edit-buttons {
    margin-top: 4px;
  }
}
@media (min-width: 0) and (max-width: 1550px) {
  #app #view-profile #user-profile #cover-photo .user-info h1 {
    text-align: left;
    margin-left: 170px;
    padding-top: 22px;
  }
  #app #view-profile #user-profile #cover-photo .user-info h1.has-antavo-score {
    padding-top: 12px;
  }
}
#app #view-profile #user-profile #cover-photo .user-info h1 .athlete {
  background: url("../images/icon-probadge.png") no-repeat;
  display: inline-block;
  width: 25px;
  height: 19px;
  margin-left: 11px;
}
@media (min--moz-device-pixel-ratio: 1.3), (-o-min-device-pixel-ratio: 2.6/2), (-webkit-min-device-pixel-ratio: 1.3), (min-device-pixel-ratio: 1.3), (min-resolution: 1.3dppx) {
  #app #view-profile #user-profile #cover-photo .user-info h1 .athlete {
    background-image: url("../images/icon-probadge@2x.png");
    background-size: 25px 19px;
  }
}
#app #view-profile #user-profile #cover-photo .user-info h1 .padlock {
  background: url("../images/icon-padlock.png") no-repeat;
  width: 9px;
  height: 12px;
  display: inline-block;
}
@media (min--moz-device-pixel-ratio: 1.3), (-o-min-device-pixel-ratio: 2.6/2), (-webkit-min-device-pixel-ratio: 1.3), (min-device-pixel-ratio: 1.3), (min-resolution: 1.3dppx) {
  #app #view-profile #user-profile #cover-photo .user-info h1 .padlock {
    background-image: url("../images/icon-padlock@2x.png");
    background-size: 9px 12px;
  }
}
#app #view-profile #user-profile #cover-photo .user-info .location {
  font-family: "Stag Sans Light", Arial;
  font-size: 0.9230769231em;
  color: #FFF;
  height: 14px;
  margin-bottom: 18px;
  text-align: center;
}
@media (min-width: 0) and (max-width: 1550px) {
  #app #view-profile #user-profile #cover-photo .user-info .location {
    display: flex;
    justify-content: space-between;
  }
}
#app #view-profile #user-profile #cover-photo .user-info .location .antavo-score {
  display: block;
  padding-top: 4px;
}
#app #view-profile #user-profile #cover-photo .user-info .location .antavo-score strong {
  font-family: "Stag Sans Medium", Arial;
}
#app #view-profile #user-profile #cover-photo .user-info .location .actual-value {
  background: url("../images/icon-location-pin-small-white.png") no-repeat;
  height: 10px;
  padding-left: 16px;
  padding-top: 4px;
  margin-right: 18px;
  display: inline-block;
  float: left;
  margin-right: 20px;
}
@media (min--moz-device-pixel-ratio: 1.3), (-o-min-device-pixel-ratio: 2.6/2), (-webkit-min-device-pixel-ratio: 1.3), (min-device-pixel-ratio: 1.3), (min-resolution: 1.3dppx) {
  #app #view-profile #user-profile #cover-photo .user-info .location .actual-value {
    background-image: url("../images/icon-location-pin-small-white@2x.png");
    background-size: 10px 14px;
  }
}
#app #view-profile #user-profile #cover-photo .user-info .location .surfer-stats {
  display: none;
}
@media (min-width: 0) and (max-width: 1550px) {
  #app #view-profile #user-profile #cover-photo .user-info .location {
    margin: 0;
    height: auto;
    text-align: left;
  }
  #app #view-profile #user-profile #cover-photo .user-info .location .global-follow-button {
    display: none;
  }
  #app #view-profile #user-profile #cover-photo .user-info .location .surfer-stats {
    display: block;
    padding-top: 4px;
  }
  #app #view-profile #user-profile #cover-photo .user-info .location .value {
    margin-left: 170px;
  }
  #app #view-profile #user-profile #cover-photo .user-info .location .edit-buttons {
    margin-top: -6px;
  }
}
#app #view-profile #user-profile #cover-photo .user-info .location .find-more-surfers {
  padding: 4px 0 0 0;
  display: inline-block;
}
@media (min-width: 0) and (max-width: 1550px) {
  #app #view-profile #user-profile #cover-photo .user-info .location .find-more-surfers {
    padding-top: 0;
  }
}
#app #view-profile #user-profile #cover-photo .user-info .location .find-more-surfers a {
  background: rgba(0, 0, 0, 0.2) url("../images/icon-follow-more-surfers.png") 8px 7px no-repeat;
  border: 1px solid rgba(255, 255, 255, 0.2);
  border-radius: 3px;
  color: #FFF;
  display: block;
  height: 28px;
  font-size: 0.92308em;
  line-height: 30px;
  text-transform: uppercase;
  text-decoration: none;
  text-align: left;
  padding-left: 31px;
  padding-right: 15px;
}
#app #view-profile #user-profile #cover-photo .user-info .location .edit {
  padding: 4px 0 0 0;
  display: inline-block;
}
@media (min-width: 0) and (max-width: 1550px) {
  #app #view-profile #user-profile #cover-photo .user-info .location .edit {
    padding-top: 0;
  }
}
#app #view-profile #user-profile #cover-photo .user-info .location .edit a {
  background: rgba(0, 0, 0, 0.2) url("../images/icon-pencil.png") 11px 8px no-repeat;
  border: 1px solid rgba(255, 255, 255, 0.2);
  border-radius: 3px;
  color: #FFF;
  display: block;
  width: 88px;
  height: 28px;
  font-size: 0.9230769231em;
  line-height: 30px;
  text-transform: uppercase;
  text-decoration: none;
  text-align: left;
  padding-left: 31px;
  margin-left: 15px;
}
@media (min--moz-device-pixel-ratio: 1.3), (-o-min-device-pixel-ratio: 2.6/2), (-webkit-min-device-pixel-ratio: 1.3), (min-device-pixel-ratio: 1.3), (min-resolution: 1.3dppx) {
  #app #view-profile #user-profile #cover-photo .user-info .location .edit a {
    background-image: url("../images/icon-pencil@2x.png");
    background-size: 14px 14px;
  }
}
#app #view-profile #user-profile #cover-photo .user-info .location .global-follow-button {
  float: none;
  margin: 5px 0 0 0;
  display: inline-block;
}
@media (min-width: 0) and (max-width: 1550px) {
  #app #view-profile #user-profile #cover-photo .user-info .location .global-follow-button {
    display: none;
  }
}
#app #view-profile #user-profile #cover-photo .user-info .location .global-follow-button a {
  display: inline-block !important;
  margin: 0 !important;
}
#app #view-profile #user-profile #profile-information {
  margin-left: 30%;
  height: calc(100% - 10px);
  outline: none;
  overflow-x: hidden;
}
@media (min-width: 0) and (max-width: 880px) {
  #app #view-profile #user-profile #profile-information {
    overflow: visible;
  }
}
@media (min-width: 0) and (max-width: 1550px) {
  #app #view-profile #user-profile #profile-information {
    margin-left: 81px;
    height: 100%;
  }
}
#app #view-profile #user-profile #profile-information #profile-wrapper {
  width: 100% !important;
  outline: none;
}
@media (min-width: 0) and (max-width: 1550px) {
  #app #view-profile #user-profile #profile-information #profile-wrapper {
    width: 100% !important;
    height: 100% !important;
  }
}
#app #view-profile #user-profile #profile-information .jspPane {
  width: 100% !important;
}
#app #view-profile #user-profile #profile-information .tabs {
  background: #35373B;
  overflow: hidden;
}
#app #view-profile #user-profile #profile-information .tabs ul {
  padding-left: 50px;
  float: left;
}
@media (min-width: 0) and (max-width: 1550px) {
  #app #view-profile #user-profile #profile-information .tabs ul {
    padding-left: 170px;
  }
  #app #view-profile #user-profile #profile-information .tabs ul li:last-child {
    margin-right: 0;
  }
}
#app #view-profile #user-profile #profile-information .tabs ul li {
  float: left;
  margin-right: 37px;
}
#app #view-profile #user-profile #profile-information .tabs ul li a {
  -webkit-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  font-family: "Stag Sans Light", Arial;
  font-size: 1.0769230769em;
  color: #FFF;
  display: block;
  height: 48px;
  line-height: 52px;
  text-decoration: none;
  border-bottom: 2px solid transparent;
}
#app #view-profile #user-profile #profile-information .tabs ul li.active a {
  border-bottom: 2px solid #DF1B00;
}
#app #view-profile #user-profile #profile-information .tabs .friend-count {
  float: right;
  padding: 0 50px 0 0;
}
@media (min-width: 0) and (max-width: 1550px) {
  #app #view-profile #user-profile #profile-information .tabs .friend-count {
    padding-right: 20px;
  }
  #app #view-profile #user-profile #profile-information .tabs .friend-count .followers {
    margin-left: 0;
  }
}
#app #view-profile #user-profile #profile-information .tabs .friend-count p {
  color: #BEBEBE;
  font-family: "Stag Sans Light", Arial;
  font-size: 1.0769230769em;
  float: left;
  margin-left: 25px;
}
#app #view-profile #user-profile #profile-information .tabs .friend-count p a,
#app #view-profile #user-profile #profile-information .tabs .friend-count p .fake-link {
  -webkit-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  display: block;
  height: 48px;
  line-height: 52px;
  color: #BEBEBE;
  text-decoration: none;
  border-bottom: 2px solid transparent;
}
#app #view-profile #user-profile #profile-information .tabs .friend-count p a span,
#app #view-profile #user-profile #profile-information .tabs .friend-count p .fake-link span {
  font-family: "Stag Sans Medium", Arial;
  color: #FFF;
}
#app #view-profile #user-profile #profile-information .tabs .friend-count p a span.follow-requests,
#app #view-profile #user-profile #profile-information .tabs .friend-count p .fake-link span.follow-requests {
  background: #3D86C8;
  border-radius: 3px;
  font-family: Arial;
  font-size: 12px;
  height: 13px;
  padding: 2px 4px 0 4px;
  margin-right: 4px;
}
#app #view-profile #user-profile #profile-information .tabs .friend-count p a.active,
#app #view-profile #user-profile #profile-information .tabs .friend-count p .fake-link.active {
  border-color: #DF1B00;
}
#app #view-profile #user-profile #profile-information #profile-overview {
  display: block;
}
#app #view-profile #user-profile #profile-information #profile-overview .surf-graphs {
  background: #232528;
  width: 940px;
  height: 373px;
  padding-top: 61px;
  margin: 0 auto;
}
@media (min-width: 0) and (max-width: 1550px) {
  #app #view-profile #user-profile #profile-information #profile-overview .surf-graphs {
    width: 650px;
    padding: 60px 20px 0 20px;
  }
}
#app #view-profile #user-profile #profile-information #profile-overview .surf-graphs .tab-section {
  display: none;
}
#app #view-profile #user-profile #profile-information #profile-overview .surf-graphs .tab-section.graph-28-days {
  display: block;
}
#app #view-profile #user-profile #profile-information #profile-overview .surf-graphs .follow-tabs {
  margin-bottom: 0;
}
#app #view-profile #user-profile #profile-information #profile-overview .surf-graphs .follow-tabs li {
  font-size: 1.2307692308em;
}
#app #view-profile #user-profile #profile-information #profile-overview .surf-graphs .graph {
  background: url("../images/bg-profile-graph.png") repeat-x;
  position: relative;
  width: 940px;
  height: 253px;
}
@media (min-width: 0) and (max-width: 1550px) {
  #app #view-profile #user-profile #profile-information #profile-overview .surf-graphs .graph {
    width: 640px;
  }
}
#app #view-profile #user-profile #profile-information #profile-overview .surf-graphs .graph.all_time .tooltip {
  margin-left: -37px;
}
#app #view-profile #user-profile #profile-information #profile-overview .surf-graphs .graph.year .tooltip {
  margin-left: -62px;
}
#app #view-profile #user-profile #profile-information #profile-overview .surf-graphs .graph .graph-controls {
  position: absolute;
  background: #35373B;
  border-radius: 3px;
  height: 21px;
  padding: 4px;
  top: 20px;
  right: 0;
}
#app #view-profile #user-profile #profile-information #profile-overview .surf-graphs .graph .graph-controls a {
  float: left;
  display: block;
  height: 21px;
  line-height: 23px;
  padding-left: 26px;
  color: #FFF;
  text-transform: uppercase;
  font-family: "Stag Sans Light", Arial;
  font-size: 0.9230769231em;
  text-decoration: none;
  margin-right: 21px;
}
#app #view-profile #user-profile #profile-information #profile-overview .surf-graphs .graph .graph-controls a.wave-count {
  background: url("../images/icon-wave-count-blank.png") no-repeat;
}
@media (min--moz-device-pixel-ratio: 1.3), (-o-min-device-pixel-ratio: 2.6/2), (-webkit-min-device-pixel-ratio: 1.3), (min-device-pixel-ratio: 1.3), (min-resolution: 1.3dppx) {
  #app #view-profile #user-profile #profile-information #profile-overview .surf-graphs .graph .graph-controls a.wave-count {
    background-image: url("../images/icon-wave-count-blank@2x.png");
    background-size: 21px 21px;
  }
}
#app #view-profile #user-profile #profile-information #profile-overview .surf-graphs .graph .graph-controls a.wave-count.only {
  margin-right: 4px;
}
#app #view-profile #user-profile #profile-information #profile-overview .surf-graphs .graph .graph-controls a.wave-count.active {
  background: url("../images/icon-wave-count.png") no-repeat;
}
@media (min--moz-device-pixel-ratio: 1.3), (-o-min-device-pixel-ratio: 2.6/2), (-webkit-min-device-pixel-ratio: 1.3), (min-device-pixel-ratio: 1.3), (min-resolution: 1.3dppx) {
  #app #view-profile #user-profile #profile-information #profile-overview .surf-graphs .graph .graph-controls a.wave-count.active {
    background-image: url("../images/icon-wave-count@2x.png");
    background-size: 21px 21px;
  }
}
#app #view-profile #user-profile #profile-information #profile-overview .surf-graphs .graph .graph-controls a.swell-height {
  background: url("../images/icon-swell-height-blank.png") no-repeat;
  margin-right: 4px;
}
@media (min--moz-device-pixel-ratio: 1.3), (-o-min-device-pixel-ratio: 2.6/2), (-webkit-min-device-pixel-ratio: 1.3), (min-device-pixel-ratio: 1.3), (min-resolution: 1.3dppx) {
  #app #view-profile #user-profile #profile-information #profile-overview .surf-graphs .graph .graph-controls a.swell-height {
    background-image: url("../images/icon-swell-height-blank@2x.png");
    background-size: 21px 21px;
  }
}
#app #view-profile #user-profile #profile-information #profile-overview .surf-graphs .graph .graph-controls a.swell-height.active {
  background: url("../images/icon-swell-height.png") no-repeat;
}
@media (min--moz-device-pixel-ratio: 1.3), (-o-min-device-pixel-ratio: 2.6/2), (-webkit-min-device-pixel-ratio: 1.3), (min-device-pixel-ratio: 1.3), (min-resolution: 1.3dppx) {
  #app #view-profile #user-profile #profile-information #profile-overview .surf-graphs .graph .graph-controls a.swell-height.active {
    background-image: url("../images/icon-swell-height@2x.png");
    background-size: 21px 21px;
  }
}
#app #view-profile #user-profile #profile-information #profile-overview .surf-graphs .graph ol {
  position: absolute;
  bottom: 0;
  height: 177px;
}
#app #view-profile #user-profile #profile-information #profile-overview .surf-graphs .graph ol li {
  font-size: 0.9230769231em;
  font-family: "Stag Sans Light", Arial;
  height: 39px;
  padding: 0 2px 2px 6px;
}
#app #view-profile #user-profile #profile-information #profile-overview .surf-graphs .graph ol.wave-count {
  left: 0;
}
#app #view-profile #user-profile #profile-information #profile-overview .surf-graphs .graph ol.wave-count li {
  color: #6297C8;
  text-align: left;
}
#app #view-profile #user-profile #profile-information #profile-overview .surf-graphs .graph ol.swell-height {
  right: 0;
}
#app #view-profile #user-profile #profile-information #profile-overview .surf-graphs .graph ol.swell-height li {
  color: #FFF;
  text-align: right;
}
#app #view-profile #user-profile #profile-information #profile-overview .surf-graphs .graph ol.weeks, #app #view-profile #user-profile #profile-information #profile-overview .surf-graphs .graph ol.ytd-months {
  width: 100%;
  top: 262px;
  height: 20px;
}
#app #view-profile #user-profile #profile-information #profile-overview .surf-graphs .graph ol.weeks li, #app #view-profile #user-profile #profile-information #profile-overview .surf-graphs .graph ol.ytd-months li {
  width: 223px;
  height: auto;
  float: left;
  text-transform: uppercase;
  color: #FFF;
  text-align: left;
  font-size: 12px;
  font-family: "Stag Sans Light", Arial;
  box-sizing: border-box;
  padding: 0;
}
@media (min-width: 0) and (max-width: 1550px) {
  #app #view-profile #user-profile #profile-information #profile-overview .surf-graphs .graph ol.weeks li, #app #view-profile #user-profile #profile-information #profile-overview .surf-graphs .graph ol.ytd-months li {
    width: 154px;
  }
}
#app #view-profile #user-profile #profile-information #profile-overview .surf-graphs .graph ol.weeks li.first {
  padding-left: 20px;
}
#app #view-profile #user-profile #profile-information #profile-overview .surf-graphs .graph ol.weeks li.second {
  padding-left: 22px;
  width: 216px;
}
@media (min-width: 0) and (max-width: 1550px) {
  #app #view-profile #user-profile #profile-information #profile-overview .surf-graphs .graph ol.weeks li.second {
    padding-left: 0;
    width: 154px;
  }
}
#app #view-profile #user-profile #profile-information #profile-overview .surf-graphs .graph ol.ytd-months li {
  width: 78px;
}
@media (min-width: 0) and (max-width: 1550px) {
  #app #view-profile #user-profile #profile-information #profile-overview .surf-graphs .graph ol.ytd-months li {
    width: 51px;
  }
}
#app #view-profile #user-profile #profile-information #profile-overview .surf-graphs .graph ol.ytd-months li.jan {
  padding-left: 20px;
}
@media (min-width: 0) and (max-width: 1550px) {
  #app #view-profile #user-profile #profile-information #profile-overview .surf-graphs .graph ol.ytd-months li.jan {
    padding-left: 0;
    margin-left: 20px;
  }
}
#app #view-profile #user-profile #profile-information #profile-overview .surf-graphs .graph.all_time .bars {
  overflow: auto;
  height: 207px;
  bottom: -40px;
  outline: none;
}
@media (min-width: 0) and (max-width: 1550px) {
  #app #view-profile #user-profile #profile-information #profile-overview .surf-graphs .graph.all_time .bars {
    width: calc(100% - 21px - 10px) !important;
    margin-left: 10px;
  }
}
#app #view-profile #user-profile #profile-information #profile-overview .surf-graphs .graph.all_time .bars .jspHorizontalBar {
  display: block !important;
  bottom: -5px;
}
#app #view-profile #user-profile #profile-information #profile-overview .surf-graphs .graph.all_time .bars .jspTrack {
  background: #35373B;
  width: 100% !important;
  height: 10px;
  padding: 2px;
  border-radius: 10px;
  box-sizing: border-box;
  overflow: hidden;
}
#app #view-profile #user-profile #profile-information #profile-overview .surf-graphs .graph.all_time .bars .jspTrack .jspDrag {
  background: #868789;
  height: 6px;
  border-radius: 10px;
  display: block !important;
}
#app #view-profile #user-profile #profile-information #profile-overview .surf-graphs .graph .bars {
  width: calc(100% - 21px) !important;
  height: 167px;
  position: absolute;
  bottom: 0;
  left: 21px;
}
#app #view-profile #user-profile #profile-information #profile-overview .surf-graphs .graph .bars ol {
  position: relative;
  height: 167px;
}
#app #view-profile #user-profile #profile-information #profile-overview .surf-graphs .graph .bars ol .bar {
  -webkit-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  position: absolute;
  bottom: 0;
  width: 27px;
  height: 167px;
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;
  padding: 0;
}
@media (min-width: 0) and (max-width: 1550px) {
  #app #view-profile #user-profile #profile-information #profile-overview .surf-graphs .graph .bars ol .bar {
    width: 16px;
  }
}
#app #view-profile #user-profile #profile-information #profile-overview .surf-graphs .graph .bars ol .bar.no-surfs-day {
  opacity: 1;
  border-radius: 0;
}
#app #view-profile #user-profile #profile-information #profile-overview .surf-graphs .graph .bars ol .bar.no-surfs-day .wave-count {
  background: #972213;
  opacity: 1;
}
#app #view-profile #user-profile #profile-information #profile-overview .surf-graphs .graph .bars ol .bar:hover .wave-count,
#app #view-profile #user-profile #profile-information #profile-overview .surf-graphs .graph .bars ol .bar:hover .swell-height, #app #view-profile #user-profile #profile-information #profile-overview .surf-graphs .graph .bars ol .bar.hover .wave-count,
#app #view-profile #user-profile #profile-information #profile-overview .surf-graphs .graph .bars ol .bar.hover .swell-height {
  opacity: 1;
}
#app #view-profile #user-profile #profile-information #profile-overview .surf-graphs .graph .bars ol .bar span {
  -webkit-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  display: block;
  width: 27px;
  position: absolute;
  bottom: 0;
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;
  cursor: pointer;
  opacity: 0.4;
}
@media (min-width: 0) and (max-width: 1550px) {
  #app #view-profile #user-profile #profile-information #profile-overview .surf-graphs .graph .bars ol .bar span {
    width: 16px;
  }
}
#app #view-profile #user-profile #profile-information #profile-overview .surf-graphs .graph .bars ol .bar span.wave-count {
  background: #3D86C8;
  z-index: 1;
}
#app #view-profile #user-profile #profile-information #profile-overview .surf-graphs .graph .bars ol .bar span.swell-height {
  background: url("../images/bg-stripe-pattern.png") repeat;
  z-index: 2;
}
#app #view-profile #user-profile #profile-information #profile-overview .surf-graphs .graph .bars ol .bar span.month {
  position: absolute;
  bottom: -20px;
  width: 70px;
  height: auto;
  text-transform: uppercase;
  color: #FFF;
  text-align: center;
  font-size: 11px;
  font-family: "Stag Sans Light", Arial;
  opacity: 1;
}
#app #view-profile #user-profile #profile-information #profile-overview .surf-graphs .graph .bars ol.all_time .bar {
  width: 70px;
}
@media (min-width: 0) and (max-width: 1550px) {
  #app #view-profile #user-profile #profile-information #profile-overview .surf-graphs .graph .bars ol.all_time .bar {
    width: 40px;
  }
}
#app #view-profile #user-profile #profile-information #profile-overview .surf-graphs .graph .bars ol.all_time .bar span {
  width: 70px;
}
@media (min-width: 0) and (max-width: 1550px) {
  #app #view-profile #user-profile #profile-information #profile-overview .surf-graphs .graph .bars ol.all_time .bar span {
    width: 40px;
  }
}
#app #view-profile #user-profile #profile-information #profile-overview .surf-graphs .graph .bars ol.year .bar {
  width: 15px;
  margin-right: 2px;
}
@media (min-width: 0) and (max-width: 1550px) {
  #app #view-profile #user-profile #profile-information #profile-overview .surf-graphs .graph .bars ol.year .bar {
    width: 9px;
    margin-right: 1px;
  }
}
#app #view-profile #user-profile #profile-information #profile-overview .surf-graphs .graph .bars ol.year .bar span {
  width: 15px;
}
@media (min-width: 0) and (max-width: 1550px) {
  #app #view-profile #user-profile #profile-information #profile-overview .surf-graphs .graph .bars ol.year .bar span {
    width: 9px;
  }
}
#app #view-profile #user-profile #profile-information #profile-overview .profile-stats {
  background: #27292C;
  padding: 46px 0 52px 0;
}
@media (min-width: 881px) and (max-width: 1550px) {
  #app #view-profile #user-profile #profile-information #profile-overview .profile-stats {
    width: auto;
    padding-left: 40px;
    padding-right: 40px;
  }
}
@media (min-width: 0) and (max-width: 880px) {
  #app #view-profile #user-profile #profile-information #profile-overview .profile-stats {
    width: 100%;
    padding-left: 20px;
    padding-right: 20px;
    box-sizing: border-box;
  }
}
#app #view-profile #user-profile #profile-information #profile-overview .profile-stats .inner {
  width: 728px;
  margin: 0 auto;
  zoom: 1;
}
#app #view-profile #user-profile #profile-information #profile-overview .profile-stats .inner:before, #app #view-profile #user-profile #profile-information #profile-overview .profile-stats .inner:after {
  content: " ";
  display: block;
  height: 0;
  overflow: hidden;
}
#app #view-profile #user-profile #profile-information #profile-overview .profile-stats .inner:after {
  clear: both;
}
@media (min-width: 0) and (max-width: 880px) {
  #app #view-profile #user-profile #profile-information #profile-overview .profile-stats .inner {
    width: 100%;
  }
}
@media (min-width: 881px) and (max-width: 1550px) {
  #app #view-profile #user-profile #profile-information #profile-overview .profile-stats .inner {
    width: 728px;
  }
}
#app #view-profile #user-profile #profile-information #profile-overview .profile-stats .column.wave-stats {
  width: 292px;
  float: left;
}
@media (min-width: 0) and (max-width: 1550px) {
  #app #view-profile #user-profile #profile-information #profile-overview .profile-stats .column.wave-stats {
    width: 300px;
  }
}
@media (min-width: 0) and (max-width: 880px) {
  #app #view-profile #user-profile #profile-information #profile-overview .profile-stats .column.wave-stats {
    width: 300px;
  }
}
#app #view-profile #user-profile #profile-information #profile-overview .profile-stats .column.wave-stats .stat {
  -webkit-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  zoom: 1;
  border-bottom: 1px solid #323437;
  padding-top: 24px;
}
#app #view-profile #user-profile #profile-information #profile-overview .profile-stats .column.wave-stats .stat:before, #app #view-profile #user-profile #profile-information #profile-overview .profile-stats .column.wave-stats .stat:after {
  content: " ";
  display: block;
  height: 0;
  overflow: hidden;
}
#app #view-profile #user-profile #profile-information #profile-overview .profile-stats .column.wave-stats .stat:after {
  clear: both;
}
#app #view-profile #user-profile #profile-information #profile-overview .profile-stats .column.wave-stats .stat.total-waves {
  padding-top: 0;
}
#app #view-profile #user-profile #profile-information #profile-overview .profile-stats .column.wave-stats .stat h2 {
  float: left;
  width: 88px;
  font-family: "Stag Sans Light", Arial;
  font-size: 1.3846153846em;
  line-height: 22px;
  color: #8E8F8F;
  padding-top: 15px;
  padding-left: 10px;
}
#app #view-profile #user-profile #profile-information #profile-overview .profile-stats .column.wave-stats .stat .value {
  float: right;
  font-family: "Stag Sans Thin", Arial;
  font-size: 5.0769230769em;
  color: #FFF;
  padding-bottom: 15px;
  border-bottom: 2px solid #6A9CCB;
  padding-right: 10px;
}
#app #view-profile #user-profile #profile-information #profile-overview .profile-stats .column.wave-stats .stat.longest-wave .value {
  border-color: #E1270D;
}
#app #view-profile #user-profile #profile-information #profile-overview .profile-stats .column.wave-stats .stat.longest-wave[data-surf-id], #app #view-profile #user-profile #profile-information #profile-overview .profile-stats .column.wave-stats .stat.top-speed[data-surf-id] {
  cursor: pointer;
}
#app #view-profile #user-profile #profile-information #profile-overview .profile-stats .column.wave-stats .stat.longest-wave[data-surf-id]:hover, #app #view-profile #user-profile #profile-information #profile-overview .profile-stats .column.wave-stats .stat.top-speed[data-surf-id]:hover {
  background: #36383C;
}
#app #view-profile #user-profile #profile-information #profile-overview .profile-stats .column.surf-stats {
  float: right;
  width: 301px;
}
@media (min-width: 0) and (max-width: 1550px) {
  #app #view-profile #user-profile #profile-information #profile-overview .profile-stats .column.surf-stats {
    width: 300px;
  }
}
#app #view-profile #user-profile #profile-information #profile-overview .profile-stats .column.surf-stats .header {
  padding-bottom: 10px;
  border-bottom: 1px solid #323437;
  margin-bottom: 14px;
  zoom: 1;
}
#app #view-profile #user-profile #profile-information #profile-overview .profile-stats .column.surf-stats .header:before, #app #view-profile #user-profile #profile-information #profile-overview .profile-stats .column.surf-stats .header:after {
  content: " ";
  display: block;
  height: 0;
  overflow: hidden;
}
#app #view-profile #user-profile #profile-information #profile-overview .profile-stats .column.surf-stats .header:after {
  clear: both;
}
#app #view-profile #user-profile #profile-information #profile-overview .profile-stats .column.surf-stats .header h2 {
  float: left;
  font-family: "Stag Sans Light", Arial;
  font-size: 1.2307692308em;
  color: #68696A;
  padding-top: 10px;
}
#app #view-profile #user-profile #profile-information #profile-overview .profile-stats .column.surf-stats .header a {
  background: #232528;
  display: inline-block;
  border: 1px solid #525456;
  border-radius: 3px;
  float: right;
  width: 109px;
  height: 28px;
  line-height: 31px;
  color: #BCBDBD;
  font-family: "Stag Sans Light", Arial;
  font-size: 0.8461538462em;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
}
#app #view-profile #user-profile #profile-information #profile-overview .profile-stats .column.surf-stats .stat {
  padding-bottom: 15px;
  border-bottom: 1px solid #323437;
  margin-bottom: 17px;
  zoom: 1;
}
#app #view-profile #user-profile #profile-information #profile-overview .profile-stats .column.surf-stats .stat:before, #app #view-profile #user-profile #profile-information #profile-overview .profile-stats .column.surf-stats .stat:after {
  content: " ";
  display: block;
  height: 0;
  overflow: hidden;
}
#app #view-profile #user-profile #profile-information #profile-overview .profile-stats .column.surf-stats .stat:after {
  clear: both;
}
#app #view-profile #user-profile #profile-information #profile-overview .profile-stats .column.surf-stats .stat.surfs h3 {
  background: url("../images/icon-profile-surfs.png") no-repeat;
}
@media (min--moz-device-pixel-ratio: 1.3), (-o-min-device-pixel-ratio: 2.6/2), (-webkit-min-device-pixel-ratio: 1.3), (min-device-pixel-ratio: 1.3), (min-resolution: 1.3dppx) {
  #app #view-profile #user-profile #profile-information #profile-overview .profile-stats .column.surf-stats .stat.surfs h3 {
    background-image: url("../images/icon-profile-surfs@2x.png");
    background-size: 32px 32px;
  }
}
#app #view-profile #user-profile #profile-information #profile-overview .profile-stats .column.surf-stats .stat.distance h3 {
  background: url("../images/icon-profile-distance.png") no-repeat;
}
@media (min--moz-device-pixel-ratio: 1.3), (-o-min-device-pixel-ratio: 2.6/2), (-webkit-min-device-pixel-ratio: 1.3), (min-device-pixel-ratio: 1.3), (min-resolution: 1.3dppx) {
  #app #view-profile #user-profile #profile-information #profile-overview .profile-stats .column.surf-stats .stat.distance h3 {
    background-image: url("../images/icon-profile-distance@2x.png");
    background-size: 32px 32px;
  }
}
#app #view-profile #user-profile #profile-information #profile-overview .profile-stats .column.surf-stats .stat.water-time h3 {
  background: url("../images/icon-profile-water-time.png") no-repeat;
}
@media (min--moz-device-pixel-ratio: 1.3), (-o-min-device-pixel-ratio: 2.6/2), (-webkit-min-device-pixel-ratio: 1.3), (min-device-pixel-ratio: 1.3), (min-resolution: 1.3dppx) {
  #app #view-profile #user-profile #profile-information #profile-overview .profile-stats .column.surf-stats .stat.water-time h3 {
    background-image: url("../images/icon-profile-water-time@2x.png");
    background-size: 32px 32px;
  }
}
#app #view-profile #user-profile #profile-information #profile-overview .profile-stats .column.surf-stats .stat.days-surfed h3 {
  background: url("../images/icon-profile-days-surfed.png") no-repeat;
  position: relative;
}
@media (min--moz-device-pixel-ratio: 1.3), (-o-min-device-pixel-ratio: 2.6/2), (-webkit-min-device-pixel-ratio: 1.3), (min-device-pixel-ratio: 1.3), (min-resolution: 1.3dppx) {
  #app #view-profile #user-profile #profile-information #profile-overview .profile-stats .column.surf-stats .stat.days-surfed h3 {
    background-image: url("../images/icon-profile-days-surfed@2x.png");
    background-size: 32px 32px;
  }
}
#app #view-profile #user-profile #profile-information #profile-overview .profile-stats .column.surf-stats .stat.days-surfed h3 span {
  position: absolute;
  font-size: 9px;
  top: 2px;
  left: 8px;
  display: block;
  width: 14px;
  text-align: center;
}
#app #view-profile #user-profile #profile-information #profile-overview .profile-stats .column.surf-stats .stat h3 {
  float: left;
  height: 32px;
  margin-left: -4px;
  font-family: "Stag Sans Light", Arial;
  font-size: 1.2307692308em;
  color: #969797;
  line-height: 32px;
  padding-left: 40px;
}
#app #view-profile #user-profile #profile-information #profile-overview .profile-stats .column.surf-stats .stat .value {
  float: right;
  color: #FFF;
  font-family: "Stag Sans Light", Arial;
  font-size: 1.5384615385em;
  padding: 10px 15px 0 0;
}
#app #view-profile #user-profile #profile-information #profile-overview .profile-stats .column.surf-stats .stat .value span {
  color: #BEBEBE;
  font-size: 12px;
}
#app #view-profile #user-profile #profile-information #profile-overview .bottom {
  width: 940px;
  margin: 0 auto;
  padding: 60px;
}
@media (min-width: 0) and (max-width: 1550px) {
  #app #view-profile #user-profile #profile-information #profile-overview .bottom {
    width: 650px;
    padding-left: 20px;
    padding-right: 20px;
  }
}
#app #view-profile #user-profile #profile-information #profile-overview .bottom #profile-latest-surf {
  position: relative;
  background: #000;
  width: 940px;
  height: 395px;
  cursor: pointer;
}
@media (min-width: 0) and (max-width: 1550px) {
  #app #view-profile #user-profile #profile-information #profile-overview .bottom #profile-latest-surf {
    width: 650px;
  }
}
#app #view-profile #user-profile #profile-information #profile-overview .bottom #profile-latest-surf .surf-stats {
  position: absolute;
  background: rgba(0, 0, 0, 0.3);
  width: 934px;
  height: 60px;
  padding: 6px 0 0 6px;
  bottom: 0;
  left: 0;
  z-index: 1;
}
@media (min-width: 0) and (max-width: 1550px) {
  #app #view-profile #user-profile #profile-information #profile-overview .bottom #profile-latest-surf .surf-stats {
    width: 644px;
  }
}
#app #view-profile #user-profile #profile-information #profile-overview .bottom #profile-latest-surf .surf-stats .photo {
  width: 81px;
  height: 55px;
  float: left;
  margin-right: 20px;
}
#app #view-profile #user-profile #profile-information #profile-overview .bottom #profile-latest-surf .surf-stats .photo img {
  width: 81px;
  height: 55px;
}
#app #view-profile #user-profile #profile-information #profile-overview .bottom #profile-latest-surf .surf-stats .location {
  float: left;
  margin-right: 40px;
  padding-top: 11px;
}
#app #view-profile #user-profile #profile-information #profile-overview .bottom #profile-latest-surf .surf-stats .location .beach-name {
  background: url("../images/icon-location-pin-medium-white.png") no-repeat;
  height: 12px;
  font-family: "Stag Sans Light", Arial;
  font-size: 1.2307692308em;
  color: #FFF;
  padding: 4px 0 0 19px;
  margin-bottom: 6px;
}
@media (min--moz-device-pixel-ratio: 1.3), (-o-min-device-pixel-ratio: 2.6/2), (-webkit-min-device-pixel-ratio: 1.3), (min-device-pixel-ratio: 1.3), (min-resolution: 1.3dppx) {
  #app #view-profile #user-profile #profile-information #profile-overview .bottom #profile-latest-surf .surf-stats .location .beach-name {
    background-image: url("../images/icon-location-pin-medium-white@2x.png");
    background-size: 11px 16px;
  }
}
#app #view-profile #user-profile #profile-information #profile-overview .bottom #profile-latest-surf .surf-stats .location .date-time {
  font-size: 0.8461538462em;
  color: #B8BDBE;
}
#app #view-profile #user-profile #profile-information #profile-overview .bottom #profile-latest-surf .surf-stats .rating {
  float: left;
  margin: 27px 37px 0 0;
}
#app #view-profile #user-profile #profile-information #profile-overview .bottom #profile-latest-surf .surf-stats .top-speed {
  background: url("../images/icon-lightning-bolt-small.png") bottom 4px left no-repeat;
  float: left;
  margin-right: 40px;
  padding: 19px 0 0 14px;
}
@media (min--moz-device-pixel-ratio: 1.3), (-o-min-device-pixel-ratio: 2.6/2), (-webkit-min-device-pixel-ratio: 1.3), (min-device-pixel-ratio: 1.3), (min-resolution: 1.3dppx) {
  #app #view-profile #user-profile #profile-information #profile-overview .bottom #profile-latest-surf .surf-stats .top-speed {
    background-image: url("../images/icon-lightning-bolt-small@2x.png");
    background-size: 8px 13px;
  }
}
#app #view-profile #user-profile #profile-information #profile-overview .bottom #profile-latest-surf .surf-stats .top-speed .value {
  font-family: "Stag Sans Thin", Arial;
  font-size: 2em;
  color: #FFF;
}
#app #view-profile #user-profile #profile-information #profile-overview .bottom #profile-latest-surf .surf-stats .top-speed .unit {
  font-size: 0.8461538462em;
  color: #B8BDBE;
}
#app #view-profile #user-profile #profile-information #profile-overview .bottom #profile-latest-surf .surf-stats .total-waves {
  float: left;
  padding-top: 10px;
  text-align: center;
}
#app #view-profile #user-profile #profile-information #profile-overview .bottom #profile-latest-surf .surf-stats .total-waves .value {
  display: block;
  font-family: "Stag Sans Thin", Arial;
  font-size: 2.7692307692em;
  color: #FFF;
}
#app #view-profile #user-profile #profile-information #profile-overview .bottom #profile-latest-surf .surf-stats .total-waves .label {
  display: block;
  font-size: 0.8461538462em;
  color: #B8BDBE;
}
#app #view-profile #user-profile #profile-information #profile-overview .bottom #profile-latest-surf #profile-latest-surf-map {
  width: 940px;
  height: 395px;
}
@media (min-width: 0) and (max-width: 1550px) {
  #app #view-profile #user-profile #profile-information #profile-overview .bottom #profile-latest-surf #profile-latest-surf-map {
    width: 650px;
  }
}
#app #view-profile #user-profile #profile-information #profile-map {
  position: relative;
}
#app #view-profile #user-profile #profile-information #profile-map .zoom-controls {
  position: absolute;
  z-index: 1;
  top: 27px;
  left: 20px;
}
#app #view-profile #user-profile #profile-information #profile-map .customInfoWindow {
  background: aqua;
  border-left: #df1b00 4px;
  background: url("../images/bg-infoBox-border.png") no-repeat top left rgba(28, 29, 31, 0.8);
  height: 70px;
  border-radius: 3px;
}
#app #view-profile #user-profile #profile-information #profile-map .customInfoWindow a {
  display: block;
  text-decoration: none;
  height: 100%;
  width: 100%;
}
#app #view-profile #user-profile #profile-information #profile-map .customInfoWindow .main-content {
  color: #FFFFFF;
  font: 16px "Stag Sans Light", Arial;
  padding-top: 10px;
  padding-left: 20px;
  position: relative;
}
#app #view-profile #user-profile #profile-information #profile-map .customInfoWindow .main-content.individual {
  margin-top: -17px;
}
#app #view-profile #user-profile #profile-information #profile-map .customInfoWindow .main-content .surfs {
  color: #b3b3b3;
  font: 16px "Stag Sans Light", Arial;
}
#app #view-profile #user-profile #profile-information #profile-map .customInfoWindow .main-content .arrow {
  background: url("../images/bg-infoBox-arrow.png") no-repeat;
  position: absolute;
  height: 7px;
  width: 14px;
  display: block;
  top: 70px;
  right: 20px;
}
#app #view-profile #user-profile #profile-information #profile-boards {
  zoom: 1;
}
#app #view-profile #user-profile #profile-information #profile-boards:before, #app #view-profile #user-profile #profile-information #profile-boards:after {
  content: " ";
  display: block;
  height: 0;
  overflow: hidden;
}
#app #view-profile #user-profile #profile-information #profile-boards:after {
  clear: both;
}
#app #view-profile #user-profile #profile-information #profile-boards .boards {
  padding-top: 51px;
  padding-left: 80px;
  zoom: 1;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
}
#app #view-profile #user-profile #profile-information #profile-boards .boards:before, #app #view-profile #user-profile #profile-information #profile-boards .boards:after {
  content: " ";
  display: block;
  height: 0;
  overflow: hidden;
}
#app #view-profile #user-profile #profile-information #profile-boards .boards:after {
  clear: both;
}
#app #view-profile #user-profile #profile-information #profile-boards .boards .add {
  position: relative;
  font-family: "Stag Sans Thin", Arial;
  border: 1px dashed #36383A;
  width: 298px;
  height: 218px;
  float: left;
  margin: 10px;
  background: #1F2124 url("../images/icon-add-board.png") 124px 70px no-repeat;
  cursor: pointer;
}
@media (min--moz-device-pixel-ratio: 1.3), (-o-min-device-pixel-ratio: 2.6/2), (-webkit-min-device-pixel-ratio: 1.3), (min-device-pixel-ratio: 1.3), (min-resolution: 1.3dppx) {
  #app #view-profile #user-profile #profile-information #profile-boards .boards .add {
    background-image: url("../images/icon-add-board.png");
    background-size: 50px 50px;
  }
}
#app #view-profile #user-profile #profile-information #profile-boards .boards .add .label {
  position: absolute;
  top: 126px;
  left: 103px;
  font-family: "Stag Sans Thin", Arial;
  font-size: 18px;
  color: #949495;
}
#app #view-profile #user-profile #profile-information #profile-boards .boards .board {
  -webkit-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  width: 300px;
  height: 220px;
  font-family: "Stag Sans Thin", Arial;
  position: relative;
  margin: 10px;
  float: left;
  border-top: 2px solid transparent;
  overflow: hidden;
}
#app #view-profile #user-profile #profile-information #profile-boards .boards .board .board-edit {
  display: none;
}
#app #view-profile #user-profile #profile-information #profile-boards .boards .board .board-edit .select-group {
  height: 30px;
  margin-bottom: 5px;
  clear: both;
}
#app #view-profile #user-profile #profile-information #profile-boards .boards .board .board-edit .select-group label {
  margin-right: 10px;
}
#app #view-profile #user-profile #profile-information #profile-boards .boards .board .board-edit .select-group select {
  width: 93px !important;
  float: left !important;
  margin-bottom: 0 !important;
}
#app #view-profile #user-profile #profile-information #profile-boards .boards .board .board-edit .select-group select.right-select {
  float: right !important;
}
#app #view-profile #user-profile #profile-information #profile-boards .boards .board .inner {
  background-color: #36383C;
  width: 262px;
  height: 172px;
  color: #ffffff;
  font-size: 3.0769230769em;
  padding-left: 18px;
  padding-right: 20px;
}
#app #view-profile #user-profile #profile-information #profile-boards .boards .board .inner .title {
  display: block;
  line-height: 36px;
  padding-top: 38px;
  word-break: break-word;
}
#app #view-profile #user-profile #profile-information #profile-boards .boards .board .inner .dimensions {
  color: #a2a3a4;
  font-size: 0.4em;
  display: block;
  position: absolute;
  left: 19px;
  bottom: 65px;
}
#app #view-profile #user-profile #profile-information #profile-boards .boards .board .bottom {
  -webkit-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  background: #303236;
  width: 300px;
  height: 36px;
  padding-top: 12px;
  position: absolute;
  bottom: 0;
}
#app #view-profile #user-profile #profile-information #profile-boards .boards .board .bottom .waves {
  padding-left: 17px;
  display: inline-block;
}
#app #view-profile #user-profile #profile-information #profile-boards .boards .board .bottom .waves .value {
  font-size: 2em;
  color: #FFF;
}
#app #view-profile #user-profile #profile-information #profile-boards .boards .board .bottom .waves .label {
  font-family: Arial;
  font-size: 0.8461538462em;
  color: #BEBEBE;
}
#app #view-profile #user-profile #profile-information #profile-boards .boards .board .bottom .speed {
  display: inline-block;
  background: url("../images/icon-lightning-bolt-small.png") 27px 8px no-repeat;
  padding-left: 20px;
}
@media (min--moz-device-pixel-ratio: 1.3), (-o-min-device-pixel-ratio: 2.6/2), (-webkit-min-device-pixel-ratio: 1.3), (min-device-pixel-ratio: 1.3), (min-resolution: 1.3dppx) {
  #app #view-profile #user-profile #profile-information #profile-boards .boards .board .bottom .speed {
    background-image: url("../images/icon-lightning-bolt-small@2x.png");
    background-size: 8px 13px;
  }
}
#app #view-profile #user-profile #profile-information #profile-boards .boards .board .bottom .speed .value {
  color: #ffffff;
  font-size: 2em;
  padding-left: 17px;
}
#app #view-profile #user-profile #profile-information #profile-boards .boards .board .bottom .speed .label {
  color: #bebebe;
}
#app #view-profile #user-profile #profile-information #profile-boards .boards .board .bottom .speed .measurement {
  font-family: Arial;
  font-size: 0.8461538462em;
  color: #bebebe;
  padding-left: 2px;
}
#app #view-profile #user-profile #profile-information #profile-boards .boards .board .bottom .edit {
  display: inline-block;
  background: url("../images/icon-pencil.png") no-repeat;
  display: block;
  position: absolute;
  height: 48px;
  width: 48px;
  background-position: center;
  bottom: 0;
  right: 0;
}
@media (min--moz-device-pixel-ratio: 1.3), (-o-min-device-pixel-ratio: 2.6/2), (-webkit-min-device-pixel-ratio: 1.3), (min-device-pixel-ratio: 1.3), (min-resolution: 1.3dppx) {
  #app #view-profile #user-profile #profile-information #profile-boards .boards .board .bottom .edit {
    background-image: url("../images/icon-pencil@2x.png");
    background-size: 14px 14px;
  }
}
#app #view-profile #user-profile #profile-information #profile-boards .boards .board .bottom .edit:hover {
  background-color: #36383C;
}
#app #view-profile #user-profile #profile-information #profile-boards .boards .board:hover {
  border-color: #DF1B00;
}
#app #view-profile #user-profile #profile-information #profile-boards .boards .board:hover .inner {
  background: #404246;
}
#app #view-profile #user-profile #profile-information #profile-boards .boards .board:hover .bottom {
  background: #393B3F;
}
#app #view-profile #user-profile #profile-information #profile-boards .boards .board.edit, #app #view-profile #user-profile #profile-information #profile-boards .boards .board.new-board {
  border-color: #DF1B00;
}
#app #view-profile #user-profile #profile-information #profile-boards .boards .board.edit .inner, #app #view-profile #user-profile #profile-information #profile-boards .boards .board.new-board .inner {
  background: #404246;
  padding-top: 20px;
  height: 152px;
}
#app #view-profile #user-profile #profile-information #profile-boards .boards .board.edit .inner input[type=text],
#app #view-profile #user-profile #profile-information #profile-boards .boards .board.edit .inner select, #app #view-profile #user-profile #profile-information #profile-boards .boards .board.new-board .inner input[type=text],
#app #view-profile #user-profile #profile-information #profile-boards .boards .board.new-board .inner select {
  float: right;
  width: 190px;
  font-family: Arial;
  font-size: 0.35em;
  margin-bottom: 5px;
  height: 30px;
  color: #B8B8B8;
}
#app #view-profile #user-profile #profile-information #profile-boards .boards .board.edit .inner input[type=text], #app #view-profile #user-profile #profile-information #profile-boards .boards .board.new-board .inner input[type=text] {
  height: 16px;
  border: 0;
  padding: 7px 9px;
  width: 172px;
  border-radius: 3px;
  margin: 0 0 5px 0;
  height: 16px;
}
#app #view-profile #user-profile #profile-information #profile-boards .boards .board.edit .inner select, #app #view-profile #user-profile #profile-information #profile-boards .boards .board.new-board .inner select {
  text-indent: 9px;
}
#app #view-profile #user-profile #profile-information #profile-boards .boards .board.edit .inner label, #app #view-profile #user-profile #profile-information #profile-boards .boards .board.new-board .inner label {
  font-family: "Stag Sans Light", Arial;
  font-size: 0.275em;
  text-transform: uppercase;
  float: left;
  clear: right;
  text-align: right;
  width: 62px;
  padding-top: 11px;
}
#app #view-profile #user-profile #profile-information #profile-boards .boards .board.edit .bottom, #app #view-profile #user-profile #profile-information #profile-boards .boards .board.new-board .bottom {
  background: #393B3F;
  height: 39px;
  padding-top: 9px;
  padding-left: 20px;
  width: 280px;
}
#app #view-profile #user-profile #profile-information #profile-boards .boards .board.edit .bottom a, #app #view-profile #user-profile #profile-information #profile-boards .boards .board.new-board .bottom a {
  font-family: "Stag Sans Light", Arial;
  font-size: 1.0769230769em;
  width: 78px;
  height: 28px;
  border: 1px solid #595B5E;
  border-radius: 3px;
  display: block;
  color: #FFF;
  background-color: #2B2D30;
  text-decoration: none;
  text-align: center;
  line-height: 28px;
  float: left;
  margin-right: 11px;
}
#app #view-profile #user-profile #profile-information #profile-boards .boards .board.edit .bottom a.last, #app #view-profile #user-profile #profile-information #profile-boards .boards .board.new-board .bottom a.last {
  margin-right: 0;
}
#app #view-profile #user-profile #profile-information #profile-boards .boards .board.new-board .bottom {
  -webkit-transition: all 0s ease-in-out;
  -o-transition: all 0s ease-in-out;
  transition: all 0s ease-in-out;
  padding-right: 20px;
  width: 260px;
}
#app #view-profile #user-profile #profile-information #profile-boards .boards .board.new-board .bottom .delete {
  display: none;
}
#app #view-profile #user-profile #profile-information #profile-boards .boards .board.new-board .bottom .save {
  float: right;
}
#app #view-profile #edit-profile {
  display: none;
  height: 100%;
  margin-left: 80px;
}
#app #view-profile #edit-profile .header {
  width: 100%;
  height: 226px;
  position: relative;
}
#app #view-profile #edit-profile .header .top {
  background: rgba(35, 37, 40, 0.95);
  width: 100%;
  height: 40px;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
}
#app #view-profile #edit-profile .header .top h2 {
  color: #676A6C;
  font-family: "Stag Sans Light", Arial;
  font-size: 1.3846153846em;
  float: left;
  text-indent: 20px;
  padding-top: 12px;
}
#app #view-profile #edit-profile .header .top .buttons {
  float: right;
  padding-right: 42px;
}
#app #view-profile #edit-profile .header .top .buttons a {
  -webkit-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  display: inline-block;
  height: 40px;
  line-height: 40px;
  text-decoration: none;
  color: #FFF;
  font-family: "Stag Sans Light", Arial;
  font-size: 1.0769230769em;
  padding: 0 20px;
}
#app #view-profile #edit-profile .header .top .buttons a:hover {
  background: #36383C;
}
#app #view-profile #edit-profile .header #edit-cover-photo {
  width: 100%;
  height: 226px;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
}
#app #view-profile #edit-profile .header #edit-cover-photo img {
  width: 100%;
}
#app #view-profile #edit-profile .header .profile-photo {
  position: absolute;
  top: 121px;
  left: 69px;
  z-index: 2;
}
#app #view-profile #edit-profile .header .profile-photo #profile-photo-placeholder {
  width: 120px;
  height: 120px;
  border-radius: 100px;
  background-position: center;
  background-size: cover;
}
#app #view-profile #edit-profile .header .bottom {
  background: rgba(0, 0, 0, 0.4);
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 50px;
}
#app #view-profile #edit-profile .header .bottom .buttons {
  padding-left: 241px;
}
#app #view-profile #edit-profile .header .bottom .buttons span {
  -webkit-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  position: relative;
  display: block;
  padding: 0 10px;
  float: left;
  height: 50px;
  line-height: 52px;
  color: #FFF;
  text-decoration: none;
  font-family: "Stag Sans Light", Arial;
  font-size: 0.9230769231em;
  text-transform: uppercase;
  overflow: hidden;
  cursor: pointer;
}
#app #view-profile #edit-profile .header .bottom .buttons span:hover {
  background: rgba(255, 255, 255, 0.1);
}
#app #view-profile #edit-profile .header .bottom .buttons span .dz-preview {
  display: none;
}
#app #view-profile #edit-profile .edit-profile-form {
  width: 390px;
  margin-left: 150px;
  padding-top: 43px;
}
@media (min-width: 881px) and (max-width: 1550px) {
  #app #view-profile #edit-profile .edit-profile-form {
    padding-bottom: 100px;
  }
}
#app #view-profile #edit-profile .edit-profile-form p {
  margin-bottom: 10px;
  height: 40px;
}
#app #view-profile #edit-profile .edit-profile-form label {
  color: #BEBEBE;
  font-family: "Stag Sans Light", Arial;
  font-size: 0.9230769231em;
  padding-top: 16px;
  width: 82px;
  padding-right: 20px;
  text-align: right;
  text-transform: uppercase;
  float: left;
}
#app #view-profile #edit-profile .edit-profile-form input.text,
#app #view-profile #edit-profile .edit-profile-form select {
  background-color: #FFF;
  border-radius: 3px;
  height: 40px;
  color: #B8B8B8;
  border: none;
  outline: none;
}
#app #view-profile #edit-profile .edit-profile-form input.text {
  width: 265px;
  padding-left: 15px;
  -webkit-appearance: none;
}
#app #view-profile #edit-profile .edit-profile-form .small input.text {
  width: 55px;
}
#app #view-profile #edit-profile .edit-profile-form select {
  float: left;
  width: 282px;
}
#app #view-profile #edit-profile .edit-profile-form select.day {
  width: 80px;
  margin-right: 5px;
}
#app #view-profile #edit-profile .edit-profile-form select.month {
  width: 90px;
  margin-right: 5px;
}
#app #view-profile #edit-profile .edit-profile-form select.year {
  width: 100px;
}
#app #view-profile #edit-profile .edit-profile-form .buttons {
  height: 38px;
  padding-left: 101px;
}
#app #view-profile #edit-profile .edit-profile-form .buttons .btn {
  float: left;
  margin-right: 10px;
  width: 136px;
}
#app #view-profile #edit-profile .edit-profile-form .buttons .btn.save {
  margin-right: 0;
  -webkit-appearance: none;
}
#app #view-watch {
  margin-left: 80px;
}
#app #view-watch .scroll-pane {
  width: 100%;
  height: 100%;
}
#app #view-watch .card-one {
  background: url("../images/bg-watch-background.png") no-repeat;
  background-size: cover;
  height: 549px;
}
#app #view-watch .card-one .left {
  margin-left: 88px;
  width: 350px;
  float: left;
}
#app #view-watch .card-one .left .subscribe-button-inner {
  display: inline-block !important;
}
#app #view-watch .card-one .left .heading {
  font: 4.6153846154em "Stag Sans Thin", Arial;
  color: #2f2f2f;
  padding-top: 30px;
  margin-left: -2px;
  line-height: 55px;
  text-align: center;
}
#app #view-watch .card-one .left .description {
  font: 1.0769230769em "Stag Sans Light", Arial;
  color: #2f2f2f;
  padding-top: 11px;
  line-height: 22px;
}
#app #view-watch .card-one .left ul {
  margin: 20px 0;
}
#app #view-watch .card-one .left ul li {
  font: 1.0769230769em "Stag Sans Light", Arial;
  color: #2f2f2f;
  line-height: 22px;
  background: url("/images/welcome/li.png") 0% 50% no-repeat;
  padding-left: 15px;
}
#app #view-watch .card-one .left .cta {
  font: 1.5384615385em "Stag Sans Light", Arial;
  color: #df1b00;
  display: block;
  text-decoration: none;
  margin-top: 14px;
  padding-top: 3px;
  width: 150px;
  background: url("../images/icon-buy-watch-cta.png") no-repeat right 0 top 0;
}
@media (min--moz-device-pixel-ratio: 1.3), (-o-min-device-pixel-ratio: 2.6/2), (-webkit-min-device-pixel-ratio: 1.3), (min-device-pixel-ratio: 1.3), (min-resolution: 1.3dppx) {
  #app #view-watch .card-one .left .cta {
    background-image: url("../images/icon-buy-watch-cta@2x.png");
    background-size: 24px 24px;
  }
}
#app #view-watch .card-one .left .cta:hover {
  background: url("../images/icon-buy-watch-cta-hover.png") no-repeat right 0 top 0;
  text-decoration: underline;
}
@media (min--moz-device-pixel-ratio: 1.3), (-o-min-device-pixel-ratio: 2.6/2), (-webkit-min-device-pixel-ratio: 1.3), (min-device-pixel-ratio: 1.3), (min-resolution: 1.3dppx) {
  #app #view-watch .card-one .left .cta:hover {
    background-image: url("../images/icon-buy-watch-cta-hover@2x.png");
    background-size: 24px 24px;
  }
}
#app #view-watch .card-one .right {
  margin-left: 408px;
}
#app #view-watch .card-one .right .watches {
  position: relative;
}
#app #view-watch .card-one .right .watches .watch {
  background: url("/images/welcome/watches.png") no-repeat;
  width: 576px;
  height: 429px;
  position: absolute;
  top: 40px;
  left: 80px;
}
@media (min--moz-device-pixel-ratio: 1.3), (-o-min-device-pixel-ratio: 2.6/2), (-webkit-min-device-pixel-ratio: 1.3), (min-device-pixel-ratio: 1.3), (min-resolution: 1.3dppx) {
  #app #view-watch .card-one .right .watches .watch {
    background-image: url("../images/welcome/watches@2x.png");
    background-size: 576px 429px;
  }
}
#app #view-watch .card-one .right .watches .watch.white {
  background-position: 0px -428px;
}
#app #view-watch .card-one .right .watches .watch-small {
  width: 290px;
  height: 341px;
  top: 118px;
  left: 131px;
}
#app #view-watch .card-one .right .watches .watch-small.black {
  background: url("../images/buy-watch-small-black.png") no-repeat;
}
@media (min--moz-device-pixel-ratio: 1.3), (-o-min-device-pixel-ratio: 2.6/2), (-webkit-min-device-pixel-ratio: 1.3), (min-device-pixel-ratio: 1.3), (min-resolution: 1.3dppx) {
  #app #view-watch .card-one .right .watches .watch-small.black {
    background-image: url("../images/buy-watch-small-black.png");
    background-size: 290px 341px;
  }
}
#app #view-watch .card-one .right .watches .watch-small.white {
  background: url("../images/buy-watch-small-white.png") no-repeat;
}
@media (min--moz-device-pixel-ratio: 1.3), (-o-min-device-pixel-ratio: 2.6/2), (-webkit-min-device-pixel-ratio: 1.3), (min-device-pixel-ratio: 1.3), (min-resolution: 1.3dppx) {
  #app #view-watch .card-one .right .watches .watch-small.white {
    background-image: url("../images/buy-watch-small-white.png");
    background-size: 290px 341px;
  }
}
#app #view-watch .card-one .right .watches .watch-large {
  width: 377px;
  height: 381px;
  position: absolute;
  top: 100px;
  left: 245px;
}
#app #view-watch .card-one .right .watches .watch-large.black {
  background: url("../images/buy-watch-large-black.png") no-repeat;
}
@media (min--moz-device-pixel-ratio: 1.3), (-o-min-device-pixel-ratio: 2.6/2), (-webkit-min-device-pixel-ratio: 1.3), (min-device-pixel-ratio: 1.3), (min-resolution: 1.3dppx) {
  #app #view-watch .card-one .right .watches .watch-large.black {
    background-image: url("../images/buy-watch-large-black@2x.png");
    background-size: 377px 381px;
  }
}
#app #view-watch .card-one .right .watches .watch-large.white {
  background: url("../images/buy-watch-large-white.png") no-repeat;
}
@media (min--moz-device-pixel-ratio: 1.3), (-o-min-device-pixel-ratio: 2.6/2), (-webkit-min-device-pixel-ratio: 1.3), (min-device-pixel-ratio: 1.3), (min-resolution: 1.3dppx) {
  #app #view-watch .card-one .right .watches .watch-large.white {
    background-image: url("../images/buy-watch-large-white@2x.png");
    background-size: 377px 381px;
  }
}
#app #view-watch .card-one .right .watches .color-swatch {
  font: 0.9230769231em "Stag Sans Light", Arial;
  color: #2f2f2f;
  position: absolute;
  top: 434px;
  left: 291px;
}
#app #view-watch .card-one .right .watches .color-swatch ul {
  width: 97px;
}
#app #view-watch .card-one .right .watches .color-swatch ul li {
  display: inline-block;
  text-align: center;
  width: 47px;
  padding-top: 35px;
  cursor: pointer;
}
#app #view-watch .card-one .right .watches .color-swatch ul .white {
  background: url("../images/icon-color-swatch-white.png") no-repeat;
  background-position: 50% 2px;
}
@media (min--moz-device-pixel-ratio: 1.3), (-o-min-device-pixel-ratio: 2.6/2), (-webkit-min-device-pixel-ratio: 1.3), (min-device-pixel-ratio: 1.3), (min-resolution: 1.3dppx) {
  #app #view-watch .card-one .right .watches .color-swatch ul .white {
    background-image: url("../images/icon-color-swatch-white@2x.png");
    background-size: 28px 28px;
  }
}
#app #view-watch .card-one .right .watches .color-swatch ul .white:hover, #app #view-watch .card-one .right .watches .color-swatch ul .white.active {
  background: url("../images/icon-color-swatch-white.png") no-repeat;
  background-position: 50% 0px;
}
@media (min--moz-device-pixel-ratio: 1.3), (-o-min-device-pixel-ratio: 2.6/2), (-webkit-min-device-pixel-ratio: 1.3), (min-device-pixel-ratio: 1.3), (min-resolution: 1.3dppx) {
  #app #view-watch .card-one .right .watches .color-swatch ul .white:hover, #app #view-watch .card-one .right .watches .color-swatch ul .white.active {
    background-image: url("../images/icon-color-swatch-white@2x.png");
    background-size: 32px 32px;
  }
}
#app #view-watch .card-one .right .watches .color-swatch ul .black {
  background: url("../images/icon-color-swatch-black.png") no-repeat;
  background-position: 50% 2px;
}
@media (min--moz-device-pixel-ratio: 1.3), (-o-min-device-pixel-ratio: 2.6/2), (-webkit-min-device-pixel-ratio: 1.3), (min-device-pixel-ratio: 1.3), (min-resolution: 1.3dppx) {
  #app #view-watch .card-one .right .watches .color-swatch ul .black {
    background-image: url("../images/icon-color-swatch-black@2x.png");
    background-size: 28px 28px;
  }
}
#app #view-watch .card-one .right .watches .color-swatch ul .black:hover, #app #view-watch .card-one .right .watches .color-swatch ul .black.active {
  background: url("../images/icon-color-swatch-black-active.png") no-repeat;
  background-position: 50% 0px;
}
@media (min--moz-device-pixel-ratio: 1.3), (-o-min-device-pixel-ratio: 2.6/2), (-webkit-min-device-pixel-ratio: 1.3), (min-device-pixel-ratio: 1.3), (min-resolution: 1.3dppx) {
  #app #view-watch .card-one .right .watches .color-swatch ul .black:hover, #app #view-watch .card-one .right .watches .color-swatch ul .black.active {
    background-image: url("../images/icon-color-swatch-black-active@2x.png");
    background-size: 32px 32px;
  }
}
#app #view-watch .card-one .right .watches .color-swatch ul .black .text {
  padding-left: 1px;
}
#app #view-watch .card-two {
  height: 540px;
  position: relative;
  background-color: #2e3033;
}
#app #view-watch .card-two .left {
  float: left;
  width: 560px;
}
#app #view-watch .card-two .left .heading {
  font: 3.0769230769em "Stag Sans Thin", Arial;
  color: #ffffff;
  padding-top: 65px;
  padding-left: 210px;
}
#app #view-watch .card-two .left .sub-heading {
  font: 1.2307692308em "Stag Sans Light", Arial;
  color: #999999;
  padding-left: 155px;
  padding-top: 2px;
}
#app #view-watch .card-two .left .app-store {
  display: block;
  width: 127px;
  height: 36px;
  background: url("../images/icon-app-store-icon.png") no-repeat;
  margin-top: 24px;
  margin-left: 238px;
}
@media (min--moz-device-pixel-ratio: 1.3), (-o-min-device-pixel-ratio: 2.6/2), (-webkit-min-device-pixel-ratio: 1.3), (min-device-pixel-ratio: 1.3), (min-resolution: 1.3dppx) {
  #app #view-watch .card-two .left .app-store {
    background-image: url("../images/icon-app-store-icon@2x.png");
    background-size: 127px 36px;
  }
}
#app #view-watch .card-two .left .bluetooth-connection {
  width: 348px;
  height: 313px;
  background: url("../images/buy-watch-iphone.png") no-repeat;
  margin-left: 113px;
  margin-top: 20px;
}
@media (min--moz-device-pixel-ratio: 1.3), (-o-min-device-pixel-ratio: 2.6/2), (-webkit-min-device-pixel-ratio: 1.3), (min-device-pixel-ratio: 1.3), (min-resolution: 1.3dppx) {
  #app #view-watch .card-two .left .bluetooth-connection {
    background-image: url("../images/buy-watch-iphone@2x.png");
    background-size: 375px 313px;
  }
}
#app #view-watch .card-two .left:after {
  display: block;
  width: 1px;
  height: 30px;
  content: " ";
  background-color: #58595c;
  position: absolute;
  left: 559px;
  top: 80px;
  height: 418px;
}
#app #view-watch .card-two .right {
  float: left;
  width: 560px;
}
#app #view-watch .card-two .right .heading {
  font: 3.0769230769em "Stag Sans Thin", Arial;
  color: #ffffff;
  padding-top: 67px;
  padding-left: 147px;
}
#app #view-watch .card-two .right .sub-heading {
  font: 1.2307692308em "Stag Sans Light", Arial;
  color: #999999;
  padding-top: 2px;
  padding-left: 121px;
}
#app #view-watch .card-two .right .usb-connection {
  width: 349px;
  height: 179px;
  background: url("../images/usb-connection.png") no-repeat;
  margin-top: 46px;
  margin-left: 65px;
}
@media (min--moz-device-pixel-ratio: 1.3), (-o-min-device-pixel-ratio: 2.6/2), (-webkit-min-device-pixel-ratio: 1.3), (min-device-pixel-ratio: 1.3), (min-resolution: 1.3dppx) {
  #app #view-watch .card-two .right .usb-connection {
    background-image: url("../images/usb-connection@2x.png");
    background-size: 349px 179px;
  }
}
#app #view-watch .card-two .right a {
  color: #ffffff;
  border-bottom: 2px solid #486e91;
  border-radius: 3px;
  display: block;
  font: 1.0769230769em "Stag Sans Light", Arial;
  text-decoration: none;
  margin: 35px 0 0 114px;
  padding: 19px 0 14px 65px;
  width: 190px;
}
#app #view-watch .card-two .right a.app-download {
  background: #6093C2;
  text-align: center;
  padding-left: 0;
  width: 265px;
}
#app #view-watch .card-two .right a.mac-download {
  background: #6093C2 url("../images/icon-mac-logo.png") no-repeat 19px 8px;
}
@media (min--moz-device-pixel-ratio: 1.3), (-o-min-device-pixel-ratio: 2.6/2), (-webkit-min-device-pixel-ratio: 1.3), (min-device-pixel-ratio: 1.3), (min-resolution: 1.3dppx) {
  #app #view-watch .card-two .right a.mac-download {
    background-image: url("../images/icon-mac-logo@2x.png");
    background-size: 26px 32px;
  }
}
#app #view-watch .card-two .right a.windows-download {
  background: #3d86C8 url("../images/icon-windows-logo.png") no-repeat 17px 8px;
}
@media (min--moz-device-pixel-ratio: 1.3), (-o-min-device-pixel-ratio: 2.6/2), (-webkit-min-device-pixel-ratio: 1.3), (min-device-pixel-ratio: 1.3), (min-resolution: 1.3dppx) {
  #app #view-watch .card-two .right a.windows-download {
    background-image: url("../images/icon-windows-logo@2x.png");
    background-size: 32px 32px;
  }
}
#app #view-watch .card-three {
  background: #232528;
  min-height: 556px;
  width: 1120px;
  overflow: hidden;
}
#app #view-watch .card-three .left {
  padding-left: 91px;
  padding-top: 154px;
  width: 550px;
  float: left;
}
#app #view-watch .card-three .left .title {
  font: 1.5384615385em "Stag Sans Light", Arial;
  color: #ffffff;
  padding-bottom: 1px;
}
#app #view-watch .card-three .left li {
  font: 1.0769230769em "Stag Sans Light", Arial;
  color: #999999;
  background: url("../images/icon-red-bullet-point.png") no-repeat 1px 7px;
  padding-left: 12px;
  line-height: 20px;
}
@media (min--moz-device-pixel-ratio: 1.3), (-o-min-device-pixel-ratio: 2.6/2), (-webkit-min-device-pixel-ratio: 1.3), (min-device-pixel-ratio: 1.3), (min-resolution: 1.3dppx) {
  #app #view-watch .card-three .left li {
    background-image: url("../images/icon-red-bullet-point@2x.png");
    background-size: 5px 5px;
  }
}
#app #view-watch .card-three .left ul ul li {
  margin-left: 12px;
}
#app #view-watch .card-three .left .system-requirements-ios {
  background: url(../images/icon-ios-dark.png) no-repeat;
  padding-left: 51px;
  padding-top: 11px;
  padding-bottom: 11px;
}
@media (min--moz-device-pixel-ratio: 1.3), (-o-min-device-pixel-ratio: 2.6/2), (-webkit-min-device-pixel-ratio: 1.3), (min-device-pixel-ratio: 1.3), (min-resolution: 1.3dppx) {
  #app #view-watch .card-three .left .system-requirements-ios {
    background-image: url("../images/icon-ios-dark@2x.png");
    background-size: 37px 37px;
  }
}
#app #view-watch .card-three .left .system-requirements-mac {
  background: url(../images/icon-mac-dark.png) no-repeat;
  padding-top: 11px;
  padding-left: 51px;
  padding-bottom: 27px;
}
@media (min--moz-device-pixel-ratio: 1.3), (-o-min-device-pixel-ratio: 2.6/2), (-webkit-min-device-pixel-ratio: 1.3), (min-device-pixel-ratio: 1.3), (min-resolution: 1.3dppx) {
  #app #view-watch .card-three .left .system-requirements-mac {
    background-image: url("../images/icon-mac-dark@2x.png");
    background-size: 37px 37px;
  }
}
#app #view-watch .card-three .left .system-requirements-windows {
  background: url(../images/icon-windows-dark.png) no-repeat;
  padding-left: 51px;
  padding-top: 5px;
}
@media (min--moz-device-pixel-ratio: 1.3), (-o-min-device-pixel-ratio: 2.6/2), (-webkit-min-device-pixel-ratio: 1.3), (min-device-pixel-ratio: 1.3), (min-resolution: 1.3dppx) {
  #app #view-watch .card-three .left .system-requirements-windows {
    background-image: url("../images/icon-windows-dark@2x.png");
    background-size: 37px 37px;
  }
}
#app #view-watch .card-three .right {
  float: left;
}
#app #view-watch .card-three .right .heading {
  font: 3.8461538462em "Stag Sans Thin", Arial;
  color: #ffffff;
  padding-top: 165px;
  padding-left: 58px;
  padding-bottom: 26px;
}
#app #view-watch .card-three .right a {
  font: 1.0769230769em "Stag Sans Light", Arial;
  background-color: #1f2124;
  border: 1px solid #4f5153;
  border-radius: 3px;
  color: #ffffff;
  text-decoration: none;
  margin: 0 0 8px 63px;
  width: 191px;
  padding: 18px 0 16px 62px;
  display: block;
}
#app #view-watch .card-three .right a .thin {
  font: 1em "Stag Sans Thin", Arial;
}
#app #view-watch .card-three .right a.support-website {
  background: url("../images/icon-support.png") no-repeat 16px 9px;
}
@media (min--moz-device-pixel-ratio: 1.3), (-o-min-device-pixel-ratio: 2.6/2), (-webkit-min-device-pixel-ratio: 1.3), (min-device-pixel-ratio: 1.3), (min-resolution: 1.3dppx) {
  #app #view-watch .card-three .right a.support-website {
    background-image: url("../images/icon-support@2x.png");
    background-size: 30px 30px;
  }
}
#app #view-watch .card-three .right a.watch-manual {
  background: url("../images/icon-pdf.png") no-repeat 16px 9px;
}
@media (min--moz-device-pixel-ratio: 1.3), (-o-min-device-pixel-ratio: 2.6/2), (-webkit-min-device-pixel-ratio: 1.3), (min-device-pixel-ratio: 1.3), (min-resolution: 1.3dppx) {
  #app #view-watch .card-three .right a.watch-manual {
    background-image: url("../images/icon-pdf@2x.png");
    background-size: 30px 30px;
  }
}
#app #view-watch .download-box a {
  color: #ffffff;
  border-bottom: 2px solid #486e91;
  border-radius: 3px;
  display: block;
  font: 1.0769230769em "Stag Sans Light", Arial;
  text-decoration: none;
  margin: 35px 0 0 114px;
  padding: 19px 0 14px 65px;
  width: 190px;
}
#app #view-watch .download-box a.app-download {
  background: #6093C2;
  text-align: center;
  padding-left: 0;
  width: 265px;
}
#app #view-watch .download-box a.mac-download {
  background: #6093C2 url("../images/icon-mac-logo.png") no-repeat 19px 8px;
}
@media (min--moz-device-pixel-ratio: 1.3), (-o-min-device-pixel-ratio: 2.6/2), (-webkit-min-device-pixel-ratio: 1.3), (min-device-pixel-ratio: 1.3), (min-resolution: 1.3dppx) {
  #app #view-watch .download-box a.mac-download {
    background-image: url("../images/icon-mac-logo@2x.png");
    background-size: 26px 32px;
  }
}
#app #view-watch .download-box a.windows-download {
  background: #3d86C8 url("../images/icon-windows-logo.png") no-repeat 17px 8px;
}
@media (min--moz-device-pixel-ratio: 1.3), (-o-min-device-pixel-ratio: 2.6/2), (-webkit-min-device-pixel-ratio: 1.3), (min-device-pixel-ratio: 1.3), (min-resolution: 1.3dppx) {
  #app #view-watch .download-box a.windows-download {
    background-image: url("../images/icon-windows-logo@2x.png");
    background-size: 32px 32px;
  }
}
#app #view-settings {
  margin-left: 80px;
}
#app #view-settings .header .top {
  zoom: 1;
  height: 20px;
  padding: 150px 90px 0 90px;
}
#app #view-settings .header .top:before, #app #view-settings .header .top:after {
  content: " ";
  display: block;
  height: 0;
  overflow: hidden;
}
#app #view-settings .header .top:after {
  clear: both;
}
#app #view-settings .header .title {
  font-family: "Stag Sans Thin", Arial;
  font-size: 2.3076923077em;
  color: #FFF;
  float: left;
}
#app #view-settings .header .title .value {
  color: #BEBEBE;
}
#app #view-settings .header .buttons {
  float: right;
}
#app #view-settings .header .nav {
  background: #35373B;
  height: 50px;
  margin: 30px 0 49px 0;
}
#app #view-settings .header .nav ul {
  margin-left: 90px;
}
#app #view-settings .header .nav ul li {
  float: left;
}
#app #view-settings .header .nav ul li:first-child a {
  margin-left: 0;
}
#app #view-settings .header .nav ul li a {
  -webkit-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  display: block;
  color: #BEBEBE;
  border-bottom: 2px solid transparent;
  font-family: "Stag Sans Light", Arial;
  font-size: 1.0769230769em;
  text-decoration: none;
  line-height: 50px;
  margin: 0 20px;
  height: 48px;
}
#app #view-settings .header .nav ul li a:hover {
  color: #FFF;
  border-color: #DF1B00;
  height: 48px;
}
#app #view-settings .header .nav ul li.active a {
  color: #FFF;
  border-color: #DF1B00;
  height: 48px;
}
#app #view-settings .content {
  padding-left: 90px;
}
#app #view-settings .content .tab-section {
  display: none;
}
#app #view-settings .content .tab-account {
  display: block;
}
#app #view-settings .content .row {
  margin-bottom: 10px;
}
#app #view-settings .content .row.profile-privacy {
  margin-bottom: 20px;
}
#app #view-settings .content .row.profile-privacy, #app #view-settings .content .row.surf-privacy {
  height: 142px;
}
#app #view-settings .content .tab-about h2 {
  color: #FFF;
  font-family: "Stag Sans Thin", Arial;
  font-size: 24px;
  margin-bottom: 10px;
}
#app #view-settings .content .tab-about h2.weather {
  margin-top: 40px;
}
#app #view-settings .content .tab-about p {
  color: #BEBEBE;
  font-size: 14px;
  line-height: 17px;
  font-family: "Stag Sans Light", Arial;
  margin-bottom: 5px;
}
#app #view-settings .content .tab-about img {
  display: block;
  margin-bottom: 10px;
}
#app #view-settings .content .tab-about a {
  color: #FFF;
}
#app #view-settings .content .btn {
  width: 280px;
  margin-left: 159px;
}
#app #view-settings .content .btn.support {
  background: url("../images/icon-support-website.png") 18px 9px no-repeat;
  padding-left: 70px;
  width: 277px;
  height: 48px;
  line-height: 50px;
  text-align: left;
}
@media (min--moz-device-pixel-ratio: 1.3), (-o-min-device-pixel-ratio: 2.6/2), (-webkit-min-device-pixel-ratio: 1.3), (min-device-pixel-ratio: 1.3), (min-resolution: 1.3dppx) {
  #app #view-settings .content .btn.support {
    background-image: url("../images/icon-support-website@2x.png");
    background-size: 30px 30px;
  }
}
#app #view-settings .content .btn.manual {
  background: url("../images/icon-manual.png") 18px 9px no-repeat;
  padding-left: 70px;
  width: 277px;
  height: 48px;
  line-height: 50px;
  text-align: left;
}
@media (min--moz-device-pixel-ratio: 1.3), (-o-min-device-pixel-ratio: 2.6/2), (-webkit-min-device-pixel-ratio: 1.3), (min-device-pixel-ratio: 1.3), (min-resolution: 1.3dppx) {
  #app #view-settings .content .btn.manual {
    background-image: url("../images/icon-manual@2x.png");
    background-size: 30px 30px;
  }
}
#app #view-settings .content label {
  font-family: "Stag Sans Light", Arial;
  font-size: 0.9230769231em;
  text-transform: uppercase;
  color: #BEBEBE;
  float: left;
  text-align: right;
  padding: 14px 20px 0 0;
  width: 140px;
}
#app #view-settings .content .text {
  color: #B8B8B8;
  border-radius: 3px;
  border: none;
  width: 267px;
  height: 40px;
  padding-left: 13px;
  outline: none;
  cursor: text;
}
#app #view-settings .content .box {
  background: #36383C;
  width: 243px;
  height: 50px;
  padding: 20px 15px 0 22px;
  border-radius: 3px;
  float: left;
}
#app #view-settings .content .box.public, #app #view-settings .content .box.private {
  cursor: pointer;
}
#app #view-settings .content .box .title {
  display: block;
  color: #FFF;
  font-family: "Stag Sans Light", Arial;
  font-size: 1.0769230769em;
  margin-bottom: 5px;
}
#app #view-settings .content .box .name {
  color: #9B9B9D;
  font-family: "Stag Sans Light", Arial;
  font-size: 0.9230769231em;
}
#app #view-settings .content .box .switcher {
  float: right;
  cursor: pointer;
}
#app #view-settings .content .box .tick {
  width: 17px;
  height: 15px;
  float: right;
  margin-top: 10px;
}
#app #view-settings .content .box .tick.ticked {
  background: url("../images/icon-profile-tick.png") no-repeat;
}
@media (min--moz-device-pixel-ratio: 1.3), (-o-min-device-pixel-ratio: 2.6/2), (-webkit-min-device-pixel-ratio: 1.3), (min-device-pixel-ratio: 1.3), (min-resolution: 1.3dppx) {
  #app #view-settings .content .box .tick.ticked {
    background-image: url("../images/icon-profile-tick@2x.png");
    background-size: 17px 15px;
  }
}
#app #view-settings .content .boxes {
  float: left;
}
#app #view-settings .content .boxes .box {
  float: none;
  border-radius: 3px 3px 0 0;
  border-bottom: 1px solid #26272A;
}
#app #view-settings .content .boxes .box.last {
  border-radius: 0 0 3px 3px;
  border-bottom: none;
  border-top: 1px solid #4B4D51;
}
#app #view-settings .content select {
  width: 280px;
  height: 40px;
}
#app #view-settings .content .change-password-lightbox,
#app #view-settings .content .change-email-lightbox {
  z-index: 4;
}
#app #view-settings .content .change-password-lightbox .panel,
#app #view-settings .content .change-email-lightbox .panel {
  display: block;
}
#app #view-settings .content .change-password-lightbox .panel .form-text,
#app #view-settings .content .change-email-lightbox .panel .form-text {
  border-radius: 0;
}
#app #view-settings .content .change-password-lightbox .panel .form-text.first,
#app #view-settings .content .change-email-lightbox .panel .form-text.first {
  border-radius: 3px 3px 0 0;
}
#app #view-settings .content .change-password-lightbox .panel .form-text.last,
#app #view-settings .content .change-email-lightbox .panel .form-text.last {
  border-radius: 0 0 3px 3px;
}
#app #view-clubs {
  margin-left: 80px;
  position: relative;
  overflow-x: hidden;
}
#app #view-clubs.club-disabled {
  overflow: hidden;
  height: 100% !important;
}
#app #view-clubs.club-disabled #club {
  overflow: hidden;
  height: 100%;
}
#app #view-clubs .find-surfers {
  display: block;
  padding: 0 90px;
}
#app #view-clubs .find-surfers .no-results h2 {
  font-family: "Stag Sans Thin", Arial;
  font-size: 20px;
  color: #FFF;
  margin-bottom: 17px;
}
#app #view-clubs .find-surfers .no-results p {
  font-family: "Stag Sans Light", Arial;
  font-size: 16px;
  color: #A7A8A9;
  margin-bottom: 17px;
}
#app #view-clubs .find-surfers .no-results p a {
  color: #FFF;
  text-decoration: underline;
}
@media (min-width: 0) and (max-width: 1550px) {
  #app #view-clubs .find-surfers {
    padding: 0 40px;
  }
}
#app #view-clubs .find-surfers.first-time .follow-tabs {
  padding-top: 20px;
}
#app #view-clubs .find-surfers.first-time .go-to-club {
  float: right;
  margin-top: -26px;
}
#app #view-clubs .find-surfers .person {
  position: relative;
  height: 203px;
  margin-bottom: 20px;
}
#app #view-clubs .find-surfers .person .bottom {
  text-align: center;
}
#app #view-clubs .find-surfers .person .bottom .profile-info {
  float: none;
}
#app #view-clubs .find-surfers .person .bottom .profile-info .invite,
#app #view-clubs .find-surfers .person .bottom .profile-info .invited {
  position: absolute;
  bottom: 15px;
  left: 50%;
  border: 1px solid rgba(255, 255, 255, 0.2);
  border-radius: 3px;
  color: #FFF;
  display: inline-block;
  width: 30px;
  height: 20px;
  font-size: 0.8461538462em;
  line-height: 22px;
  text-transform: uppercase;
  text-decoration: none;
  text-align: center;
  padding: 0 15px;
  font-family: "Stag Sans Light", Arial;
  margin: 10px 0 0 -30px;
}
#app #view-clubs .find-surfers .person .bottom .profile-info .invited {
  width: 37px;
  margin-left: -35px;
  cursor: default;
}
#app #view-clubs .club-overlay {
  background: rgba(0, 0, 0, 0.5);
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 2;
  display: none;
}
#app #view-clubs #club-logbook {
  position: fixed;
  width: 320px;
  height: 100%;
  top: 0;
  right: -400px;
  background: #232528;
  box-shadow: 0 -20px 50px 5px #000;
  z-index: 3;
  outline: none;
}
#app #view-clubs #club-logbook .header a {
  background-image: url("../images/icon-close-arrow-r.png");
}
@media (min--moz-device-pixel-ratio: 1.3), (-o-min-device-pixel-ratio: 2.6/2), (-webkit-min-device-pixel-ratio: 1.3), (min-device-pixel-ratio: 1.3), (min-resolution: 1.3dppx) {
  #app #view-clubs #club-logbook .header a {
    background-image: url("../images/icon-close-arrow-r@2x.png");
    background-size: 7px 12px;
  }
}
#app #view-clubs .club-section {
  display: none;
}
#app #view-clubs .top-banner {
  height: 111px;
  background-color: #1c1d1f;
  width: 100%;
  margin-bottom: 39px;
}
#app #view-clubs .top-banner.no-breadcrumbs .heading {
  padding-top: 60px;
}
@media (min-width: 0) and (max-width: 1550px) {
  #app #view-clubs .top-banner.no-breadcrumbs .heading {
    padding-left: 40px;
  }
}
#app #view-clubs .top-banner.members-heading {
  padding-top: 36px;
  box-sizing: border-box;
}
#app #view-clubs .top-banner.members-heading .breadcrumbs {
  padding-top: 10px;
}
#app #view-clubs .top-banner .heading {
  font: 30px "Stag Sans Thin", Arial;
  color: #FFFFFF;
  display: block;
  padding-left: 86px;
}
@media (min-width: 0) and (max-width: 1550px) {
  #app #view-clubs .top-banner .heading {
    padding-left: 40px;
  }
}
#app #view-clubs .top-banner .heading .label {
  color: #BEBEBE;
  font-size: 14px;
  font-family: "Stag Sans Light", Arial;
}
#app #view-clubs .top-banner .breadcrumbs {
  padding: 18px 0 0 86px;
  margin-bottom: 30px;
  height: 12px;
}
@media (min-width: 0) and (max-width: 1550px) {
  #app #view-clubs .top-banner .breadcrumbs {
    padding-left: 0 40px;
  }
}
#app #view-clubs .top-banner .breadcrumbs li {
  background: url("../images/icon-breadcrumb.png") top right no-repeat;
  color: #FFF;
  font-family: "Stag Sans Thin", Arial;
  font-size: 0.9230769231em;
  text-transform: uppercase;
  float: left;
  padding-right: 15px;
  margin-right: 13px;
}
@media (min--moz-device-pixel-ratio: 1.3), (-o-min-device-pixel-ratio: 2.6/2), (-webkit-min-device-pixel-ratio: 1.3), (min-device-pixel-ratio: 1.3), (min-resolution: 1.3dppx) {
  #app #view-clubs .top-banner .breadcrumbs li {
    background-image: url("../images/icon-breadcrumb.png");
    background-size: 5px 9px;
  }
}
#app #view-clubs .top-banner .breadcrumbs li.current {
  background: none;
}
#app #view-clubs .top-banner .breadcrumbs li a {
  -webkit-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  color: #BEBEBE;
  text-decoration: none;
}
#app #view-clubs .top-banner .breadcrumbs li a:hover {
  color: #FFF;
  text-decoration: underline;
}
#app #view-clubs .members-list {
  margin-top: 16px;
  padding: 0 90px;
  zoom: 1;
}
#app #view-clubs .members-list:before, #app #view-clubs .members-list:after {
  content: " ";
  display: block;
  height: 0;
  overflow: hidden;
}
#app #view-clubs .members-list:after {
  clear: both;
}
@media (min-width: 0) and (max-width: 1550px) {
  #app #view-clubs .members-list {
    padding: 0 40px;
  }
}
#app #view-clubs .members-list .strip {
  border-bottom: 1px solid #DF1B00;
  margin-bottom: 40px;
  height: 55px;
}
#app #view-clubs .members-list .strip a {
  background: #1D1F21;
  float: right;
  padding: 0 14px 0 14px;
  width: auto;
  margin-left: 10px;
}
#app #view-clubs .members-list .strip a.invite-members {
  background: #1D1F21 url("../images/icon-btn-circle-plus.png") 15px 8px no-repeat;
  padding-left: 43px;
}
@media (min--moz-device-pixel-ratio: 1.3), (-o-min-device-pixel-ratio: 2.6/2), (-webkit-min-device-pixel-ratio: 1.3), (min-device-pixel-ratio: 1.3), (min-resolution: 1.3dppx) {
  #app #view-clubs .members-list .strip a.invite-members {
    background-image: url("../images/icon-btn-circle-plus.png");
    background-size: 22px 22px;
  }
}
#app #view-clubs .clubs {
  zoom: 1;
  padding: 0 86px;
  height: 100%;
}
#app #view-clubs .clubs:before, #app #view-clubs .clubs:after {
  content: " ";
  display: block;
  height: 0;
  overflow: hidden;
}
#app #view-clubs .clubs:after {
  clear: both;
}
@media (min-width: 0) and (max-width: 1550px) {
  #app #view-clubs .clubs {
    padding: 0 40px;
  }
}
#app #view-clubs .clubs.club-no-clubs {
  padding: 0;
}
#app #view-clubs .clubs.club-no-clubs .clubs-initial {
  background: url("../images/bg-club-cover-photo-default.jpg") no-repeat;
  background-size: cover;
  background-position: center;
  width: 100%;
  height: 100%;
  position: relative;
}
#app #view-clubs .clubs.club-no-clubs .clubs-initial .overlay {
  background: url("../images/bg-image-tile-overlay.png") repeat;
  position: absolute;
  z-index: 1;
  width: 100%;
  height: 100%;
}
#app #view-clubs .clubs.club-no-clubs .clubs-initial .inner {
  position: absolute;
  z-index: 2;
  padding: 110px 0 0 90px;
}
@media (min-width: 0) and (max-width: 1550px) {
  #app #view-clubs .clubs.club-no-clubs .clubs-initial .inner {
    padding-left: 40px;
  }
}
#app #view-clubs .clubs.club-no-clubs .clubs-initial .inner h1 {
  font-size: 5.3846153846em;
  font-family: "Stag Sans Thin", Arial;
  color: #000;
  margin-bottom: 29px;
}
#app #view-clubs .clubs.club-no-clubs .clubs-initial .inner ul {
  margin-bottom: 26px;
}
#app #view-clubs .clubs.club-no-clubs .clubs-initial .inner ul li {
  background: url("../images/icon-clubs-li.png") 0px 11px no-repeat;
  padding-left: 16px;
  font-family: "Stag Sans Light", Arial;
  color: #2F2F2F;
  font-size: 1.3846153846em;
  line-height: 30px;
}
@media (min--moz-device-pixel-ratio: 1.3), (-o-min-device-pixel-ratio: 2.6/2), (-webkit-min-device-pixel-ratio: 1.3), (min-device-pixel-ratio: 1.3), (min-resolution: 1.3dppx) {
  #app #view-clubs .clubs.club-no-clubs .clubs-initial .inner ul li {
    background-image: url("../images/icon-clubs-li@2x.png");
    background-size: 6px 6px;
  }
}
#app #view-clubs .clubs.club-no-clubs .clubs-initial .inner a {
  background: rgba(0, 0, 0, 0.4);
  border-radius: 3px;
  display: inline-block;
  color: #FFF;
  font-size: 1.2307692308em;
  font-family: "Stag Sans Light", Arial;
  height: 48px;
  line-height: 50px;
  padding: 0 30px;
  text-transform: uppercase;
  text-decoration: none;
}
#app #view-clubs .clubs .club,
#app #view-clubs .clubs .club-invite {
  background: #1F2124 url("../images/loader-club.gif") left 130px top 80px no-repeat;
  position: relative;
  width: 300px;
  height: 300px;
  float: left;
  margin-right: 20px;
  cursor: pointer;
  margin-bottom: 20px;
}
#app #view-clubs .clubs .club.add-club a,
#app #view-clubs .clubs .club-invite.add-club a {
  background: #1F2124 url("../images/icon-add-club.png") top 105px left 124px no-repeat;
  display: block;
  width: 296px;
  height: 134px;
  padding-top: 162px;
  border: 2px dashed #393B3E;
  color: #949495;
  font-size: 1.3846153846em;
  font-family: "Stag Sans Thin", Arial;
  text-align: center;
  text-decoration: none;
}
@media (min--moz-device-pixel-ratio: 1.3), (-o-min-device-pixel-ratio: 2.6/2), (-webkit-min-device-pixel-ratio: 1.3), (min-device-pixel-ratio: 1.3), (min-resolution: 1.3dppx) {
  #app #view-clubs .clubs .club.add-club a,
#app #view-clubs .clubs .club-invite.add-club a {
    background-image: url("../images/icon-add-club@2x.png");
    background-size: 50px 50px;
  }
}
#app #view-clubs .clubs .club .cover-photo,
#app #view-clubs .clubs .club-invite .cover-photo {
  position: absolute;
  width: 300px;
  height: 300px;
  background-size: cover;
  background-repeat: no-repeat;
}
#app #view-clubs .clubs .club .bottom,
#app #view-clubs .clubs .club-invite .bottom {
  position: absolute;
  bottom: 0;
  width: 300px;
}
#app #view-clubs .clubs .club .bottom .name,
#app #view-clubs .clubs .club .bottom .invite-box,
#app #view-clubs .clubs .club-invite .bottom .name,
#app #view-clubs .clubs .club-invite .bottom .invite-box {
  background: rgba(0, 0, 0, 0.7);
  padding: 18px 20px 16px 20px;
}
#app #view-clubs .clubs .club .bottom .name h2,
#app #view-clubs .clubs .club .bottom .invite-box h2,
#app #view-clubs .clubs .club-invite .bottom .name h2,
#app #view-clubs .clubs .club-invite .bottom .invite-box h2 {
  color: #FFF;
  font-family: "Stag Sans Light", Arial;
  font-size: 1.3846153846em;
  margin-bottom: 8px;
}
#app #view-clubs .clubs .club .bottom .name .tagline,
#app #view-clubs .clubs .club .bottom .invite-box .tagline,
#app #view-clubs .clubs .club-invite .bottom .name .tagline,
#app #view-clubs .clubs .club-invite .bottom .invite-box .tagline {
  color: #BEBEBE;
  font-family: "Stag Sans Light", Arial;
  font-size: 0.9230769231em;
}
#app #view-clubs .clubs .club .bottom .stats,
#app #view-clubs .clubs .club-invite .bottom .stats {
  height: 36px;
  background: #36383C;
  padding: 16px 20px 0;
}
#app #view-clubs .clubs .club .bottom .stats .num-waves,
#app #view-clubs .clubs .club-invite .bottom .stats .num-waves {
  float: left;
}
#app #view-clubs .clubs .club .bottom .stats .num-members,
#app #view-clubs .clubs .club-invite .bottom .stats .num-members {
  float: right;
}
#app #view-clubs .clubs .club .bottom .stats .num-members a,
#app #view-clubs .clubs .club-invite .bottom .stats .num-members a {
  display: block;
  text-decoration: none;
}
#app #view-clubs .clubs .club .bottom .stats span,
#app #view-clubs .clubs .club-invite .bottom .stats span {
  display: inline-block;
}
#app #view-clubs .clubs .club .bottom .stats .value,
#app #view-clubs .clubs .club-invite .bottom .stats .value {
  font-size: 2.3076923077em;
  color: #FFF;
  font-family: "Stag Sans Thin", Arial;
  margin-right: 4px;
}
#app #view-clubs .clubs .club .bottom .stats .label,
#app #view-clubs .clubs .club-invite .bottom .stats .label {
  font-size: 0.9230769231em;
  font-family: "Stag Sans Light", Arial;
  color: #A9A9A9;
}
#app #view-clubs .clubs .club-invite .bottom .name {
  display: none;
}
#app #view-clubs .clubs .club-invite .bottom .invite-box {
  height: 56px;
  padding: 8px 15px 0 10px;
}
#app #view-clubs .clubs .club-invite .bottom .invite-box img {
  width: 44px;
  height: 44px;
  border-radius: 100px;
  border: 2px solid #424547;
  float: left;
  margin-right: 8px;
}
#app #view-clubs .clubs .club-invite .bottom .invite-box .invite-info {
  float: left;
  padding-top: 11px;
}
#app #view-clubs .clubs .club-invite .bottom .invite-box .invite-info h2 {
  width: 160px;
}
#app #view-clubs .clubs .club-invite .bottom .invite-box .invite-info p {
  color: #BEBEBE;
  font-size: 0.8461538462em;
  margin-bottom: 4px;
}
#app #view-clubs .clubs .club-invite .bottom .invite-box .buttons {
  float: right;
  margin-top: 13px;
}
#app #view-clubs .clubs .club-invite .bottom .invite-box .buttons a {
  display: block;
  width: 22px;
  height: 22px;
  text-indent: -9999px;
  float: left;
  margin-right: 7px;
}
#app #view-clubs .clubs .club-invite .bottom .invite-box .buttons .decline {
  background: url("../images/icon-decline-invite.png") no-repeat;
}
@media (min--moz-device-pixel-ratio: 1.3), (-o-min-device-pixel-ratio: 2.6/2), (-webkit-min-device-pixel-ratio: 1.3), (min-device-pixel-ratio: 1.3), (min-resolution: 1.3dppx) {
  #app #view-clubs .clubs .club-invite .bottom .invite-box .buttons .decline {
    background-image: url("../images/icon-decline-invite@2x.png");
    background-size: 22px 22px;
  }
}
#app #view-clubs .clubs .club-invite .bottom .invite-box .buttons .accept {
  background: url("../images/icon-accept-invite.png") no-repeat;
  margin-right: 0;
}
@media (min--moz-device-pixel-ratio: 1.3), (-o-min-device-pixel-ratio: 2.6/2), (-webkit-min-device-pixel-ratio: 1.3), (min-device-pixel-ratio: 1.3), (min-resolution: 1.3dppx) {
  #app #view-clubs .clubs .club-invite .bottom .invite-box .buttons .accept {
    background-image: url("../images/icon-accept-invite@2x.png");
    background-size: 22px 22px;
  }
}
#app #view-clubs .create-club-wrapper {
  width: 609px;
  margin: 0 auto;
}
#app #view-clubs .create-club-wrapper .create-club,
#app #view-clubs .create-club-wrapper .invite-members {
  -webkit-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  font: 14px "Stag Sans Light", Arial;
  color: #FFFFFF;
  background-color: #1F2124;
  border: 1px solid #4F5153;
  border-radius: 3px;
  margin: 0;
  padding-top: 18px;
  padding-bottom: 15px;
  width: 100%;
  outline: none;
  cursor: pointer;
}
#app #view-clubs .create-club-wrapper .create-club:hover,
#app #view-clubs .create-club-wrapper .invite-members:hover {
  background-color: #6297C8;
  border-color: #6297C8;
}
#app #view-clubs .create-club-wrapper .step-2 {
  display: none;
  width: 300px;
  margin: 0 auto;
}
#app #view-clubs .create-club-wrapper .step-2 .drag-and-drop {
  border: 1px dashed #393b3e;
  height: 134px;
  background: #1c1e20 url("../images/icon-upload.png") no-repeat top 106px left 118px;
  text-align: center;
  padding-top: 156px;
  position: relative;
  margin-bottom: 10px;
}
@media (min--moz-device-pixel-ratio: 1.3), (-o-min-device-pixel-ratio: 2.6/2), (-webkit-min-device-pixel-ratio: 1.3), (min-device-pixel-ratio: 1.3), (min-resolution: 1.3dppx) {
  #app #view-clubs .create-club-wrapper .step-2 .drag-and-drop {
    background-image: url("../images/icon-upload@2x.png");
    background-size: 55px 39px;
  }
}
#app #view-clubs .create-club-wrapper .step-2 .drag-and-drop .text {
  font: 16px "Stag Sans Light", Arial;
  color: #FFFFFF;
  display: block;
}
#app #view-clubs .create-club-wrapper .step-2 .drag-and-drop .red {
  font-size: 14px;
  color: #df1b00;
  display: block;
}
#app #view-clubs .create-club-wrapper .step-2 .drag-and-drop .overlay-upload {
  position: absolute;
  top: 0;
  left: 0;
  height: 298px;
  width: 290px;
  cursor: pointer;
  z-index: 2;
  opacity: 0;
  background: #000;
}
#app #view-clubs .create-club-wrapper .step-2 .drag-and-drop .overlay-upload .dz-progress, #app #view-clubs .create-club-wrapper .step-2 .drag-and-drop .overlay-upload .dz-success-mark, #app #view-clubs .create-club-wrapper .step-2 .drag-and-drop .overlay-upload .dz-error-mark, #app #view-clubs .create-club-wrapper .step-2 .drag-and-drop .overlay-upload .dz-error-message, #app #view-clubs .create-club-wrapper .step-2 .drag-and-drop .overlay-upload .dz-details .dz-filename, #app #view-clubs .create-club-wrapper .step-2 .drag-and-drop .overlay-upload .dz-details .dz-size {
  display: none;
}
#app #view-clubs .create-club-wrapper .step-2 .drag-and-drop .overlay-upload .dz-image-preview {
  height: 100%;
  width: 100%;
}
#app #view-clubs .create-club-wrapper .step-2 .drag-and-drop .overlay-upload .dz-image-preview .dz-details {
  height: 100%;
  width: 100%;
}
#app #view-clubs .create-club-wrapper .step-2 .drag-and-drop .overlay-upload .dz-image-preview .dz-details img {
  width: 298px;
  height: 290px;
}
#app #view-clubs .create-club-wrapper .step-1 {
  width: 279px;
  margin: 0 auto;
}
#app #view-clubs .create-club-wrapper .step-1 .add-name {
  border: 0;
  margin: 0;
  padding: 0;
  width: 100%;
  border-radius: 3px 3px 0 0;
  font: 14px Arial;
  padding: 15px 14px 9px;
  box-sizing: border-box;
  border-bottom: 1px solid #f1f1f1;
  outline: none;
}
#app #view-clubs .create-club-wrapper .step-1 .add-description {
  border: 0;
  margin: 0;
  padding: 0;
  border-radius: 0 0 3px 3px;
  font: 14px Arial;
  padding: 12px 14px;
  box-sizing: border-box;
  height: 141px;
  max-height: 141px;
  max-width: 279px;
  width: 279px;
  margin-bottom: 20px;
  outline: none;
}
#app #view-clubs .create-club-wrapper .step-1 .ongoing-wrapper {
  float: left;
  margin-right: 10px;
}
#app #view-clubs .create-club-wrapper .step-1 .ongoing-wrapper .ongoing-label {
  font: 14px "Stag Sans Light", Arial;
  text-transform: uppercase;
  color: #999999;
  padding-top: 4px;
}
#app #view-clubs .create-club-wrapper .step-1 .time-based-wrapper {
  float: left;
  padding-left: 29px;
  margin-bottom: 19px;
}
#app #view-clubs .create-club-wrapper .step-1 .time-based-wrapper .time-based-label {
  font: 14px "Stag Sans Light", Arial;
  text-transform: uppercase;
  color: #999999;
  padding-top: 4px;
}
#app #view-clubs .create-club-wrapper .step-1 .time-based-date-pickers {
  float: left;
  width: 100%;
  margin-top: 5px;
  margin-bottom: 10px;
  display: none;
}
#app #view-clubs .create-club-wrapper .step-1 .time-based-date-pickers .start {
  float: left;
  width: 136px;
  margin-right: 8px;
}
#app #view-clubs .create-club-wrapper .step-1 .time-based-date-pickers .end {
  margin-left: 146px;
}
#app #view-clubs .create-club-wrapper .step-1 .time-based-date-pickers .end .title {
  padding-left: 1px;
}
#app #view-clubs .create-club-wrapper .step-1 .time-based-date-pickers .title {
  font: 12px "Stag Sans Light", Arial;
  text-transform: uppercase;
  color: #999999;
  display: block;
  padding-bottom: 12px;
}
#app #view-clubs .create-club-wrapper .step-1 .time-based-date-pickers .datepicker {
  background: #FFF;
  border: none;
  border-radius: 3px;
  color: #B8B8B8;
  outline: none;
  margin: 0;
  width: 105px;
  padding: 13px 14px;
}
#app #view-clubs #club {
  padding-bottom: 70px;
}
#app #view-clubs #club .top-banner {
  position: relative;
  height: 540px;
  margin-bottom: 44px;
}
#app #view-clubs #club .top-banner .breadcrumbs {
  padding: 0 0 0 90px;
}
#app #view-clubs #club .top-banner .breadcrumbs .leaderboard {
  display: none;
}
#app #view-clubs #club .top-banner .cover-photo {
  position: absolute;
  background-size: cover;
  background-position: left center;
  width: 100%;
  height: 540px;
  box-sizing: border-box;
  padding: 20px 90px 0 0;
}
@media (min-width: 0) and (max-width: 1550px) {
  #app #view-clubs #club .top-banner .cover-photo {
    padding-right: 40px;
  }
}
#app #view-clubs #club .top-banner .club-info {
  background: rgba(0, 0, 0, 0.8);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 140px;
  box-sizing: border-box;
  padding: 20px 0 0 0;
}
@media (min-width: 0) and (max-width: 1550px) {
  #app #view-clubs #club .top-banner .club-info {
    padding: 20px 40px 0 40px;
  }
}
#app #view-clubs #club .top-banner .club-info .members {
  float: right;
  padding-top: 10px;
  padding-right: 90px;
}
#app #view-clubs #club .top-banner .club-info .members a {
  text-decoration: none;
}
#app #view-clubs #club .top-banner .club-info .members .value {
  font-size: 2.6153846154em;
  font-family: "Stag Sans Thin", Arial;
  color: #FFF;
}
#app #view-clubs #club .top-banner .club-info .members .label {
  color: #BEBEBE;
  font-size: 1.0769230769em;
  font-family: "Stag Sans Light", Arial;
}
#app #view-clubs #club .top-banner .club-info h1 {
  font-size: 2.6153846154em;
  font-family: "Stag Sans Thin", Arial;
  color: #FFF;
  margin-bottom: 3px;
  padding-left: 90px;
}
#app #view-clubs #club .top-banner .club-info .tagline {
  color: #FFF;
  font-family: "Stag Sans Light", Arial;
  font-size: 0.9230769231em;
  padding-left: 90px;
}
#app #view-clubs #club .club-stats {
  padding: 0 90px;
}
@media (min-width: 0) and (max-width: 1550px) {
  #app #view-clubs #club .club-stats {
    padding: 0 40px;
  }
}
#app #view-clubs #club .top {
  height: 34px;
  margin-bottom: 27px;
  padding: 0 90px;
}
@media (min-width: 0) and (max-width: 1550px) {
  #app #view-clubs #club .top {
    padding: 0 40px;
  }
}
#app #view-clubs #club .top .num-surfs,
#app #view-clubs #club .top .total-waves {
  float: left;
  margin-right: 68px;
}
#app #view-clubs #club .top .num-surfs a,
#app #view-clubs #club .top .total-waves a {
  text-decoration: none;
}
#app #view-clubs #club .top .num-surfs .value,
#app #view-clubs #club .top .total-waves .value {
  font-size: 2.6153846154em;
  font-family: "Stag Sans Thin", Arial;
  color: #FFF;
}
#app #view-clubs #club .top .num-surfs .label,
#app #view-clubs #club .top .total-waves .label {
  color: #BEBEBE;
  font-size: 1.0769230769em;
  font-family: "Stag Sans Light", Arial;
}
#app #view-clubs #club .tabs {
  height: 48px;
  border-bottom: 1px solid #DF1B00;
  margin-bottom: 36px;
}
#app #view-clubs #club .tabs ul {
  zoom: 1;
}
#app #view-clubs #club .tabs ul:before, #app #view-clubs #club .tabs ul:after {
  content: " ";
  display: block;
  height: 0;
  overflow: hidden;
}
#app #view-clubs #club .tabs ul:after {
  clear: both;
}
#app #view-clubs #club .tabs ul li {
  list-style: none;
  float: left;
  height: 48px;
  margin-right: 28px;
}
#app #view-clubs #club .tabs ul li.active a {
  background: url("../images/icon-red-arrow-up.png") bottom center no-repeat;
  color: #FFF;
}
@media (min--moz-device-pixel-ratio: 1.3), (-o-min-device-pixel-ratio: 2.6/2), (-webkit-min-device-pixel-ratio: 1.3), (min-device-pixel-ratio: 1.3), (min-resolution: 1.3dppx) {
  #app #view-clubs #club .tabs ul li.active a {
    background-image: url("../images/icon-red-arrow-up@2x.png");
    background-size: 16px 8px;
  }
}
#app #view-clubs #club .tabs ul li a {
  font-family: "Stag Sans Light", Arial;
  display: block;
  height: 29px;
  padding-top: 19px;
  font-size: 1.0769230769em;
  color: #BEBEBE;
  text-decoration: none;
  text-align: center;
}
#app #view-clubs #club .tabs ul li a:hover {
  color: #FFF;
}
#app #view-clubs #club .tab-section {
  display: none;
}
#app #view-clubs #club .tab-section .leaderboard .header {
  zoom: 1;
}
#app #view-clubs #club .tab-section .leaderboard .header:before, #app #view-clubs #club .tab-section .leaderboard .header:after {
  content: " ";
  display: block;
  height: 0;
  overflow: hidden;
}
#app #view-clubs #club .tab-section .leaderboard .header:after {
  clear: both;
}
#app #view-clubs #club .tab-section .leaderboard .header .lb-max-stat {
  float: left;
  margin-bottom: 30px;
}
#app #view-clubs #club .tab-section .leaderboard .header .lb-max-stat span {
  display: block;
}
#app #view-clubs #club .tab-section .leaderboard .header .lb-max-stat span.value {
  font-family: "Stag Sans Thin", Arial;
  font-size: 4.3076923077em;
  color: #FFF;
}
#app #view-clubs #club .tab-section .leaderboard .header .lb-max-stat span.label {
  font-family: "Stag Sans Light", Arial;
  font-size: 1.0769230769em;
  color: #BEBEBE;
}
#app #view-clubs #club .tab-section .leaderboard .header .your-ranking {
  float: right;
  margin-bottom: 30px;
}
#app #view-clubs #club .tab-section .leaderboard .header .your-ranking a {
  text-decoration: none;
}
#app #view-clubs #club .tab-section .leaderboard .header .your-ranking span.value {
  display: block;
  font-family: "Stag Sans Thin", Arial;
  color: #FFF;
  text-align: right;
}
#app #view-clubs #club .tab-section .leaderboard .header .your-ranking span.your-position {
  font-size: 4.3076923077em;
}
#app #view-clubs #club .tab-section .leaderboard .header .your-ranking span.total-people {
  font-size: 1.0769230769em;
}
#app #view-clubs #club .tab-section .leaderboard .header .your-ranking span.label {
  text-align: right;
  display: block;
  font-family: "Stag Sans Light", Arial;
  font-size: 1.0769230769em;
  color: #BEBEBE;
}
#app #view-clubs #club .tab-section .leaderboard ol {
  width: 100%;
}
#app #view-clubs #club .tab-section .leaderboard ol li {
  position: relative;
  width: 100%;
  height: 60px;
  background: #303236;
  margin-bottom: 1px;
  box-sizing: border-box;
  padding-right: 21px;
  position: relative;
}
#app #view-clubs #club .tab-section .leaderboard ol li.is-link {
  cursor: pointer;
}
#app #view-clubs #club .tab-section .leaderboard ol li .hover {
  position: absolute;
  background: #DF1B00;
  width: 5px;
  height: 60px;
  opacity: 0;
  top: 0;
  left: 0;
}
#app #view-clubs #club .tab-section .leaderboard ol li:hover {
  background: #36383C;
}
#app #view-clubs #club .tab-section .leaderboard ol li:hover .hover {
  opacity: 1;
}
#app #view-clubs #club .tab-section .leaderboard ol li .profile-image {
  float: left;
  margin-left: 21px;
  margin-top: 6px;
  margin-bottom: 4px;
}
#app #view-clubs #club .tab-section .leaderboard ol li .profile-image img {
  height: 44px;
  width: 44px;
  border-radius: 48px;
  border: 2px solid #45474A;
}
#app #view-clubs #club .tab-section .leaderboard ol li.me {
  background: #6297C8;
}
#app #view-clubs #club .tab-section .leaderboard ol li.me:hover {
  background: #6297C8;
}
#app #view-clubs #club .tab-section .leaderboard ol li.me .hover {
  background: #3D86C8;
}
#app #view-clubs #club .tab-section .leaderboard ol li.me .name {
  color: #FFF;
}
#app #view-clubs #club .tab-section .leaderboard ol li.me .date {
  color: #FFF;
}
#app #view-clubs #club .tab-section .leaderboard ol li .rank {
  float: left;
  font: 18px "Stag Sans Light", Arial;
  margin-top: 23px;
  margin-left: 27px;
  color: #FFF;
  width: 26px;
}
#app #view-clubs #club .tab-section .leaderboard ol li .name {
  float: left;
  font: 18px "Stag Sans Light", Arial;
  color: #BEBEBE;
  margin-top: 23px;
  margin-left: 19px;
  width: 248px;
}
@media (min-width: 0) and (max-width: 1550px) {
  #app #view-clubs #club .tab-section .leaderboard ol li .name {
    width: 20%;
    margin-right: 5%;
  }
}
@media (min-width: 0) and (max-width: 880px) {
  #app #view-clubs #club .tab-section .leaderboard ol li .name {
    width: 30%;
  }
}
#app #view-clubs #club .tab-section .leaderboard ol li .distance {
  float: left;
  font: 18px "Stag Sans Light", Arial;
  color: #FFF;
  margin-top: 23px;
}
#app #view-clubs #club .tab-section .leaderboard ol li .distance .unit {
  font: 12px "Stag Sans Light", Arial;
  color: #bebebe;
}
#app #view-clubs #club .tab-section .leaderboard ol li .date {
  float: right;
  font: 18px "Stag Sans Light", Arial;
  color: #BEBEBE;
  margin-top: 23px;
}
#app #view-clubs #club .t-28-days {
  display: block;
}
#app #view-clubs #club .stats {
  zoom: 1;
}
#app #view-clubs #club .stats:before, #app #view-clubs #club .stats:after {
  content: " ";
  display: block;
  height: 0;
  overflow: hidden;
}
#app #view-clubs #club .stats:after {
  clear: both;
}
#app #view-clubs #club .stats .stat {
  position: relative;
  background: #36383C;
  width: 300px;
  height: 155px;
  float: left;
  margin: 0 20px 20px 0;
  cursor: pointer;
}
@media (min-width: 0px) and (max-width: 768px) {
  #app #view-clubs #club .stats .stat {
    width: 100%;
  }
}
@media (min-width: 768px) and (max-width: 1023px) {
  #app #view-clubs #club .stats .stat {
    width: 293px;
  }
  #app #view-clubs #club .stats .stat:nth-child(+2n) {
    float: right;
    margin-right: 0;
  }
}
@media (min-width: 1023px) and (max-width: 1024px) {
  #app #view-clubs #club .stats .stat {
    width: 270px;
  }
  #app #view-clubs #club .stats .stat:nth-child(+3n) {
    margin-right: 0;
  }
}
#app #view-clubs #club .stats .stat .value {
  font-size: 4.3076923077em;
  font-family: "Stag Sans Thin", Arial;
  color: #FFF;
  display: block;
  padding: 18px 0 0 19px;
}
#app #view-clubs #club .stats .stat .label {
  font-size: 1.0769230769em;
  font-family: "Stag Sans Light", Arial;
  color: #BEBEBE;
  padding-left: 21px;
}
#app #view-clubs #club .stats .stat .user {
  position: absolute;
  bottom: 0;
  background: #303236;
  width: 100%;
  height: 40px;
}
#app #view-clubs #club .stats .stat .user .profile-photo {
  width: 48px;
  height: 48px;
  margin: -16px 12px 0 16px;
  float: left;
}
#app #view-clubs #club .stats .stat .user .profile-photo img {
  width: 44px;
  height: 44px;
  border-radius: 100px;
  border: 2px solid #45474A;
}
#app #view-clubs #club .stats .stat .user h2 {
  font-size: 1.0769230769em;
  font-family: "Stag Sans Light", Arial;
  padding-top: 15px;
  float: left;
}
#app #view-clubs #club .stats .stat .user h2 a {
  color: #FFF;
  text-decoration: none;
}
#app #view-clubs #club .stats .stat .user p {
  float: right;
  padding-top: 15px;
  color: #BEBEBE;
  font-size: 1.0769230769em;
  font-family: "Stag Sans Light", Arial;
  padding-right: 14px;
}

.ie9-upload {
  display: none;
  margin-bottom: 10px;
}
.ie9-upload label {
  font-weight: bold;
  color: #FFF;
}
.ie9-upload label input {
  margin-top: 5px;
}
.ie9-upload .preview {
  width: 300px;
  height: 300px;
}

/*
    Browser fixes
*/
.ie10 ::-ms-clear {
  display: none;
}
.ie10 a:active {
  background-color: transparent;
}
.ie10 #welcome #page-selector ul li.active {
  position: relative;
  left: -80px;
}
.ie10 #app #view-profile select,
.ie10 #app #panel-edit-surf select {
  background-image: none !important;
  padding-left: 15px !important;
  -ms-appearance: none;
}

#edit-profile .ie9-upload-cover,
#edit-profile .ie9-upload-photo {
  display: none;
}

.ie9 .ios-switch.on .handle {
  left: 33px;
}
.ie9 #app #view-profile #edit-profile .header .bottom .buttons span {
  width: 100px;
  text-align: center;
}
.ie9 #edit-profile #edit-cover-photo .buttons {
  display: none;
}
.ie9 #edit-profile .ie9-upload-cover,
.ie9 #edit-profile .ie9-upload-photo {
  display: block;
}
.ie9 #app #view-surf #right-sidebar #panel-edit-surf .drag-drop .dropzone {
  display: none;
}
.ie9 #app #view-surf #right-sidebar #panel-edit-surf .drag-drop .ie9-upload {
  display: block;
  height: 90px;
}

.ie9 body,
.ie10 body {
  overflow-x: hidden;
}
.ie9 #app.page-surf,
.ie10 #app.page-surf {
  overflow: hidden;
}

body.loggedout #site-menu nav {
  display: none;
}

@keyframes loading {
  0% {
    -moz-transform: rotate(0deg);
  }
  100% {
    -moz-transform: rotate(360deg);
  }
}
@-moz-keyframes loading {
  0% {
    -moz-transform: rotate(0deg);
  }
  100% {
    -moz-transform: rotate(360deg);
  }
}
@-webkit-keyframes loading {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}