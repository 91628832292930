/****************************

 ____                    ____                   ___
/\  _`\   __            /\  _`\                /\_ \
\ \ \L\ \/\_\  _____    \ \ \/\_\  __  __  _ __\//\ \
 \ \ ,  /\/\ \/\ '__`\   \ \ \/_/_/\ \/\ \/\`'__\\ \ \
  \ \ \\ \\ \ \ \ \L\ \   \ \ \L\ \ \ \_\ \ \ \/  \_\ \_
   \ \_\ \_\ \_\ \ ,__/    \ \____/\ \____/\ \_\  /\____\
    \/_/\/ /\/_/\ \ \/      \/___/  \/___/  \/_/  \/____/
                 \ \_\
                  \/_/

*****************************/

@charset "UTF-8";

@use 'sass:math';

// Sass Plugins
@import "compass/reset";
@import "compass/support";
@import "compass/utilities/general/clearfix";
@import "compass/css3";
@import "compass/breakpoint";

// Variables
$tabletAll: 0 1550px;
$tabletPortrait: 0 880px;
$tabletLandscape: 881px 1550px;
$desktop: 1551px;
$base-font-size: 13px;
$base-font: 'Arial';
$font-light: 'Stag Sans Light', Arial;
$font-thin: 'Stag Sans Thin', Arial;
$font-medium: 'Stag Sans Medium', Arial;
$primary: #DF1B00;
$hover: #C81800;

// Mixins
@mixin all-transitions {
    -webkit-transition: all 0.2s ease-in-out;
    -o-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
}

@mixin remove-all-transitions {
    -webkit-transition: all 0s ease-in-out;
    -o-transition: all 0s ease-in-out;
    transition: all 0s ease-in-out;
}

@mixin image2x($image, $width, $height) {
    @media (min--moz-device-pixel-ratio: 1.3), (-o-min-device-pixel-ratio: 2.6/2), (-webkit-min-device-pixel-ratio: 1.3), (min-device-pixel-ratio: 1.3), (min-resolution: 1.3dppx) {
        background-image: url($image);
        background-size: $width $height;
    }
}

@mixin button {
    background: $primary;
    width: 100%;
    height: 48px;
    color: #FFF;
    text-align: center;
    text-transform: uppercase;
    font-size: em(16px);
    font-family: $font_light;
    border-radius: 3px;
    border: none;
    border-bottom: 2px solid #A71400;
    cursor: pointer;
    line-height: 48px;
    outline: none;

    &:hover {
    	background: $hover;
    }
}

@mixin clearfix {
    zoom: 1;

    &:before, &:after {
        content: "\0020";
        display: block;
        height: 0;
        overflow: hidden;
    }

    &:after {
        clear: both;
    }
}

@mixin select {
    background: #FFF url('../images/icon-dropdown.png') top 50% right 10px no-repeat;
    @include image2x('../images/icon-dropdown@2x.png', 11px, 8px);
    border: none;
    width: 100%;
    height: 50px;
    margin-bottom: 10px;
    -webkit-appearance: none;
    outline: none;
    cursor: pointer;
    text-indent: 12px;
    color: #B8B8B8;
    border-radius: 3px;
}

// Functions
@function em($target, $context: $base-font-size) {

	@if $target == 0 {
		@return 0;
	}

	@return math.div($target, $context) + 0em;
}

// Fonts
@font-face {
	font-family: 'Stag Sans Light';
	src: url('../fonts/Stag Sans-Light.ttf');
	src: local('☺'), url('../fonts/Stag Sans-Light.woff') format('woff'), url('../fonts/Stag Sans-Light.ttf') format('truetype'), url('../fonts/Stag Sans-Light.svg') format('svg');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'Stag Sans Thin';
	src: url('../fonts/Stag Sans-Thin.ttf');
	src: local('☺'), url('../fonts/Stag Sans-Thin.woff') format('woff'), url('../fonts/Stag Sans-Thin.ttf') format('truetype'), url('../fonts/Stag Sans-Thin.svg') format('svg');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'Stag Sans Medium';
	src: url('../fonts/Stag-Sans-Medium.ttf');
	src: local('☺'), url('../fonts/Stag-Sans-Medium.woff') format('woff'), url('../fonts/Stag-Sans-Medium.ttf') format('truetype'), url('../fonts/Stag-Sans-Medium.svg') format('svg');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'HelveticaNeueBlackCon';
	src: url('../fonts/HelveticaNeueLTPro-BlkCn.ttf');
	src: local('☺'), url('../fonts/HelveticaNeueLTPro-BlkCn.woff') format('woff'), url('../fonts/HelveticaNeueLTPro-BlkCn.ttf') format('truetype'), url('../fonts/HelveticaNeueLTPro-BlkCn.svg') format('svg');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'HelveticaNeueBoldCon';
	src: url('../fonts/HelveticaNeueLTPro-BdCn.ttf');
	src: local('☺'), url('../fonts/HelveticaNeueLTPro-BdCn.woff') format('woff'), url('../fonts/HelveticaNeueLTPro-BdCn.ttf') format('truetype'), url('../fonts/HelveticaNeueLTPro-BdCn.svg') format('svg');
	font-weight: normal;
	font-style: normal;
}

@font-face {
  font-family: 'Stag Sans Light';
  src: url("../fonts/Stag Sans-Light.ttf?#iefix"), format('embedded-opentype'), url("../fonts/Stag Sans-Light.woff") format("woff"), url("../fonts/Stag Sans-Light.ttf") format("truetype"), url("../fonts/Stag Sans-Light.svg") format("svg");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Stag Sans Thin';
  src: url("../fonts/Stag Sans-Thin.ttf?#iefix") format('embedded-opentype'), url("../fonts/Stag Sans-Thin.woff") format("woff"), url("../fonts/Stag Sans-Thin.ttf") format("truetype"), url("../fonts/Stag Sans-Thin.svg") format("svg");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Stag Sans Medium';
  src: url("../fonts/Stag-Sans-Medium.ttf?#iefix"), format('embedded-opentype'), url("../fonts/Stag-Sans-Medium.woff") format("woff"), url("../fonts/Stag-Sans-Medium.ttf") format("truetype"), url("../fonts/Stag-Sans-Medium.svg") format("svg");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'HelveticaNeueBlackCon';
  src: url("../fonts/HelveticaNeueLTPro-BlkCn.ttf?#iefix"), format('embedded-opentype'), url("../fonts/HelveticaNeueLTPro-BlkCn.woff") format("woff"), url("../fonts/HelveticaNeueLTPro-BlkCn.ttf") format("truetype"), url("../fonts/HelveticaNeueLTPro-BlkCn.svg") format("svg");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'HelveticaNeueBoldCon';
  src: url("../fonts/HelveticaNeueLTPro-BdCn.ttf?#iefix"), format('embedded-opentype'), url("../fonts/HelveticaNeueLTPro-BdCn.woff") format("woff"), url("../fonts/HelveticaNeueLTPro-BdCn.ttf") format("truetype"), url("../fonts/HelveticaNeueLTPro-BdCn.svg") format("svg");
  font-weight: normal;
  font-style: normal;
}
