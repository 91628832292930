html {
    background: #232528 url('../images/bg-global-nav.png') repeat-y;

    &#page-welcome {
        background: none;
    }
}

html, body {
    height: 100%;
}

body {
    overflow: hidden;
    font-family: Arial;
    font-size: $base-font-size;

    &.page-profile,
    &.page-watch,
    &.page-settings {
        overflow-y: auto;
    }
}

textarea {
    resize: none;
}

select {
    @include select;
}
