/****************************

 ____                    ____                   ___      
/\  _`\   __            /\  _`\                /\_ \     
\ \ \L\ \/\_\  _____    \ \ \/\_\  __  __  _ __\//\ \    
 \ \ ,  /\/\ \/\ '__`\   \ \ \/_/_/\ \/\ \/\`'__\\ \ \   
  \ \ \\ \\ \ \ \ \L\ \   \ \ \L\ \ \ \_\ \ \ \/  \_\ \_ 
   \ \_\ \_\ \_\ \ ,__/    \ \____/\ \____/\ \_\  /\____\
    \/_/\/ /\/_/\ \ \/      \/___/  \/___/  \/_/  \/____/
                 \ \_\                                   
                  \/_/

*****************************/

@import "base";

.rateit {
    position: relative;
    display: inline-block;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -o-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-touch-callout: none;
    cursor: pointer;

    .rateit-range {
        position: relative;
        display: -moz-inline-box;
        display: inline-block;
        background: url('../images/icon-star-full.png') 0 0;
        @include image2x('../images/icon-star-full@2x.png', 19px, 34px);
        height: 17px;
        outline: none;

        * {
            display:block;
        }
    }

    .rateit-hover,
    .rateit-selected {
        position: absolute;
        left: 0px;
    }

    .rateit-hover {
        background: url('../images/icon-star-full.png') left -17px;
        @include image2x('../images/icon-star-full@2x.png', 19px, 34px);
    }

    .rateit-selected {
        background: url('../images/icon-star-full.png') left -17px;
        @include image2x('../images/icon-star-full@2x.png', 19px, 34px);
    }


    .rateit-preset {
        background: url('../images/icon-star-full.png') left -17px;
        @include image2x('../images/icon-star-full@2x.png', 19px, 34px);
    }

    button.rateit-reset {
        display: none !important;
    }

    button.rateit-reset:hover,
    button.rateit-reset:focus {
        background-position: 0 -17px;
    }
}