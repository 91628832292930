.ios-switch {
  width: 58px;
  height: 25px;
  position: relative;
  background-color: #E5E5E5;
  border-radius: 100px;
  backface-visibility: hidden;

  .label {
    color: #FFF;
    font-size: 11px;
    position: absolute;
    top: 8px;
    left: 8px;
  }

  &.on {
    background: #6297C8;
  }
}

.ios-switch .background-fill {
  width: 100%;
  height: 100%;
  border-radius: 100px;
  position: absolute;
  left: 0;
  top: 0;
}

.ios-switch .state-background {
  border-radius: 100px;
  z-index: 2;
  background: #BEBEBE;
  -o-box-sizing: border-box;
  -ms-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -o-transform: scale(1);
  -ms-transform: scale(1);
  -moz-transform: scale(1);
  -webkit-transform: scale(1);
  transform: scale(1);
  -o-transition: .4s;
  -ms-transition: .4s;
  -moz-transition: .4s;
  -webkit-transition: .4s;
  transition: .4s;

  .label {
    right: 8px;
    left: auto;
  }
}

.ios-switch .handle {
  width: 23px;
  height: 23px;
  background: #464A4C;
  cursor: pointer;
  top: 1px;
  left: 1px;
  position: absolute;
  border-radius: 20px;
  z-index: 3;
  -o-transition: -o-transform .3s 0.25s cubic-bezier(0.455, 0.030, 0.215, 1.330);
  -ms-transition: -ms-transform .3s 0.25s cubic-bezier(0.455, 0.030, 0.215, 1.330);
  -moz-transition: -moz-transform .3s 0.25s cubic-bezier(0.455, 0.030, 0.215, 1.330);
  -webkit-transition: -webkit-transform .3s 0.25s cubic-bezier(0.455, 0.030, 0.215, 1.330);
  -o-transition: transform .3s 0.25s cubic-bezier(0.455, 0.030, 0.215, 1.330);
  -ms-transition: transform .3s 0.25s cubic-bezier(0.455, 0.030, 0.215, 1.330);
  -moz-transition: transform .3s 0.25s cubic-bezier(0.455, 0.030, 0.215, 1.330);
  -webkit-transition: transform .3s 0.25s cubic-bezier(0.455, 0.030, 0.215, 1.330);
  transition: transform .3s 0.25s cubic-bezier(0.455, 0.030, 0.215, 1.330);
}

.ios-switch.off .handle {
  -o-animation: expand-off .3s 0.2s;
  -ms-animation: expand-off .3s 0.2s;
  -moz-animation: expand-off .3s 0.2s;
  -webkit-animation: expand-off .3s 0.2s;
  animation: expand-off .3s 0.2s;
  -o-transform: translate3d(0px, 0, 0);
  -ms-transform: translate3d(0px, 0, 0);
  -moz-transform: translate3d(0px, 0, 0);
  -webkit-transform: translate3d(0px, 0, 0);
  transform: translate3d(0px, 0, 0);
}

.ios-switch.off .on-background {
  -o-transition: .3s 0s;
  -ms-transition: .3s 0s;
  -moz-transition: .3s 0s;
  -webkit-transition: .3s 0s;
  transition: .3s 0s;
}

.ios-switch.off .state-background {
  -o-transition: .4s 0.25s;
  -ms-transition: .4s 0.25s;
  -moz-transition: .4s 0.25s;
  -webkit-transition: .4s 0.25s;
  transition: .4s 0.25s;
}

.ios-switch.on .handle {
  -o-animation: expand-on .3s 0.2s;
  -ms-animation: expand-on .3s 0.2s;
  -moz-animation: expand-on .3s 0.2s;
  -webkit-animation: expand-on .3s 0.2s;
  animation: expand-on .3s 0.2s;
  -o-transform: translate3d(33px, 0, 0);
  -ms-transform: translate3d(33px, 0, 0);
  -moz-transform: translate3d(33px, 0, 0);
  -webkit-transform: translate3d(33px, 0, 0);
  transform: translate3d(33px, 0, 0);
}

.ios-switch.on .on-background {
  opacity: 1;
  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
  filter: alpha(opacity=100);
}

.ios-switch.on .state-background {
  -o-transform: scale(0);
  -ms-transform: scale(0);
  -moz-transform: scale(0);
  -webkit-transform: scale(0);
  transform: scale(0);
}

@keyframes expand-on {
  0% {
    width: 23px;
  }

  40% {
    width: 23px;
  }

  100% {
    width: 23px;
  }
}

@keyframes expand-off {
  0% {
    width: 23px;
    left: 1px;
  }

  40% {
    width: 23px;
    left: 1px;
  }

  100% {
    width: 23px;
    left: 1px;
  }
}

@-o-keyframes expand-on {
  0% {
    width: 23px;
  }

  40% {
    width: 23px;
  }

  100% {
    width: 23px;
  }
}

@-ms-keyframes expand-on {
  0% {
    width: 23px;
  }

  40% {
    width: 23px;
  }

  100% {
    width: 23px;
  }
}

@-moz-keyframes expand-on {
  0% {
    width: 23px;
  }

  40% {
    width: 23px;
  }

  100% {
    width: 23px;
  }
}

@-webkit-keyframes expand-on {
  0% {
    width: 23px;
  }

  40% {
    width: 23px;
  }

  100% {
    width: 23px;
  }
}

@-o-keyframes expand-off {
  0% {
    width: 23px;
    left: 1px;
  }

  40% {
    width: 23px;
    left: 1px;
  }

  100% {
    width: 23px;
    left: 1px;
  }
}

@-ms-keyframes expand-off {
  0% {
    width: 23px;
    left: 1px;
  }

  40% {
    width: 23px;
    left: 1px;
  }

  100% {
    width: 23px;
    left: 1px;
  }
}

@-moz-keyframes expand-off {
  0% {
    width: 23px;
    left: 1px;
  }

  40% {
    width: 23px;
    left: 1px;
  }

  100% {
    width: 23px;
    left: 1px;
  }
}

@-webkit-keyframes expand-off {
  0% {
    width: 23px;
    left: 1px;
  }

  40% {
    width: 23px;
    left: 1px;
  }

  100% {
    width: 23px;
    left: 1px;
  }
}